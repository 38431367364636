import { sendDeleteRequest, sendGetRequest, sendPutRequest } from '../client/FetchBaseClient';
import { FrontendResponse } from '../client/FrontendResponse';
import { OfferEngineAngebotDto, Rollentyp } from './OfferEngineAngebotDto';
import { Dispatch } from 'redux';
import { OfferEngineFahrzeugdatenRequestDto } from './OfferEngineFahrzeugdatenRequestDto';
import { Fahrzeug, Typklasse } from '../fahrzeugsuche/FahrzeugsucheDto';
import { UpdateFahrzeugNutzerRequestDto } from './UpdateFahrzeugNutzerRequestDto';
import { UpdateAmtlichesKennzeichenRequestDto } from './UpdateFahrzeugAmtlichesKennzeichenRequestDto';
import { UpdateFahrzeugRequestDto } from './UpdateFahrzeugRequestDto';
import { OfferEngineBankverbindungRequestDto } from './OfferEngineBankverbindungRequestDto';
import { OfferEngineAngebotsgrundRequestDto } from './OfferEngineAngebotsgrundRequestDto';
import { OfferEngineSfrErstwagenErgoZweitwagenDto } from './OfferEngineSfrEinstufungRequestDto';
import { UpdateVorversicherungRequestDto } from './UpdateVorversicherungRequestDto';
import { OfferEngineVersicherungenRequestDto } from './OfferEngineVersicherungenRequestDto';
import { OfferEngineVertraegeRequestDto, OfferEngineVertraegeSfrRequestDto } from './OfferEngineVertraegeRequestDto';
import { OfferEngineLeistungsvereinbarungenRequestDto } from './OfferEngineLeistungsvereinbarungenRequestDto';
import { UpdateTopLevelRequestDto } from './UpdateTopLevelRequestDto';
import { UpdateOrtHalterRequestDto } from './UpdateOrtHalterRequestDto';
import { OfferEngineEvbRequestDto } from './OfferEngineEvbRequestDto';

export const getAngebotHttpRequest = (businessId: string, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendGetRequest(`/kfz2019/angebot/${businessId}/`, dispatch);
};

export const updateFahrzeugdatenHttpRequest = (
    businessId: string,
    fahrzeug: Fahrzeug,
    typklasse: Typklasse,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    const payload: OfferEngineFahrzeugdatenRequestDto = {
        versichertesKfz: {
            elektrofahrzeug: fahrzeug.istElektro ? fahrzeug.istElektro : false,
            herstellerBezeichnung: fahrzeug.hersteller,
            fahrzeugtypBezeichnung: fahrzeug.typ,
            hsn: fahrzeug.hsn,
            tsn: fahrzeug.tsn,
            kilowatt: fahrzeug.leistungKW,
            ps: fahrzeug.leistungPS,
            typklasseHaftpflicht: typklasse.hp,
            typklasseTeilkasko: typklasse.tk,
            typklasseVollkasko: typklasse.vk
        }
    };

    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateAngebotsgrundHttpRequest = (
    businessId: string,
    payload: OfferEngineAngebotsgrundRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateSfrEinstufungHttpRequest = (
    businessId: string,
    payload: OfferEngineSfrErstwagenErgoZweitwagenDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateNutzerHttpRequest = (businessId: string, payload: UpdateFahrzeugNutzerRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateAmtlichesKennzeichenHttpRequest = (
    businessId: string,
    payload: UpdateAmtlichesKennzeichenRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateFahrzeugHttpRequest = (businessId: string, payload: UpdateFahrzeugRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateBankverbindungHttpRequest = (
    businessId: string,
    payload: OfferEngineBankverbindungRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateVersicherungenHttpRequest = (
    businessId: string,
    payload: OfferEngineVersicherungenRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateVertraegeHttpRequest = (
    businessId: string,
    payload: OfferEngineVertraegeRequestDto | OfferEngineVertraegeSfrRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateLeistungsvereinbarungHttpRequest = (
    businessId: string,
    payload: OfferEngineLeistungsvereinbarungenRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateVorversicherungHttpRequest = (
    businessId: string,
    payload: OfferEngineSfrErstwagenErgoZweitwagenDto | UpdateVorversicherungRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const deleteRolleHttpRequest = (businessId: string, pdsId: string, rolle: Rollentyp, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendDeleteRequest(`/kfz2019/angebot/${businessId}/rollen/${pdsId}/rollentyp/${rolle}/`, dispatch);
};

export const updateAngebotTopLevelHttpRequest = (businessId: string, payload: UpdateTopLevelRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};

export const updateOrtHalterHttpRequest = (businessId: string, payload: UpdateOrtHalterRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-orthalter/`, payload, dispatch);
};

export const updateEvbHttpRequest = (
    businessId: string,
    payload: OfferEngineEvbRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfz2019/angebot/${businessId}/update-angebot/`, payload, dispatch);
};