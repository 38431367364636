import React, { FormEvent } from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import SelectRow from '@eg/elements/SelectRow';
import DatePickerRow from '@eg/elements/DatePickerRow';
import Checkbox from '@eg/elements/Checkbox';
import FormRow from '@eg/elements/FormRow';
import RadioTile from '@eg/elements/RadioTile';
import { EVB } from '../evb/Evb';
import { BITTE_WAEHLEN, createOptions, createOptionsFromWertebereichAngebot, getWertebereichElementName, WertebereichLokal } from '../util/WertebereicheHelper';
import { Ersteinstufungsservice } from '../ersteinstufungsservice/Ersteinstufungsservice';
import {
    wertebereichDeckungsschluessel_TK,
    wertebereichDeckungsschluessel_VK,
    wertebereichLeistungsvereinbarung,
    wertebereichSfkHaftpflicht,
    wertebereichSfkVollkasko,
    wertebereichVertragsId,
    wertebereichZahlweiseMapping
} from './VersicherungsschutzWertebereiche';
import { Versicherung, VertragsBeitrag, VertragState } from './VersicherungsschutzReducer';
import {
    Deckungsschluessel,
    Deckungsumfang,
    Leistungsvereinbarung,
    Leistungsvereinbarungsart,
    Schadenfreiheitsklasse,
    TypVertrag,
    VersicherungsId,
    VertragsId
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import {
    updateDeckungsschluesselAsync,
    updateDeckungsumfangVollkaskoAsync,
    updateLeistungsvereinbarungAsync,
    updateSchadenfreiheitsklasseAsync,
    updateSelectVersicherungAsync,
    updateVersicherungFieldAsync
} from './VersicherungsschutzAction';
import Price from '@eg/elements/Price';
import { CheckboxEvent, SelectEvent } from '../util/UiEventTypes';
import LoadingSpinner from '@eg/elements/LoadingSpinner';

interface VersicherungsschutzPresentationProps {
    businessId: string;
    versicherung: Versicherung;
    vertraege: VertragState[];
    showLoadingSpinner: boolean;
    ersteinstufungDisplayed: boolean;
    oberflaecheSperren: boolean;
    handleChangeVersicherungField: (businessId: string, fieldName: string, fieldValue: string | Date) => void;
    handleChangeDeckungsumfangVollkasko: (businessId: string, deckungsumfang: Deckungsumfang) => void;
    handleChangeSchadenfreiheitsklasse: (
        businessId: string,
        schadenfreiheitsklasse: Schadenfreiheitsklasse,
        deckungsUmfang: Deckungsumfang,
        vertragsId: VertragsId,
        typ: TypVertrag
    ) => void;
    handleChangeDeckungsschluessel: (businessId: string, deckungsschluessel: Deckungsschluessel, deckungsUmfang: Deckungsumfang, vertragsId: VertragsId, typ: TypVertrag) => void;
    handleChangeLeistungsvereinbarungSelection: (
        businessId: string,
        versicherungsId: VersicherungsId,
        leistungsvereinbarungsart: Leistungsvereinbarungsart,
        checked: boolean
    ) => void;
    handleSelectVersicherung: (businessId: string, versicherungsId: VersicherungsId, berechenbar: boolean) => void;
}

export class VersicherungsschutzPresentation extends React.Component<VersicherungsschutzPresentationProps> {
    render() {
        const {
            businessId,
            versicherung,
            vertraege,
            showLoadingSpinner,
            ersteinstufungDisplayed,
            oberflaecheSperren,
            handleChangeVersicherungField,
            handleChangeDeckungsumfangVollkasko,
            handleChangeSchadenfreiheitsklasse,
            handleChangeDeckungsschluessel,
            handleChangeLeistungsvereinbarungSelection,
            handleSelectVersicherung
        } = this.props;

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Versicherungsschutz</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper versicherungsschutz">
                        <div className="esc_col esc_col-s-12 esc_col-m-6 esc_col-l-4">
                            <SelectRow
                                label="Haftpflicht Schadenfreiheitsklasse(SF)"
                                value={versicherung.sfkHaftpflicht}
                                onChange={(e: SelectEvent) =>
                                    handleChangeSchadenfreiheitsklasse(
                                        businessId,
                                        e.target.value as Schadenfreiheitsklasse,
                                        versicherung.deckungsumfang,
                                        VertragsId.HAFTPFLICHT,
                                        TypVertrag.haftpflicht
                                    )
                                }
                                disabled={oberflaecheSperren || ersteinstufungDisplayed}
                            >
                                <option value={BITTE_WAEHLEN}>Bitte wählen</option>
                                {versicherung.wertebereichSfkHaftpflicht.werte.length > 0
                                    ? createOptionsFromWertebereichAngebot(versicherung.wertebereichSfkHaftpflicht.werte as string[], wertebereichSfkHaftpflicht)
                                    : createOptions(wertebereichSfkHaftpflicht.filter((wert: WertebereichLokal) => wert.value !== BITTE_WAEHLEN))}
                            </SelectRow>
                            <DatePickerRow
                                label="Versicherungsbeginn"
                                disabled={oberflaecheSperren}
                                valueDate={versicherung.versicherungsbeginn}
                                minDate={versicherung.wertebereichVersicherungsbeginn.min}
                                maxDate={versicherung.wertebereichVersicherungsbeginn.max}
                                onChange={(e: FormEvent, date: Date) => (date ? handleChangeVersicherungField(businessId, 'versicherungsbeginn', date) : null)}
                            />
                            <DatePickerRow
                                label="Wann soll das erste Versicherungsjahr enden?"
                                disabled={oberflaecheSperren}
                                defaultDate={versicherung.wertebereichVersicherungsende.max}
                                valueDate={versicherung.versicherungsende}
                                onChange={(e: FormEvent, date: Date) => (date ? handleChangeVersicherungField(businessId, 'versicherungsende', date) : null)}
                                minDate={versicherung.wertebereichVersicherungsende.min}
                                maxDate={versicherung.wertebereichVersicherungsende.max}
                                filterDate={(date: Date) => date.getDate() === 1}
                            />
                            <SelectRow
                                label="Zahlweise"
                                disabled={oberflaecheSperren}
                                value={versicherung.zahlweise}
                                onChange={(e: SelectEvent) => handleChangeVersicherungField(businessId, 'zahlweise', e.target.value)}
                            >
                                {createOptionsFromWertebereichAngebot(versicherung.wertebereichZahlweise.werte as string[], wertebereichZahlweiseMapping)}
                            </SelectRow>
                        </div>
                        <div className="esc_col esc_col-s-12 esc_col-m-6 esc_col-l-4">
                            <FormRow label="Kasko">
                                <div className="esc_grid">
                                    <div className="esc_grid__wrapper">
                                        <div className="esc_col esc_col-s-12 checkbox-distance">
                                            <Checkbox
                                                label="Vollkasko"
                                                disabled={oberflaecheSperren}
                                                checked={versicherung.deckungsumfang === Deckungsumfang.HAFTPFLICHT_VOLLKASKO}
                                                onChange={(e: CheckboxEvent) =>
                                                    handleChangeDeckungsumfangVollkasko(
                                                        businessId,
                                                        e.currentTarget.checked ? Deckungsumfang.HAFTPFLICHT_VOLLKASKO : Deckungsumfang.HAFTPFLICHT
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="esc_col esc_col-s-12 checkbox-distance">
                                            <Checkbox
                                                label="Teilkasko"
                                                disabled={oberflaecheSperren}
                                                checked={versicherung.deckungsumfang === Deckungsumfang.HAFTPFLICHT_TEILKASKO}
                                                onChange={(e: CheckboxEvent) =>
                                                    handleChangeVersicherungField(
                                                        businessId,
                                                        'deckungsumfang',
                                                        e.currentTarget.checked ? Deckungsumfang.HAFTPFLICHT_TEILKASKO : Deckungsumfang.HAFTPFLICHT
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormRow>
                            {versicherung.deckungsumfang === Deckungsumfang.HAFTPFLICHT_VOLLKASKO && (
                                <div>
                                    <SelectRow
                                        label="Kasko(SF)"
                                        value={versicherung.sfkVollkasko}
                                        onChange={(e: SelectEvent) =>
                                            handleChangeSchadenfreiheitsklasse(
                                                businessId,
                                                e.target.value as Schadenfreiheitsklasse,
                                                versicherung.deckungsumfang,
                                                VertragsId.KASKO,
                                                TypVertrag.vollkasko
                                            )
                                        }
                                        disabled={oberflaecheSperren || ersteinstufungDisplayed}
                                    >
                                        <option value={BITTE_WAEHLEN}>Bitte wählen</option>
                                        {versicherung.wertebereichSfkVollkasko.werte.length > 0
                                            ? createOptionsFromWertebereichAngebot(versicherung.wertebereichSfkVollkasko.werte as string[], wertebereichSfkVollkasko)
                                            : createOptions(wertebereichSfkVollkasko.filter((wert: WertebereichLokal) => wert.value !== BITTE_WAEHLEN))}
                                    </SelectRow>
                                    <SelectRow
                                        label="Selbstbeteiligung VK"
                                        disabled={oberflaecheSperren}
                                        value={versicherung.selbstbeteiligungVollkasko}
                                        onChange={(e: SelectEvent) =>
                                            handleChangeDeckungsschluessel(
                                                businessId,
                                                e.target.value as Deckungsschluessel,
                                                versicherung.deckungsumfang,
                                                VertragsId.KASKO,
                                                TypVertrag.vollkasko
                                            )
                                        }
                                    >
                                        {/*Todo: Wertebereich von OE übernehmen, sobald er richtig gesetzt wurde --> Story: EKFZ-3127
                                        versicherung.wertebereichDeckungsschluesselVollkasko.werte.length > 0
                                            ? createOptionsFromWertebereichAngebot(versicherung.wertebereichDeckungsschluesselVollkasko.werte as string[])
                                            : createOptions(wertebereichDeckungsschluessel_VK)*/}
                                        {createOptions(wertebereichDeckungsschluessel_VK)}
                                    </SelectRow>
                                </div>
                            )}
                            {versicherung.deckungsumfang === Deckungsumfang.HAFTPFLICHT_TEILKASKO && (
                                <SelectRow
                                    label="Selbstbeteiligung TK"
                                    disabled={oberflaecheSperren}
                                    value={versicherung.selbstbeteiligungTeilkasko}
                                    onChange={(e: SelectEvent) =>
                                        handleChangeDeckungsschluessel(
                                            businessId,
                                            e.target.value as Deckungsschluessel,
                                            versicherung.deckungsumfang,
                                            VertragsId.KASKO,
                                            TypVertrag.teilkasko
                                        )
                                    }
                                >
                                    {/*Todo: Wertebereich von OE übernehmen, sobald er richtig gesetzt wurde --> Story: EKFZ-3127
                                    versicherung.wertebereichDeckungsschluesselTeilkasko.werte.length > 0
                                        ? createOptionsFromWertebereichAngebot(versicherung.wertebereichDeckungsschluesselTeilkasko.werte as string[])
                                        : createOptions(wertebereichDeckungsschluessel_TK)*/}
                                    {createOptions(wertebereichDeckungsschluessel_TK)}
                                </SelectRow>
                            )}
                        </div>
                        <div className="esc_col esc_col-s-12 esc_col-m-6 esc_col-l-4">
                            <Ersteinstufungsservice />
                        </div>
                        <div className="esc_col esc_col-l-12 margin-bottom-15" >
                            {/* for the UI to display the evb radio field */}
                            <EVB />
                        </div>
                        <div className="esc-col esc_col-s-9">
                            <div className="esc_grid">
                                <div className="esc_grid__wrapper">
                                    {vertraege.map((vertrag: VertragState) => (
                                        <div key={vertrag.versicherungsId} className="esc_col esc_col-s-6">
                                            <RadioTile
                                                title={vertrag.versicherungsId}
                                                disabled={oberflaecheSperren}
                                                checked={vertrag.ausgewaehlt}
                                                onChange={() => handleSelectVersicherung(businessId, vertrag.versicherungsId, vertrag.berechenbar)}
                                                className="capitalize"
                                            >
                                                {vertrag.vertragsBeitraege.map((vertragsBeitrag: VertragsBeitrag) => (
                                                    <div key={vertragsBeitrag.vertragsId}>
                                                        <Price
                                                            value={vertragsBeitrag.beitrag}
                                                            prefix={getWertebereichElementName(wertebereichVertragsId, vertragsBeitrag.vertragsId)}
                                                        />
                                                    </div>
                                                ))}
                                                <br />
                                                {vertrag.leistungsvereinbarungen.map((leistungsvereinbarung: Leistungsvereinbarung) => (
                                                    <div key={leistungsvereinbarung.leistungsvereinbarungsart}>
                                                        <Checkbox
                                                            label={getWertebereichElementName(wertebereichLeistungsvereinbarung, leistungsvereinbarung.leistungsvereinbarungsart)}
                                                            checked={leistungsvereinbarung.obligatorisch || leistungsvereinbarung.vereinbart}
                                                            disabled={oberflaecheSperren || leistungsvereinbarung.obligatorisch || !leistungsvereinbarung.auswaehlbar}
                                                            onChange={(e: CheckboxEvent) =>
                                                                handleChangeLeistungsvereinbarungSelection(
                                                                    businessId,
                                                                    vertrag.versicherungsId,
                                                                    leistungsvereinbarung.leistungsvereinbarungsart,
                                                                    e.target.checked
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                                <Price value={vertrag.BeitragGesamt} prefix="Beitrag" />
                                            </RadioTile>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <LoadingSpinner viewport="relative" show={showLoadingSpinner} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    businessId: state.basisdaten.businessId,
    versicherung: state.versicherungsschutz.versicherung,
    vertraege: state.versicherungsschutz.vertraege,
    showLoadingSpinner: state.versicherungsschutz.showVersicherungsschutzLoadingSpinner,
    ersteinstufungDisplayed: state.ersteinstufung.display,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleChangeVersicherungField: (businessId: string, fieldName: string, fieldValue: string | Date) => {
        // @ts-ignore
        dispatch(updateVersicherungFieldAsync(businessId, fieldName, fieldValue));
    },
    handleChangeDeckungsumfangVollkasko: (businessId: string, deckungsumfang: Deckungsumfang) => {
        // @ts-ignore
        dispatch(updateDeckungsumfangVollkaskoAsync(businessId, deckungsumfang));
    },
    handleChangeSchadenfreiheitsklasse: (
        businessId: string,
        schadensfreiheitsklasse: Schadenfreiheitsklasse,
        deckungsUmfang: Deckungsumfang,
        vertragsId: VertragsId,
        typ: TypVertrag
    ) => {
        dispatch(
            // @ts-ignore
            updateSchadenfreiheitsklasseAsync(
                businessId,
                BITTE_WAEHLEN === schadensfreiheitsklasse.toString() ? undefined : schadensfreiheitsklasse,
                deckungsUmfang,
                vertragsId,
                typ
            )
        );
    },
    handleChangeDeckungsschluessel: (businessId: string, deckungsschluessel: Deckungsschluessel, deckungsUmfang: Deckungsumfang, vertragsId: VertragsId, typ: TypVertrag) => {
        // @ts-ignore
        dispatch(updateDeckungsschluesselAsync(businessId, deckungsschluessel, deckungsUmfang, vertragsId, typ));
    },
    handleChangeLeistungsvereinbarungSelection: (businessId: string, versicherungsId: VersicherungsId, leistungsvereinbarungsart: Leistungsvereinbarungsart, checked: boolean) => {
        // @ts-ignore
        dispatch(updateLeistungsvereinbarungAsync(businessId, versicherungsId, leistungsvereinbarungsart, checked));
    },
    handleSelectVersicherung: (businessId: string, versicherungsId: VersicherungsId, berechenbar: boolean) => {
        if (berechenbar) {
            //@ts-ignore
            dispatch(updateSelectVersicherungAsync(businessId, versicherungsId));
        }
    }
});

export const Versicherungsschutz = connect(mapStateToProps, mapDispatchToProps)(VersicherungsschutzPresentation);
