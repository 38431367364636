export const sepaIbans: Record<string, number> = {
    AD:    24,
    AT:    20,
    BE:    16,
    BG:    22,
    HR:    21,
    CH:    21,
    CY:    28,
    CZ:    24,
    DE:    22,
    DK:    18,
    EE:    20,
    ES:    24,
    FI:    18,
    FR:    27,
    GB:    22,
    GR:    27,
    HU:    28,
    IE:    22,
    IS:    26,
    IT:    27,
    LI:    21,
    LT:    20,
    LU:    20,
    LV:    21,
    MC:    27,
    MT:    31,
    NL:    18,
    NO:    15,
    PL:    28,
    PT:    25,
    RO:    24,
    SE:    24,
    SI:    19,
    SK:    24,
    SM:    27,
    VA:    22,
};

const checkErrorProperties = {
    IBAN_OUTSIDE_SEPA_ERR_MSG: 'Bitte geben Sie eine IBAN von einer Bank aus dem SEPA-Zahlungsraum an.',
    INCORRECT_ERR_MSG: 'Bitte geben Sie eine gültige IBAN ein.',
    IBAN_PREFIX_LENGTH: 2
}

export const checkIbanError = (iban: string) => {
    if (iban.length > 0) {
        const countryCode = iban.substring(0, checkErrorProperties.IBAN_PREFIX_LENGTH).toUpperCase();
        if (iban.length > 1 && !sepaIbans[countryCode]) {
            return checkErrorProperties.IBAN_OUTSIDE_SEPA_ERR_MSG;
        } else {
            return iban.length < checkErrorProperties.IBAN_PREFIX_LENGTH || sepaIbans[countryCode] !== iban.length ? checkErrorProperties.INCORRECT_ERR_MSG : '';
        }
    }
    return '';
}