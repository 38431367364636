import { WertebereichLokal } from '../util/WertebereicheHelper';
import { Gekuendigt, Vorschaeden } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export const wertebereichVorschaeden: WertebereichLokal[] = [
    { value: Vorschaeden.EIN, name: 'Ein' },
    { value: Vorschaeden.KEINE, name: 'Keine' },
    { value: Vorschaeden.MEHR_ALS_ZWEI, name: 'Mehr als zwei' },
    { value: Vorschaeden.ZWEI, name: 'Zwei' }
];

export const wertebereichGekuendigt: WertebereichLokal[] = [
    { value: Gekuendigt.JA_VERSICHERUNGSNEHMER, name: 'Versicherungsnehmer' },
    { value: Gekuendigt.JA_VERSICHERUNGSUNTERNEHMEN, name: 'Versicherung' }
];
