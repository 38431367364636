import { BITTE_WAEHLEN, WertebereichLokal } from '../util/WertebereicheHelper';
import { Deckungsschluessel, Leistungsvereinbarungsart, Schadenfreiheitsklasse, VertragsId, Zahlweise } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export const wertebereichSfkHaftpflicht: WertebereichLokal[] = [
    { value: BITTE_WAEHLEN, name: BITTE_WAEHLEN },
    { value: Schadenfreiheitsklasse.KL_0, name: 'KL 0, 86 %' },
    { value: Schadenfreiheitsklasse.SF_1_2, name: 'SF 1/2, 66 %' },
    { value: Schadenfreiheitsklasse.SF_1, name: 'SF 1, 53 %' },
    { value: Schadenfreiheitsklasse.SF_2, name: 'SF 2, 50 %' },
    { value: Schadenfreiheitsklasse.SF_3, name: 'SF 3, 47 %' },
    { value: Schadenfreiheitsklasse.SF_4, name: 'SF 4, 44 %' },
    { value: Schadenfreiheitsklasse.SF_5, name: 'SF 5, 42 %' },
    { value: Schadenfreiheitsklasse.SF_6, name: 'SF 6, 40 %' },
    { value: Schadenfreiheitsklasse.SF_7, name: 'SF 7, 38 %' },
    { value: Schadenfreiheitsklasse.SF_8, name: 'SF 8, 36 %' },
    { value: Schadenfreiheitsklasse.SF_9, name: 'SF 9, 35 %' },
    { value: Schadenfreiheitsklasse.SF_10, name: 'SF 10, 33 %' },
    { value: Schadenfreiheitsklasse.SF_11, name: 'SF 11, 32 %' },
    { value: Schadenfreiheitsklasse.SF_12, name: 'SF 12, 31 %' },
    { value: Schadenfreiheitsklasse.SF_13, name: 'SF 13, 30 %' },
    { value: Schadenfreiheitsklasse.SF_14, name: 'SF 14, 29 %' },
    { value: Schadenfreiheitsklasse.SF_15, name: 'SF 15, 28 %' },
    { value: Schadenfreiheitsklasse.SF_16, name: 'SF 16, 27 %' },
    { value: Schadenfreiheitsklasse.SF_17, name: 'SF 17, 26 %' },
    { value: Schadenfreiheitsklasse.SF_18, name: 'SF 18, 26 %' },
    { value: Schadenfreiheitsklasse.SF_19, name: 'SF 19, 25 %' },
    { value: Schadenfreiheitsklasse.SF_20, name: 'SF 20, 24 %' },
    { value: Schadenfreiheitsklasse.SF_21, name: 'SF 21, 24 %' },
    { value: Schadenfreiheitsklasse.SF_22, name: 'SF 22, 23 %' },
    { value: Schadenfreiheitsklasse.SF_23, name: 'SF 23, 23 %' },
    { value: Schadenfreiheitsklasse.SF_24, name: 'SF 24, 22 %' },
    { value: Schadenfreiheitsklasse.SF_25, name: 'SF 25, 22 %' },
    { value: Schadenfreiheitsklasse.SF_26, name: 'SF 26, 21 %' },
    { value: Schadenfreiheitsklasse.SF_27, name: 'SF 27, 21 %' },
    { value: Schadenfreiheitsklasse.SF_28, name: 'SF 28, 21 %' },
    { value: Schadenfreiheitsklasse.SF_29, name: 'SF 29, 20 %' },
    { value: Schadenfreiheitsklasse.SF_30, name: 'SF 30, 20 %' },
    { value: Schadenfreiheitsklasse.SF_31, name: 'SF 31, 19 %' },
    { value: Schadenfreiheitsklasse.SF_32, name: 'SF 32, 19 %' },
    { value: Schadenfreiheitsklasse.SF_33, name: 'SF 33, 19 %' },
    { value: Schadenfreiheitsklasse.SF_34, name: 'SF 34, 18 %' },
    { value: Schadenfreiheitsklasse.SF_35, name: 'SF 35, 18 %' },
    { value: Schadenfreiheitsklasse.SF_36, name: 'SF 36, 18 %' },
    { value: Schadenfreiheitsklasse.SF_37, name: 'SF 37, 18 %' },
    { value: Schadenfreiheitsklasse.SF_38, name: 'SF 38, 17 %' },
    { value: Schadenfreiheitsklasse.SF_39, name: 'SF 39, 17 %' },
    { value: Schadenfreiheitsklasse.SF_40, name: 'SF 40, 17 %' },
    { value: Schadenfreiheitsklasse.SF_41, name: 'SF 41, 17 %' },
    { value: Schadenfreiheitsklasse.SF_42, name: 'SF 42, 16 %' },
    { value: Schadenfreiheitsklasse.SF_43, name: 'SF 43, 16 %' },
    { value: Schadenfreiheitsklasse.SF_44, name: 'SF 44, 16 %' },
    { value: Schadenfreiheitsklasse.SF_45, name: 'SF 45, 16 %' },
    { value: Schadenfreiheitsklasse.SF_46, name: 'SF 46, 16 %' },
    { value: Schadenfreiheitsklasse.SF_47, name: 'SF 47, 16 %' },
    { value: Schadenfreiheitsklasse.SF_48, name: 'SF 48, 15 %' },
    { value: Schadenfreiheitsklasse.SF_49, name: 'SF 49, 15 %' },
    { value: Schadenfreiheitsklasse.SF_50, name: 'SF 50, 15 %' }
];

export const wertebereichSfkVollkasko: WertebereichLokal[] = [
    { value: BITTE_WAEHLEN, name: BITTE_WAEHLEN },
    { value: Schadenfreiheitsklasse.KL_0, name: 'KL 0, 54 %' },
    { value: Schadenfreiheitsklasse.SF_1_2, name: 'SF 1/2, 49 %' },
    { value: Schadenfreiheitsklasse.SF_1, name: 'SF 1, 44 %' },
    { value: Schadenfreiheitsklasse.SF_2, name: 'SF 2, 42 %' },
    { value: Schadenfreiheitsklasse.SF_3, name: 'SF 3, 41 %' },
    { value: Schadenfreiheitsklasse.SF_4, name: 'SF 4, 39 %' },
    { value: Schadenfreiheitsklasse.SF_5, name: 'SF 5, 38 %' },
    { value: Schadenfreiheitsklasse.SF_6, name: 'SF 6, 37 %' },
    { value: Schadenfreiheitsklasse.SF_7, name: 'SF 7, 36 %' },
    { value: Schadenfreiheitsklasse.SF_8, name: 'SF 8, 34 %' },
    { value: Schadenfreiheitsklasse.SF_9, name: 'SF 9, 33 %' },
    { value: Schadenfreiheitsklasse.SF_10, name: 'SF 10, 33 %' },
    { value: Schadenfreiheitsklasse.SF_11, name: 'SF 11, 32 %' },
    { value: Schadenfreiheitsklasse.SF_12, name: 'SF 12, 31 %' },
    { value: Schadenfreiheitsklasse.SF_13, name: 'SF 13, 30 %' },
    { value: Schadenfreiheitsklasse.SF_14, name: 'SF 14, 29 %' },
    { value: Schadenfreiheitsklasse.SF_15, name: 'SF 15, 28 %' },
    { value: Schadenfreiheitsklasse.SF_16, name: 'SF 16, 28 %' },
    { value: Schadenfreiheitsklasse.SF_17, name: 'SF 17, 27 %' },
    { value: Schadenfreiheitsklasse.SF_18, name: 'SF 18, 27 %' },
    { value: Schadenfreiheitsklasse.SF_19, name: 'SF 19, 26 %' },
    { value: Schadenfreiheitsklasse.SF_20, name: 'SF 20, 25 %' },
    { value: Schadenfreiheitsklasse.SF_21, name: 'SF 21, 25 %' },
    { value: Schadenfreiheitsklasse.SF_22, name: 'SF 22, 24 %' },
    { value: Schadenfreiheitsklasse.SF_23, name: 'SF 23, 24 %' },
    { value: Schadenfreiheitsklasse.SF_24, name: 'SF 24, 23 %' },
    { value: Schadenfreiheitsklasse.SF_25, name: 'SF 25, 23 %' },
    { value: Schadenfreiheitsklasse.SF_26, name: 'SF 26, 23 %' },
    { value: Schadenfreiheitsklasse.SF_27, name: 'SF 27, 22 %' },
    { value: Schadenfreiheitsklasse.SF_28, name: 'SF 28, 22 %' },
    { value: Schadenfreiheitsklasse.SF_29, name: 'SF 29, 21 %' },
    { value: Schadenfreiheitsklasse.SF_30, name: 'SF 30, 21 %' },
    { value: Schadenfreiheitsklasse.SF_31, name: 'SF 31, 21 %' },
    { value: Schadenfreiheitsklasse.SF_32, name: 'SF 32, 20 %' },
    { value: Schadenfreiheitsklasse.SF_33, name: 'SF 33, 20 %' },
    { value: Schadenfreiheitsklasse.SF_34, name: 'SF 34, 20 %' },
    { value: Schadenfreiheitsklasse.SF_35, name: 'SF 35, 19 %' },
    { value: Schadenfreiheitsklasse.SF_36, name: 'SF 36, 19 %' },
    { value: Schadenfreiheitsklasse.SF_37, name: 'SF 37, 19 %' },
    { value: Schadenfreiheitsklasse.SF_38, name: 'SF 38, 19 %' },
    { value: Schadenfreiheitsklasse.SF_39, name: 'SF 39, 18 %' },
    { value: Schadenfreiheitsklasse.SF_40, name: 'SF 40, 18 %' },
    { value: Schadenfreiheitsklasse.SF_41, name: 'SF 41, 18 %' },
    { value: Schadenfreiheitsklasse.SF_42, name: 'SF 42, 18 %' },
    { value: Schadenfreiheitsklasse.SF_43, name: 'SF 43, 17 %' },
    { value: Schadenfreiheitsklasse.SF_44, name: 'SF 44, 17 %' },
    { value: Schadenfreiheitsklasse.SF_45, name: 'SF 45, 17 %' },
    { value: Schadenfreiheitsklasse.SF_46, name: 'SF 46, 17 %' },
    { value: Schadenfreiheitsklasse.SF_47, name: 'SF 47, 16 %' },
    { value: Schadenfreiheitsklasse.SF_48, name: 'SF 48, 16 %' },
    { value: Schadenfreiheitsklasse.SF_49, name: 'SF 49, 16 %' },
    { value: Schadenfreiheitsklasse.SF_50, name: 'SF 50, 15 %' }
];

export const wertebereichDeckungsschluessel_VK: WertebereichLokal[] = [
    { value: Deckungsschluessel.VK_OHNE_TK_OHNE, name: 'VK_OHNE_TK_OHNE' },
    { value: Deckungsschluessel.VK_150_TK_150, name: 'VK_150_TK_150' },
    { value: Deckungsschluessel.VK_300_TK_150, name: 'VK_300_TK_150' },
    { value: Deckungsschluessel.VK_500_TK_150, name: 'VK_500_TK_150' },
    { value: Deckungsschluessel.VK_1000_TK_150, name: 'VK_1000_TK_150' },
    { value: Deckungsschluessel.VK_150_TK_OHNE, name: 'VK_150_TK_OHNE' },
    { value: Deckungsschluessel.VK_300_TK_OHNE, name: 'VK_300_TK_OHNE' },
    { value: Deckungsschluessel.VK_500_TK_OHNE, name: 'VK_500_TK_OHNE' },
    { value: Deckungsschluessel.VK_1000_TK_OHNE, name: 'VK_1000_TK_OHNE' },
    { value: Deckungsschluessel.VK_500_TK_500, name: 'VK_500_TK_500' },
    { value: Deckungsschluessel.VK_1000_TK_1000, name: 'VK_1000_TK_1000' }
];

export const wertebereichDeckungsschluessel_TK: WertebereichLokal[] = [
    { value: Deckungsschluessel.TK_OHNE, name: 'TK_OHNE' },
    { value: Deckungsschluessel.TK_150, name: 'TK_150' },
    { value: Deckungsschluessel.TK_500, name: 'TK_500' }
];

export const wertebereichZahlweiseMapping: WertebereichLokal[] = [
    { value: Zahlweise.JAEHRLICH, name: 'Jährlich' },
    { value: Zahlweise.HALBJAEHRLICH, name: 'Halbjährlich' },
    { value: Zahlweise.VIERTELJAEHRLICH, name: 'Vierteljährlich' },
    { value: Zahlweise.MONATLICH, name: 'Monatlich' }
];

export const wertebereichVertragsId: WertebereichLokal[] = [
    { value: VertragsId.HAFTPFLICHT, name: 'Haftpflicht' },
    { value: VertragsId.KASKO, name: 'Kasko' }
];

export const wertebereichLeistungsvereinbarung: WertebereichLokal[] = [
    { value: Leistungsvereinbarungsart.SCHUTZBRIEF, name: 'Schutzbrief' },
    { value: Leistungsvereinbarungsart.WERTSCHUTZ_24, name: 'Wertschutz 24' },
    { value: Leistungsvereinbarungsart.WERTSCHUTZ_36, name: 'Wertschutz 36' },
    { value: Leistungsvereinbarungsart.RABATTSCHUTZ, name: 'Rabattschutz' },
    { value: Leistungsvereinbarungsart.GAP, name: 'GAP-Deckung' },
    { value: Leistungsvereinbarungsart.ERSATZFAHRZEUG_PLUS, name: 'Ersatzfahrzeug Plus' },
    { value: Leistungsvereinbarungsart.WERKSTATTBONUS, name: 'Werkstattbonus' },
    { value: Leistungsvereinbarungsart.SAFE_DRIVE, name: 'Safe Drive' }
];
