import { ClassificationResponse } from './ErsteinstufungsReducer';
import { Dispatch } from 'redux';
import { updateVertraegeHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { Einstufungsgrund, Schadenfreiheitsklasse } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { OfferEngineVertraegeRequestDto, OfferEngineVertraegeSfrRequestDto, VertragOptionalRequestDto } from '../util/fetch/offerengine/OfferEngineVertraegeRequestDto';
import { createVertraegeRequest } from '../util/OfferEngineRequestHelper';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { getIsoDateString } from '../util/DateFormattingHelper';
import { IAppState } from '../app/IAppState';

export const UPDATE_ERSTEINSTUFUNG: string = 'UPDATE_ERSTEINSTUFUNG';
export type UPDATE_ERSTEINSTUFUNG = typeof UPDATE_ERSTEINSTUFUNG;

export const UPDATE_EES_SCRIPT_ERROR: string = 'UPDATE_EES_SCRIPT_ERROR';
export type UPDATE_EES_SCRIPT_ERROR = typeof UPDATE_EES_SCRIPT_ERROR;

export const UPDATE_EES_SCRIPT_LOADED: string = 'UPDATE_EES_SCRIPT_LOADED';
export type UPDATE_EES_SCRIPT_LOADED = typeof UPDATE_EES_SCRIPT_LOADED;

export const ZEIGE_ERSTEINSTUFUNG: string = 'ZEIGE_ERSTEINSTUFUNG';
export type ZEIGE_ERSTEINSTUFUNG = typeof ZEIGE_ERSTEINSTUFUNG;

export interface UpdateScriptErrorAction {
    type: UPDATE_EES_SCRIPT_ERROR;
}

export const updateScriptError = (): UpdateScriptErrorAction => {
    return { type: UPDATE_EES_SCRIPT_ERROR };
};

export interface UpdateScriptLoadedAction {
    type: UPDATE_EES_SCRIPT_LOADED;
}
export const updateScriptLoaded = (): UpdateScriptLoadedAction => {
    return { type: UPDATE_EES_SCRIPT_LOADED };
};

export interface UpdateErsteinstufungsAction {
    type: UPDATE_ERSTEINSTUFUNG;
    detail: ClassificationResponse | undefined;
}

export const updateErsteinstufungSync = (detail: ClassificationResponse | undefined): UpdateErsteinstufungsAction => {
    return {
        type: UPDATE_ERSTEINSTUFUNG,
        detail
    };
};

export const updateErsteinstufungAsync = (detail: ClassificationResponse) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(updateErsteinstufungSync(detail));

        const businessId = getState().basisdaten.businessId;
        const deckungsumfang = getState().versicherungsschutz.versicherung.deckungsumfang;

        const einstufungsgrund: Einstufungsgrund = detail.context.id as Einstufungsgrund;

        const payload: VertragOptionalRequestDto = {
            schadenfreiheitsrabatt: {
                schadenfreiheitsklasse: detail.id as Schadenfreiheitsklasse,
                einstufungsgrund
            }
        };

        const request: OfferEngineVertraegeRequestDto = createVertraegeRequest(payload, deckungsumfang, true);
        (request as OfferEngineVertraegeSfrRequestDto).nutzer = detail.fuehrerschein
            ? { fuehrerscheinausstellung: getIsoDateString(new Date(detail.fuehrerscheinDatum!)) }
            : { fuehrerscheinausstellung: null };

        if (einstufungsgrund === Einstufungsgrund.VERBESSERTE_ZWEITWAGENREGELUNG_04) {
            (request as OfferEngineVertraegeSfrRequestDto).versichertesKfz = { zweitwagen: false };
            (request as OfferEngineVertraegeSfrRequestDto).sfrEinstufung = { vsnrErstwagenErgo: null };
        }

        return updateVertraegeHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ShowErsteinstufungAction {
    type: ZEIGE_ERSTEINSTUFUNG;
    showErsteinstufung: boolean;
}

export const showErsteinstufungSync = (showErsteinstufung: boolean): ShowErsteinstufungAction => {
    return {
        type: ZEIGE_ERSTEINSTUFUNG,
        showErsteinstufung: showErsteinstufung
    };
};
