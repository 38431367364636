import { Dispatch } from 'redux';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { Fahrzeugnutzer, OfferEngineAngebotDto, Rollentyp, Zahlweise } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { getWohnorteHttpRequest } from '../util/fetch/plz-zu-stadt/PostleitzahlZuStadtController';
import { PostleitzahlZuStadtResponseDto } from '../util/fetch/plz-zu-stadt/PostleitzahlZuStadtResponseDto';
import {
    updateAmtlichesKennzeichenHttpRequest,
    updateFahrzeugHttpRequest,
    updateNutzerHttpRequest,
    updateOrtHalterHttpRequest
} from '../util/fetch/offerengine/OfferEngineController';
import { UpdateFahrzeugNutzerRequestDto } from '../util/fetch/offerengine/UpdateFahrzeugNutzerRequestDto';
import { UpdateAmtlichesKennzeichenRequestDto } from '../util/fetch/offerengine/UpdateFahrzeugAmtlichesKennzeichenRequestDto';
import { UpdateFahrzeugRequestDto } from '../util/fetch/offerengine/UpdateFahrzeugRequestDto';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { FieldAction } from '../util/FieldAction';
import { isDate } from '../util/validate';
import { getIsoDateString } from '../util/DateFormattingHelper';
import { updateVersicherungFieldAsync } from '../versicherungsschutz/VersicherungsschutzAction';
import { CarFundingType, initialFahrzeugnutzungState } from './FahrzeugnutzungReducer';
import { UpdateOrtHalterRequestDto } from '../util/fetch/offerengine/UpdateOrtHalterRequestDto';
import { getPersonMitRolle } from '../util/PersonenHelper';
import { IAppState } from '../app/IAppState';
import { loadPersonsSync } from '../personen/PersonenAction';
import { showAddressIsInvalidSync } from '../personensuche/PersonensucheAction';

export const CHANGE_FAHRZEUG: string = 'CHANGE_FAHRZEUG';
export const CHANGE_NUTZER: string = 'CHANGE_NUTZER';
export const CHANGE_KENNZEICHEN: string = 'CHANGE_KENNZEICHEN';
export const RESET_SAISONKENNZEICHEN: string = 'RESET_SAISONKENNZEICHEN';
export const CHANGE_FAHRZEUGNUTZER: string = 'CHANGE_FAHRZEUGNUTZER';
export const LADE_WOHNORTE: string = 'LADE_WOHNORTE';
export const ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER: string = 'ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER';

const adressIsInvalidMessage: string = 'Der Wohnort des Halters ist fehlerhaft und konnte nicht korrigiert werden. Der Wohnort wurde nicht geändert.';

export interface ResetSaisonKennzeichenAction {
    type: typeof RESET_SAISONKENNZEICHEN;
    istSaisonKennzeichen: boolean;
    saisonkennzeichenVon: number | null;
    saisonkennzeichenBis: number | null;
}

export const resetSaisonKennzeichenSync = (
    istSaisonKennzeichen: boolean,
    saisonkennzeichenVon: number | null,
    saisonkennzeichenBis: number | null
): ResetSaisonKennzeichenAction => {
    return {
        type: RESET_SAISONKENNZEICHEN,
        istSaisonKennzeichen,
        saisonkennzeichenVon,
        saisonkennzeichenBis
    };
};

export interface ChangeFahrzeugnutzerAction {
    type: typeof CHANGE_FAHRZEUGNUTZER;
    fahrzeugnutzer: Fahrzeugnutzer[];
}
export const changeFahrzeugnutzerSync = (fahrzeugnutzer: Fahrzeugnutzer[]): ChangeFahrzeugnutzerAction => {
    return {
        type: CHANGE_FAHRZEUGNUTZER,
        fahrzeugnutzer
    };
};

export interface ChangeNutzerFieldAction {
    type: typeof CHANGE_NUTZER;
    payload: FieldAction;
}
export const changeNutzerFieldSync = (fieldName: string, fieldValue: string | number | Date): ChangeNutzerFieldAction => {
    return {
        type: CHANGE_NUTZER,
        payload: {
            fieldName,
            fieldValue
        }
    };
};

export interface ChangeFahrzeugFieldAction {
    type: typeof CHANGE_FAHRZEUG;
    payload: FieldAction;
}

export const changeFahrzeugFieldSync = (fieldName: string, fieldValue: any): ChangeFahrzeugFieldAction => {
    return {
        type: CHANGE_FAHRZEUG,
        payload: {
            fieldName,
            fieldValue
        }
    };
};

export interface ChangeKennzeichenFieldAction {
    type: typeof CHANGE_KENNZEICHEN;
    payload: FieldAction;
}

export const changeKennzeichenFieldSync = (fieldName: string, fieldValue: string): ChangeKennzeichenFieldAction => {
    return {
        type: CHANGE_KENNZEICHEN,
        payload: {
            fieldName,
            fieldValue
        }
    };
};

export interface LoadWohnorteAction {
    type: typeof LADE_WOHNORTE;
    payload: {
        wohnorte: string[];
    };
}

export const loadWohnorteSync = (wohnorte: string[]): LoadWohnorteAction => {
    return {
        type: LADE_WOHNORTE,
        payload: {
            wohnorte
        }
    };
};

export const updateNutzerFieldAsync = (businessId: string, fieldName: string, fieldValue: any) => {
    return (dispatch: Dispatch) => {
        dispatch(changeNutzerFieldSync(fieldName, fieldValue));
        const value = isDate(fieldValue) ? getIsoDateString(fieldValue as Date) : fieldValue;

        const request: UpdateFahrzeugNutzerRequestDto = {
            nutzer: {
                [fieldName]: value
            }
        };

        return updateNutzerHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateFahrzeugFieldAsync = (businessId: string, fieldName: string, fieldValue: any) => {
    return (dispatch: Dispatch) => {
        dispatch(changeFahrzeugFieldSync(fieldName, fieldValue));
        const value = isDate(fieldValue) ? getIsoDateString(fieldValue as Date) : fieldValue;
        const request: UpdateFahrzeugRequestDto = {
            versichertesKfz: {
                [fieldName]: value
            }
        };
        return updateFahrzeugHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateCarFundingTypeFieldAsync = (businessId: string, fieldName: string, fieldValue: any) => {
    return (dispatch: Dispatch) => {
        dispatch(changeFahrzeugFieldSync(fieldName, fieldValue));
        let request: UpdateFahrzeugRequestDto;
        switch (fieldValue) {
            case CarFundingType.CREDIT:
                request = {
                    versichertesKfz: {
                        leasing: false,
                        kreditfinanziert: true
                    }
                };
                break;
            case CarFundingType.LEASING:
                request = {
                    versichertesKfz: {
                        leasing: true,
                        kreditfinanziert: false
                    }
                };
                break;
            case CarFundingType.NONE:
            default:
                request = {
                    versichertesKfz: {
                        leasing: false,
                        kreditfinanziert: false
                    }
                };
                break;
        }
        return updateFahrzeugHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};
export const updateErstzulassungAsync = (businessId: string, erstzulassung: Date, erstzulassungUebernehmen: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(changeFahrzeugFieldSync('erstzulassung', erstzulassung));
        erstzulassungUebernehmen && dispatch(changeFahrzeugFieldSync('zulassung', erstzulassung));

        const erstzulassungString = getIsoDateString(erstzulassung);

        let request: UpdateFahrzeugRequestDto = {
            versichertesKfz: {
                erstzulassung: erstzulassungString
            }
        };

        if (erstzulassungUebernehmen) {
            request = {
                versichertesKfz: {
                    erstzulassung: erstzulassungString,
                    zulassung: erstzulassungString
                }
            };
        }

        return updateFahrzeugHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const resetSaisonkennzeichenAsync = (businessId: string, istSaisonKennzeichen: boolean) => {
    return (dispatch: Dispatch) => {
        let saisonkennzeichenVon: number | null;
        let saisonkennzeichenBis: number | null;

        if (istSaisonKennzeichen) {
            saisonkennzeichenVon = initialFahrzeugnutzungState.amtlichesKennzeichen.saisonkennzeichenVon;
            saisonkennzeichenBis = initialFahrzeugnutzungState.amtlichesKennzeichen.saisonkennzeichenBis;
        } else {
            saisonkennzeichenVon = null;
            saisonkennzeichenBis = null;
        }
        dispatch(resetSaisonKennzeichenSync(istSaisonKennzeichen, saisonkennzeichenVon, saisonkennzeichenBis));

        const request: UpdateAmtlichesKennzeichenRequestDto = {
            versichertesKfz: {
                amtlichesKennzeichen: {
                    saisonkennzeichenVon: saisonkennzeichenVon,
                    saisonkennzeichenBis: saisonkennzeichenBis
                }
            }
        };

        return updateAmtlichesKennzeichenHttpRequest(businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                if (istSaisonKennzeichen) {
                    // @ts-ignore
                    dispatch(updateVersicherungFieldAsync(businessId, 'zahlweise', Zahlweise.JAEHRLICH));
                } else {
                    onFulfilledStoreOffer(response, dispatch);
                }
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateKennzeichenFieldAsync = (businessId: string, fieldName: string, fieldValue: string) => {
    return (dispatch: Dispatch) => {
        dispatch(showKennzeichenIsSavingSpinnerSync(true));
        dispatch(changeKennzeichenFieldSync(fieldName, fieldValue));

        const request: UpdateAmtlichesKennzeichenRequestDto = {
            versichertesKfz: {
                amtlichesKennzeichen: {
                    [fieldName]: fieldValue === undefined || fieldValue === '' ? null : fieldValue
                }
            }
        };

        return updateAmtlichesKennzeichenHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch))
            .finally(() => dispatch(showKennzeichenIsSavingSpinnerSync(false)));
    };
};

export const loadWohnorteAsync = (businessId: string, postleitzahl: string) => {
    return (dispatch: Dispatch) => {
        return getWohnorteHttpRequest(businessId, postleitzahl, dispatch)
            .then((response: FrontendResponse<PostleitzahlZuStadtResponseDto>) => {
                response.payload && dispatch(loadWohnorteSync(response.payload.wohnorte));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateFahrzeugnutzerAsync = (businessId: string, fahrzeugnutzer: Fahrzeugnutzer[], removeYoungestBirthDate = false) => {
    return (dispatch: Dispatch) => {
        dispatch(changeFahrzeugnutzerSync(fahrzeugnutzer));

        const request: UpdateFahrzeugNutzerRequestDto = {
            nutzer: {
                fahrzeugnutzer
            }
        };

        if (removeYoungestBirthDate) {
            request.nutzer = {
                ...request.nutzer,
                geburtsdatumJuengsterFahrer: null
            };
        }

        return updateNutzerHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateOrtHalterAsync = (businessId: string, ortHalter: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(changeFahrzeugFieldSync('ortHalter', ortHalter));

        const request: UpdateOrtHalterRequestDto = {
            adresse: {
                ort: ortHalter
            },
            personIdHalter: getPersonMitRolle(getState().personen.personen, Rollentyp.HALTER)!.pdsId
        };
        return updateOrtHalterHttpRequest(businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                if (response.payload) {
                    if (response.payload.personen.length > 0) {
                        dispatch(loadPersonsSync(response.payload.personen));
                    } else {
                        dispatch(showAddressIsInvalidSync(true, adressIsInvalidMessage));
                    }
                    onFulfilledStoreOffer(response, dispatch);
                }
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ShowKennzeichenIsSavingSpinnerAction {
    type: typeof ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER;
    showSpinner: boolean;
}

export const showKennzeichenIsSavingSpinnerSync = (showSpinner: boolean): ShowKennzeichenIsSavingSpinnerAction => {
    return {
        type: ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER,
        showSpinner
    };
};
