// This file manages edcom api calls.
import { Dispatch } from 'redux';
import { sendPostRequest } from '../client/FetchBaseClient';
import { FrontendResponse } from '../client/FrontendResponse';
import { EdcomEvbFileRequestDto } from './EdcomEvbFileRequestDto';
import { EdcomEvbFileResponseDto } from './EdcomEvbFileResponseDto';

export const downloadEvbFileHttpRequest = (
    businessId: String,
    payload: EdcomEvbFileRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<EdcomEvbFileResponseDto>> => {
    return sendPostRequest(`/kfz2019/edcomRequest/${businessId}`, payload, dispatch);
};// This file manages edcom api calls.