import React from 'react';
import { IAppState } from '../app/IAppState';
import { connect } from 'react-redux';
import Details from '@eg/elements/Details';
import Card from '@eg/elements/Card';
import { Meldung, Schweregrad } from '../util/fetch/client/MeldungenDto';
import DamageIcon from '@eg/elements/components/Icons/DamageIcon';
import InfoIcon from '@eg/elements/components/Icons/InfoIcon';
import CheckIcon from '@eg/elements/components/Icons/CheckIcon';
import AlertIcon from '@eg/elements/components/Icons/AlertIcon';
import { compareMeldungen, MeldungenState } from './MeldungenReducer';
import { uniqueByKey } from '../util/ReducerHelper';

const SUCCESS_COLOR: string = '#e2f6f8';
const INFO_COLOR: string = '#ccebed';
const WARNING_COLOR: string = '#ffeede';
const ERROR_COLOR: string = '#e87a16';

const ICON_SIZE = 20;
const ICON_DIMENSIONS = { width: ICON_SIZE, height: ICON_SIZE };
const ICON_STYLE = { float: 'left', paddingTop: 2 };
const INFO_ICON = <InfoIcon {...ICON_DIMENSIONS} style={ICON_STYLE} />;
const SUCCESS_ICON = <CheckIcon {...ICON_DIMENSIONS} style={ICON_STYLE} />;
const WARNING_ICON = <AlertIcon {...ICON_DIMENSIONS} style={ICON_STYLE} />;
const ERROR_ICON = <DamageIcon {...ICON_DIMENSIONS} style={ICON_STYLE} />;

interface MeldungenPresentationProps {
    meldungen: MeldungenState;
    changeVehicleSelected: boolean;
}

export class MeldungenPresentation extends React.Component<MeldungenPresentationProps, {}> {
    render() {
        const { meldungen, changeVehicleSelected } = this.props;

        const addtionalErrors: Meldung[] = [];

        if (changeVehicleSelected) {
            addtionalErrors.push({
                text: 'Ein Fahrzeugwechsel ist nicht möglich. Bitte einen Rückruf für das Inhouse Dialog Sales vereinbaren.',
                schweregrad: Schweregrad.ERROR
            } as Meldung);
        }

        const allMeldungen = uniqueByKey(
            [...meldungen.meldungenPDS, ...meldungen.meldungenAK, ...meldungen.meldungenOE, ...meldungen.meldungenBFF, ...meldungen.meldungenFE, ...addtionalErrors],
            item => item.text
        ).sort(compareMeldungen);

        return (
            <Details summary={<h2 className="esc_h4">Meldungen ({allMeldungen.length})</h2>} isInitiallyOpen>
                {allMeldungen &&
                    allMeldungen
                        .filter(meldung => meldung.text)
                        .map((meldung: Meldung) => {
                            const color = cardColor(meldung.schweregrad);
                            const icon = cardIcon(meldung.schweregrad);
                            // Note 2019-08-13 mf: text is only auto-wrapped at spaces
                            const textWithSpaces: string = meldung.text.replace(/":/g, '": ');
                            return (
                                // Note 2019-08-08 bn: meldung.code is not unique, therefore using meldung.text as key.
                                <Card key={meldung.text} padding="small" style={{ backgroundColor: color }} accentColor={color}>
                                    <div>{icon}</div>
                                    <div style={{ paddingLeft: 24 }}>{textWithSpaces}</div>
                                </Card>
                            );
                        })}
            </Details>
        );
    }
}

const cardIcon = (schweregrad: string) => {
    switch (schweregrad) {
        case Schweregrad.SUCCESS:
            return SUCCESS_ICON;
        case Schweregrad.WARNING:
            return WARNING_ICON;
        case Schweregrad.ERROR:
            return ERROR_ICON;
        case Schweregrad.INFO:
        default:
            return INFO_ICON;
    }
};

const cardColor = (schweregrad: string) => {
    switch (schweregrad) {
        case Schweregrad.SUCCESS:
            return SUCCESS_COLOR;
        case Schweregrad.INFO:
            return INFO_COLOR;
        case Schweregrad.WARNING:
            return WARNING_COLOR;
        case Schweregrad.ERROR:
            return ERROR_COLOR;
        default:
            return 'undefined';
    }
};

const mapStateToProps = (state: IAppState): MeldungenPresentationProps => ({
    meldungen: state.meldungen,
    changeVehicleSelected: state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

export const Meldungen = connect(mapStateToProps, {})(MeldungenPresentation);
