import { Halter, Nutzung, Tarifgruppe } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { BITTE_WAEHLEN, WertebereichLokal } from '../util/WertebereicheHelper';

export const halterWertebereichMapping: WertebereichLokal[] = [
    { value: BITTE_WAEHLEN, name: BITTE_WAEHLEN },
    { value: Halter.VERSICHERUNGSNEHMER, name: 'Versicherungsnehmer' },
    { value: Halter.PARTNER, name: 'Partner' },
    { value: Halter.SONSTIGE, name: 'Sonstige' },
    { value: Halter.KIND, name: 'Kind' }
];

export const wertebereichMonate: WertebereichLokal[] = [
    { value: '1', name: 'Jan' },
    { value: '2', name: 'Feb' },
    { value: '3', name: 'März' },
    { value: '4', name: 'April' },
    { value: '5', name: 'Mai' },
    { value: '6', name: 'Juni' },
    { value: '7', name: 'Juli' },
    { value: '8', name: 'Aug' },
    { value: '9', name: 'Sep' },
    { value: '10', name: 'Okt' },
    { value: '11', name: 'Nov' },
    { value: '12', name: 'Dez' }
];

export const tarifgruppenWertebereichMapping: WertebereichLokal[] = [
    { value: Tarifgruppe.STANDARD_19, name: 'Sonstige' },
    { value: Tarifgruppe.FINANZDIENSTLEISTER_INNENDIENST, name: 'Finanzdienstleister Innendienst' },
    { value: Tarifgruppe.FINANZDIENSTLEISTER_AUSSENDIENST, name: 'Finanzdienstleister Außendienst' },
    {
        value: Tarifgruppe.BEAMTE_RICHTER_PENSIONAERE_19,
        name: 'Beamte, Richter, Pensionäre und deren nicht erwerbstätige (Ehe-) Partner bzw. Witwer'
    },
    {
        value: Tarifgruppe.BESCHAEFTIGTE_19,
        name: 'Beschäftigte im öffentlichen Dienst und Berufssoldaten - auch im Ruhestand - und deren nicht erwerbstätige (Ehe-)Partner bzw. Witwer'
    },
    { value: Tarifgruppe.AGRARIER_19, name: 'Agrarier' }
];

export const wertebereichNutzung: WertebereichLokal[] = [
    { value: Nutzung.PRIVAT, name: 'privat' },
    { value: Nutzung.GESCHAEFTLICH, name: 'geschäftlich' }
];
