import { Dispatch } from 'redux';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { IAppState } from '../app/IAppState';
import { downloadEvbFileAsync } from '../evb/EvbAction';
import { clearMeldungenAfterAbschlussSync } from '../meldungen/MeldungenAction';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { postAngebotHttpRequest, postAntragPruefenHttpRequest, postDirektAbschlussHttpRequest } from '../util/fetch/abschluss/AbschlussController';
import { AbschlussDto } from '../util/fetch/abschluss/AbschlussDto';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { Verkaufsprozessart, Versandweg } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { getAngebotHttpRequest, updateAngebotTopLevelHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { UpdateTopLevelRequestDto } from '../util/fetch/offerengine/UpdateTopLevelRequestDto';
import { ANDERE_EMAIL } from './AbschlussReducer';
import {
    AENDERE_PROGRESSCIRCLE_LABEL,
    ANGEBOT_PRUEFEN_ABGESCHLOSSEN_LABEL,
    ANGEBOT_WIRD_GEPRUEFT_LABEL,
    DIREKTABSCHLUSS_DURCHFUEHREN,
    DIREKTABSCHLUSS_ERFOLGREICH,
    DIREKTABSCHLUSS_NICHT_ERFOLGREICH,
    changeProgressCircleValueSync,
    changeWartenAufPruefergebnisSync
} from './DirektabschlussAction';

export const AENDERE_ABSCHLUSS_ERGEBNIS: string = 'AENDERE_ABSCHLUSS_ERGEBNIS';
export const SHOW_ABSCHLUSS_DIALOG: string = 'SHOW_ABSCHLUSS_DIALOG';
export const CHANGE_VERKAUFSPROZESSART: string = 'CHANGE_VERKAUFSPROZESSART';
export const CHANGE_VERSANDWEG: string = 'CHANGE_VERSANDWEG';
export const CHANGE_VERSANDMAIL: string = 'CHANGE_VERSANDMAIL';
export const SET_BUTTON_LOADING: string = 'SET_BUTTON_LOADING';

export interface ChangeAbschlussErgebnisAction {
    type: typeof AENDERE_ABSCHLUSS_ERGEBNIS;
    payload: {
        evbNummer: string | undefined;
        abschlussErfolgreichDetails: string;
        abschlussErfolgreich: boolean;
    };
}

export const changeAbschlussErgebnisSync = (evbNummer: string | undefined, abschlussErfolgreichDetails: string, abschlussErfolgreich: boolean): ChangeAbschlussErgebnisAction => {
    return {
        type: AENDERE_ABSCHLUSS_ERGEBNIS,
        payload: {
            evbNummer,
            abschlussErfolgreichDetails,
            abschlussErfolgreich
        }
    };
};

export interface ShowAbschlussErgebnisDialogAction {
    type: typeof SHOW_ABSCHLUSS_DIALOG;
    showDialog: boolean;
}

export const showAbschlussErgebnisDialogSync = (showDialog: boolean): ShowAbschlussErgebnisDialogAction => {
    return {
        type: SHOW_ABSCHLUSS_DIALOG,
        showDialog
    };
};

export interface ChangeVerkaufsprozessartAction {
    type: typeof CHANGE_VERKAUFSPROZESSART;
    verkaufsprozessart: Verkaufsprozessart;
}

export const updateVerkaufsprozessartSync = (verkaufsprozessart: Verkaufsprozessart): ChangeVerkaufsprozessartAction => {
    return {
        type: CHANGE_VERKAUFSPROZESSART,
        verkaufsprozessart
    };
};

export interface ChangeVersandwegAction {
    type: typeof CHANGE_VERSANDWEG;
    versandweg: Versandweg;
}

export const updateVersandwegSync = (versandweg: Versandweg): ChangeVersandwegAction => {
    return {
        type: CHANGE_VERSANDWEG,
        versandweg
    };
};

export interface ChangeVersandmailAction {
    type: typeof CHANGE_VERSANDMAIL;
    versandmail: string;
}

export const updateVersandmailSync = (versandmail: string): ChangeVersandmailAction => {
    return {
        type: CHANGE_VERSANDMAIL,
        versandmail
    };
};

export interface ChangeProgressCircleLabelAction {
    type: typeof AENDERE_PROGRESSCIRCLE_LABEL;
    label: string;
}

const changeProgressCircleLabelSync = (label: string): ChangeProgressCircleLabelAction => {
    return {
        type: AENDERE_PROGRESSCIRCLE_LABEL,
        label: label
    };
};

export interface SetButtonLoadingAction {
    type: typeof SET_BUTTON_LOADING;
    setButtonLoading: boolean;
}

export const setButtonLoadingSync = (setButtonLoading: boolean): SetButtonLoadingAction => {
    return {
        type: SET_BUTTON_LOADING,
        setButtonLoading
    };
};

export const updateVersandwegSyncAsync = (versandweg: Versandweg) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(updateVersandwegSync(versandweg));

        const request: UpdateTopLevelRequestDto = {
            versandweg: versandweg
        };

        return updateAngebotTopLevelHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateVersandmailAsync = (email: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(updateVersandmailSync(email));

        if (email === ANDERE_EMAIL) {
            return;
        }

        const request: UpdateTopLevelRequestDto = {
            versandmail: email
        };

        return updateAngebotTopLevelHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateVerkaufsprozessartAsync = (verkaufsprozessart: Verkaufsprozessart) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(updateVerkaufsprozessartSync(verkaufsprozessart));
        const request: UpdateTopLevelRequestDto = {
            beratungsprotokollId: '',
            verkaufsprozessart: verkaufsprozessart
        };
        return updateAngebotTopLevelHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const versendeVollangebotAsync = () => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(setButtonLoadingSync(true));
        const businessId = getState().basisdaten.businessId;
        return postAngebotHttpRequest(businessId, dispatch)
            .then(async(response: FrontendResponse<AbschlussDto>) => {
                if (hasValidAbschlussResponseStatusCode(response)) {
                    const details = response.payload!.evbNummer !== null && response.payload!.evbNummer.length > 0 ? `eVB-Nummer: ${response.payload!.evbNummer}` : '';

                    dispatch(changeAbschlussErgebnisSync(response.payload!.evbNummer, details, true));
                    //@ts-ignore
                     await dispatch(downloadEvbFileAsync());
                    dispatch(showAbschlussErgebnisDialogSync(true));
                    dispatch(clearMeldungenAfterAbschlussSync());
                    /* Note: AbschlussKomponente will change the angebot
                     * (e.g. set versicherung->versicherungsstatus from ANGELEGT to ABSCHLUSS_BEANTRAGT)
                     * without sending us the updated angebot.
                     * This call fetches the final angebot to have a clean and consistent state in the frontend.
                     * */

                    // @ts-ignore
                    dispatch(ladeFinalAngebotAsync(businessId));
                }
            })
            .catch((e: Error) => {
                onRejectedStoreTechnischerFehler(e, dispatch);
            })
            .finally(() => dispatch(setButtonLoadingSync(false)));
    };
};

export const pruefeAntragUndDirektAbschlussAsync = () => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const antragPruefenAbortController = new AbortController();
        const signal = antragPruefenAbortController.signal;
        const businessId = getState().basisdaten.businessId;

        let pruefungInVorgegebenerZeit = true;
        let direktAbschlussGestartet = false;
        const maxWartezeit: number = 30000;
        dispatch(changeWartenAufPruefergebnisSync(true));
        dispatch(changeProgressCircleValueSync(0, 0));
        dispatch(changeProgressCircleLabelSync(ANGEBOT_WIRD_GEPRUEFT_LABEL));

        let startProgressCircle = setInterval(() => {
            const progressCircleValue: number = getState().abschluss.direktAbschluss.progressCircleValue;
            const vergangeneWartezeitInMillisekunden: number = getState().abschluss.direktAbschluss.vergangeneWartezeitInMillisekunden;
            if (progressCircleValue >= 100) {
                clearInterval(startProgressCircle);
            } else {
                if (vergangeneWartezeitInMillisekunden === maxWartezeit && !direktAbschlussGestartet) {
                    antragPruefenAbortController.abort();

                    pruefungInVorgegebenerZeit = false;
                    dispatch(changeProgressCircleLabelSync(ANGEBOT_PRUEFEN_ABGESCHLOSSEN_LABEL));
                    // @ts-ignore
                    dispatch(direktAbschlussAsync(businessId, startProgressCircle));
                    direktAbschlussGestartet = true;
                } else {
                    dispatch(changeProgressCircleValueSync(progressCircleValue + 1, vergangeneWartezeitInMillisekunden + 500));
                }
            }
        }, 500);

        return postAntragPruefenHttpRequest(businessId, dispatch, signal)
            .then((response: FrontendResponse<AbschlussDto>) => {
                if (pruefungInVorgegebenerZeit) {
                    if (hasValidAbschlussResponseStatusCode(response)) {
                        // @ts-ignore
                        dispatch(direktAbschlussAsync(businessId, startProgressCircle));
                        direktAbschlussGestartet = true;
                    } else {
                        dispatch(changeProgressCircleLabelSync(ANGEBOT_PRUEFEN_ABGESCHLOSSEN_LABEL));
                        dispatch(changeProgressCircleValueSync(100, 50));
                        clearInterval(startProgressCircle);
                    }
                }
            })
            .catch((e: Error) => {
                clearInterval(startProgressCircle);
                onRejectedStoreTechnischerFehler(e, dispatch);
            });
    };
};

export const direktAbschlussAsync = (businessId: string, startProgressCircle: any) => {
    return (dispatch: Dispatch,getState: () => IAppState) => {// getState is used get redux state

        dispatch(changeProgressCircleLabelSync(DIREKTABSCHLUSS_DURCHFUEHREN));
        return postDirektAbschlussHttpRequest(businessId, dispatch)
            .then(async (response: FrontendResponse<AbschlussDto>) => {
                if (hasValidAbschlussResponseStatusCode(response)) {
                    const details = response.payload!.evbNummer !== null && response.payload!.evbNummer.length > 0 ? `eVB-Nummer: ${response.payload!.evbNummer}` : '';

                     dispatch(changeAbschlussErgebnisSync(response.payload!.evbNummer, details, true));
                    
                    // @ts-ignore
                    await dispatch(downloadEvbFileAsync());
                    dispatch(showAbschlussErgebnisDialogSync(true));
                    dispatch(changeProgressCircleLabelSync(DIREKTABSCHLUSS_ERFOLGREICH));
                    dispatch(clearMeldungenAfterAbschlussSync());
                    /* Note: AbschlussKomponente will change the angebot
                     * (e.g. set versicherung->versicherungsstatus from ANGELEGT to ABSCHLUSS_BEANTRAGT)
                     * without sending us the updated angebot.
                     * This call fetches the final angebot to have a clean and consistent state in the frontend.
                     * */

                    // @ts-ignore
                    dispatch(ladeFinalAngebotAsync(businessId));
                } else {
                    dispatch(changeProgressCircleLabelSync(DIREKTABSCHLUSS_NICHT_ERFOLGREICH));
                }
            })
            .catch((e: Error) => {
                clearInterval(startProgressCircle);
                onRejectedStoreTechnischerFehler(e, dispatch);
            })
            .finally(() => {
                dispatch(changeProgressCircleValueSync(100, 50));
                clearInterval(startProgressCircle);
            });
    };
};

const ladeFinalAngebotAsync = (businessId: string) => {
    return (dispatch: Dispatch) => {
        return getAngebotHttpRequest(businessId, dispatch).then(response => onFulfilledStoreOffer(response, dispatch));
        // Note: if this call will fail, we do not display a technical error message.
    };
};

const hasValidAbschlussResponseStatusCode = (response: FrontendResponse<AbschlussDto>): boolean => {
    return response !== null && response !== undefined && response.payload !== null && response.payload !== undefined && response.payload.statusCode < 400;
};
