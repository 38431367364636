import { Dispatch } from 'redux';
import { updateVersicherungenHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { Hinweis, Versicherung } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { HinweisDto, OfferEngineVersicherungenRequestDto, Versicherung as VersicherungDto } from '../util/fetch/offerengine/OfferEngineVersicherungenRequestDto';
import { IAppState } from '../app/IAppState';

export const BESTAETIGE_HINWEIS: string = 'BESTAETIGE_HINWEIS';
export const BESTAETIGE_ALLE_HINWEISE: string = 'BESTAETIGE_ALLE_HINWEISE';
export const WARTEN_AUF_PRUEFERGEBNIS: string = 'WARTEN_AUF_PRUEFERGEBNIS';

export const ANGEBOT_WIRD_GEPRUEFT_LABEL: string = 'Das Angebot wird geprüft...';
export const ANGEBOT_PRUEFEN_ABGESCHLOSSEN_LABEL: string = 'Das Angebot wurde geprüft. Bitte prüfen Sie die Meldungen.';
export const DIREKTABSCHLUSS_DURCHFUEHREN: string = 'Der Versand wird durchgeführt...';
export const DIREKTABSCHLUSS_NICHT_ERFOLGREICH: string = 'Der Versand war nicht erfolgreich. Bitte prüfen Sie die Meldungen.';
export const DIREKTABSCHLUSS_ERFOLGREICH: string = 'Versand erfolgreich';

export const AENDERE_PROGRESSCIRCLE_VALUE: string = 'AENDERE_PROGRESSCIRCLE_VALUE';
export const AENDERE_PROGRESSCIRCLE_LABEL: string = 'AENDERE_PROGRESSCIRCLE_LABEL';

export interface ChangeHinweisAction {
    type: typeof BESTAETIGE_HINWEIS;
    payload: {
        hinweis: Hinweis;
        index: number;
    };
}

export const updateBestaetigeHinweisSync = (hinweis: Hinweis, index: number): ChangeHinweisAction => {
    return {
        type: BESTAETIGE_HINWEIS,
        payload: {
            hinweis,
            index
        }
    };
};

function mapHinweis(hinweis: Hinweis): HinweisDto {
    return {
        bestaetigt: hinweis.bestaetigt,
        hinweisart: hinweis.hinweisart
    };
}

export const updateBestaetigeAlleHinweiseAsync = (gewaehlteVersicherung: Versicherung) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const hinweisDto: HinweisDto[] = gewaehlteVersicherung.hinweise.map((hinweis: Hinweis) => mapHinweis(hinweis));

        const versicherung: VersicherungDto = {
            versicherungsId: gewaehlteVersicherung.versicherungsId,
            hinweise: hinweisDto
        };

        const request: OfferEngineVersicherungenRequestDto = {
            versicherungen: [versicherung]
        };

        dispatch(changeAlleHinweiseBestaetigtSync(true));

        return updateVersicherungenHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeBestaetigeAlleHinweiseAction {
    type: typeof BESTAETIGE_ALLE_HINWEISE;
    alleHinweiseBestaetigt: boolean;
}

export const changeAlleHinweiseBestaetigtSync = (alleHinweiseBestaetigt: boolean): ChangeBestaetigeAlleHinweiseAction => {
    return {
        type: BESTAETIGE_ALLE_HINWEISE,
        alleHinweiseBestaetigt
    };
};

export interface ChangeWartenAufPruefergebnisAction {
    type: typeof WARTEN_AUF_PRUEFERGEBNIS;
    wartenAufPruefErgebnis: boolean;
}

export const changeWartenAufPruefergebnisSync = (wartenAufPruefErgebnis: boolean): ChangeWartenAufPruefergebnisAction => {
    return {
        type: WARTEN_AUF_PRUEFERGEBNIS,
        wartenAufPruefErgebnis
    };
};

export interface ChangeProgressCircleValueAction {
    type: typeof AENDERE_PROGRESSCIRCLE_VALUE;
    payload: {
        progressCircleValue: number;
        vergangeneWartezeit: number;
    };
}

export const changeProgressCircleValueSync = (value: number, vergangeneWartezeit: number): ChangeProgressCircleValueAction => {
    return {
        type: AENDERE_PROGRESSCIRCLE_VALUE,
        payload: {
            progressCircleValue: value,
            vergangeneWartezeit: vergangeneWartezeit
        }
    };
};
