import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import {
    ShowErsteinstufungAction,
    UPDATE_EES_SCRIPT_ERROR,
    UPDATE_EES_SCRIPT_LOADED,
    UPDATE_ERSTEINSTUFUNG,
    UpdateErsteinstufungsAction,
    UpdateScriptErrorAction,
    UpdateScriptLoadedAction,
    ZEIGE_ERSTEINSTUFUNG
} from './ErsteinstufungsAction';
import { Angebotsgrund, Einstufungsgrund, SfrEinstufung } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { ErstfahrzeugInnerRadioButton } from '../angebotsgrund/AngebotsgrundWertebereiche';

export type ErsteinstufungState = {
    display: boolean;
    detail?: ClassificationResponse | undefined;
    isErsteinstufungsServiceLoaded: boolean;
    isErsteinstufungsServiceLoadFailed: boolean;
};

export interface ClassificationResponse {
    id: string;
    context: ClassificationContext;
    fuehrerschein: boolean;
    fuehrerscheinDatum: string | undefined;
}
export interface ClassificationContext {
    id: string;
    text: string;
}

const initialErsteinstufungsState: ErsteinstufungState = {
    display: false,
    isErsteinstufungsServiceLoaded: false,
    isErsteinstufungsServiceLoadFailed: false
};

type ErsteinstufungsserviceAction = LoadOfferAction & UpdateErsteinstufungsAction & UpdateScriptErrorAction & UpdateScriptLoadedAction & ShowErsteinstufungAction;

export const ersteinstufungsserviceReducer = (state: ErsteinstufungState = initialErsteinstufungsState, action: ErsteinstufungsserviceAction): ErsteinstufungState => {
    const displayErsteinstufungService = (angebotsgrund: string, sfrEinstufung: SfrEinstufung): boolean => {
        return angebotsgrund === Angebotsgrund.ERSTVERTRAG && (sfrEinstufung === null || !sfrEinstufung.uebernahmePerson);
    };

    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                display: displayErsteinstufungService(action.angebot.angebotsgrund, action.angebot.sfrEinstufung)
            };
        }

        case UPDATE_ERSTEINSTUFUNG: {
            return {
                ...state,
                detail: action.detail
            };
        }

        case UPDATE_EES_SCRIPT_ERROR: {
            return {
                ...state,
                isErsteinstufungsServiceLoadFailed: true
            };
        }
        case UPDATE_EES_SCRIPT_LOADED: {
            return {
                ...state,
                isErsteinstufungsServiceLoaded: true
            };
        }
        case ZEIGE_ERSTEINSTUFUNG: {
            return {
                ...state,
                display: action.showErsteinstufung
            };
        }
        default:
            return state;
    }
};

export function isErstvertragMitZweitwagenRegelung(angebotsgrund: Angebotsgrund, classification: ClassificationResponse | undefined): boolean {
    return angebotsgrund === Angebotsgrund.ERSTVERTRAG && isZweitKradRegelung(classification);
}

export function isZweitKradRegelung(classification: ClassificationResponse | undefined): boolean {
    return classification !== undefined && (classification.context.id as Einstufungsgrund) === Einstufungsgrund.ZWEITWAGENREGELUNG_ZWEITKRADREGELUNG_01;
}

export function isErstvertragMitErsteinstufungsService(angebotsgrund: Angebotsgrund, erstvertragGrund: ErstfahrzeugInnerRadioButton): boolean {
    return angebotsgrund === Angebotsgrund.ERSTVERTRAG && erstvertragGrund === ErstfahrzeugInnerRadioButton.SFK_MIT_EES;
}

export function isErstvertragMitUebernahmePerson(angebotsgrund: Angebotsgrund, erstvertragGrund: ErstfahrzeugInnerRadioButton): boolean {
    return angebotsgrund === Angebotsgrund.ERSTVERTRAG && erstvertragGrund === ErstfahrzeugInnerRadioButton.SFK_UEBERNAHME_PERSON;
}

export interface ErstEinstufungsFieldFlags {
    disableVorschaeden: boolean;
    disableFahrzeugGestohlen: boolean;
    disableVorversicherung: boolean;
    mandatoryFuerZweitwagenRegelung: boolean;
    hideBereitsEinFahrzeugBeiErgoVersichert: boolean;
    hideSchadenfreiheitsklasseUebernahme: boolean;
    isVsnrErstwagenRelevant: boolean;
}

export function getErsteinstufungFieldFlags(
    angebotsgrund: Angebotsgrund,
    erstvertragGrund: ErstfahrzeugInnerRadioButton,
    einstufungsGrund: ClassificationResponse | undefined
): ErstEinstufungsFieldFlags {
    const fieldFlags = {
        disableVorschaeden: false,
        disableFahrzeugGestohlen: false,
        disableVorversicherung: false,
        mandatoryFuerZweitwagenRegelung: false,
        hideBereitsEinFahrzeugBeiErgoVersichert: false,
        hideSchadenfreiheitsklasseUebernahme: false,
        isVsnrErstwagenRelevant: false
    };

    if (angebotsgrund !== Angebotsgrund.ERSTVERTRAG || (angebotsgrund === Angebotsgrund.ERSTVERTRAG && erstvertragGrund === ErstfahrzeugInnerRadioButton.SFK_UEBERNAHME_PERSON)) {
        return fieldFlags;
    } else {
        // Diese Felder werden bei jedem EES-Grund ausgegraut
        fieldFlags.disableVorschaeden = true;
        fieldFlags.disableFahrzeugGestohlen = true;
        fieldFlags.disableVorversicherung = true;
    }

    if (!einstufungsGrund) {
        return fieldFlags;
    }

    switch (einstufungsGrund.context.id as Einstufungsgrund) {
        case Einstufungsgrund.VERBESSERTE_ZWEITWAGENREGELUNG_04:
            fieldFlags.hideBereitsEinFahrzeugBeiErgoVersichert = true;
            fieldFlags.isVsnrErstwagenRelevant = true;
            break;

        case Einstufungsgrund.BESONDERE_VEREINBARUNG_11:
            fieldFlags.isVsnrErstwagenRelevant = true;
            fieldFlags.hideBereitsEinFahrzeugBeiErgoVersichert = true;
            break;

        case Einstufungsgrund.ZWEITWAGENREGELUNG_ZWEITKRADREGELUNG_01:
            fieldFlags.hideSchadenfreiheitsklasseUebernahme = true;
            fieldFlags.mandatoryFuerZweitwagenRegelung = true;
            fieldFlags.disableVorversicherung = false;
            break;

        case Einstufungsgrund.ERSTVERSICHERUNG_18:
        case Einstufungsgrund.FUEHRERSCHEINREGELUNG_03:
        default:
    }
    return fieldFlags;
}
