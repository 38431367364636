import { OfferEngineVersicherungenRequestDto, Versicherung, VersicherungOptionalRequestDto } from './fetch/offerengine/OfferEngineVersicherungenRequestDto';
import { Deckungsumfang, TypVertrag, VersicherungsId, VertragsId } from './fetch/offerengine/OfferEngineAngebotDto';
import { OfferEngineVertraegeRequestDto, Versicherung as VersicherungVertrag, Vertrag, VertragOptionalRequestDto } from './fetch/offerengine/OfferEngineVertraegeRequestDto';
import { OfferEngineEvbRequestDto } from './fetch/offerengine/OfferEngineEvbRequestDto';

export const createVersicherungenRequest = (payload: VersicherungOptionalRequestDto): OfferEngineVersicherungenRequestDto => {
    let versicherungen: Versicherung[] = [];
    Object.values(VersicherungsId).map((versicherungsId: VersicherungsId) => {
        const versicherung: Versicherung = {
            versicherungsId: versicherungsId,
            ...payload
        };
        return versicherungen.push(versicherung);
    });
    return {
        versicherungen: versicherungen
    };
};

export const createVertraegeRequest = (payload: VertragOptionalRequestDto, deckungsumfang: Deckungsumfang, ignoreTeilkasko: boolean): OfferEngineVertraegeRequestDto => {
    let versicherungen: VersicherungVertrag[] = [];
    let vertraege: Vertrag[] = [];
    let vertragsTypen: TypVertrag[] = Object.values(TypVertrag);
    switch (deckungsumfang) {
        case Deckungsumfang.HAFTPFLICHT:
            vertragsTypen = vertragsTypen.filter((vertragsTyp: TypVertrag) => vertragsTyp === TypVertrag.haftpflicht);
            break;
        case Deckungsumfang.HAFTPFLICHT_TEILKASKO:
            vertragsTypen = vertragsTypen.filter((vertragsTyp: TypVertrag) => vertragsTyp !== TypVertrag.vollkasko);
            break;
        case Deckungsumfang.HAFTPFLICHT_VOLLKASKO:
            vertragsTypen = vertragsTypen.filter((vertragsTyp: TypVertrag) => vertragsTyp !== TypVertrag.teilkasko);
            break;
        default:
            vertragsTypen = Object.values(TypVertrag);
    }

    if (ignoreTeilkasko) {
        vertragsTypen = vertragsTypen.filter((vertragsTyp: TypVertrag) => vertragsTyp !== TypVertrag.teilkasko);
    }

    Object.values(VersicherungsId).map((versicherungsId: VersicherungsId) => {
        const versicherung: VersicherungVertrag = {
            versicherungsId: versicherungsId,
            deckungsumfang: deckungsumfang
        };
        versicherungen.push(versicherung);

        vertragsTypen.map((typ: TypVertrag) => {
            const vertrag: Vertrag = {
                typ: typ,
                vertragsId: typ === TypVertrag.haftpflicht ? VertragsId.HAFTPFLICHT : VertragsId.KASKO,
                struktur: { versicherungen: versicherungsId },
                ...payload
            };

            return vertraege.push(vertrag);
        });

        return { versicherungen, vertraege };
    });

    return {
        versicherungen: versicherungen,
        vertraege: vertraege
    };
};

export const createSpecificVertragRequest = (
    payload: VertragOptionalRequestDto,
    deckungsumfang: Deckungsumfang,
    vertragsId: VertragsId,
    typ: TypVertrag
): OfferEngineVertraegeRequestDto => {
    let versicherungen: VersicherungVertrag[] = [];
    let vertraege: Vertrag[] = [];
    Object.values(VersicherungsId).map((versicherungsId: VersicherungsId) => {
        const versicherung: VersicherungVertrag = {
            versicherungsId: versicherungsId,
            deckungsumfang: deckungsumfang
        };
        const vertrag: Vertrag = {
            typ: typ,
            vertragsId: vertragsId,
            struktur: { versicherungen: versicherungsId },
            ...payload
        };
        versicherungen.push(versicherung);
        vertraege.push(vertrag);
        return { versicherungen, vertraege };
    });

    return {
        versicherungen: versicherungen,
        vertraege: vertraege
    };
};

export const createEvbRequest = (payload: boolean): OfferEngineEvbRequestDto => {// used to create the actual request body for OE that updates the value of the evb radio field.
    return {evb: { angefordert: payload}};
};
