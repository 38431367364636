import { WertebereichLokal } from '../util/WertebereicheHelper';
import { Typ } from '../util/fetch/personen/PersonDto';
import { Rollentyp } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export const wertebereichMailTyp: WertebereichLokal[] = [
    { value: Typ.privat, name: 'privat' },
    { value: Typ.geschaeftlich, name: 'geschäftlich' }
];

export const wertebereichRollen: WertebereichLokal[] = [
    { value: Rollentyp.VERSICHERUNGSNEHMER, name: 'Versicherungsnehmer' },
    { value: Rollentyp.HALTER, name: 'Halter' },
    { value: Rollentyp.BETEILIGTER_FAHRER, name: 'Begleiteter Fahrer' }
];
