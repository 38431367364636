import { ErrorObject, Ursprung } from '../util/fetch/client/ErrorObject';
import { Dispatch } from 'redux';

export const SPEICHERE_TECHNISCHE_FEHLER: string = 'SPEICHERE_TECHNISCHE_FEHLER';
export type SPEICHERE_TECHNISCHE_FEHLER = typeof SPEICHERE_TECHNISCHE_FEHLER;

export interface StoreTechnischeFehlerAction {
    type: SPEICHERE_TECHNISCHE_FEHLER;
    errorObject: ErrorObject;
}

export const storeTechnischeFehlerSync = (errorObject: ErrorObject): StoreTechnischeFehlerAction => {
    return {
        type: SPEICHERE_TECHNISCHE_FEHLER,
        errorObject: errorObject
    };
};

export function onRejectedStoreTechnischerFehler(e: Error, dispatch: Dispatch, className?: string): void {
    const errorObject: ErrorObject = {
        className,
        causeClassName: e.name,
        message: e.message,
        ursprung: Ursprung.URSPRUNG_RECEIVED_PROMISE
    };
    dispatch(storeTechnischeFehlerSync(errorObject));
}
