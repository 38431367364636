import { Dispatch } from 'redux';
import { FrontendResponse } from '../client/FrontendResponse';
import { sendPostRequest } from '../client/FetchBaseClient';
import { AbschlussDto } from './AbschlussDto';

export const postAntragPruefenHttpRequest = (businessId: string, dispatch: Dispatch, signal: AbortSignal): Promise<FrontendResponse<AbschlussDto>> => {
    return sendPostRequest(`/kfz2019/abschluss/${businessId}/antrag-pruefen/`, {}, dispatch, signal);
};

export const postDirektAbschlussHttpRequest = (businessId: string, dispatch: Dispatch): Promise<FrontendResponse<AbschlussDto>> => {
    return sendPostRequest(`/kfz2019/abschluss/${businessId}/direktabschluss/`, {}, dispatch);
};

export const postAngebotHttpRequest = (businessId: string, dispatch: Dispatch): Promise<FrontendResponse<AbschlussDto>> => {
    return sendPostRequest(`/kfz2019/abschluss/${businessId}/angebot/`, {}, dispatch);
};
