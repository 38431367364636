import { Fahrzeug, Hersteller, Kategorie, Kraftstoff, Leistung, Modell } from '../util/fetch/fahrzeugsuche/FahrzeugsucheDto';
import {
    AENDERE_HERSTELLER,
    AENDERE_HSN,
    AENDERE_KATEGORIE,
    AENDERE_KRAFTSTOFF,
    AENDERE_LEISTUNG,
    AENDERE_MODELL,
    AENDERE_SUCHART,
    AENDERE_TSN,
    ChangeHerstellerAction,
    ChangeHsnAction,
    ChangeKategorieAction,
    ChangeKraftstoffAction,
    ChangeLeistungAction,
    ChangeModellAction,
    ChangeSearchTypeAction,
    ChangeTsnAction,
    CloseFahrzeugsucheAction,
    FAHRZEUGSUCHE_ZURUECKSETZEN,
    GetFahrzeugsucheResponseAction,
    LADE_ERGEBNIS_FAHRZEUGSUCHE,
    ResetCloseFahrzeugsucheAction,
    SCHLIESSE_FAHRZEUGSUCHE,
    SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN,
    SelectFahrzeugAction,
    WAEHLE_FAHRZEUG
} from './FahrzeugsucheAction';
import { BITTE_WAEHLEN } from '../util/WertebereicheHelper';

export const MIT_KFZ_SCHEIN: string = 'mitKfzSchein';
export const OHNE_KFZ_SCHEIN: string = 'ohneKfzSchein';

export interface FahrzeugsucheState {
    searchType: string;
    herstellerAuswahl: Hersteller[];
    herstellerGewaehlt: string;
    modellAuswahl: Modell[];
    modellGewaehlt: string;
    kraftstoffAuswahl: Kraftstoff[];
    kraftstoffGewaehlt: string;
    kategorieAuswahl: Kategorie[];
    kategorieGewaehlt: string;
    leistungAuswahl: Leistung[];
    leistungGewaehlt: string;
    hsn: string;
    tsn: string;
    hoeherwertigesFahrzeug: boolean;
    fahrzeugsucheResponse: Fahrzeug[];
    showResponse: boolean;
    selectedFahrzeugRowIndex: number;
    closeFahrzeugsuche: boolean;
}

const initialFahrzeugsucheState = {
    searchType: MIT_KFZ_SCHEIN,
    herstellerAuswahl: [],
    herstellerGewaehlt: BITTE_WAEHLEN,
    modellAuswahl: [],
    modellGewaehlt: BITTE_WAEHLEN,
    kraftstoffAuswahl: [],
    kraftstoffGewaehlt: BITTE_WAEHLEN,
    kategorieAuswahl: [],
    kategorieGewaehlt: BITTE_WAEHLEN,
    leistungAuswahl: [],
    leistungGewaehlt: BITTE_WAEHLEN,
    hsn: '',
    tsn: '',
    hoeherwertigesFahrzeug: false,
    fahrzeugsucheResponse: [],
    showResponse: false,
    selectedFahrzeugRowIndex: -1,
    closeFahrzeugsuche: false
};

type FahrzeugsucheAction = ChangeSearchTypeAction &
    ChangeHsnAction &
    ChangeTsnAction &
    ChangeHerstellerAction &
    ChangeModellAction &
    ChangeKraftstoffAction &
    ChangeKategorieAction &
    ChangeLeistungAction &
    GetFahrzeugsucheResponseAction &
    SelectFahrzeugAction &
    CloseFahrzeugsucheAction &
    ResetCloseFahrzeugsucheAction;

export const fahrzeugsucheReducer = (state: FahrzeugsucheState = initialFahrzeugsucheState, action: FahrzeugsucheAction): FahrzeugsucheState => {
    switch (action.type) {
        case AENDERE_SUCHART: {
            return {
                ...state,
                searchType: action.payload.searchType,
                herstellerAuswahl: action.payload.hersteller,
                fahrzeugsucheResponse: [],
                showResponse: false
            };
        }
        case AENDERE_HSN: {
            return {
                ...state,
                hsn: action.hsn,
                fahrzeugsucheResponse: [],
                showResponse: false
            };
        }
        case AENDERE_TSN: {
            return {
                ...state,
                tsn: action.tsn,
                fahrzeugsucheResponse: [],
                showResponse: false
            };
        }
        case AENDERE_HERSTELLER: {
            return {
                ...state,
                herstellerGewaehlt: action.payload.hersteller,
                modellAuswahl: action.payload.modelle,
                modellGewaehlt: BITTE_WAEHLEN,
                kraftstoffAuswahl: [],
                kraftstoffGewaehlt: BITTE_WAEHLEN,
                kategorieAuswahl: [],
                kategorieGewaehlt: BITTE_WAEHLEN,
                leistungAuswahl: [],
                leistungGewaehlt: BITTE_WAEHLEN,
                fahrzeugsucheResponse: [],
                showResponse: false
            };
        }
        case AENDERE_MODELL: {
            return {
                ...state,
                modellGewaehlt: action.payload.modell,
                kraftstoffAuswahl: action.payload.kraftstoffe,
                kraftstoffGewaehlt: BITTE_WAEHLEN,
                kategorieAuswahl: [],
                kategorieGewaehlt: BITTE_WAEHLEN,
                leistungAuswahl: [],
                leistungGewaehlt: BITTE_WAEHLEN,
                fahrzeugsucheResponse: [],
                showResponse: false
            };
        }
        case AENDERE_KRAFTSTOFF: {
            return {
                ...state,
                kraftstoffGewaehlt: action.payload.kraftstoff,
                kategorieAuswahl: action.payload.kategorien,
                kategorieGewaehlt: BITTE_WAEHLEN,
                leistungAuswahl: [],
                leistungGewaehlt: BITTE_WAEHLEN,
                fahrzeugsucheResponse: [],
                showResponse: false
            };
        }
        case AENDERE_KATEGORIE: {
            return {
                ...state,
                kategorieGewaehlt: action.payload.kategorie,
                leistungAuswahl: action.payload.leistungen,
                leistungGewaehlt: BITTE_WAEHLEN,
                fahrzeugsucheResponse: [],
                showResponse: false
            };
        }
        case AENDERE_LEISTUNG: {
            return {
                ...state,
                leistungGewaehlt: action.payload.leistung,
                fahrzeugsucheResponse: action.payload.fahrzeuge,
                selectedFahrzeugRowIndex: -1,
                showResponse: true
            };
        }
        case LADE_ERGEBNIS_FAHRZEUGSUCHE: {
            return {
                ...state,
                fahrzeugsucheResponse: action.fahrzeuge,
                selectedFahrzeugRowIndex: -1,
                showResponse: true
            };
        }
        case WAEHLE_FAHRZEUG: {
            return {
                ...state,
                selectedFahrzeugRowIndex: action.payload.selectedFahrzeugRowIndex,
                hoeherwertigesFahrzeug: action.payload.hoeherwertigesFahrzeug
            };
        }
        case SCHLIESSE_FAHRZEUGSUCHE: {
            return {
                ...state,
                closeFahrzeugsuche: true
            };
        }
        case FAHRZEUGSUCHE_ZURUECKSETZEN: {
            return initialFahrzeugsucheState;
        }
        case SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN: {
            return {
                ...state,
                closeFahrzeugsuche: false
            };
        }
        default:
            return state;
    }
};
