import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ClassificationResponse, ErsteinstufungState } from './ErsteinstufungsReducer';
import { theme as mandator } from '../../index';
import { updateErsteinstufungAsync, updateScriptError, updateScriptLoaded } from './ErsteinstufungsAction';
import { EVENT_RENDER_ERSTEINSTUFUNG, EVENT_UNMOUNT_ERSTEINSTUFUNG, EVENTLISTENER_CHANGED_ERSTEINSTUFUNG, EVENTLISTENER_FINISHED_ERSTEINSTUFUNG } from './ErsteinstufungsEvents';
import { getUrl } from '../util/fetch/client/FetchBaseClient';
import { Person } from '../personen/PersonenReducer';
import { getPersonMitRolle } from '../util/PersonenHelper';
import { Rollentyp } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { getIsoDateString } from '../util/DateFormattingHelper';

const Script = require('react-load-script');

type ErsteinstufungsservicePresentationProps = ErsteinstufungState & {
    businessId: string;
    geburtsdatumVN: Date | undefined;
    onFinish: (detail: ClassificationResponse) => void;
    onScriptLoaded: () => void;
    onScriptError: () => void;
};

export class ErsteinstufungsservicePresentation extends React.Component<ErsteinstufungsservicePresentationProps, {}> {
    backendEndpoint = 'kfz2019/ersteinstufung';

    handleFinish = (event: Event) => {
        // @ts-ignore
        this.props.onFinish(event.detail);
    };

    handleChange = (event: Event) => {};

    handleOnLoad = () => {
        document.addEventListener(EVENTLISTENER_CHANGED_ERSTEINSTUFUNG, this.handleChange);
        document.addEventListener(EVENTLISTENER_FINISHED_ERSTEINSTUFUNG, this.handleFinish);

        this.props.onScriptLoaded();

        this.triggerErsteinstufungRemount();
    };

    onError = () => {
        this.props.onScriptError();
    };

    triggerErsteinstufungRemount() {
        if (!this.props.geburtsdatumVN) {
            return;
        }

        const { businessId } = this.props;
        const endpoint = getUrl(`${this.backendEndpoint}/${businessId}`);

        document.dispatchEvent(new CustomEvent(EVENT_UNMOUNT_ERSTEINSTUFUNG));

        const ev = new CustomEvent(EVENT_RENDER_ERSTEINSTUFUNG, {
            detail: {
                mandator: mandator,
                urlPrefix: endpoint,
                dateOfBirth: getIsoDateString(this.props.geburtsdatumVN)
            }
        });

        document.dispatchEvent(ev);
    }

    componentDidUpdate(prevProps: Readonly<ErsteinstufungsservicePresentationProps>): void {
        if (!this.props.geburtsdatumVN || this.props.geburtsdatumVN === prevProps.geburtsdatumVN) {
            return;
        }

        this.triggerErsteinstufungRemount();
    }

    componentWillUnmount() {
        document.removeEventListener(EVENTLISTENER_CHANGED_ERSTEINSTUFUNG, this.handleChange);
        document.removeEventListener(EVENTLISTENER_FINISHED_ERSTEINSTUFUNG, this.handleFinish);
        document.dispatchEvent(new CustomEvent(EVENT_UNMOUNT_ERSTEINSTUFUNG));
    }

    render() {
        const { display } = this.props;

        return (
            <div id="ersteinstufungsserviceSection" className="col-md-4 col-sm-12 col-lg-4" key="ersteinstufungsServiceComponent">
                {display && (
                    <Script url={getUrl(`${this.backendEndpoint}/js/main.js`)} defer={true} onLoad={this.handleOnLoad.bind(this)} onError={this.onError.bind(this)} />
                )}
                <div style={{ display: display ? 'block' : 'none' }}>
                    <div className="sectionLabel">SFR-Einstufung</div>
                    <div id="ersteinstufungsserviceRoot" className="row col-sm-12" />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IAppState) => {
    const versicherungsnehmer: Person | null = getPersonMitRolle(state.personen.personen, Rollentyp.VERSICHERUNGSNEHMER);

    return {
        businessId: state.basisdaten.businessId,
        display: state.ersteinstufung!.display && !state.abschluss.abschlussErfolgreich,
        geburtsdatumVN: versicherungsnehmer ? versicherungsnehmer.geburtsdatum : undefined,
        isErsteinstufungsServiceLoaded: state.ersteinstufung!.isErsteinstufungsServiceLoaded,
        isErsteinstufungsServiceLoadFailed: state.ersteinstufung!.isErsteinstufungsServiceLoadFailed
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onScriptLoaded: (): void => {
        // @ts-ignore
        dispatch(updateScriptLoaded());
    },
    onScriptError: (): void => {
        // @ts-ignore
        dispatch(updateScriptError());
    },

    onFinish: (detail: ClassificationResponse): void => {
        // @ts-ignore
        dispatch(updateErsteinstufungAsync(detail));
    }
});

export const Ersteinstufungsservice = connect(mapStateToProps, mapDispatchToProps)(ErsteinstufungsservicePresentation);
