import { Bankverbindung, Typ, WertebereichAufzaehlung } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import {
    AENDERE_ABBUCHUNGSTAG,
    AENDERE_AKTIVIERUNG_IBAN_FELD,
    ChangeAbbuchungstagAction,
    ChangeActivationIbanFieldAction,
    IbanVerificationStatus,
    SaveNewVersicherungsnehmerAction,
    ShowBankverbindungIsLoadingSpinnerAction,
    AKTUALISIERE_VERSICHERUNGSNEHMER,
    ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER,
    AENDERE_GEWAEHLTEN_ROW_ENTRY,
    ChangeSelectedRowEntryAction,
    AENDERE_IBAN_ENTRY,
    UpdateIbanEntryAction,
    SETZE_IBAN_NICHT_VALIDE
} from './BankverbindungAction';
import { beautifyIban, normalizeIban } from '@eg/elements/utils/validation/iban';
import { Person } from '../personen/PersonenReducer';
import { getWertebereichFromAngebot } from '../util/WertebereicheHelper';

export const ANDERE_IBAN: string = 'Andere IBAN angeben';

export interface BankverbindungState extends Bankverbindung {
    versicherungsnehmer: Person | null;
    selectRowEntries: string[];
    selectedRowEntry: string;

    /*
    kontoinhaber: string;
    iban: string;
    bic: string;
    bankname: string;
    */

    ibanValidationStatus: string;
    ibanVerificationStatus: IbanVerificationStatus;
    isIbanUnderVerification: boolean;
    showBankverbindungIsLoadingSpinner: boolean;
    abbuchungstag: number;
    wertebereichAbbuchungstag: WertebereichAufzaehlung;
}

const initialBankverbindungState: BankverbindungState = {
    versicherungsnehmer: null,
    selectRowEntries: [ANDERE_IBAN],
    selectedRowEntry: ANDERE_IBAN,

    kontoinhaber: '',
    iban: '',
    bic: '',
    bankname: '',

    ibanValidationStatus: '',
    ibanVerificationStatus: IbanVerificationStatus.NOT_VERIFIED,
    isIbanUnderVerification: false,
    showBankverbindungIsLoadingSpinner: false,
    abbuchungstag: 1,
    wertebereichAbbuchungstag: {
        werte: [],
        attribut: '',
        obligatorisch: false,
        typ: Typ.AUFZAEHLUNG,
        vorbelegung: {}
    }
};

type BankverbindungAction = LoadOfferAction &
    SaveNewVersicherungsnehmerAction &
    ChangeSelectedRowEntryAction &
    UpdateIbanEntryAction &
    ChangeActivationIbanFieldAction &
    ShowBankverbindungIsLoadingSpinnerAction &
    ChangeAbbuchungstagAction;

export const bankverbindungReducer = (state: BankverbindungState = initialBankverbindungState, action: BankverbindungAction): BankverbindungState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            if (action.angebot.bankverbindung) {
                const ibanAusAngebot: string | undefined =
                    action.angebot.bankverbindung.iban && action.angebot.bankverbindung.iban.length > 0 ? beautifyIban(action.angebot.bankverbindung.iban) : undefined;
                return {
                    ...state,
                    kontoinhaber:
                        action.angebot.bankverbindung.kontoinhaber && action.angebot.bankverbindung.kontoinhaber !== ''
                            ? action.angebot.bankverbindung.kontoinhaber
                            : state.versicherungsnehmer
                            ? state.versicherungsnehmer.vorname + ' ' + state.versicherungsnehmer.nachname
                            : '',
                    iban: action.angebot.bankverbindung.iban,
                    bic: action.angebot.bankverbindung.bic,
                    bankname: action.angebot.bankverbindung.bankname,
                    selectedRowEntry: ibanAusAngebot && state.selectRowEntries.includes(ibanAusAngebot) ? ibanAusAngebot : ANDERE_IBAN,
                    ibanVerificationStatus: ibanAusAngebot ? IbanVerificationStatus.SUCCESSFULLY_VERIFIED : IbanVerificationStatus.NOT_VERIFIED,
                    abbuchungstag: action.angebot.versicherungen && action.angebot.versicherungen.length > 0 ? action.angebot.versicherungen[0].abbuchungstag : 1,
                    wertebereichAbbuchungstag:
                        action.angebot.versicherungen && action.angebot.versicherungen.length > 0
                            ? getWertebereichFromAngebot<WertebereichAufzaehlung>('abbuchungstag', action.angebot.versicherungen[0].wertebereiche)
                            : initialBankverbindungState.wertebereichAbbuchungstag
                };
            } else {
                return {
                    ...state,
                    kontoinhaber: state.versicherungsnehmer ? state.versicherungsnehmer.vorname + ' ' + state.versicherungsnehmer.nachname : '',
                    selectedRowEntry: ANDERE_IBAN,
                    ibanVerificationStatus: IbanVerificationStatus.NOT_VERIFIED,
                    abbuchungstag: action.angebot.versicherungen && action.angebot.versicherungen.length > 0 ? action.angebot.versicherungen[0].abbuchungstag : 1
                };
            }
        }

        case AKTUALISIERE_VERSICHERUNGSNEHMER: {
            return {
                ...state,
                versicherungsnehmer: action.versicherungsnehmer,
                selectRowEntries: action.versicherungsnehmer!.bankverbindungen
                    ? action.versicherungsnehmer!.bankverbindungen.map(bv => beautifyIban(bv.iban)).concat([ANDERE_IBAN])
                    : [ANDERE_IBAN],
                selectedRowEntry:
                    action.versicherungsnehmer!.bankverbindungen && action.versicherungsnehmer!.bankverbindungen.length > 0
                        ? action.versicherungsnehmer!.bankverbindungen[0].iban
                        : ANDERE_IBAN,
                kontoinhaber: action.versicherungsnehmer ? action.versicherungsnehmer.vorname + ' ' + action.versicherungsnehmer.nachname : ''
            };
        }

        case AENDERE_GEWAEHLTEN_ROW_ENTRY: {
            if (action.iban !== ANDERE_IBAN) {
                return {
                    ...state,
                    selectedRowEntry: action.iban,
                    ...state.versicherungsnehmer!.bankverbindungen.find(bv => bv.iban === normalizeIban(action.iban)),
                    ibanVerificationStatus: IbanVerificationStatus.SUCCESSFULLY_VERIFIED
                };
            } else {
                return {
                    ...state,
                    selectedRowEntry: ANDERE_IBAN,
                    iban: '',
                    bic: '',
                    bankname: '',
                    ibanVerificationStatus: IbanVerificationStatus.NOT_VERIFIED
                };
            }
        }

        case AENDERE_IBAN_ENTRY: {
            return {
                ...state,
                iban: action.iban,
                bic: '',
                bankname: '',
                ibanValidationStatus: action.ibanValidationStatus,
                ibanVerificationStatus: IbanVerificationStatus.NOT_VERIFIED
            };
        }

        case AENDERE_AKTIVIERUNG_IBAN_FELD: {
            return {
                ...state,
                isIbanUnderVerification: action.isIbanUnderVerification
            };
        }

        case ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER: {
            return {
                ...state,
                showBankverbindungIsLoadingSpinner: action.showBankverbindungIsLoadingSpinner
            };
        }

        case SETZE_IBAN_NICHT_VALIDE: {
            return {
                ...state,
                bic: 'Diese IBAN ist nicht korrekt. Bitte korrigieren Sie Ihre Angabe.',
                bankname: 'Diese IBAN ist nicht korrekt. Bitte korrigieren Sie Ihre Angabe.',
                ibanVerificationStatus: IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED
            };
        }

        case AENDERE_ABBUCHUNGSTAG: {
            return {
                ...state,
                abbuchungstag: action.abbuchungstag
            };
        }

        default:
            return state;
    }
};
