import { ErstfahrzeugInnerRadioButton, OuterRadioButton } from './AngebotsgrundWertebereiche';
import {
    AENDERE_ANGEBOTSGRUND,
    AENDERE_ERSTFAHRZEUG_RADIO_BUTTON,
    AENDERE_OUTER_RADIO_BUTTON,
    ChangeAngebotsgrundAction,
    ChangeErstfahrzeugRadioButtonAction,
    ChangeFahrzeugwechselRadioButtonAction,
    ChangeOuterRadioButtonAction,
    CloseErgoMeldungAction,
    SCHLIESSE_ERGO_MELDUNG
} from './AngebotsgrundAction';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import { Angebotsgrund, SfrEinstufung } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export interface AngebotsgrundState {
    selectedOuterRadioButton: OuterRadioButton;
    selectedErstfahrzeugInnerRadioButton: ErstfahrzeugInnerRadioButton;
    showErstfahrzeugInnerRadioButtons: boolean;
    showFahrzeugwechselInnerRadioButtons: boolean;
    showMeldungErgoFahrzeug: boolean;
    angebotsgrund: Angebotsgrund;
}

const initialAngebotsgrundState: AngebotsgrundState = {
    selectedOuterRadioButton: OuterRadioButton.ERSTVERTRAG,
    selectedErstfahrzeugInnerRadioButton: ErstfahrzeugInnerRadioButton.SFK_MIT_EES,
    showErstfahrzeugInnerRadioButtons: true,
    showFahrzeugwechselInnerRadioButtons: false,
    showMeldungErgoFahrzeug: false,
    angebotsgrund: Angebotsgrund.ERSTVERTRAG
};

type AngebotsgrundAction = LoadOfferAction &
    ChangeOuterRadioButtonAction &
    ChangeErstfahrzeugRadioButtonAction &
    ChangeFahrzeugwechselRadioButtonAction &
    CloseErgoMeldungAction &
    ChangeAngebotsgrundAction;

export const angebotsgrundReducer = (state: AngebotsgrundState = initialAngebotsgrundState, action: AngebotsgrundAction): AngebotsgrundState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return getInitialState(state, action.angebot.angebotsgrund, action.angebot.sfrEinstufung);
        }
        case AENDERE_OUTER_RADIO_BUTTON: {
            return {
                ...state,
                selectedOuterRadioButton: action.payload.outerRadioButton,
                showErstfahrzeugInnerRadioButtons: action.payload.showErstfahrzeugInnerRadioButtons,
                showMeldungErgoFahrzeug: action.payload.showMeldungErgoFahrzeug
            };
        }
        case AENDERE_ERSTFAHRZEUG_RADIO_BUTTON: {
            return {
                ...state,
                selectedErstfahrzeugInnerRadioButton: action.payload.erstfahrzeugInnerRadioButton,
                showMeldungErgoFahrzeug: false
            };
        }
        case SCHLIESSE_ERGO_MELDUNG: {
            return {
                ...state,
                showMeldungErgoFahrzeug: false
            };
        }
        case AENDERE_ANGEBOTSGRUND: {
            return {
                ...state,
                angebotsgrund: action.angebotsgrund
            };
        }
        default:
            return state;
    }
};

const getInitialState = (state: AngebotsgrundState, angebotsgrund: Angebotsgrund, sfrEinstufung: SfrEinstufung): AngebotsgrundState => {
    switch (angebotsgrund) {
        case Angebotsgrund.ERSTVERTRAG: {
            return {
                ...state,
                selectedOuterRadioButton: OuterRadioButton.ERSTVERTRAG,
                selectedErstfahrzeugInnerRadioButton:
                    sfrEinstufung === null || !sfrEinstufung.uebernahmePerson ? ErstfahrzeugInnerRadioButton.SFK_MIT_EES : ErstfahrzeugInnerRadioButton.SFK_UEBERNAHME_PERSON,
                showErstfahrzeugInnerRadioButtons: true,
                showFahrzeugwechselInnerRadioButtons: false,
                showMeldungErgoFahrzeug: false,
                angebotsgrund
            };
        }
        case Angebotsgrund.VERSICHERER_WECHSEL: {
            return {
                ...state,
                selectedOuterRadioButton: OuterRadioButton.VERSICHERER_WECHSEL,
                showErstfahrzeugInnerRadioButtons: false,
                showFahrzeugwechselInnerRadioButtons: false,
                showMeldungErgoFahrzeug: false,
                angebotsgrund
            };
        }
        case Angebotsgrund.BISHERIGES_FAHRZEUG_ERGO: {
            return {
                ...state,
                selectedOuterRadioButton: OuterRadioButton.FAHRZEUG_WECHSELN,
                showErstfahrzeugInnerRadioButtons: false,
                showFahrzeugwechselInnerRadioButtons: true,
                showMeldungErgoFahrzeug: true,
                angebotsgrund
            };
        }
        case Angebotsgrund.BISHERIGES_FAHRZEUG_ANDERER_VERSICHERER: {
            return {
                ...state,
                selectedOuterRadioButton: OuterRadioButton.FAHRZEUG_WECHSELN,
                showErstfahrzeugInnerRadioButtons: false,
                showFahrzeugwechselInnerRadioButtons: true,
                showMeldungErgoFahrzeug: false,
                angebotsgrund
            };
        }
        default:
            return state;
    }
};
