import { Dispatch } from 'redux';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { updateSfrEinstufungHttpRequest, updateVorversicherungHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { UpdateVorversicherungRequestDto } from '../util/fetch/offerengine/UpdateVorversicherungRequestDto';
import { VersicherungsGesellschaft, Vorschaeden } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { FieldAction } from '../util/FieldAction';
import { OfferEngineSfrErstwagenErgoZweitwagenDto } from '../util/fetch/offerengine/OfferEngineSfrEinstufungRequestDto';
import { changeFahrzeugFieldSync, showKennzeichenIsSavingSpinnerSync } from '../fahrzeugnutzung/FahrzeugnutzungAction';
import { ERGO_VORVERSICHERUNG } from './VorversicherungReducer';

export const AENDERE_BISHERIGES_KENNZEICHEN: string = 'AENDERE_BISHERIGES_KENNZEICHEN';
export const LADE_VERSICHERUNGS_GESELLSCHAFTEN: string = 'LADE_VERSICHERUNGS_GESELLSCHAFTEN';
export const AENDERE_VORVERSICHERUNG: string = 'AENDERE_VORVERSICHERUNGEN';
export const AENDERE_GESELLSCHAFT: string = 'AENDERE_GESELLSCHAFT';
export const RESET_VORSCHAEDEN: string = 'RESET_VORSCHAEDEN';
export const AENDERE_BEI_ERGO_VERSICHERT: string = 'AENDERE_BEI_ERGO_VERSICHERT';
export const AENDERE_SCHADENSFREIHEITSKLASSE: string = 'AENDERE_SCHADENSFREIHEITSKLASSE';
export const AENDERE_SCHADENSFREIHEITSKLASSE_VSNR: string = 'AENDERE_SCHADENSFREIHEITSKLASSE_VSNR';

export interface LoadVersicherungenAction {
    type: typeof LADE_VERSICHERUNGS_GESELLSCHAFTEN;
    payload: {
        versicherungsGesellschaften: VersicherungsGesellschaft[];
    };
}

export const loadVorversicherungenSync = (versicherungsGesellschaften: VersicherungsGesellschaft[]): LoadVersicherungenAction => {
    return {
        type: LADE_VERSICHERUNGS_GESELLSCHAFTEN,
        payload: {
            versicherungsGesellschaften
        }
    };
};

export const updateVorversicherungenAsync = (businessId: string, fieldName: string, fieldValue: string | boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(changeVorversicherungFieldSync(fieldName, fieldValue));

        const requestDto: UpdateVorversicherungRequestDto = {
            vorversicherung: {
                [fieldName]: fieldValue
            }
        };
        return dispatchUpdateVorversicherungHttpRequestAsync(businessId, requestDto, dispatch);
    };
};

export const resetVorschaedenAsync = (businessId: string, value: Vorschaeden) => {
    return (dispatch: Dispatch) => {
        dispatch(resetVorschaedenSync(value));

        const requestDto: UpdateVorversicherungRequestDto = {
            vorversicherung: {
                vorschaedenHaftpflicht: value,
                vorschaedenTeilkasko: value,
                vorschaedenVollkasko: value
            }
        };
        return dispatchUpdateVorversicherungHttpRequestAsync(businessId, requestDto, dispatch);
    };
};

export interface ResetVorschaedenAction {
    type: typeof RESET_VORSCHAEDEN;
    payload: {
        vorschadenHaftpflicht: Vorschaeden;
        vorschadenTeilkasko: Vorschaeden;
        vorschadenVollkasko: Vorschaeden;
    };
}

const resetVorschaedenSync = (vorschadenValue: Vorschaeden): ResetVorschaedenAction => {
    return {
        type: RESET_VORSCHAEDEN,
        payload: {
            vorschadenHaftpflicht: vorschadenValue,
            vorschadenTeilkasko: vorschadenValue,
            vorschadenVollkasko: vorschadenValue
        }
    };
};

const dispatchUpdateVorversicherungHttpRequestAsync = (businessId: string, requestDto: UpdateVorversicherungRequestDto, dispatch: Dispatch) => {
    return updateVorversicherungHttpRequest(businessId, requestDto, dispatch)
        .then(response => onFulfilledStoreOffer(response, dispatch))
        .catch(e => onRejectedStoreTechnischerFehler(e, dispatch))
        .finally(() => dispatch(showKennzeichenIsSavingSpinnerSync(false)));
};

export interface ChangeVorversicherungFieldAction {
    type: typeof AENDERE_VORVERSICHERUNG;
    payload: FieldAction;
}

export const changeVorversicherungFieldSync = (fieldName: string, fieldValue: string | boolean): ChangeVorversicherungFieldAction => {
    return {
        type: AENDERE_VORVERSICHERUNG,
        payload: {
            fieldName,
            fieldValue
        }
    };
};

export interface ChangeGesellschaftAction {
    type: typeof AENDERE_GESELLSCHAFT;
    payload: {
        gesellschaft: string;
        versicherungsunternehmennummer: string;
        beiErgoVersichert: boolean;
    };
}

const changeGesellschaftSync = (gesellschaft: string | null, versicherungsunternehmennummer: string | null, beiErgoVersichert: boolean = false): ChangeGesellschaftAction => {
    return {
        type: AENDERE_GESELLSCHAFT,
        payload: {
            gesellschaft: gesellschaft ? gesellschaft : '',
            versicherungsunternehmennummer: versicherungsunternehmennummer ? versicherungsunternehmennummer : '',
            beiErgoVersichert
        }
    };
};

export const updateGesellschaftAsync = (businessId: string, gesellschaft: string | null, versicherungsunternehmennummer: string | null, vorversicherungErgo: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(changeGesellschaftSync(gesellschaft, versicherungsunternehmennummer, vorversicherungErgo));

        const requestDto: UpdateVorversicherungRequestDto = {
            vorversicherung: {
                gesellschaft,
                versicherungsunternehmennummer,
                vorversicherungErgo
            }
        };
        return dispatchUpdateVorversicherungHttpRequestAsync(businessId, requestDto, dispatch);
    };
};

export const updateKennzeichenAsync = (businessId: string, fieldName: string, fieldValue: string) => {
    return (dispatch: Dispatch) => {
        dispatch(changeKennzeichenSync(fieldName, fieldValue));

        dispatch(showKennzeichenIsSavingSpinnerSync(true));

        const requestDto: UpdateVorversicherungRequestDto = {
            vorversicherung: {
                bisherigesAmtlichesKennzeichen: {
                    [fieldName]: fieldValue === undefined || fieldValue === '' ? null : fieldValue
                }
            }
        };
        return dispatchUpdateVorversicherungHttpRequestAsync(businessId, requestDto, dispatch);
    };
};

export interface ChangeBisherigesKennzeichenAction {
    type: typeof AENDERE_BISHERIGES_KENNZEICHEN;
    payload: FieldAction;
}

export const changeKennzeichenSync = (fieldName: string, fieldValue: string): ChangeBisherigesKennzeichenAction => {
    return {
        type: AENDERE_BISHERIGES_KENNZEICHEN,
        payload: {
            fieldName,
            fieldValue
        }
    };
};

export interface ChangeSfrAction {
    type: typeof AENDERE_SCHADENSFREIHEITSKLASSE;
    payload: {
        uebernahmePerson: boolean;
        uebernahmeVorvertrag: boolean;
    };
}

const changeSfrEinstufungSync = (uebernahmePerson: boolean, uebernahmeVorvertrag: boolean): ChangeSfrAction => {
    return {
        type: AENDERE_SCHADENSFREIHEITSKLASSE,
        payload: {
            uebernahmePerson,
            uebernahmeVorvertrag
        }
    };
};

export const updateSfrEinstufungAsync = (businessId: string, uebernahmePerson: boolean, uebernahmeVorvertrag: boolean, beiErgoVersichert: boolean) => {
    return (dispatch: Dispatch) => {
        const request: OfferEngineSfrErstwagenErgoZweitwagenDto = {
            sfrEinstufung: {
                uebernahmePerson,
                uebernahmeVorvertrag
            },
            versichertesKfz: {
                zweitwagen: beiErgoVersichert
            },
            vorversicherung: {
                vorversicherungErgo: beiErgoVersichert
            }
        };

        dispatch(changeBeiErgoVersichertSync(beiErgoVersichert));
        dispatch(changeSfrEinstufungSync(uebernahmePerson, uebernahmeVorvertrag));
        dispatch(changeFahrzeugFieldSync('zweitwagen', beiErgoVersichert));

        return updateSfrEinstufungHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeBeiErgoVersichertAction {
    type: typeof AENDERE_BEI_ERGO_VERSICHERT;
    beiErgoVersichert: boolean;
}

const changeBeiErgoVersichertSync = (beiErgoVersichert: boolean): ChangeBeiErgoVersichertAction => {
    return {
        type: AENDERE_BEI_ERGO_VERSICHERT,
        beiErgoVersichert
    };
};

export const updateBeiErgoVersichertAsync = (businessId: string, beiErgoVersichert: boolean) => {
    return (dispatch: Dispatch) => {
        const gesellschaft = beiErgoVersichert ? ERGO_VORVERSICHERUNG.name : null;
        const versicherungsunternehmennummer = beiErgoVersichert ? ERGO_VORVERSICHERUNG.versicherungsunternehmennummer : null;

        const request: OfferEngineSfrErstwagenErgoZweitwagenDto = {
            vorversicherung: {
                gesellschaft,
                versicherungsunternehmennummer,
                vorversicherungErgo: beiErgoVersichert,
                vsnr: null
            }
        };
        dispatch(changeGesellschaftSync(gesellschaft, versicherungsunternehmennummer, beiErgoVersichert));
        dispatch(changeVorversicherungFieldSync('vsnr', ''));

        return updateVorversicherungHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeVsnrErstwagenErgoAction {
    type: typeof AENDERE_BEI_ERGO_VERSICHERT;
    vsnrErstwagenErgo: string;
}
export const changeVsnrErstwagenErgoSync = (vsnrErstwagenErgo: string): ChangeVsnrErstwagenErgoAction => {
    return {
        type: AENDERE_SCHADENSFREIHEITSKLASSE_VSNR,
        vsnrErstwagenErgo
    };
};
export const updateVsnrErstwagenErgoAsync = (businessId: string, vsnrErstwagenErgo: string, beiErgoVersichert: boolean) => {
    return (dispatch: Dispatch) => {
        const request: OfferEngineSfrErstwagenErgoZweitwagenDto = {
            sfrEinstufung: {
                vsnrErstwagenErgo
            }
        };

        dispatch(changeVsnrErstwagenErgoSync(vsnrErstwagenErgo));

        return updateSfrEinstufungHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateFahrzeugBereitsBeiErgoVersichertAsync = (businessId: string, erstFahrzeugBeiErgoVersichert: boolean) => {
    return (dispatch: Dispatch) => {
        const request: OfferEngineSfrErstwagenErgoZweitwagenDto = {
            sfrEinstufung: {
                vsnrErstwagenErgo: erstFahrzeugBeiErgoVersichert ? '' : null
            },
            versichertesKfz: {
                zweitwagen: erstFahrzeugBeiErgoVersichert
            }
        };
        dispatch(changeVsnrErstwagenErgoSync('')); // set to empty string for ui
        dispatch(changeFahrzeugFieldSync('zweitwagen', request.versichertesKfz!.zweitwagen));

        return updateSfrEinstufungHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};
