import { Dispatch } from 'redux';
import { OfferEngineVersicherungenRequestDto, Versicherung, VersicherungOptionalRequestDto } from '../util/fetch/offerengine/OfferEngineVersicherungenRequestDto';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import {
    Deckungsschluessel,
    Deckungsumfang,
    Einstufungsgrund,
    Leistungsvereinbarungsart,
    OfferEngineAngebotDto,
    Schadenfreiheitsklasse,
    TypVertrag,
    VersicherungsId,
    VertragsId
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { updateLeistungsvereinbarungHttpRequest, updateVersicherungenHttpRequest, updateVertraegeHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { OfferEngineVertraegeRequestDto, VertragOptionalRequestDto } from '../util/fetch/offerengine/OfferEngineVertraegeRequestDto';
import { createSpecificVertragRequest, createVersicherungenRequest } from '../util/OfferEngineRequestHelper';
import { OfferEngineLeistungsvereinbarungenRequestDto } from '../util/fetch/offerengine/OfferEngineLeistungsvereinbarungenRequestDto';
import { isDate } from '../util/validate';
import { getIsoDateString } from '../util/DateFormattingHelper';
import { Person } from '../personen/PersonenReducer';
import { updateMailAdressenHttpRequest } from '../util/fetch/personen/PersonController';
import { UpdateMailAdressenRequestDto } from '../util/fetch/personen/UpdateMailAdressenRequestDto';
import { MailAdresse, PersonDto, Typ } from '../util/fetch/personen/PersonDto';
import { speicherPersonSync } from '../personen/PersonenAction';
import { IAppState } from '../app/IAppState';
import { ClassificationResponse } from '../ersteinstufungsservice/ErsteinstufungsReducer';
import { BITTE_WAEHLEN } from '../util/WertebereicheHelper';

export const AENDERE_VERSICHERUNGSFELD: string = 'AENDERE_VERSICHERUNGSFELD';
export type AENDERE_VERSICHERUNGSFELD = typeof AENDERE_VERSICHERUNGSFELD;
export const AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG: string = 'AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG';
export type AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG = typeof AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG;
export const AENDERE_AUSWAHL_VERSICHERUNG: string = 'AENDERE_AUSWAHL_VERSICHERUNG';
export type AENDERE_AUSWAHL_VERSICHERUNG = typeof AENDERE_AUSWAHL_VERSICHERUNG;
export const ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ: string = 'ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ';
export type ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ = typeof ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ;

export interface ChangeVersicherungFieldAction {
    type: AENDERE_VERSICHERUNGSFELD;
    payload: {
        fieldName: string;
        fieldValue: string | Date | undefined;
    };
}

export const changeVersicherungFieldSync = (fieldName: string, fieldValue: string | Date | undefined): ChangeVersicherungFieldAction => {
    return {
        type: AENDERE_VERSICHERUNGSFELD,
        payload: {
            fieldName: fieldName,
            fieldValue: fieldValue
        }
    };
};

export interface ChangeLeistungsvereinbarungSelectionAction {
    type: AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG;
    payload: {
        versicherungsId: VersicherungsId;
        leistungsvereinbarungsart: Leistungsvereinbarungsart;
        checked: boolean;
    };
}

export const changeLeistungsvereinbarungSelectionSync = (
    versicherungsId: VersicherungsId,
    leistungsvereinbarungsart: Leistungsvereinbarungsart,
    checked: boolean
): ChangeLeistungsvereinbarungSelectionAction => {
    return {
        type: AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG,
        payload: {
            versicherungsId: versicherungsId,
            leistungsvereinbarungsart: leistungsvereinbarungsart,
            checked: checked
        }
    };
};

export interface ChangeVersicherungSelectionAction {
    type: AENDERE_AUSWAHL_VERSICHERUNG;
    payload: {
        versicherungsId: VersicherungsId;
    };
}

export const changeVersicherungSelectionSync = (versicherungsId: VersicherungsId): ChangeVersicherungSelectionAction => {
    return {
        type: AENDERE_AUSWAHL_VERSICHERUNG,
        payload: {
            versicherungsId: versicherungsId
        }
    };
};

export const updateVersicherungFieldAsync = (businessId: string, fieldName: string, fieldValue: string | Date) => {
    return (dispatch: Dispatch) => {
        return updateVersicherungField(businessId, fieldName, fieldValue, dispatch);
    };
};

const updateVersicherungField = (businessId: string, fieldName: string, fieldValue: string | Date, dispatch: Dispatch) => {
    dispatch(changeVersicherungFieldSync(fieldName, fieldValue));
    const value = isDate(fieldValue) ? getIsoDateString(fieldValue as Date) : fieldValue;

    const payload: VersicherungOptionalRequestDto = {
        [fieldName]: value
    };

    const request: OfferEngineVersicherungenRequestDto = createVersicherungenRequest(payload);
    return updateVersicherungenHttpRequest(businessId, request, dispatch)
        .then(response => onFulfilledStoreOffer(response, dispatch))
        .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
};

export const updateDeckungsumfangVollkaskoAsync = (businessId: string, deckungsumfang: Deckungsumfang) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const detail: ClassificationResponse | undefined = getState().ersteinstufung.detail;

        if (detail && deckungsumfang === Deckungsumfang.HAFTPFLICHT_VOLLKASKO) {
            const einstufungsgrund: Einstufungsgrund | null = detail ? (detail.context.id as Einstufungsgrund) : null;
            const schadenfreiheitsklasse: Schadenfreiheitsklasse | null = detail ? (detail.id as Schadenfreiheitsklasse) : null;

            dispatch(changeVersicherungFieldSync('sfkVollkasko', schadenfreiheitsklasse ? schadenfreiheitsklasse : BITTE_WAEHLEN));

            const payload: VertragOptionalRequestDto = {
                schadenfreiheitsrabatt: {
                    schadenfreiheitsklasse: schadenfreiheitsklasse,
                    einstufungsgrund
                }
            };

            const request: OfferEngineVertraegeRequestDto = createSpecificVertragRequest(payload, deckungsumfang, VertragsId.KASKO, TypVertrag.vollkasko);

            return updateVertraegeHttpRequest(businessId, request, dispatch)
                .then(response => onFulfilledStoreOffer(response, dispatch))
                .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
        }

        return updateVersicherungField(businessId, 'deckungsumfang', deckungsumfang, dispatch);
    };
};

export const updateDeckungsschluesselAsync = (
    businessId: string,
    deckungsschluessel: Deckungsschluessel,
    deckungsumfang: Deckungsumfang,
    vertragsId: VertragsId,
    typ: TypVertrag
) => {
    return (dispatch: Dispatch) => {
        if (typ === TypVertrag.teilkasko) {
            dispatch(changeVersicherungFieldSync('selbstbeteiligungTeilkasko', deckungsschluessel));
        }

        if (typ === TypVertrag.vollkasko) {
            dispatch(changeVersicherungFieldSync('selbstbeteiligungVollkasko', deckungsschluessel));
        }

        const payload: VertragOptionalRequestDto = {
            deckungsschluessel: deckungsschluessel
        };
        const request: OfferEngineVertraegeRequestDto = createSpecificVertragRequest(payload, deckungsumfang, vertragsId, typ);
        return updateVertragAsync(businessId, request, dispatch);
    };
};

export const updateSchadenfreiheitsklasseAsync = (
    businessId: string,
    schadenfreiheitsklasse: Schadenfreiheitsklasse | undefined,
    deckungsumfang: Deckungsumfang,
    vertragsId: VertragsId,
    typ: TypVertrag
) => {
    return (dispatch: Dispatch) => {
        if (typ === TypVertrag.haftpflicht) {
            dispatch(changeVersicherungFieldSync('sfkHaftpflicht', schadenfreiheitsklasse));
        }

        if (typ === TypVertrag.vollkasko) {
            dispatch(changeVersicherungFieldSync('sfkVollkasko', schadenfreiheitsklasse));
        }

        const payload: VertragOptionalRequestDto = {
            schadenfreiheitsrabatt: {
                schadenfreiheitsklasse: schadenfreiheitsklasse || null
            }
        };
        const request: OfferEngineVertraegeRequestDto = createSpecificVertragRequest(payload, deckungsumfang, vertragsId, typ);
        return updateVertragAsync(businessId, request, dispatch);
    };
};

const updateVertragAsync = (businessId: string, request: OfferEngineVertraegeRequestDto, dispatch: Dispatch) => {
    return updateVertraegeHttpRequest(businessId, request, dispatch)
        .then(response => onFulfilledStoreOffer(response, dispatch))
        .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
};

export const updateLeistungsvereinbarungAsync = (businessId: string, versicherungsId: VersicherungsId, leistungsvereinbarungsart: Leistungsvereinbarungsart, checked: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(changeLeistungsvereinbarungSelectionSync(versicherungsId, leistungsvereinbarungsart, checked));

        const payload: OfferEngineLeistungsvereinbarungenRequestDto = {
            leistungsvereinbarungen: [
                {
                    leistungsvereinbarungsart: leistungsvereinbarungsart,
                    struktur: { versicherungen: versicherungsId },
                    vereinbart: checked
                }
            ]
        };
        return updateLeistungsvereinbarungHttpRequest(businessId, payload, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export const updateSelectVersicherungAsync = (businessId: string, versicherungsId: VersicherungsId) => {
    return (dispatch: Dispatch) => {
        let waitForResponse = setTimeout(() => {
            dispatch(showVersicherungsschutzLoadingSpinnerSync(true));
        }, 200);
        dispatch(changeVersicherungSelectionSync(versicherungsId));

        let versicherungen: Versicherung[] = [];
        Object.values(VersicherungsId).map((currentVersicherungsId: VersicherungsId) => {
            const versicherung: Versicherung = {
                versicherungsId: currentVersicherungsId,
                ausgewaehlt: currentVersicherungsId === versicherungsId
            };
            return versicherungen.push(versicherung);
        });

        const payload: OfferEngineVersicherungenRequestDto = {
            versicherungen: versicherungen
        };

        return updateVersicherungenHttpRequest(businessId, payload, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                clearTimeout(waitForResponse);
                onFulfilledStoreOffer(response, dispatch);
            })
            .catch((e: Error) => {
                clearTimeout(waitForResponse);
                onRejectedStoreTechnischerFehler(e, dispatch);
            })
            .finally(() => {
                dispatch(showVersicherungsschutzLoadingSpinnerSync(false));
            });
    };
};

export const updateMailAdresseAsync = (versicherungsnehmer: Person | null, mailAdresse: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        if (versicherungsnehmer) {
            const mailAdressen: MailAdresse[] = versicherungsnehmer.mailadressen;
            mailAdressen.push({
                adresse: mailAdresse,
                typ: Typ.privat
            });
            const request: UpdateMailAdressenRequestDto = {
                pdsId: versicherungsnehmer.pdsId,
                mailAdressen: mailAdressen
            };
            return updateMailAdressenHttpRequest(getState().basisdaten.businessId, request, dispatch)
                .then((response: FrontendResponse<PersonDto>) => {
                    response.payload && dispatch(speicherPersonSync(response.payload.pdsPerson));
                })
                .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
        }
    };
};

export interface ShowVersicherungsschutzLoadingSpinnerAction {
    type: ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ;
    showLoadingSpinner: boolean;
}

export const showVersicherungsschutzLoadingSpinnerSync = (showLoadingSpinner: boolean): ShowVersicherungsschutzLoadingSpinnerAction => {
    return {
        type: ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ,
        showLoadingSpinner: showLoadingSpinner
    };
};
