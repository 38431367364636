import { Dispatch } from 'redux';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { IAppState } from '../app/IAppState';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { createEvbFileRequest } from '../util/EdcomRequestHelper';
import { createEvbRequest } from '../util/OfferEngineRequestHelper';
import { downloadEvbFileHttpRequest } from '../util/fetch/edcom/EdcomController';
import { EdcomEvbFileRequestDto } from '../util/fetch/edcom/EdcomEvbFileRequestDto';
import { updateEvbHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { OfferEngineEvbRequestDto } from '../util/fetch/offerengine/OfferEngineEvbRequestDto';
import { EdcomResponse } from './EvbReducer';

export const CHANGE_EVB_FILE_DOWNLOAD_INPROGRESS: string = 'CHANGE_EVB_FILE_DOWNLOAD_INPROGRESS';
export const SET_EDCOM_RESPONSE: string = 'SET_EDCOM_RESPONSE';

export const updateEvbFieldAsync = (businessId: string, fieldValue: boolean) => {
    return (dispatch: Dispatch) => {
        const request: OfferEngineEvbRequestDto = createEvbRequest(fieldValue);
        return updateEvbHttpRequest(businessId, request, dispatch) // to update selected evb radio value in OE.
            .then(response => onFulfilledStoreOffer(response, dispatch))// OE response needs to be set into redux so that the new value appears everywhere.
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeIsEvbFileDownloadInProgressAction {
    type: string;
    payload: {
        fieldName: string;
        fieldValue: boolean;
    };
}

export const changeIsEvbFileDownloadInProgress = (fieldValue: boolean): ChangeIsEvbFileDownloadInProgressAction => {// action for changing the value of 'isEvbFileDownloadInProgress'
    return {
        type: CHANGE_EVB_FILE_DOWNLOAD_INPROGRESS,
        payload: {
            fieldName: "isEvbFileDownloadInProgress",
            fieldValue: fieldValue
        }
    };
};

export interface SetEdcomResponseAction {
    type: string;
    payload: {
        fieldName: string,
        fieldValue: EdcomResponse
    }

}

export const setEdcomResponse = (fieldValue: EdcomResponse): SetEdcomResponseAction => {// action for setting actual edcom response into redux.
    return {
        type: SET_EDCOM_RESPONSE,
        payload: {
            fieldName: "edcomResponse",
            fieldValue: fieldValue
        }
    };
};

export const downloadEvbFileAsync = () => {// to make an edcom api request in order to obtain an evb pdf file in string format.
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const businessId = getState().basisdaten.businessId;
        let isEvbSelected = getState().abschluss.evb.angefordert;
        if (isEvbSelected) {// download the evb file, If the evb radio field is selected(true).
            dispatch(changeIsEvbFileDownloadInProgress(true))// To indicate that an edcom request is being processed, set the flag to true.
            const request: EdcomEvbFileRequestDto = createEvbFileRequest(getState);// create request body
            return downloadEvbFileHttpRequest(businessId,request, dispatch)// make edcom api call.
                .then(response => {
                    dispatch(changeIsEvbFileDownloadInProgress(false))// To indicate that the edcom request is complete, set the flag to false.
                    if (response.payload !== undefined) {
                        dispatch(setEdcomResponse(response.payload));
                    }
                })
                .catch(e => {
                    dispatch(changeIsEvbFileDownloadInProgress(false))// To indicate that the edcom request is complete, set the flag to false.
                });
        } else {
            dispatch(changeIsEvbFileDownloadInProgress(false))
        }
    };
};