import { FrontendResponse } from '../client/FrontendResponse';
import {
    FahrzeugsucheResponseDto,
    HerstellerAuswahlDto,
    HoeherwertigesFahrzeugDto,
    KategorieAuswahlDto,
    KrafstoffAuswahlDto,
    LeistungAuswahlDto,
    ModelleAuswahlDto,
    TypklasseDto
} from './FahrzeugsucheDto';
import { sendGetRequest } from '../client/FetchBaseClient';
import { Dispatch } from 'redux';

export const getHerstellerHttpRequest = (businessId: string, dispatch: Dispatch): Promise<FrontendResponse<HerstellerAuswahlDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/hersteller/`, dispatch);
};

export const getModelleHttpRequest = (businessId: string, hersteller: string, dispatch: Dispatch): Promise<FrontendResponse<ModelleAuswahlDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/modelle/?hersteller=${hersteller}`, dispatch);
};

export const getKraftstoffeHttpRequest = (businessId: string, hersteller: string, modell: string, dispatch: Dispatch): Promise<FrontendResponse<KrafstoffAuswahlDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/antriebsarten/?hersteller=${hersteller}&modell=${modell}`, dispatch);
};

export const getKategorienHttpRequest = (
    businessId: string,
    hersteller: string,
    modell: string,
    kraftstoff: string,
    dispatch: Dispatch
): Promise<FrontendResponse<KategorieAuswahlDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/kategorien/?hersteller=${hersteller}&modell=${modell}&antriebsart=${kraftstoff}`, dispatch);
};

export const getLeistungenHttpRequest = (
    businessId: string,
    hersteller: string,
    modell: string,
    kraftstoff: string,
    kategorie: string,
    dispatch: Dispatch
): Promise<FrontendResponse<LeistungAuswahlDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/leistungsbereiche/?hersteller=${hersteller}&modell=${modell}&antriebsart=${kraftstoff}`, dispatch);
};

export const getFahrzeugeOhneKfzScheinHttpRequest = (
    businessId: string,
    hersteller: string,
    modell: string,
    kraftstoff: string,
    kategorie: string,
    leistung: string,
    dispatch: Dispatch
): Promise<FrontendResponse<FahrzeugsucheResponseDto>> => {
    return sendGetRequest(
        `/kfz2019/fahrzeugsuche/${businessId}/suche-fahrzeuge/?hersteller=${hersteller}&modell=${modell}&antriebsart=${kraftstoff}&kategorie=${kategorie}&leistunginKW=${leistung}`,
        dispatch
    );
};

export const getFahrzeugeMitKfzScheinHttpRequest = (businessId: string, hsn: string, tsn: string, dispatch: Dispatch): Promise<FrontendResponse<FahrzeugsucheResponseDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/hole-fahrzeug/?hsn=${hsn}&tsn=${tsn}`, dispatch);
};

export const getHoeherwertigesFahrzeugHttpRequest = (businessId: string, hsn: string, tsn: string, dispatch: Dispatch): Promise<FrontendResponse<HoeherwertigesFahrzeugDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/pruefe-hochwertiges-fahrzeug/?hsn=${hsn}&tsn=${tsn}`, dispatch);
};

export const getTypklasseHttpRequest = (businessId: string, hsn: string, tsn: string, dispatch: Dispatch): Promise<FrontendResponse<TypklasseDto>> => {
    return sendGetRequest(`/kfz2019/fahrzeugsuche/${businessId}/typklasse/?hsn=${hsn}&tsn=${tsn}`, dispatch);
};
