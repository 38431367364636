import {
    AmtlichesKennzeichen,
    Deckungsschluessel,
    Deckungsumfang,
    Gekuendigt,
    TeilkaskoVertrag,
    VersicherungsGesellschaft,
    Vertrag,
    VertragsId,
    Vorschaeden,
    Vorversicherung
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import {
    AENDERE_BEI_ERGO_VERSICHERT,
    AENDERE_BISHERIGES_KENNZEICHEN,
    AENDERE_GESELLSCHAFT,
    AENDERE_SCHADENSFREIHEITSKLASSE,
    AENDERE_SCHADENSFREIHEITSKLASSE_VSNR,
    AENDERE_VORVERSICHERUNG,
    ChangeBeiErgoVersichertAction,
    ChangeBisherigesKennzeichenAction,
    ChangeGesellschaftAction,
    ChangeSfrAction,
    ChangeVorversicherungFieldAction,
    ChangeVsnrErstwagenErgoAction,
    LADE_VERSICHERUNGS_GESELLSCHAFTEN,
    LoadVersicherungenAction,
    RESET_VORSCHAEDEN,
    ResetVorschaedenAction
} from './VorversicherungAction';

import { getStringValueOrEmpty } from '../util/ReducerHelper';
import { UpdateErsteinstufungsAction } from '../ersteinstufungsservice/ErsteinstufungsAction';
import { ShowKennzeichenIsSavingSpinnerAction, ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER } from '../fahrzeugnutzung/FahrzeugnutzungAction';

export const ERGO_VORVERSICHERUNG = {
    name: 'ERGO Versicherung AG',
    versicherungsunternehmennummer: '54720003'
};

export type VorversicherungState = {
    gekuendigt: Gekuendigt;
    gesellschaft: string;
    versicherungsunternehmennummer: string;
    bisherigesAmtlichesKennzeichen: AmtlichesKennzeichen;
    versicherungsGesellschaften: VersicherungsGesellschaft[];
    fahrzeugGestohlen: boolean | null;
    vorschaedenHaftpflicht: Vorschaeden;
    vorschaedenTeilkasko: Vorschaeden;
    vorschaedenVollkasko: Vorschaeden;
    vsnr: string;
    uebernahmePerson: boolean;
    uebernahmeVorvertrag: boolean;
    beiErgoVersichert: boolean;
    vsnrErstwagenErgo: string;
    isFahrzeugGestohlenAktiv: boolean | undefined;
    showKennzeichenIsSavingSpinner: boolean;
};

const initialVorversicherungState: VorversicherungState = {
    gekuendigt: Gekuendigt.NEIN,
    bisherigesAmtlichesKennzeichen: {
        buchstabenkombination: null,
        ort: null,
        ziffernkombination: null
    },
    versicherungsGesellschaften: [],
    fahrzeugGestohlen: null,
    vorschaedenHaftpflicht: Vorschaeden.EIN,
    vorschaedenTeilkasko: Vorschaeden.EIN,
    vorschaedenVollkasko: Vorschaeden.EIN,
    gesellschaft: '',
    versicherungsunternehmennummer: '',
    vsnr: '',
    uebernahmePerson: false,
    uebernahmeVorvertrag: false,
    beiErgoVersichert: false,
    vsnrErstwagenErgo: '',
    isFahrzeugGestohlenAktiv: false,
    showKennzeichenIsSavingSpinner: false
};

export type AllVorversicherungAction = LoadOfferAction &
    ChangeVorversicherungFieldAction &
    ResetVorschaedenAction &
    ChangeGesellschaftAction &
    ChangeBisherigesKennzeichenAction &
    UpdateErsteinstufungsAction &
    ChangeBeiErgoVersichertAction &
    ChangeSfrAction &
    ChangeVsnrErstwagenErgoAction &
    LoadVersicherungenAction &
    ShowKennzeichenIsSavingSpinnerAction;

export const vorversicherungReducer = (state: VorversicherungState = initialVorversicherungState, action: AllVorversicherungAction): VorversicherungState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            const vuNummer = action.angebot.vorversicherung ? getStringValueOrEmpty(action.angebot.vorversicherung.versicherungsunternehmennummer) : '';

            return {
                ...state,
                bisherigesAmtlichesKennzeichen: getBisherigesAmtlichesKennzeichen(action.angebot.vorversicherung),
                fahrzeugGestohlen: action.angebot.vorversicherung && action.angebot.vorversicherung.fahrzeugGestohlen,
                vorschaedenHaftpflicht: getVorschaedenOrDefault(action.angebot.vorversicherung, 'vorschaedenHaftpflicht'),
                vorschaedenTeilkasko: getVorschaedenOrDefault(action.angebot.vorversicherung, 'vorschaedenTeilkasko'),
                vorschaedenVollkasko: getVorschaedenOrDefault(action.angebot.vorversicherung, 'vorschaedenVollkasko'),
                gekuendigt: action.angebot.vorversicherung && action.angebot.vorversicherung.gekuendigt,
                gesellschaft: action.angebot.vorversicherung ? getStringValueOrEmpty(action.angebot.vorversicherung.gesellschaft) : '',
                versicherungsunternehmennummer: vuNummer,
                vsnr: action.angebot.vorversicherung ? getStringValueOrEmpty(action.angebot.vorversicherung.vsnr) : '',
                uebernahmePerson: action.angebot.sfrEinstufung ? action.angebot.sfrEinstufung.uebernahmePerson : false,
                uebernahmeVorvertrag: action.angebot.sfrEinstufung ? action.angebot.sfrEinstufung.uebernahmeVorvertrag : false,
                vsnrErstwagenErgo: action.angebot.sfrEinstufung ? getStringValueOrEmpty(action.angebot.sfrEinstufung.vsnrErstwagenErgo) : '',
                isFahrzeugGestohlenAktiv: isFahrzeugGestohlenAktiv(action.angebot.vertraege, action.angebot.versicherungen[0].deckungsumfang),
                beiErgoVersichert: action.angebot.vorversicherung ? action.angebot.vorversicherung.vorversicherungErgo : false
            };
        }

        case LADE_VERSICHERUNGS_GESELLSCHAFTEN: {
            return {
                ...state,
                versicherungsGesellschaften: action.payload.versicherungsGesellschaften
            };
        }
        case AENDERE_VORVERSICHERUNG: {
            return {
                ...state,
                [action.payload.fieldName]: action.payload.fieldValue
            };
        }
        case RESET_VORSCHAEDEN: {
            return {
                ...state,
                vorschaedenHaftpflicht: action.payload.vorschadenHaftpflicht,
                vorschaedenTeilkasko: action.payload.vorschadenTeilkasko,
                vorschaedenVollkasko: action.payload.vorschadenVollkasko
            };
        }
        case AENDERE_GESELLSCHAFT: {
            return {
                ...state,
                gesellschaft: action.payload.gesellschaft,
                versicherungsunternehmennummer: action.payload.versicherungsunternehmennummer,
                beiErgoVersichert: action.payload.beiErgoVersichert
            };
        }
        case AENDERE_BISHERIGES_KENNZEICHEN: {
            return {
                ...state,

                bisherigesAmtlichesKennzeichen: {
                    ...state.bisherigesAmtlichesKennzeichen,
                    [action.payload.fieldName]: action.payload.fieldValue
                }
            };
        }

        case AENDERE_BEI_ERGO_VERSICHERT: {
            return {
                ...state,
                beiErgoVersichert: action.beiErgoVersichert
            };
        }
        case AENDERE_SCHADENSFREIHEITSKLASSE: {
            return {
                ...state,
                uebernahmePerson: action.payload.uebernahmePerson,
                uebernahmeVorvertrag: action.payload.uebernahmeVorvertrag
            };
        }
        case AENDERE_SCHADENSFREIHEITSKLASSE_VSNR: {
            return {
                ...state,
                vsnrErstwagenErgo: action.vsnrErstwagenErgo
            };
        }
        case ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER: {
            return {
                ...state,
                showKennzeichenIsSavingSpinner: action.showSpinner
            };
        }
        default:
            return state;
    }
};

function getBisherigesAmtlichesKennzeichen(vorversicherung: Vorversicherung): AmtlichesKennzeichen {
    const hasValue = vorversicherung && vorversicherung.bisherigesAmtlichesKennzeichen;

    return {
        ort: hasValue ? getStringValueOrEmpty(vorversicherung.bisherigesAmtlichesKennzeichen.ort) : '',
        ziffernkombination: hasValue ? vorversicherung.bisherigesAmtlichesKennzeichen.ziffernkombination : null,
        buchstabenkombination: hasValue ? getStringValueOrEmpty(vorversicherung.bisherigesAmtlichesKennzeichen.buchstabenkombination) : ''
    };
}

function getVorschaedenOrDefault(vorversicherung: Vorversicherung, propertyName: string): Vorschaeden {
    // @ts-ignore
    return vorversicherung && vorversicherung[propertyName] ? (vorversicherung[propertyName] as Vorschaeden) : Vorschaeden.KEINE;
}

function isFahrzeugGestohlenAktiv(vertraege: Vertrag[], deckungsumfang: Deckungsumfang): boolean | undefined {
    function isDeckungsSchluesselKaskoOhne(deckungsSchluessel: Deckungsschluessel): boolean {
        switch (deckungsSchluessel) {
            case 'TK_OHNE':
            case 'VK_OHNE_TK_OHNE':
            case 'VK_150_TK_OHNE':
            case 'VK_300_TK_OHNE':
            case 'VK_500_TK_OHNE':
            case 'VK_1000_TK_OHNE':
                return true;
            default:
                return false;
        }
    }

    const isKasko: boolean = deckungsumfang === Deckungsumfang.HAFTPFLICHT_TEILKASKO || deckungsumfang === Deckungsumfang.HAFTPFLICHT_VOLLKASKO;

    if (vertraege !== undefined) {
        const kaskovertrag: Vertrag | undefined = vertraege.find((vertrag: Vertrag) => vertrag.vertragsId === VertragsId.KASKO);

        if (kaskovertrag) {
            // Da gilt: VollkaskoVertrag extends TeilkaskoVertrag, wird hier generell der Typ TeilkaskoVertrag gewählt
            const kaskoVertrag: TeilkaskoVertrag = kaskovertrag as TeilkaskoVertrag;
            if (kaskoVertrag.deckungsschluessel) {
                return isKasko && isDeckungsSchluesselKaskoOhne(kaskoVertrag.deckungsschluessel);
            }
        }
    }
}
