import { Dispatch } from 'redux';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import {
    Fahrzeug,
    FahrzeugsucheResponseDto,
    Hersteller,
    HerstellerAuswahlDto,
    HoeherwertigesFahrzeugDto,
    Kategorie,
    KategorieAuswahlDto,
    KrafstoffAuswahlDto,
    Kraftstoff,
    Leistung,
    LeistungAuswahlDto,
    Modell,
    ModelleAuswahlDto,
    TypklasseDto
} from '../util/fetch/fahrzeugsuche/FahrzeugsucheDto';
import {
    getFahrzeugeMitKfzScheinHttpRequest,
    getFahrzeugeOhneKfzScheinHttpRequest,
    getHerstellerHttpRequest,
    getHoeherwertigesFahrzeugHttpRequest,
    getKategorienHttpRequest,
    getKraftstoffeHttpRequest,
    getLeistungenHttpRequest,
    getModelleHttpRequest,
    getTypklasseHttpRequest
} from '../util/fetch/fahrzeugsuche/FahrzeugsucheController';
import { MIT_KFZ_SCHEIN, OHNE_KFZ_SCHEIN } from './FahrzeugsucheReducer';
import { updateFahrzeugdatenHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { OfferEngineAngebotDto } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';

export const AENDERE_SUCHART: string = 'AENDERE_SUCHART';
export type AENDERE_SUCHART = typeof AENDERE_SUCHART;
export const AENDERE_HSN: string = 'AENDERE_HSN';
export type AENDERE_HSN = typeof AENDERE_HSN;
export const AENDERE_TSN: string = 'AENDERE_TSN';
export type AENDERE_TSN = typeof AENDERE_TSN;
export const AENDERE_HERSTELLER: string = 'AENDERE_HERSTELLER';
export type AENDERE_HERSTELLER = typeof AENDERE_HERSTELLER;
export const AENDERE_MODELL: string = 'AENDERE_MODELL';
export type AENDERE_MODELL = typeof AENDERE_MODELL;
export const AENDERE_KRAFTSTOFF: string = 'AENDERE_KRAFTSTOFF';
export type AENDERE_KRAFTSTOFF = typeof AENDERE_KRAFTSTOFF;
export const AENDERE_KATEGORIE: string = 'AENDERE_KATEGORIE';
export type AENDERE_KATEGORIE = typeof AENDERE_KATEGORIE;
export const AENDERE_LEISTUNG: string = 'AENDERE_LEISTUNG';
export type AENDERE_LEISTUNG = typeof AENDERE_LEISTUNG;
export const LADE_ERGEBNIS_FAHRZEUGSUCHE: string = 'LADE_ERGEBNIS_FAHRZEUGSUCHE';
export type LADE_ERGEBNIS_FAHRZEUGSUCHE = typeof LADE_ERGEBNIS_FAHRZEUGSUCHE;
export const WAEHLE_FAHRZEUG: string = 'WAEHLE_FAHRZEUG';
export type WAEHLE_FAHRZEUG = typeof WAEHLE_FAHRZEUG;
export const FAHRZEUGSUCHE_ZURUECKSETZEN: string = 'FAHRZEUGSUCHE_ZURUECKSETZEN';
export type FAHRZEUGSUCHE_ZURUECKSETZEN = typeof FAHRZEUGSUCHE_ZURUECKSETZEN;
export const SCHLIESSE_FAHRZEUGSUCHE: string = 'SCHLIESSE_FAHRZEUGSUCHE';
export type SCHLIESSE_FAHRZEUGSUCHE = typeof SCHLIESSE_FAHRZEUGSUCHE;
export const SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN: string = 'SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN';
export type SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN = typeof SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN;

export interface ChangeSearchTypeAction {
    type: AENDERE_SUCHART;
    payload: {
        searchType: string;
        hersteller: Hersteller[];
    };
}

export const changeSearchTypeSync = (searchType: string, hersteller: Hersteller[]): ChangeSearchTypeAction => {
    return {
        type: AENDERE_SUCHART,
        payload: {
            searchType: searchType,
            hersteller: hersteller
        }
    };
};

export const changeSearchTypeAsync = (businessId: string, searchType: string) => {
    return (dispatch: Dispatch) => {
        switch (searchType) {
            case OHNE_KFZ_SCHEIN: {
                return getHerstellerHttpRequest(businessId, dispatch)
                    .then((response: FrontendResponse<HerstellerAuswahlDto>) => {
                        response.payload && dispatch(changeSearchTypeSync(searchType, response.payload.hersteller));
                    })
                    .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
            }
            case MIT_KFZ_SCHEIN: {
                return dispatch(changeSearchTypeSync(searchType, []));
            }
            default: {
                console.error(`Not implemented for searchtype ${searchType}`);
            }
        }
    };
};

export interface ChangeHsnAction {
    type: AENDERE_HSN;
    hsn: string;
}

export const changeHsnSync = (hsn: string): ChangeHsnAction => {
    return {
        type: AENDERE_HSN,
        hsn: hsn
    };
};

export interface ChangeTsnAction {
    type: AENDERE_TSN;
    tsn: string;
}

export const changeTsnSync = (tsn: string): ChangeTsnAction => {
    return {
        type: AENDERE_TSN,
        tsn: tsn
    };
};

export interface ChangeHerstellerAction {
    type: AENDERE_HERSTELLER;
    payload: {
        hersteller: string;
        modelle: Modell[];
    };
}

export const changeHerstellerSync = (hersteller: string, modelle: Modell[]): ChangeHerstellerAction => {
    return {
        type: AENDERE_HERSTELLER,
        payload: {
            hersteller: hersteller,
            modelle: modelle
        }
    };
};

export const changeHerstellerAsync = (businessId: string, hersteller: string) => {
    return (dispatch: Dispatch) => {
        return getModelleHttpRequest(businessId, hersteller, dispatch)
            .then((response: FrontendResponse<ModelleAuswahlDto>) => {
                response.payload && dispatch(changeHerstellerSync(hersteller, response.payload.modelle));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeModellAction {
    type: AENDERE_MODELL;
    payload: {
        modell: string;
        kraftstoffe: Kraftstoff[];
    };
}

export const changeModellSync = (modell: string, kraftstoffe: Kraftstoff[]): ChangeModellAction => {
    return {
        type: AENDERE_MODELL,
        payload: {
            modell: modell,
            kraftstoffe: kraftstoffe
        }
    };
};

export const changeModellAsync = (businessId: string, hersteller: string, modell: string) => {
    return (dispatch: Dispatch) => {
        return getKraftstoffeHttpRequest(businessId, hersteller, modell, dispatch)
            .then((response: FrontendResponse<KrafstoffAuswahlDto>) => {
                response.payload && dispatch(changeModellSync(modell, response.payload.antriebsarten));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeKraftstoffAction {
    type: AENDERE_KRAFTSTOFF;
    payload: {
        kraftstoff: string;
        kategorien: Kategorie[];
    };
}

export const changeKraftstoffSync = (kraftstoff: string, kategorien: Kategorie[]): ChangeKraftstoffAction => {
    return {
        type: AENDERE_KRAFTSTOFF,
        payload: {
            kraftstoff: kraftstoff,
            kategorien: kategorien
        }
    };
};

export const changeKraftstoffAsync = (businessId: string, hersteller: string, modell: string, kraftstoff: string) => {
    return (dispatch: Dispatch) => {
        return getKategorienHttpRequest(businessId, hersteller, modell, kraftstoff, dispatch)
            .then((response: FrontendResponse<KategorieAuswahlDto>) => {
                response.payload && dispatch(changeKraftstoffSync(kraftstoff, response.payload.kategorien));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeKategorieAction {
    type: AENDERE_KATEGORIE;
    payload: {
        kategorie: string;
        leistungen: Leistung[];
    };
}

export const changeKategorieSync = (kategorie: string, leistungen: Leistung[]): ChangeKategorieAction => {
    return {
        type: AENDERE_KATEGORIE,
        payload: {
            kategorie: kategorie,
            leistungen: leistungen
        }
    };
};

export const changeKategorieAsync = (businessId: string, hersteller: string, modell: string, kraftstoff: string, kategorie: string) => {
    return (dispatch: Dispatch) => {
        return getLeistungenHttpRequest(businessId, hersteller, modell, kraftstoff, kategorie, dispatch)
            .then((response: FrontendResponse<LeistungAuswahlDto>) => {
                response.payload && dispatch(changeKategorieSync(kategorie, response.payload.leistungsbereiche));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface ChangeLeistungAction {
    type: AENDERE_LEISTUNG;
    payload: {
        leistung: string;
        fahrzeuge: Fahrzeug[];
    };
}

export const changeLeistungSync = (leistung: string, fahrzeuge: Fahrzeug[]): ChangeLeistungAction => {
    return {
        type: AENDERE_LEISTUNG,
        payload: {
            leistung: leistung,
            fahrzeuge: fahrzeuge
        }
    };
};

export const changeLeistungAsync = (businessId: string, hersteller: string, modell: string, kraftstoff: string, kategorie: string, leistung: string) => {
    return (dispatch: Dispatch) => {
        return getFahrzeugeOhneKfzScheinHttpRequest(businessId, hersteller, modell, kraftstoff, kategorie, leistung, dispatch)
            .then((response: FrontendResponse<FahrzeugsucheResponseDto>) => {
                response.payload && dispatch(changeLeistungSync(leistung, response.payload.fahrzeuge));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface GetFahrzeugsucheResponseAction {
    type: LADE_ERGEBNIS_FAHRZEUGSUCHE;
    fahrzeuge: Fahrzeug[];
}

export const storeFahrzeugsucheResponseSync = (fahrzeuge: Fahrzeug[]): GetFahrzeugsucheResponseAction => {
    return {
        type: LADE_ERGEBNIS_FAHRZEUGSUCHE,
        fahrzeuge: fahrzeuge
    };
};

export const storeFahrzeugsucheResponseAsync = (businessId: string, hsn: string, tsn: string) => {
    return (dispatch: Dispatch) => {
        return getFahrzeugeMitKfzScheinHttpRequest(businessId, hsn, tsn, dispatch)
            .then((response: FrontendResponse<FahrzeugsucheResponseDto>) => {
                response.payload && dispatch(storeFahrzeugsucheResponseSync(response.payload.fahrzeuge));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface SelectFahrzeugAction {
    type: WAEHLE_FAHRZEUG;
    payload: {
        selectedFahrzeugRowIndex: number;
        hoeherwertigesFahrzeug: boolean;
    };
}

export const selectFahrzeugSync = (selectedFahrzeugRowIndex: number, hoeherwertigesFahrzeug: boolean): SelectFahrzeugAction => {
    return {
        type: WAEHLE_FAHRZEUG,
        payload: {
            selectedFahrzeugRowIndex: selectedFahrzeugRowIndex,
            hoeherwertigesFahrzeug: hoeherwertigesFahrzeug
        }
    };
};

export const selectFahrzeugAsync = (businessId: string, selectedFahrzeugRowIndex: number, fahrzeug: Fahrzeug) => {
    return (dispatch: Dispatch) => {
        return getHoeherwertigesFahrzeugHttpRequest(businessId, fahrzeug.hsn, fahrzeug.tsn, dispatch)
            .then((responseHochwertig: FrontendResponse<HoeherwertigesFahrzeugDto>) => {
                if (responseHochwertig.payload && !responseHochwertig.payload.hochwertig) {
                    getTypklasseHttpRequest(businessId, fahrzeug.hsn, fahrzeug.tsn, dispatch)
                        .then((responseTypklasse: FrontendResponse<TypklasseDto>) => {
                            responseTypklasse.payload &&
                                updateFahrzeugdatenHttpRequest(businessId, fahrzeug, responseTypklasse.payload.typklasse, dispatch)
                                    .then((responseOfferEngine: FrontendResponse<OfferEngineAngebotDto>) => {
                                        onFulfilledStoreOffer(responseOfferEngine, dispatch);
                                        dispatch(closeFahrzeugsucheSync());
                                        dispatch(resetCloseFahrzeugsucheSync());
                                    })
                                    .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
                        })
                        .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
                }

                responseHochwertig.payload && dispatch(selectFahrzeugSync(selectedFahrzeugRowIndex, responseHochwertig.payload.hochwertig));
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};

export interface CloseFahrzeugsucheAction {
    type: SCHLIESSE_FAHRZEUGSUCHE;
}

const closeFahrzeugsucheSync = (): CloseFahrzeugsucheAction => {
    return {
        type: SCHLIESSE_FAHRZEUGSUCHE
    };
};

export interface ResetFahrzeugsucheAction {
    type: FAHRZEUGSUCHE_ZURUECKSETZEN;
}

export const resetFahrzeugsucheSync = (): ResetFahrzeugsucheAction => {
    return {
        type: FAHRZEUGSUCHE_ZURUECKSETZEN
    };
};

export interface ResetCloseFahrzeugsucheAction {
    type: SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN;
}

export const resetCloseFahrzeugsucheSync = (): ResetCloseFahrzeugsucheAction => {
    return {
        type: SCHLIESSE_FAHZEUGSUCHE_ZURUECKSETZEN
    };
};
