import { Anrede, PdsPerson } from '../util/fetch/personen/PersonDto';
import { Person as ReducerPerson } from './PersonenReducer';
import { Dispatch } from 'redux';
import { OfferEngineAngebotDto, Rollentyp } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { getAllRollen, getPersonMitRolle } from '../util/PersonenHelper';
import { IAppState } from '../app/IAppState';
import { deleteRolleHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { onFulfilledStoreOffer, storeOfferSync } from '../app/AppAction';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import {
    changeBeteiligterFahrerHttpRequest,
    changeHalterHttpRequest,
    changeVersicherungsnehmerHttpRequest,
    deletePersonHttpRequest
} from '../util/fetch/personen/PersonController';
import { loadWohnorteSync } from '../fahrzeugnutzung/FahrzeugnutzungAction';
import { geburtsdatumToString, getIsoDateString } from '../util/DateFormattingHelper';
import { Bonipruefung, ChangeHalterRequestDto, ChangeRolleRequestDto, ChangeVersicherungsnehmerRequestDto } from '../util/fetch/personen/ChangeRolleRequestDto';
import { isEmpty } from '../util/validate';
import { ChangeHalterResponseDto } from '../util/fetch/personen/ChangeHalterResponseDto';
import { DeletePersonRequestDto } from '../util/fetch/personen/DeletePersonRequestDto';
import { ChangeVersicherungsnehmerResponseDto } from '../util/fetch/personen/ChangeVersicherungsnehmerResponseDto';
import { deleteMeldungPdsSync } from '../meldungen/MeldungenAction';

export const LADE_PERSONEN: string = 'LADE_PERSONEN';
export type LADE_PERSONEN = typeof LADE_PERSONEN;
export const PERSON_SPEICHERN: string = 'PERSON_SPEICHERN';
export type PERSON_SPEICHERN = typeof PERSON_SPEICHERN;
export const ROLLE_HINZUFUEGEN: string = 'ROLLE_HINZUFUEGEN';
export type ROLLE_HINZUFUEGEN = typeof ROLLE_HINZUFUEGEN;
export const ROLLE_LOESCHEN: string = 'ROLLE_LOESCHEN';
export type ROLLE_LOESCHEN = typeof ROLLE_LOESCHEN;
export const PERSON_LOESCHEN: string = 'PERSON_LOESCHEN';
export type PERSON_LOESCHEN = typeof PERSON_LOESCHEN;
export const ZEIGE_LOADINGSPINNER_PERSON: string = 'ZEIGE_LOADINGSPINNER_PERSON';
export type ZEIGE_LOADINGSPINNER_PERSON = typeof ZEIGE_LOADINGSPINNER_PERSON;

const PDS_ADRESSE_KORRIGIERT_CODE_PREFIX: string = 'bff.pds.ADRESSE_KORRIGIERT.';

export interface ShowPersonLoadingSpinnerAction {
    type: ZEIGE_LOADINGSPINNER_PERSON;
    showSpinner: boolean;
}

export const showPersonLoadingSpinnerSync = (showSpinner: boolean): ShowPersonLoadingSpinnerAction => {
    return {
        type: ZEIGE_LOADINGSPINNER_PERSON,
        showSpinner: showSpinner
    };
};

export interface LoadPersonsAction {
    type: LADE_PERSONEN;
    personen: PdsPerson[];
}

export const loadPersonsSync = (personen: PdsPerson[]): LoadPersonsAction => {
    return {
        type: LADE_PERSONEN,
        personen: personen
    };
};

export interface SpeicherPersonAction {
    type: PERSON_SPEICHERN;
    person: PdsPerson;
}

export const speicherPersonSync = (person: PdsPerson): SpeicherPersonAction => {
    return {
        type: PERSON_SPEICHERN,
        person: person
    };
};

export interface AddRolleAction {
    type: ROLLE_HINZUFUEGEN;
    payload: {
        pdsId: string;
        rollentyp: Rollentyp;
    };
}

export const addRolleSync = (pdsId: string, rollentyp: Rollentyp): AddRolleAction => {
    return {
        type: ROLLE_HINZUFUEGEN,
        payload: {
            pdsId: pdsId,
            rollentyp: rollentyp
        }
    };
};

export interface DeleteRolleAction {
    type: ROLLE_LOESCHEN;
    payload: {
        pdsId: string;
        rollentyp: Rollentyp;
    };
}

export const deleteRolleSync = (pdsId: string, rollentyp: Rollentyp): DeleteRolleAction => {
    return {
        type: ROLLE_LOESCHEN,
        payload: {
            pdsId: pdsId,
            rollentyp: rollentyp
        }
    };
};

export interface DeletePersonAction {
    type: PERSON_LOESCHEN;
    pdsId: string;
}

export const deletePersonSync = (pdsId: string): DeletePersonAction => {
    return {
        type: PERSON_LOESCHEN,
        pdsId: pdsId
    };
};

export const addRolleAsync = (businessId: string, aktionsnummer: string, personToAddRolle: ReducerPerson, rolle: Rollentyp) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        let waitForResponse: NodeJS.Timeout = setTimeout(() => {
            dispatch(showPersonLoadingSpinnerSync(true));
        }, 300);

        // erst den alten Rolleninhaber raussuchen, bevor die Rolle im Redux-Store hinzugefügt wird
        const currentPersonMitRolle: ReducerPerson | null = getPersonMitRolle(getState().personen.personen, rolle);

        dispatch(addRolleSync(personToAddRolle.pdsId, rolle));
        if (currentPersonMitRolle) {
            dispatch(deleteRolleSync(currentPersonMitRolle.pdsId, rolle));
        }

        switch (rolle) {
            case Rollentyp.VERSICHERUNGSNEHMER: {
                let bonipruefung: Bonipruefung = {
                    anredeSchluessel: personToAddRolle.anrede === Anrede.Herr ? '1' : '2',
                    geburtsdatum: geburtsdatumToString(personToAddRolle.geburtsdatum),
                    hausnummer: personToAddRolle.adresse.hausnummer + personToAddRolle.adresse.hausnummerZusatz ? personToAddRolle.adresse.hausnummerZusatz : '',
                    strasse: personToAddRolle.adresse.strasse,
                    plz: personToAddRolle.adresse.postleitzahl,
                    ort: personToAddRolle.adresse.ort,
                    vorname: personToAddRolle.vorname,
                    nachname: personToAddRolle.nachname,
                    aktionsnummer: parseInt(aktionsnummer)
                };
                if (personToAddRolle.kundennummerErgo && !isEmpty(personToAddRolle.kundennummerErgo)) {
                    bonipruefung = {
                        aktionsnummer: parseInt(aktionsnummer),
                        kundennummer: parseInt(personToAddRolle.kundennummerErgo)
                    };
                }
                const request: ChangeVersicherungsnehmerRequestDto = {
                    bonipruefung: bonipruefung,
                    bankverbindungen: personToAddRolle.bankverbindungen,
                    rolleninhaberAlt: currentPersonMitRolle
                        ? {
                              pdsId: currentPersonMitRolle.pdsId,
                              rollentyp: rolle
                          }
                        : null,
                    rolleninhaberNeu: { pdsId: personToAddRolle.pdsId, rollentyp: rolle },
                    rollenBisher: getAllRollen(getState().personen.personen),
                    geburtsdatum: getIsoDateString(personToAddRolle.geburtsdatum)
                };

                return changeVersicherungsnehmerHttpRequest(businessId, request, dispatch)
                    .then((response: FrontendResponse<ChangeVersicherungsnehmerResponseDto>) => {
                        clearTimeout(waitForResponse);

                        response.payload && response.payload.pdsPerson && dispatch(speicherPersonSync(response.payload.pdsPerson));
                        response.payload && response.payload.angebot && dispatch(storeOfferSync(response.payload.angebot));
                    })
                    .catch((e: Error) => {
                        clearTimeout(waitForResponse);
                        onRejectedStoreTechnischerFehler(e, dispatch, 'PersonenAction.ts -> addRolleAsync');
                    })
                    .finally(() => {
                        dispatch(showPersonLoadingSpinnerSync(false));
                    });
            }
            case Rollentyp.HALTER: {
                const request: ChangeHalterRequestDto = {
                    postleitzahlHalter: personToAddRolle.adresse.postleitzahl,
                    rolleninhaberAlt: currentPersonMitRolle
                        ? {
                              pdsId: currentPersonMitRolle.pdsId,
                              rollentyp: rolle
                          }
                        : null,
                    rolleninhaberNeu: { pdsId: personToAddRolle.pdsId, rollentyp: rolle },
                    rollenBisher: getAllRollen(getState().personen.personen)
                };
                return changeHalterHttpRequest(businessId, request, dispatch)
                    .then((response: FrontendResponse<ChangeHalterResponseDto>) => {
                        clearTimeout(waitForResponse);
                        response.payload && dispatch(storeOfferSync(response.payload.angebot)) && dispatch(loadWohnorteSync(response.payload.wohnorte));
                    })
                    .catch((e: Error) => {
                        clearTimeout(waitForResponse);
                        onRejectedStoreTechnischerFehler(e, dispatch, 'PersonenAction.ts -> addRolleAsync');
                    })
                    .finally(() => {
                        dispatch(showPersonLoadingSpinnerSync(false));
                    });
            }
            case Rollentyp.BETEILIGTER_FAHRER:
            default: {
                const request: ChangeRolleRequestDto = {
                    rolleninhaberAlt: currentPersonMitRolle
                        ? {
                              pdsId: currentPersonMitRolle.pdsId,
                              rollentyp: rolle
                          }
                        : null,
                    rolleninhaberNeu: { pdsId: personToAddRolle.pdsId, rollentyp: rolle }
                };
                return changeBeteiligterFahrerHttpRequest(businessId, request, dispatch)
                    .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                        clearTimeout(waitForResponse);
                        onFulfilledStoreOffer(response, dispatch);
                    })
                    .catch((e: Error) => {
                        clearTimeout(waitForResponse);
                        onRejectedStoreTechnischerFehler(e, dispatch, 'PersonenAction.ts -> addRolleAsync');
                    })
                    .finally(() => {
                        dispatch(showPersonLoadingSpinnerSync(false));
                    });
            }
        }
    };
};

export const deleteRolleAsync = (businessId: string, personPdsIdToDelete: string, rolle: Rollentyp) => {
    return (dispatch: Dispatch) => {
        if (rolle === Rollentyp.BETEILIGTER_FAHRER) {
            let waitForResponse: NodeJS.Timeout = setTimeout(() => {
                dispatch(showPersonLoadingSpinnerSync(true));
            }, 300);

            dispatch(deleteRolleSync(personPdsIdToDelete, rolle));
            return deleteRolleHttpRequest(businessId, personPdsIdToDelete, rolle, dispatch)
                .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                    clearTimeout(waitForResponse);
                    onFulfilledStoreOffer(response, dispatch);
                })
                .catch((e: Error) => {
                    clearTimeout(waitForResponse);
                    onRejectedStoreTechnischerFehler(e, dispatch, 'PersonenAction.ts -> deleteRolleAsync');
                })
                .finally(() => {
                    dispatch(showPersonLoadingSpinnerSync(false));
                });
        }
    };
};

export const deletePersonAsync = (businessId: string, personToDelete: ReducerPerson) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        if (personToDelete.rollen && !personToDelete.rollen.includes(Rollentyp.VERSICHERUNGSNEHMER)) {
            const state = getState();
            const {
                basisdaten: {aktionsnummer},
                personen: {personen}
            } = state;
    
            const waitForResponse: NodeJS.Timeout = setTimeout(() => {
                dispatch(showPersonLoadingSpinnerSync(true));
            }, 300);

            const versicherungsnehmer: ReducerPerson | null = getPersonMitRolle(personen, Rollentyp.VERSICHERUNGSNEHMER);

            // personToDelete aus dem ReduxStore löschen
            dispatch(deletePersonSync(personToDelete.pdsId));

            // remove meldung 'address was corrected' for personToDelete, if exist
            dispatch(deleteMeldungPdsSync(`${PDS_ADRESSE_KORRIGIERT_CODE_PREFIX}${personToDelete.pdsId}`));

            const request: DeletePersonRequestDto = {
                pdsIdVersicherungsnehmer: versicherungsnehmer ? versicherungsnehmer.pdsId : '',
                personToDelete,
                rollenBisher: getAllRollen(personen)
            };

            return deletePersonHttpRequest(businessId, request, dispatch)
                .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                    clearTimeout(waitForResponse);
                    onFulfilledStoreOffer(response, dispatch);

                    // If `personToDelete` has the role of 'holder', give this role to the current policyholder (versicherungsnehmer):
                    if (personToDelete.rollen.includes(Rollentyp.HALTER) && versicherungsnehmer) {
                        dispatch<any>(addRolleAsync(businessId, aktionsnummer, versicherungsnehmer, Rollentyp.HALTER));
                    }
                })
                .catch((e: Error) => {
                    clearTimeout(waitForResponse);
                    onRejectedStoreTechnischerFehler(e, dispatch, 'PersonenAction.ts -> deletePersonAsync');
                })
                .finally(() => {
                    dispatch(showPersonLoadingSpinnerSync(false));
                });
        }
    };
};
