import React from 'react';
import { IAppState } from '../app/IAppState';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import Radio from '@eg/elements/Radio';
import SelectRow from '@eg/elements/SelectRow';
import InputRow from '@eg/elements/InputRow';
import Input from '@eg/elements/Input';
import FormRow from '@eg/elements/FormRow';
import { ERGO_VORVERSICHERUNG, VorversicherungState } from './VorversicherungReducer';
import { Angebotsgrund, Fahrzeugnutzer, VersicherungsGesellschaft, Vorschaeden } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { BITTE_WAEHLEN, createOptions, createRadioButtons } from '../util/WertebereicheHelper';
import {
    changeKennzeichenSync,
    changeVorversicherungFieldSync,
    changeVsnrErstwagenErgoSync,
    resetVorschaedenAsync,
    updateBeiErgoVersichertAsync,
    updateFahrzeugBereitsBeiErgoVersichertAsync,
    updateGesellschaftAsync,
    updateKennzeichenAsync,
    updateSfrEinstufungAsync,
    updateVorversicherungenAsync,
    updateVsnrErstwagenErgoAsync
} from './VorversicherungAction';
import { isTrue, maxLengthAndTypeCheck } from '../util/validate';
import { wertebereichGekuendigt, wertebereichVorschaeden } from './VorversicherungWertebereiche';
import {
    ClassificationResponse,
    getErsteinstufungFieldFlags,
    isErstvertragMitUebernahmePerson,
    isErstvertragMitZweitwagenRegelung
} from '../ersteinstufungsservice/ErsteinstufungsReducer';
import { InputEvent, RadioEvent, SelectEvent } from '../util/UiEventTypes';
import { ErstfahrzeugInnerRadioButton } from '../angebotsgrund/AngebotsgrundWertebereiche';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { getEinstufungsGrundContextId, isFirstCarInsuranceQuestionsAsked as getIsFirstCarInsuranceQuestionsAsked} from '../ersteinstufungsservice/utils';

interface VorversicherungPresentationProps extends VorversicherungDispatchProps, VorversicherungLinkState {}

export class VorversicherungPresentation extends React.Component<VorversicherungPresentationProps, {}> {
    handleOnChangeVorversicherung = (businessId: string, versicherungsunternehmensNummer: string | null = null): void => {
        const { versicherungsGesellschaften, handleOnChangeGesellschaft } = this.props;

        if (versicherungsGesellschaften.length === 0) {
            return;
        }

        const isErgoVorversicherung: boolean = ERGO_VORVERSICHERUNG.versicherungsunternehmennummer === versicherungsunternehmensNummer;

        const selectedGesellschaft: VersicherungsGesellschaft | undefined = versicherungsGesellschaften.find(
            versicherung => versicherungsunternehmensNummer === versicherung.versicherungsunternehmensNummer
        );

        const name: string | null = selectedGesellschaft ? selectedGesellschaft.name : null;
        const vuNummer: string | null = selectedGesellschaft ? selectedGesellschaft.versicherungsunternehmensNummer : null;

        handleOnChangeGesellschaft(businessId, name, vuNummer, isErgoVorversicherung);
    };

    render() {
        const {
            businessId,
            uebernahmePerson,
            uebernahmeVorvertrag,
            gekuendigt,
            versicherungsGesellschaften,
            fahrzeugGestohlen,
            fahrzeugnutzer,
            versicherungsunternehmennummer,
            bisherigesAmtlichesKennzeichen,
            vorschaedenHaftpflicht,
            vorschaedenTeilkasko,
            vorschaedenVollkasko,
            vsnr,
            beiErgoVersichert,
            isErstvertragMitZweitwagenRegelung,
            vsnrErstwagenErgo,
            isErstvertragMitUebernahmePerson,
            isFahrzeugGestohlenAktiv,
            angebotsgrund,
            einstufungsGrund,
            erstVertragGrund,
            erstFahrzeugBeiErgoVersichert,
            showKennzeichenIsSavingSpinner,
            oberflaecheSperren,
            handleOnLeaveVorversicherungField,
            handleOnChangeVorversicherungField,
            handleVorschaedenAsync,
            handleOnLeaveKennzeichenField,
            handleOnChangeKennzeichenField,
            handleSfrEinstufungAsync,
            handleOnLeaveBeiErgoVersichert,
            handleOnLeaveVsnrErstwagenErgoAsync,
            handleOnChangeVsnrErstwagenErgoAsync,
            handleErstfahrzeugBeiErgoVersichertAsync
        } = this.props;

        const ortKennzeichen = bisherigesAmtlichesKennzeichen.ort !== null ? bisherigesAmtlichesKennzeichen.ort : '';
        const buchstabenkombination = bisherigesAmtlichesKennzeichen.buchstabenkombination !== null ? bisherigesAmtlichesKennzeichen.buchstabenkombination : '';
        const ziffernkombination = bisherigesAmtlichesKennzeichen.ziffernkombination !== null ? bisherigesAmtlichesKennzeichen.ziffernkombination : '';

        const hasVorschaeden = () => vorschaedenHaftpflicht !== Vorschaeden.KEINE || vorschaedenTeilkasko !== Vorschaeden.KEINE || vorschaedenVollkasko !== Vorschaeden.KEINE;

        const {
            disableVorschaeden,
            disableFahrzeugGestohlen,
            disableVorversicherung,
            mandatoryFuerZweitwagenRegelung,
            hideSchadenfreiheitsklasseUebernahme,
            isVsnrErstwagenRelevant
        } = getErsteinstufungFieldFlags(angebotsgrund, erstVertragGrund, einstufungsGrund);

        // true, if both are false
        const renderSfrBeiErgoVersichert = !(!mandatoryFuerZweitwagenRegelung && !uebernahmePerson);

        const istBeiErgoVersichertMitZweitwagenRegelung = isErstvertragMitZweitwagenRegelung && beiErgoVersichert;

        const isFirstCarInsuranceQuestionsAsked = getIsFirstCarInsuranceQuestionsAsked(getEinstufungsGrundContextId(einstufungsGrund), fahrzeugnutzer);
        const isInsuranceNumberInputShown = 
            (erstFahrzeugBeiErgoVersichert || isVsnrErstwagenRelevant)
            && !fahrzeugnutzer.includes(Fahrzeugnutzer.FAMILIENMITGLIEDER) 
            && !fahrzeugnutzer.includes(Fahrzeugnutzer.SONSTIGE)
        ;

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Angaben zur Vorversicherung</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12 esc_col-m-6">
                            {!hideSchadenfreiheitsklasseUebernahme && (
                                <RadioGroupRow
                                    label="Welche Situation trifft zu?"
                                    name="sf-klasse"
                                    disabled={oberflaecheSperren || disableVorversicherung || isErstvertragMitUebernahmePerson}
                                >
                                    <Radio
                                        label="Schadensfreiheitsklasse aus dem Vorvertrag übernehmen"
                                        value={uebernahmeVorvertrag}
                                        checked={uebernahmeVorvertrag}
                                        disabled={oberflaecheSperren || disableVorversicherung || isErstvertragMitUebernahmePerson}
                                        onChange={() => handleSfrEinstufungAsync(businessId, false, true, false)}
                                    />
                                    <Radio
                                        label="Schadensfreiheitsklasse von einer anderen Person übernehmen"
                                        value={uebernahmePerson}
                                        checked={uebernahmePerson}
                                        onChange={() => handleSfrEinstufungAsync(businessId, true, false, erstFahrzeugBeiErgoVersichert)}
                                        disabled={oberflaecheSperren || disableVorversicherung || isErstvertragMitUebernahmePerson}
                                    />
                                </RadioGroupRow>
                            )}

                            {uebernahmeVorvertrag && (
                                <RadioGroupRow
                                    label="Der bisherige Vertrag wurde gekündigt von"
                                    name="bisheriger vertrag gekuendigt"
                                    value={gekuendigt}
                                    onChange={(e: RadioEvent) => {
                                        handleOnLeaveVorversicherungField(businessId, 'gekuendigt', e.target.value);
                                    }}
                                >
                                    {createRadioButtons(wertebereichGekuendigt)}
                                </RadioGroupRow>
                            )}
                            {renderSfrBeiErgoVersichert && (
                                <RadioGroupRow
                                    label={
                                        isErstvertragMitZweitwagenRegelung
                                            ? 'Besteht der aktive Erstvertrag von Ihnen/Ihrem Ehe-/Lebenspartner bei ERGO?'
                                            : 'Besteht oder bestand für den bisherigen SFR-Berechtigten eine KFZ-Versicherung bei der ERGO Versicherung AG?'
                                    }
                                    name="sf-klasse aus vorvertrag"
                                    disabled={oberflaecheSperren || disableVorversicherung}
                                >
                                    <Radio label="Ja" value={beiErgoVersichert} checked={beiErgoVersichert} onChange={() => handleOnLeaveBeiErgoVersichert(businessId, true)} />
                                    <Radio
                                        label="Nein"
                                        value={!beiErgoVersichert}
                                        checked={!beiErgoVersichert}
                                        onChange={() => handleOnLeaveBeiErgoVersichert(businessId, false)}
                                    />
                                </RadioGroupRow>
                            )}

                            <SelectRow
                                label="Name der Gesellschaft"
                                value={versicherungsunternehmennummer}
                                disabled={oberflaecheSperren || disableVorversicherung || istBeiErgoVersichertMitZweitwagenRegelung}
                                onChange={(e: SelectEvent) => this.handleOnChangeVorversicherung(businessId, e.currentTarget.value)}
                            >
                                <option label={BITTE_WAEHLEN} value={undefined} key="bitte_waehlen" />
                                {versicherungsGesellschaften.map(gesellschaft => (
                                    <option label={gesellschaft.name} value={gesellschaft.versicherungsunternehmensNummer} key={gesellschaft.versicherungsunternehmensNummer} />
                                ))}
                            </SelectRow>

                            <InputRow
                                label={beiErgoVersichert ? 'Versicherungsscheinnummer der bisherigen ERGO-Versicherung' : 'Versicherungsscheinnummer'}
                                value={vsnr}
                                disabled={oberflaecheSperren || (!beiErgoVersichert ? disableVorversicherung : false)}
                                onBlur={(e: InputEvent) => handleOnLeaveVorversicherungField(businessId, 'vsnr', e.currentTarget.value)}
                                onChange={(e: InputEvent) => handleOnChangeVorversicherungField('vsnr', e.currentTarget.value)}
                            />

                            {renderSfrBeiErgoVersichert && (
                                <FormRow label="Bisheriges amtliches Kennzeichen" disabled={oberflaecheSperren || disableVorversicherung}>
                                    <div className="esc_grid">
                                        <div className="esc_grid__wrapper position-relative">
                                            <div className="esc_col esc_col-s-4">
                                                <Input
                                                    placeholder="N"
                                                    name="Ort"
                                                    aria-label="Ort"
                                                    disabled={oberflaecheSperren}
                                                    maxLength={3}
                                                    value={ortKennzeichen}
                                                    onInput={(e: InputEvent) => maxLengthAndTypeCheck(e)}
                                                    onBlur={(e: InputEvent) => handleOnLeaveKennzeichenField(businessId, 'ort', e.currentTarget.value.toUpperCase())}
                                                    onChange={(e: InputEvent) => handleOnChangeKennzeichenField('ort', e.currentTarget.value.toUpperCase())}
                                                />
                                            </div>
                                            <div className="esc_col esc_col-s-3">
                                                <Input
                                                    placeholder="XY"
                                                    name="Buchstabenkombi"
                                                    aria-label="Buchstabenkombi"
                                                    disabled={oberflaecheSperren}
                                                    value={buchstabenkombination}
                                                    onInput={(e: InputEvent) => maxLengthAndTypeCheck(e)}
                                                    maxLength={2}
                                                    onBlur={(e: InputEvent) =>
                                                        handleOnLeaveKennzeichenField(businessId, 'buchstabenkombination', e.currentTarget.value.toUpperCase())
                                                    }
                                                    onChange={(e: InputEvent) => handleOnChangeKennzeichenField('buchstabenkombination', e.currentTarget.value.toUpperCase())}
                                                />
                                            </div>
                                            <div className="esc_col esc_col-s-4">
                                                <Input
                                                    placeholder="123"
                                                    name="Ziffernkombi"
                                                    aria-label="Ziffernkombi"
                                                    disabled={oberflaecheSperren}
                                                    value={ziffernkombination !== null ? ziffernkombination : undefined}
                                                    maxLength={4}
                                                    type="number"
                                                    onInput={(e: InputEvent) => maxLengthAndTypeCheck(e)}
                                                    onBlur={(e: InputEvent) => handleOnLeaveKennzeichenField(businessId, 'ziffernkombination', e.currentTarget.value)}
                                                    onChange={(e: InputEvent) => handleOnChangeKennzeichenField('ziffernkombination', e.currentTarget.value)}
                                                />
                                            </div>
                                            <LoadingSpinner viewport="relative" show={showKennzeichenIsSavingSpinner} />
                                        </div>
                                    </div>
                                </FormRow>
                            )}
                        </div>
                        <div className="esc_col esc_col-s-12 esc_col-m-6">
                            {isFirstCarInsuranceQuestionsAsked && (
                                <RadioGroupRow
                                    label="Ist bereits ein Fahrzeug von Ihnen oder Ihrem (Ehe-)Partner bei ERGO versichert?"
                                    disabled={oberflaecheSperren}
                                    name="partnerErgoVersicherung"
                                    value={erstFahrzeugBeiErgoVersichert}
                                    onChange={(e: RadioEvent) => handleErstfahrzeugBeiErgoVersichertAsync(businessId, isTrue(e.target.value))}
                                >
                                    <Radio label="Ja" value={true} />
                                    <Radio label="Nein" value={false} />
                                </RadioGroupRow>
                            )}

                            {isInsuranceNumberInputShown && (
                                <InputRow
                                    label="Versicherungsscheinnummer des bei ERGO versicherten Erstwagens"
                                    disabled={oberflaecheSperren}
                                    value={vsnrErstwagenErgo}
                                    onBlur={(e: InputEvent) => handleOnLeaveVsnrErstwagenErgoAsync(businessId, e.currentTarget.value.toUpperCase(), true)}
                                    onChange={(e: InputEvent) => handleOnChangeVsnrErstwagenErgoAsync(e.currentTarget.value.toUpperCase())}
                                />
                            )}

                            <RadioGroupRow
                                label="Sind in den letzten drei Jahren Vorschäden eingetreten?"
                                name="vorschaeden"
                                disabled={oberflaecheSperren || isErstvertragMitUebernahmePerson || disableVorschaeden}
                                value={hasVorschaeden() ? Vorschaeden.EIN : Vorschaeden.KEINE}
                                onChange={(e: RadioEvent) => handleVorschaedenAsync(businessId, e.target.value as Vorschaeden)}
                            >
                                <Radio label="Ja" value={Vorschaeden.EIN} />
                                <Radio label="Nein" value={Vorschaeden.KEINE} />
                            </RadioGroupRow>

                            {hasVorschaeden() && (
                                <>
                                    <SelectRow
                                        label="KFZ-Haftpflicht"
                                        value={vorschaedenHaftpflicht}
                                        disabled={oberflaecheSperren || isErstvertragMitUebernahmePerson}
                                        onChange={(e: SelectEvent) => handleOnLeaveVorversicherungField(businessId, 'vorschaedenHaftpflicht', e.currentTarget.value)}
                                    >
                                        {createOptions(wertebereichVorschaeden)}
                                    </SelectRow>
                                    <SelectRow
                                        label="Teilkasko"
                                        value={vorschaedenTeilkasko}
                                        disabled={oberflaecheSperren || isErstvertragMitUebernahmePerson}
                                        onChange={(e: SelectEvent) => handleOnLeaveVorversicherungField(businessId, 'vorschaedenTeilkasko', e.currentTarget.value)}
                                    >
                                        {createOptions(wertebereichVorschaeden)}
                                    </SelectRow>
                                    <SelectRow
                                        label="Vollkasko"
                                        value={vorschaedenVollkasko}
                                        disabled={isErstvertragMitUebernahmePerson}
                                        onChange={(e: SelectEvent) => handleOnLeaveVorversicherungField(businessId, 'vorschaedenVollkasko', e.currentTarget.value)}
                                    >
                                        {createOptions(wertebereichVorschaeden)}
                                    </SelectRow>
                                </>
                            )}

                            {isFahrzeugGestohlenAktiv && (
                                <RadioGroupRow
                                    label="Wurde Ihnen oder dem Fahrzeughalter in den letzten 5 Jahren ein Fahrzeug gestohlen?"
                                    name="gestohlen"
                                    disabled={oberflaecheSperren || disableFahrzeugGestohlen}
                                    onChange={(e: RadioEvent) => {
                                        handleOnLeaveVorversicherungField(businessId, 'fahrzeugGestohlen', isTrue(e.target.value));
                                    }}
                                >
                                    <Radio label="Ja" value={true} checked={fahrzeugGestohlen === true} />
                                    <Radio label="Nein" value={false} checked={fahrzeugGestohlen === false} />
                                </RadioGroupRow>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

interface VorversicherungLinkState extends VorversicherungState {
    businessId: string;
    fahrzeugnutzer: Fahrzeugnutzer[];
    isErstvertragMitZweitwagenRegelung: boolean;
    isErstvertragMitUebernahmePerson: boolean;
    angebotsgrund: Angebotsgrund;
    erstVertragGrund: ErstfahrzeugInnerRadioButton;
    einstufungsGrund: ClassificationResponse | undefined;
    erstFahrzeugBeiErgoVersichert: boolean;
    oberflaecheSperren: boolean;
}

const mapStateToProps = (state: IAppState): VorversicherungLinkState => ({
    businessId: state.basisdaten.businessId,
    gekuendigt: state.vorversicherung.gekuendigt,
    gesellschaft: state.vorversicherung.gesellschaft,
    versicherungsunternehmennummer: state.vorversicherung.versicherungsunternehmennummer,
    bisherigesAmtlichesKennzeichen: state.vorversicherung.bisherigesAmtlichesKennzeichen,
    versicherungsGesellschaften: state.vorversicherung.versicherungsGesellschaften,
    fahrzeugGestohlen: state.vorversicherung.fahrzeugGestohlen,
    vorschaedenHaftpflicht: state.vorversicherung.vorschaedenHaftpflicht,
    vorschaedenTeilkasko: state.vorversicherung.vorschaedenTeilkasko,
    vorschaedenVollkasko: state.vorversicherung.vorschaedenVollkasko,
    vsnr: state.vorversicherung.vsnr,
    uebernahmePerson: state.vorversicherung.uebernahmePerson,
    uebernahmeVorvertrag: state.vorversicherung.uebernahmeVorvertrag,
    beiErgoVersichert: state.vorversicherung.beiErgoVersichert,
    isErstvertragMitZweitwagenRegelung: isErstvertragMitZweitwagenRegelung(state.angebotsgrund.angebotsgrund, state.ersteinstufung.detail),
    vsnrErstwagenErgo: state.vorversicherung.vsnrErstwagenErgo,
    isErstvertragMitUebernahmePerson: isErstvertragMitUebernahmePerson(state.angebotsgrund.angebotsgrund, state.angebotsgrund.selectedErstfahrzeugInnerRadioButton),
    isFahrzeugGestohlenAktiv: state.vorversicherung.isFahrzeugGestohlenAktiv,
    angebotsgrund: state.angebotsgrund.angebotsgrund,
    erstVertragGrund: state.angebotsgrund.selectedErstfahrzeugInnerRadioButton,
    einstufungsGrund: state.ersteinstufung.detail,
    erstFahrzeugBeiErgoVersichert: state.fahrzeug.zweitwagen ? state.fahrzeug.zweitwagen : false,
    fahrzeugnutzer: state.fahrzeug!.nutzer.fahrzeugnutzer,
    showKennzeichenIsSavingSpinner: state.vorversicherung.showKennzeichenIsSavingSpinner,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

interface VorversicherungDispatchProps {
    handleOnLeaveVorversicherungField: (businessId: string, fieldName: string, fieldValue: string | boolean) => void;
    handleOnChangeVorversicherungField: (fieldName: string, fieldValue: string) => void;
    handleVorschaedenAsync: (businessId: string, value: Vorschaeden) => void;
    handleOnChangeGesellschaft: (businessId: string, gesellschaft: string | null, versicherungsunternehmennummer: string | null, vorversicherungErgo: boolean) => void;
    handleOnLeaveKennzeichenField: (businessId: string, fieldName: string, fieldValue: string) => void;
    handleOnChangeKennzeichenField: (fieldName: string, fieldValue: string) => void;
    handleSfrEinstufungAsync: (businessId: string, uebernahmePerson: boolean, uebernahmeVorvertrag: boolean, beiErgoVersichert: boolean) => void;
    handleOnLeaveBeiErgoVersichert: (businessId: string, beiErgoVersichert: boolean) => void;
    handleOnLeaveVsnrErstwagenErgoAsync: (businessId: string, vsnrErstwagenErgo: string, beiErgoVersichert: boolean) => void;
    handleOnChangeVsnrErstwagenErgoAsync: (vsnrErstwagenErgo: string) => void;
    handleErstfahrzeugBeiErgoVersichertAsync: (businessId: string, erstFahrzeugBeiErgoVersichert: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): VorversicherungDispatchProps =>
    bindActionCreators(
        {
            handleOnLeaveVorversicherungField: updateVorversicherungenAsync,
            handleOnChangeVorversicherungField: changeVorversicherungFieldSync,
            handleVorschaedenAsync: resetVorschaedenAsync,
            handleOnChangeGesellschaft: updateGesellschaftAsync,
            handleOnLeaveKennzeichenField: updateKennzeichenAsync,
            handleOnChangeKennzeichenField: changeKennzeichenSync,
            handleSfrEinstufungAsync: updateSfrEinstufungAsync,
            handleOnLeaveBeiErgoVersichert: updateBeiErgoVersichertAsync,
            handleOnLeaveVsnrErstwagenErgoAsync: updateVsnrErstwagenErgoAsync,
            handleOnChangeVsnrErstwagenErgoAsync: changeVsnrErstwagenErgoSync,
            handleErstfahrzeugBeiErgoVersichertAsync: updateFahrzeugBereitsBeiErgoVersichertAsync
        },
        dispatch
    );

export const Vorversicherung = connect(mapStateToProps, mapDispatchToProps)(VorversicherungPresentation);
