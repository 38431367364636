import { Dispatch } from 'redux';
import { getParisKundeHttpRequest, selectParisKundeHttpRequest } from '../util/fetch/personensuche/PersonensucheController';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { ParisKunde, PersonensucheDto } from '../util/fetch/personensuche/PersonensucheDto';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { PersonDto } from '../util/fetch/personen/PersonDto';
import { speicherPersonSync } from '../personen/PersonenAction';
import { isEmpty } from '../util/validate';
import { IAppState } from '../app/IAppState';
import { Person } from '../personen/PersonenReducer';

export const EINGABE_KUNDENNUMMER: string = 'EINGABE_KUNDENNUMMER';
export type EINGABE_KUNDENNUMMER = typeof EINGABE_KUNDENNUMMER;
export const ZEIGE_SUCHERGEBNIS: string = 'ZEIGE_SUCHERGEBNIS';
export type ZEIGE_SUCHERGEBNIS = typeof ZEIGE_SUCHERGEBNIS;
export const SETZE_PERSONENSUCHE_ZURUECK: string = 'SETZE_PERSONENSUCHE_ZURUECK';
export type SETZE_PERSONENSUCHE_ZURUECK = typeof SETZE_PERSONENSUCHE_ZURUECK;
export const ZEIGE_LOADINGSPINNER: string = 'ZEIGE_LOADINGSPINNER';
export type ZEIGE_LOADINGSPINNER = typeof ZEIGE_LOADINGSPINNER;
export const ZEIGE_ADRESSE_INVALID: string = 'ZEIGE_ADRESSE_INVALID';
export const SCHLIESSE_PERSONENSUCHE: string = 'SCHLIESSE_PERSONENSUCHE';
export type SCHLIESSE_PERSONENSUCHE = typeof SCHLIESSE_PERSONENSUCHE;

const adressIsInvalidMessage: string = 'Die Adresse der Person ist fehlerhaft und konnte nicht korrigiert werden. Die Person wurde nicht hinzugefügt.';

export interface EnterKundennummerAction {
    type: EINGABE_KUNDENNUMMER;
    kundennummer: string;
}

export const enterKundennummerSync = (kundennummer: string): EnterKundennummerAction => {
    return {
        type: EINGABE_KUNDENNUMMER,
        kundennummer: kundennummer
    };
};

export interface ShowParisKundeAction {
    type: ZEIGE_SUCHERGEBNIS;
    kunde: ParisKunde | null;
    errormessage: string;
}

const showParisKundeSync = (kunde: ParisKunde | null, errormessage: string): ShowParisKundeAction => {
    return {
        type: ZEIGE_SUCHERGEBNIS,
        kunde: kunde,
        errormessage: errormessage
    };
};

export interface ShowPersonIsLoadingAction {
    type: ZEIGE_LOADINGSPINNER;
    showSpinner: boolean;
}

const showPersonIsLoadingSync = (showSpinner: boolean): ShowPersonIsLoadingAction => {
    return {
        type: ZEIGE_LOADINGSPINNER,
        showSpinner: showSpinner
    };
};

export const getParisKundeAsync = (businessId: string, kundennummer: string) => {
    return (dispatch: Dispatch) => {
        const errormessage: string = validateKundennummer(kundennummer);

        if (!isEmpty(errormessage)) {
            dispatch(showParisKundeSync(null, errormessage));
        } else {
            let waitForResponse = setTimeout(() => {
                dispatch(showPersonIsLoadingSync(true));
            }, 300);

            return getParisKundeHttpRequest(businessId, kundennummer, dispatch)
                .then((response: FrontendResponse<PersonensucheDto>) => {
                    clearTimeout(waitForResponse);
                    response.payload && response.payload.parisKunde
                        ? dispatch(showParisKundeSync(response.payload.parisKunde, ''))
                        : dispatch(showParisKundeSync(null, 'Zur eingegebenen Kundennummer konnte kein Kunde in Paris gefunden werden!'));
                })
                .catch((e: Error) => {
                    clearTimeout(waitForResponse);
                    onRejectedStoreTechnischerFehler(e, dispatch, 'getParisKundeAsync');
                })
                .finally(() => {
                    dispatch(showPersonIsLoadingSync(false));
                });
        }
    };
};

const validateKundennummer = (kundennummer: string): string => {
    if (isEmpty(kundennummer)) {
        return '';
    }

    if (Number.isNaN(Number(kundennummer))) {
        return `Kundennummer ${kundennummer} ist keine Nummer!`;
    }

    if (kundennummer.length !== 8) {
        return `Kundennummer ${kundennummer} ist keine Paris-Kundennummer!`;
    }

    return '';
};

export const selectKundeAsync = (businessId: string, kunde: ParisKunde) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const bereitsHinzugefuegteParisKundennummer: boolean = Object.values(getState().personen.personen).some(
            (person: Person) => person.kundennummerParis === String(kunde.kundennummer)
        );

        if (bereitsHinzugefuegteParisKundennummer) {
            dispatch(showParisKundeSync(null, `Der Kunde mit der Kundennummer ${kunde.kundennummer} wurde bereits hinzugefügt.`));
            return;
        }

        return selectParisKundeHttpRequest(businessId, kunde, dispatch)
            .then((response: FrontendResponse<PersonDto>) => {
                if (response.payload) {
                    if (response.payload.pdsPerson) {
                        dispatch(speicherPersonSync(response.payload.pdsPerson));
                        dispatch(closePersonensucheSync());
                        dispatch(resetPersonensucheSync());
                    } else {
                        dispatch(resetPersonensucheSync());
                        dispatch(showAddressIsInvalidSync(true, adressIsInvalidMessage));
                    }
                }
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch, 'selectKundeAsync'));
    };
};

export interface ClosePersonensucheAction {
    type: SCHLIESSE_PERSONENSUCHE;
}

const closePersonensucheSync = (): ClosePersonensucheAction => {
    return {
        type: SCHLIESSE_PERSONENSUCHE
    };
};

export interface ResetPersonensucheAction {
    type: SETZE_PERSONENSUCHE_ZURUECK;
}

const resetPersonensucheSync = (): ResetPersonensucheAction => {
    return {
        type: SETZE_PERSONENSUCHE_ZURUECK
    };
};

export interface ShowAddressIsInvalidAction {
    type: typeof ZEIGE_ADRESSE_INVALID;
    payload: {
        display: boolean;
        message: string;
    };
}

export const showAddressIsInvalidSync = (display: boolean, message: string): ShowAddressIsInvalidAction => {
    return {
        type: ZEIGE_ADRESSE_INVALID,
        payload: {
            display,
            message
        }
    };
};
