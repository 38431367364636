import Panel from '@eg/elements/Panel';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAppState } from '../app/IAppState';
import { RadioEvent } from '../util/UiEventTypes';
import { createRadioButtons, getWertebereichElementName } from '../util/WertebereicheHelper';
import { changeErstfahrzeugRadioButtonAsync, changeOuterRadioButtonAsync } from './AngebotsgrundAction';
import { AngebotsgrundState } from './AngebotsgrundReducer';
import { ErstfahrzeugInnerRadioButton, OuterRadioButton, erstfahrzeugInnerRadioButtons, outerRadioButtons } from './AngebotsgrundWertebereiche';

interface AngebotsgrundPresentationProps {
    businessId: string;
    angebotsgrund: AngebotsgrundState;
    oberflaecheSperren: boolean;
    handleChangeOuterRadioButton: (businessId: string, outerRadioButton: OuterRadioButton) => void;
    handleChangeErstfahrzeugRadioButton: (businessId: string, erstfahrzeugRadioButton: ErstfahrzeugInnerRadioButton) => void;
}

export class AngebotsgrundPresentation extends React.Component<AngebotsgrundPresentationProps, {}> {
    render() {
        const { businessId, angebotsgrund, oberflaecheSperren, handleChangeOuterRadioButton, handleChangeErstfahrzeugRadioButton } = this.props;

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Angebotsgrund</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <RadioGroupRow
                                label=""
                                name="Angebotsgrund"
                                disabled={oberflaecheSperren}
                                value={angebotsgrund.selectedOuterRadioButton}
                                onChange={(e: RadioEvent) => handleChangeOuterRadioButton(businessId, e.target.value as OuterRadioButton)}
                                style={{ marginTop: -20 }}
                                className="angebotsgrund"
                            >
                                <Radio
                                    label={
                                        <div>
                                            <div>{getWertebereichElementName(outerRadioButtons, OuterRadioButton.ERSTVERTRAG)}</div>
                                            <div>
                                                {angebotsgrund.showErstfahrzeugInnerRadioButtons && (
                                                    <RadioGroupRow
                                                        label=""
                                                        name="Erstvertrag"
                                                        disabled={oberflaecheSperren}
                                                        value={angebotsgrund.selectedErstfahrzeugInnerRadioButton}
                                                        onChange={(e: RadioEvent) => {
                                                            handleChangeErstfahrzeugRadioButton(businessId, e.target.value as ErstfahrzeugInnerRadioButton);
                                                            e.stopPropagation();
                                                        }}
                                                        className="angebotsgrund angebotsgrund-inner-group"
                                                    >
                                                        {createRadioButtons(erstfahrzeugInnerRadioButtons)}
                                                    </RadioGroupRow>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    value={OuterRadioButton.ERSTVERTRAG}
                                />
                                <br></br>
                                <Radio
                                    disabled={oberflaecheSperren}
                                    label={getWertebereichElementName(outerRadioButtons, OuterRadioButton.VERSICHERER_WECHSEL)}
                                    value={OuterRadioButton.VERSICHERER_WECHSEL}
                                />
                            </RadioGroupRow>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    businessId: state.basisdaten.businessId,
    angebotsgrund: state.angebotsgrund,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleChangeOuterRadioButton: (businessId: string, outerRadioButton: OuterRadioButton) => {
        //@ts-ignore
        dispatch(changeOuterRadioButtonAsync(businessId, outerRadioButton));
    },
    handleChangeErstfahrzeugRadioButton: (businessId: string, erstfahrzeugRadioButton: ErstfahrzeugInnerRadioButton) => {
        //@ts-ignore
        dispatch(changeErstfahrzeugRadioButtonAsync(businessId, erstfahrzeugRadioButton));
    }
});

export const Angebotsgrund = connect(mapStateToProps, mapDispatchToProps)(AngebotsgrundPresentation);
