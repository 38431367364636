import { Angebotsgrund } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { Dispatch } from 'redux';
import { updateAngebotsgrundHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { OfferEngineAngebotsgrundRequestDto } from '../util/fetch/offerengine/OfferEngineAngebotsgrundRequestDto';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { ErstfahrzeugInnerRadioButton, FahrzeugwechselInnerRadioButton, OuterRadioButton } from './AngebotsgrundWertebereiche';
import { showErsteinstufungSync, updateErsteinstufungSync } from '../ersteinstufungsservice/ErsteinstufungsAction';
import { OfferEngineVertraegeRequestDto, VertragOptionalRequestDto } from '../util/fetch/offerengine/OfferEngineVertraegeRequestDto';
import { createVertraegeRequest } from '../util/OfferEngineRequestHelper';
import { IAppState } from '../app/IAppState';

export const AENDERE_OUTER_RADIO_BUTTON: string = 'AENDERE_OUTER_RADIO_BUTTON';
export type AENDERE_OUTER_RADIO_BUTTON = typeof AENDERE_OUTER_RADIO_BUTTON;
export const AENDERE_ERSTFAHRZEUG_RADIO_BUTTON: string = 'AENDERE_ERSTFAHRZEUG_RADIO_BUTTON';
export type AENDERE_ERSTFAHRZEUG_RADIO_BUTTON = typeof AENDERE_ERSTFAHRZEUG_RADIO_BUTTON;
export const AENDERE_FAHRZEUGWECHSEL_RADIO_BUTTON: string = 'AENDERE_FAHRZEUGWECHSEL_RADIO_BUTTON';
export type AENDERE_FAHRZEUGWECHSEL_RADIO_BUTTON = typeof AENDERE_FAHRZEUGWECHSEL_RADIO_BUTTON;
export const SCHLIESSE_ERGO_MELDUNG: string = 'SCHLIESSE_ERGO_MELDUNG';
export type SCHLIESSE_ERGO_MELDUNG = typeof SCHLIESSE_ERGO_MELDUNG;
export const AENDERE_ANGEBOTSGRUND: string = 'AENDERE_ANGEBOTSGRUND';

export interface ChangeOuterRadioButtonAction {
    type: AENDERE_OUTER_RADIO_BUTTON;
    payload: {
        outerRadioButton: OuterRadioButton;
        showErstfahrzeugInnerRadioButtons: boolean;
        showMeldungErgoFahrzeug: boolean;
    };
}

export const changeOuterRadioButtonSync = (
    outerRadioButton: OuterRadioButton,
    showErstfahrzeugInnerRadioButtons: boolean,
    showMeldungErgoFahrzeug: boolean
): ChangeOuterRadioButtonAction => {
    return {
        type: AENDERE_OUTER_RADIO_BUTTON,
        payload: {
            outerRadioButton: outerRadioButton,
            showErstfahrzeugInnerRadioButtons: showErstfahrzeugInnerRadioButtons,
            showMeldungErgoFahrzeug
        }
    };
};

export interface ChangeErstfahrzeugRadioButtonAction {
    type: AENDERE_ERSTFAHRZEUG_RADIO_BUTTON;
    payload: {
        erstfahrzeugInnerRadioButton: ErstfahrzeugInnerRadioButton;
    };
}

export const changeErstfahrzeugRadioButtonSync = (erstfahrzeugRadioButton: ErstfahrzeugInnerRadioButton): ChangeErstfahrzeugRadioButtonAction => {
    return {
        type: AENDERE_ERSTFAHRZEUG_RADIO_BUTTON,
        payload: {
            erstfahrzeugInnerRadioButton: erstfahrzeugRadioButton
        }
    };
};

export interface ChangeFahrzeugwechselRadioButtonAction {
    type: AENDERE_FAHRZEUGWECHSEL_RADIO_BUTTON;
    payload: {
        fahrzeugwechselInnerRadioButton: FahrzeugwechselInnerRadioButton;
        showMeldungErgoFahrzeug: boolean;
    };
}

export interface CloseErgoMeldungAction {
    type: SCHLIESSE_ERGO_MELDUNG;
}

export const closeErgoMeldungSync = (): CloseErgoMeldungAction => {
    return {
        type: SCHLIESSE_ERGO_MELDUNG
    };
};

export interface ChangeAngebotsgrundAction {
    type: typeof AENDERE_ANGEBOTSGRUND;
    angebotsgrund: Angebotsgrund;
}

const changeAngebotsgrundSync = (angebotsgrund: Angebotsgrund): ChangeAngebotsgrundAction => {
    return {
        type: AENDERE_ANGEBOTSGRUND,
        angebotsgrund
    };
};

export const changeOuterRadioButtonAsync = (businessId: string, outerRadioButton: OuterRadioButton) => {
    return (dispatch: Dispatch) => {
        switch (outerRadioButton) {
            case OuterRadioButton.ERSTVERTRAG:
                dispatch(changeOuterRadioButtonSync(outerRadioButton, true, false));
                //@ts-ignore
                return dispatch(changeErstfahrzeugRadioButtonAsync(businessId, ErstfahrzeugInnerRadioButton.SFK_MIT_EES));
            case OuterRadioButton.VERSICHERER_WECHSEL:
                dispatch(changeOuterRadioButtonSync(outerRadioButton, false, false));
                dispatch(showErsteinstufungSync(false));
                //@ts-ignore
                return dispatch(updateAngebotsgrundAsync(businessId, Angebotsgrund.VERSICHERER_WECHSEL, false, true, true));
            case OuterRadioButton.FAHRZEUG_WECHSELN:
                dispatch(changeOuterRadioButtonSync(outerRadioButton, false, true));
                dispatch(showErsteinstufungSync(false));
                break;
            default:
                dispatch(changeOuterRadioButtonSync(OuterRadioButton.ERSTVERTRAG, true, false));
                //@ts-ignore
                dispatch(changeErstfahrzeugRadioButtonAsync(businessId, ErstfahrzeugInnerRadioButton.SFK_MIT_EES));
        }
    };
};

export const changeErstfahrzeugRadioButtonAsync = (businessId: string, erstfahrzeugRadioButton: ErstfahrzeugInnerRadioButton) => {
    return (dispatch: Dispatch) => {
        switch (erstfahrzeugRadioButton) {
            case ErstfahrzeugInnerRadioButton.SFK_MIT_EES:
                dispatch(changeErstfahrzeugRadioButtonSync(erstfahrzeugRadioButton));
                //@ts-ignore
                dispatch(updateAngebotsgrundAsync(businessId, Angebotsgrund.ERSTVERTRAG, false, false, true));
                dispatch(showErsteinstufungSync(true));
                break;
            case ErstfahrzeugInnerRadioButton.SFK_UEBERNAHME_PERSON:
                dispatch(changeErstfahrzeugRadioButtonSync(erstfahrzeugRadioButton));
                //@ts-ignore
                dispatch(updateAngebotsgrundAsync(businessId, Angebotsgrund.ERSTVERTRAG, true, true, false));
                dispatch(showErsteinstufungSync(false));
                break;
            default:
                dispatch(changeErstfahrzeugRadioButtonSync(ErstfahrzeugInnerRadioButton.SFK_MIT_EES));
                //@ts-ignore
                dispatch(updateAngebotsgrundAsync(businessId, Angebotsgrund.ERSTVERTRAG, false, false, true));
                dispatch(showErsteinstufungSync(true));
        }
    };
};

export const updateAngebotsgrundAsync = (
    businessId: string,
    angebotsgrund: Angebotsgrund,
    uebernahmePerson: boolean,
    resetSchadenfreiheitsrabatt: boolean,
    resetVorversicherung: boolean
) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(changeAngebotsgrundSync(angebotsgrund));

        let request: OfferEngineAngebotsgrundRequestDto = {
            angebotsgrund: angebotsgrund,

            evb: {
                angefordert: true
            },
            sfrEinstufung: {
                uebernahmePerson: uebernahmePerson,
                uebernahmeVorvertrag: false
            }
        };

        if (resetSchadenfreiheitsrabatt) {
            dispatch(updateErsteinstufungSync(undefined));

            const payload: VertragOptionalRequestDto = {
                schadenfreiheitsrabatt: {
                    schadenfreiheitsklasse: null,
                    einstufungsgrund: null
                }
            };

            const requestVertraege: OfferEngineVertraegeRequestDto = createVertraegeRequest(payload, getState().versicherungsschutz.versicherung.deckungsumfang, true);

            request = {
                ...request,
                ...requestVertraege
            };
        }

        if (resetVorversicherung) {
            request = {
                ...request,
                vorversicherung: null
            };
        }

        return updateAngebotsgrundHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};
