export const geburtsdatumToString = (geburtsdatum: Date | undefined): string => {
    if (!geburtsdatum) {
        return '';
    }

    try {
        return geburtsdatum.getDate() + '.' + (geburtsdatum.getMonth() + 1) + '.' + geburtsdatum.getFullYear();
    } catch (e) {
        return '';
    }
};

export const getIsoDateString = (date: Date | undefined): string => {
    if (date) {
        date.setHours(12);
        return date.toISOString().slice(0, 10);
    }

    return '';
};
