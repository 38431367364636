import { Dispatch } from 'redux';
import { FrontendResponse } from '../client/FrontendResponse';
import { sendPostRequest, sendPutRequest } from '../client/FetchBaseClient';
import { OfferEngineAngebotDto } from '../offerengine/OfferEngineAngebotDto';
import { ChangeHalterRequestDto, ChangeRolleRequestDto, ChangeVersicherungsnehmerRequestDto } from './ChangeRolleRequestDto';
import { ChangeHalterResponseDto } from './ChangeHalterResponseDto';
import { DeletePersonRequestDto } from './DeletePersonRequestDto';
import { UpdateMailAdressenRequestDto } from './UpdateMailAdressenRequestDto';
import { PersonDto } from './PersonDto';
import { ChangeVersicherungsnehmerResponseDto } from './ChangeVersicherungsnehmerResponseDto';

export const changeVersicherungsnehmerHttpRequest = (
    businessId: string,
    payload: ChangeVersicherungsnehmerRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<ChangeVersicherungsnehmerResponseDto>> => {
    return sendPostRequest(`/kfz2019/personen/${businessId}/aendere/versicherungsnehmer/`, payload, dispatch);
};

export const changeHalterHttpRequest = (businessId: string, payload: ChangeHalterRequestDto, dispatch: Dispatch): Promise<FrontendResponse<ChangeHalterResponseDto>> => {
    return sendPostRequest(`/kfz2019/personen/${businessId}/aendere/halter/`, payload, dispatch);
};

export const changeBeteiligterFahrerHttpRequest = (businessId: string, payload: ChangeRolleRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPostRequest(`/kfz2019/personen/${businessId}/aendere/beteiligten-fahrer/`, payload, dispatch);
};

export const deletePersonHttpRequest = (businessId: string, payload: DeletePersonRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPostRequest(`/kfz2019/personen/${businessId}/loesche/person/`, payload, dispatch);
};

export const updateMailAdressenHttpRequest = (businessId: string, payload: UpdateMailAdressenRequestDto, dispatch: Dispatch): Promise<FrontendResponse<PersonDto>> => {
    return sendPutRequest(`/kfz2019/personen/${businessId}/aktualisiere/mailadressen/`, payload, dispatch);
};
