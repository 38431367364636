import { Meldung, Schweregrad } from '../util/fetch/client/MeldungenDto';
import {
    DeleteMeldungFeAction,
    LADE_MELDUNGEN,
    LOESCHE_MELDUNG_ADRESSE_KORRIGIERT,
    LOESCHE_MELDUNG_FE,
    LOESCHE_MELDUNGEN_NACH_ABSCHLUSS,
    SaveMeldungFeAction,
    SPEICHERE_MELDUNG_FE,
    StoreMeldungenAction
} from './MeldungenAction';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import { Bankverbindung, Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { AENDERE_GEWAEHLTEN_ROW_ENTRY, ChangeSelectedRowEntryAction } from '../bankverbindung/BankverbindungAction';
import { ANDERE_IBAN } from '../bankverbindung/BankverbindungReducer';

export interface MeldungenState {
    meldungenOE: Meldung[];
    meldungenAK: Meldung[];
    meldungenPDS: Meldung[];
    meldungenBFF: Meldung[];
    meldungenFE: Meldung[];
}

const initialMeldungenState = {
    meldungenOE: [],
    meldungenAK: [],
    meldungenPDS: [],
    meldungenBFF: [],
    meldungenFE: []
};

type MeldungenAction = StoreMeldungenAction & SaveMeldungFeAction & DeleteMeldungFeAction & LoadOfferAction & ChangeSelectedRowEntryAction;

const MELDUNGEN_DID_CHANGE_SUFFIX: string = 'meldungen.did.change';
const MELDUNGEN_DO_ADD_SUFFIX: string = 'meldungen.do.add';

const MISSING_IBAN_CODE: string = 'fe.iban.missing';

export const meldungenReducer = (state: MeldungenState = initialMeldungenState, action: MeldungenAction): MeldungenState => {
    switch (action.type) {
        case LADE_MELDUNGEN: {
            return {
                ...state,
                meldungenOE: updateMeldungenByCodePrefix('bff.oe.', state.meldungenOE, action.meldungen),
                meldungenAK: updateMeldungenByCodePrefix('bff.ak.', state.meldungenAK, action.meldungen),
                meldungenBFF: updateMeldungenByCodePrefix('bff.bff.', state.meldungenBFF, action.meldungen),
                meldungenPDS: updateMeldungenByCodePrefix('bff.pds.', state.meldungenPDS, action.meldungen)
            };
        }

        case SPEICHERE_MELDUNG_FE: {
            return {
                ...state,
                meldungenFE: addMeldungIfItDoesNotExistYet(state.meldungenFE, action.meldung)
            };
        }

        case LOESCHE_MELDUNG_FE: {
            return {
                ...state,
                meldungenFE: deleteMeldungIfItDoesExist(state.meldungenFE, action.meldung.code)
            };
        }

        case LADE_ANGEBOT: {
            const bankverbindung: Bankverbindung = action.angebot.bankverbindung;
            const verkaufsprozessart: Verkaufsprozessart = action.angebot.verkaufsprozessart;
            if (verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT || (bankverbindung && bankverbindung.iban && bankverbindung.iban.length > 0)) {
                return {
                    ...state,
                    meldungenFE: deleteMeldungIfItDoesExist(state.meldungenFE, MISSING_IBAN_CODE)
                };
            } else {
                return {
                    ...state,
                    meldungenFE: addMeldungIfItDoesNotExistYet(state.meldungenFE, createIbanIsMissingMeldung())
                };
            }
        }

        case AENDERE_GEWAEHLTEN_ROW_ENTRY: {
            if (action.verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT || action.iban !== ANDERE_IBAN) {
                return {
                    ...state,
                    meldungenFE: deleteMeldungIfItDoesExist(state.meldungenFE, MISSING_IBAN_CODE)
                };
            } else {
                return {
                    ...state,
                    meldungenFE: addMeldungIfItDoesNotExistYet(state.meldungenFE, createIbanIsMissingMeldung())
                };
            }
        }

        case LOESCHE_MELDUNG_ADRESSE_KORRIGIERT: {
            return {
                ...state,
                meldungenPDS: deleteMeldungIfItDoesExist(state.meldungenPDS, action.code)
            };
        }

        case LOESCHE_MELDUNGEN_NACH_ABSCHLUSS: {
            return {
                ...state,
                meldungenPDS: [],
                meldungenAK: []
            };
        }

        default:
            return state;
    }
};

const updateMeldungenByCodePrefix = (codePrefix: string, currentMeldungen: Meldung[], newMeldungen: Meldung[]): Meldung[] => {
    const hasDidChangeMeldung: boolean = newMeldungen.some(meldung => meldung.code === codePrefix + MELDUNGEN_DID_CHANGE_SUFFIX);
    if (!hasDidChangeMeldung) {
        return currentMeldungen;
    }

    const hasDoAddMeldung = newMeldungen.some(meldung => meldung.code === codePrefix + MELDUNGEN_DO_ADD_SUFFIX);

    const filteredNewMeldungen = newMeldungen.filter(
        newMeldung => newMeldung.code.startsWith(codePrefix) && !newMeldung.code.endsWith(MELDUNGEN_DID_CHANGE_SUFFIX) && !newMeldung.code.endsWith(MELDUNGEN_DO_ADD_SUFFIX)
    );

    return hasDoAddMeldung ? [...currentMeldungen, ...filteredNewMeldungen] : filteredNewMeldungen;
};

export const compareMeldungen = (a: Meldung, b: Meldung): number => {
    if (a.schweregrad === Schweregrad.ERROR && b.schweregrad !== Schweregrad.ERROR) {
        return -1;
    }

    if (b.schweregrad === Schweregrad.ERROR && a.schweregrad !== Schweregrad.ERROR) {
        return 1;
    }

    return 0;
};

const addMeldungIfItDoesNotExistYet = (meldungen: Meldung[], meldungToAdd: Meldung): Meldung[] => {
    return !meldungen.some((m: Meldung) => m.code === meldungToAdd.code) ? meldungen.concat([meldungToAdd]) : meldungen;
};

const deleteMeldungIfItDoesExist = (meldungen: Meldung[], code: string): Meldung[] => {
    return meldungen.filter((meldung: Meldung) => meldung.code !== code);
};

const createIbanIsMissingMeldung = (): Meldung => {
    return {
        aenderungenUebernommen: false,
        code: MISSING_IBAN_CODE,
        schweregrad: Schweregrad.ERROR,
        tarifierungsrelevant: false,
        text: 'Bitte geben Sie eine IBAN an.',
        variantennummern: []
    };
};
