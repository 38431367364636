import React from 'react';
import { IAppState } from '../app/IAppState';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import SelectRow from '@eg/elements/SelectRow';
import InputRow from '@eg/elements/InputRow';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import Radio from '@eg/elements/Radio';
import DatePickerRow from '@eg/elements/DatePickerRow';
import Checkbox from '@eg/elements/Checkbox';
import { CarFundingType, FahrzeugnutzungState } from './FahrzeugnutzungReducer';
import { Angebotsgrund, Fahrzeugnutzer } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import {
    changeFahrzeugFieldSync,
    changeKennzeichenFieldSync,
    resetSaisonkennzeichenAsync,
    updateCarFundingTypeFieldAsync,
    updateErstzulassungAsync,
    updateFahrzeugFieldAsync,
    updateFahrzeugnutzerAsync,
    updateKennzeichenFieldAsync,
    updateNutzerFieldAsync,
    updateOrtHalterAsync
} from './FahrzeugnutzungAction';
import { isEmpty, isTrue, maxLengthAndTypeCheck } from '../util/validate';
import { removeItem } from '../util/ReducerHelper';
import { halterWertebereichMapping, tarifgruppenWertebereichMapping, wertebereichMonate, wertebereichNutzung } from './FahrzeugnutzungWertebereiche';
import { BITTE_WAEHLEN, createOptions, createOptionsFromWertebereichAngebot, createRadioButtons } from '../util/WertebereicheHelper';
import { formatKlasse } from './Regionalklassen';
import { ErstfahrzeugInnerRadioButton } from '../angebotsgrund/AngebotsgrundWertebereiche';
import { ClassificationResponse, isErstvertragMitErsteinstufungsService } from '../ersteinstufungsservice/ErsteinstufungsReducer';
import { CheckboxEvent, DatePickerEvent, InputEvent, RadioEvent, SelectEvent } from '../util/UiEventTypes';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { getEinstufungsGrundContextId, isFirstCarInsuranceQuestionsAsked as getIsFirstCarInsuranceQuestionsAsked } from '../ersteinstufungsservice/utils';
import { infoTooltipMessages, FahrzeughalterPopupInformationKeys } from './FahrzeugnutzungTooltipMessages';

interface FahrzeugnutzungPresentationProps extends FahrzeugnutzungLinkState, DispatchToProps {}

interface FahrzeugnutzungPresentationState {
    halterdatumUebernehmen: boolean;
    fahrgestellnummerErrorText: string;
}

export class FahrzeugnutzungPresentation extends React.Component<FahrzeugnutzungPresentationProps, FahrzeugnutzungPresentationState> {
    constructor(props: FahrzeugnutzungPresentationProps) {
        super(props);

        this.state = {
            halterdatumUebernehmen: false,
            fahrgestellnummerErrorText: ''
        };
    }

    createFahrzeugNutzerPayload = (fahrzeugnutzer: Fahrzeugnutzer, checked: boolean): Fahrzeugnutzer[] => {
        if (checked) {
            return [...this.props.nutzer.fahrzeugnutzer, fahrzeugnutzer];
        } else {
            return removeItem(this.props.nutzer.fahrzeugnutzer, fahrzeugnutzer);
        }
    };

    changeFahrgestellnummer = (fahrgestellnummer: string): void => {
        this.setFahrgestellnummerErrorText('');
        this.props.handleOnChangeFahrzeugField('fahrgestellnummer', fahrgestellnummer);
    };

    validateAndUpdateFahrgestellnummer = (fahrgestellnummer: string): void => {
        if (fahrgestellnummer.length === 0) {
            // Feld leeren sollte möglich sein
            this.props.handleOnLeaveFahrzeugField(this.props.businessId, 'fahrgestellnummer', fahrgestellnummer);
            return;
        }

        if (!fahrgestellnummer.match(/^[^IQO]+$/g)) {
            this.setFahrgestellnummerErrorText('Die Zeichen O, I und Q sind nicht zulässig.');
            return;
        }

        if (!fahrgestellnummer.match(/^[A-Z0-9]+$/g)) {
            this.setFahrgestellnummerErrorText('Zulässig sind nur Buchstaben und Ziffern.');
            return;
        }

        if (fahrgestellnummer.length !== 17) {
            this.setFahrgestellnummerErrorText('Die Fahrzeugidentifikationsnummer muss 17 Zeichen lang sein.');
            return;
        }

        if (!fahrgestellnummer.match(/[0-9]$/g)) {
            this.setFahrgestellnummerErrorText('Das letzte Zeichen muss eine Ziffer sein.');
            return;
        }

        this.props.handleOnLeaveFahrzeugField(this.props.businessId, 'fahrgestellnummer', fahrgestellnummer);
    };

    setFahrgestellnummerErrorText = (fahrgestellnummerErrorText: string): void => {
        this.setState(() => ({
            fahrgestellnummerErrorText: fahrgestellnummerErrorText
        }));
    };

    formatTachostand = (strecke: number | null): string | number => (strecke ? strecke / 1000 : strecke === 0 ? 0 : '');

    private getFahrzeughalterTooltip(key: FahrzeughalterPopupInformationKeys): JSX.Element {
        const infoTooltipSet = infoTooltipMessages[key];
        return (
            <div>
                <p>
                    <b>{infoTooltipSet.heading}</b>
                </p>
                <p dangerouslySetInnerHTML={{ __html: infoTooltipSet.text }} />
            </div>
        );
    }

    private getDriverInfo(drivers: Fahrzeugnutzer[]) {
        const driversIncludePolicyholder = drivers.includes(Fahrzeugnutzer.VERSICHERUNGSNEHMER);
        const driversIncludePartner = drivers.includes(Fahrzeugnutzer.PARTNER);
        const driversIncludeFamilymember = drivers.includes(Fahrzeugnutzer.FAMILIENMITGLIEDER);
        const driversIncludeOther = drivers.includes(Fahrzeugnutzer.SONSTIGE);

        const birthdateFieldNotDisplayed =
            ((driversIncludePolicyholder || driversIncludeOther) && drivers.length === 1) || (driversIncludePolicyholder && driversIncludeOther && drivers.length === 2);

        return {
            driversIncludePolicyholder,
            driversIncludePartner,
            driversIncludeFamilymember,
            driversIncludeOther,
            birthdateFieldNotDisplayed
        };
    }

    render() {
        const {
            businessId,
            einstufungsGrund,
            nutzer,
            istSaisonKennzeichen,
            amtlichesKennzeichen,
            wohnorteAuswahl,
            erstzulassung,
            zulassung,
            fahrleistung,
            kilometerstand,
            fahrgestellnummer,
            hsn,
            tsn,
            typklasseHaftpflicht,
            typklasseTeilkasko,
            typklasseVollkasko,
            regionalklasseHaftpflicht,
            regionalklasseTeilkasko,
            regionalklasseVollkasko,
            nutzung,
            carFundingType,
            zweitwagen,
            angebotsgrund,
            erstVertragGrund,
            wertebereichFahrleistung,
            showKennzeichenIsSavingSpinner,
            oberflaecheSperren,

            handleOnLeaveNutzerField,
            handleOnLeaveErstzulassung,
            handleOnLeaveFahrzeugField,
            handleOnChangeFahrzeugField,
            handleOnLeaveCarFundingTypeField,
            handleOnLeaveKennzeichenField,
            handleOnChangeKennzeichenField,
            handleOnChangeResetSaisonKennzeichen,
            handleOnLeaveFahrzeugnutzer,
            handleOnLeaveOrtHalter
        } = this.props;

        const {
            tarifgruppe,
            halter,
            postleitzahlHalter,
            ortHalter,
            fuehrerscheinausstellung,
            fahrzeugnutzer,
            geburtsdatumEhepartner,
            geburtsdatumJuengsterFahrer,
            wertebereichHalter,
            wertebereichTarifgruppe
        } = nutzer;

        const ortKennzeichen = amtlichesKennzeichen.ort !== null ? amtlichesKennzeichen.ort : '';
        const buchstabenkombination = amtlichesKennzeichen.buchstabenkombination !== null ? amtlichesKennzeichen.buchstabenkombination : '';
        const ziffernkombination = amtlichesKennzeichen.ziffernkombination !== null ? amtlichesKennzeichen.ziffernkombination : '';

        const saisonkennzeichenVon = amtlichesKennzeichen.saisonkennzeichenVon !== null ? amtlichesKennzeichen.saisonkennzeichenVon : '';
        const saisonkennzeichenBis = amtlichesKennzeichen.saisonkennzeichenBis !== null ? amtlichesKennzeichen.saisonkennzeichenBis : '';

        const tausend: number = 1000;
        const typKlasse: string = `${formatKlasse(typklasseHaftpflicht)} / ${formatKlasse(typklasseVollkasko)} / ${formatKlasse(typklasseTeilkasko)}`;
        const regionalklasse: string = `${formatKlasse(regionalklasseHaftpflicht)} / ${formatKlasse(regionalklasseVollkasko)} / ${formatKlasse(regionalklasseTeilkasko)}`;

        const { birthdateFieldNotDisplayed, driversIncludeFamilymember, driversIncludePartner, driversIncludeOther, driversIncludePolicyholder } = this.getDriverInfo(
            fahrzeugnutzer
        );

        const isFirstCarInsuranceQuestionsAsked = getIsFirstCarInsuranceQuestionsAsked(getEinstufungsGrundContextId(einstufungsGrund), fahrzeugnutzer);

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Fahrzeug und Nutzung</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12 esc_col-m-6 esc_col-l-4">
                            <SelectRow
                                disabled={oberflaecheSperren}
                                label="Halter"
                                value={halter}
                                onChange={(e: SelectEvent) => {
                                    if (BITTE_WAEHLEN === e.currentTarget.value) {
                                        return;
                                    }
                                    handleOnLeaveNutzerField(businessId, 'halter', e.currentTarget.value);
                                }}
                            >
                                {createOptionsFromWertebereichAngebot(
                                    halter ? (wertebereichHalter.werte as string[]) : [BITTE_WAEHLEN, ...(wertebereichHalter.werte as string[])],
                                    halterWertebereichMapping
                                )}
                            </SelectRow>
                            <InputRow disabled={oberflaecheSperren} label="PLZ Halter" value={postleitzahlHalter} readOnly />
                            <SelectRow
                                disabled={oberflaecheSperren}
                                label="Wohnort"
                                value={ortHalter}
                                onChange={(e: SelectEvent) => handleOnLeaveOrtHalter(businessId, e.currentTarget.value)}
                            >
                                {wohnorteAuswahl.map(ort => (
                                    <option key={ort} value={ort}>
                                        {ort}
                                    </option>
                                ))}
                            </SelectRow>
                            <SelectRow
                                label="Berufsgruppe"
                                disabled={oberflaecheSperren}
                                value={tarifgruppe}
                                onChange={(e: SelectEvent) => handleOnLeaveNutzerField(businessId, 'tarifgruppe', e.currentTarget.value)}
                            >
                                {createOptionsFromWertebereichAngebot(wertebereichTarifgruppe.werte as string[], tarifgruppenWertebereichMapping)}
                            </SelectRow>
                            <FormRow label="Amtliches Kennzeichen (mindestens Zulassungsbezirk)">
                                <div className="esc_grid">
                                    <div className="esc_grid__wrapper position-relative">
                                        <div className="esc_col esc_col-s-4">
                                            <Input
                                                disabled={oberflaecheSperren}
                                                placeholder="N"
                                                name="Ort"
                                                aria-label="Ort"
                                                value={ortKennzeichen}
                                                maxLength={3}
                                                type="text"
                                                onInput={(e: InputEvent) => maxLengthAndTypeCheck(e)}
                                                onBlur={(e: InputEvent) => handleOnLeaveKennzeichenField(businessId, 'ort', e.currentTarget.value.toUpperCase())}
                                                onChange={(e: InputEvent) => handleOnChangeKennzeichenField('ort', e.currentTarget.value.toUpperCase())}
                                            />
                                        </div>
                                        <div className="esc_col esc_col-s-3">
                                            <Input
                                                disabled={oberflaecheSperren}
                                                placeholder="XY"
                                                name="Buchstabenkombi"
                                                aria-label="Buchstabenkombi"
                                                value={buchstabenkombination}
                                                maxLength={2}
                                                type="text"
                                                onInput={(e: InputEvent) => maxLengthAndTypeCheck(e)}
                                                onBlur={(e: InputEvent) => handleOnLeaveKennzeichenField(businessId, 'buchstabenkombination', e.currentTarget.value.toUpperCase())}
                                                onChange={(e: InputEvent) => handleOnChangeKennzeichenField('buchstabenkombination', e.currentTarget.value.toUpperCase())}
                                            />
                                        </div>
                                        <div className="esc_col esc_col-s-4">
                                            <Input
                                                disabled={oberflaecheSperren}
                                                placeholder="123"
                                                name="Ziffernkombi"
                                                aria-label="Ziffernkombi"
                                                value={ziffernkombination}
                                                maxLength={4}
                                                type="number"
                                                onInput={(e: InputEvent) => maxLengthAndTypeCheck(e)}
                                                onBlur={(e: InputEvent) => handleOnLeaveKennzeichenField(businessId, 'ziffernkombination', e.currentTarget.value)}
                                                onChange={(e: InputEvent) => handleOnChangeKennzeichenField('ziffernkombination', e.currentTarget.value)}
                                            />
                                        </div>
                                        <LoadingSpinner viewport="relative" show={showKennzeichenIsSavingSpinner} />
                                    </div>
                                </div>
                            </FormRow>
                            <RadioGroupRow
                                label="Saisonkennzeichen"
                                name="saisonkennzeichen"
                                disabled={oberflaecheSperren}
                                value={istSaisonKennzeichen}
                                onChange={(e: RadioEvent) => handleOnChangeResetSaisonKennzeichen(businessId, isTrue(e.target.value))}
                            >
                                <Radio label="Ja" value={true} />
                                <Radio label="Nein" value={false} />
                            </RadioGroupRow>
                            {istSaisonKennzeichen && (
                                <>
                                    <SelectRow
                                        label="von"
                                        disabled={oberflaecheSperren}
                                        value={saisonkennzeichenVon}
                                        onChange={(e: SelectEvent) => handleOnLeaveKennzeichenField(businessId, 'saisonkennzeichenVon', e.currentTarget.value)}
                                    >
                                        {createOptions(wertebereichMonate)}
                                    </SelectRow>
                                    <SelectRow
                                        label="bis"
                                        disabled={oberflaecheSperren}
                                        value={saisonkennzeichenBis}
                                        onChange={(e: SelectEvent) => handleOnLeaveKennzeichenField(businessId, 'saisonkennzeichenBis', e.currentTarget.value)}
                                    >
                                        {createOptions(wertebereichMonate)}
                                    </SelectRow>
                                </>
                            )}

                            <DatePickerRow
                                label="Erstzulassung"
                                disabled={oberflaecheSperren}
                                valueDate={erstzulassung}
                                tooltip={this.getFahrzeughalterTooltip(FahrzeughalterPopupInformationKeys.ERSTZULASSUNG)}
                                onChange={(e: DatePickerEvent, date: Date) => (date ? handleOnLeaveErstzulassung(businessId, date, this.state.halterdatumUebernehmen) : null)}
                            />
                            <FormRow
                                label="Letzte Zulassung auf den Fahrzeughalter"
                                tooltip={this.getFahrzeughalterTooltip(FahrzeughalterPopupInformationKeys.ZULASSUNGFAHRZEUGHALTER)}
                            >
                                <Checkbox
                                    label="zeitgleich mit der Erstzulassung"
                                    disabled={oberflaecheSperren}
                                    checked={this.state.halterdatumUebernehmen}
                                    onChange={(e: CheckboxEvent) => {
                                        if (e.target.checked && erstzulassung) {
                                            handleOnLeaveFahrzeugField(businessId, 'zulassung', erstzulassung);
                                        }
                                        this.setState(prevState => ({
                                            halterdatumUebernehmen: !prevState.halterdatumUebernehmen
                                        }));
                                    }}
                                />
                                <DatePickerRow
                                    label=""
                                    disabled={oberflaecheSperren || this.state.halterdatumUebernehmen}
                                    valueDate={zulassung}
                                    onChange={(e: DatePickerEvent, date: Date) => (date ? handleOnLeaveFahrzeugField(businessId, 'zulassung', date) : null)}
                                />
                            </FormRow>
                            <InputRow
                                label="Jährliche Fahrleistung"
                                disabled={oberflaecheSperren}
                                placeholder={0}
                                maxLength={3}
                                min={(wertebereichFahrleistung.min as number) / tausend}
                                max={(wertebereichFahrleistung.max as number) / tausend}
                                value={fahrleistung ? fahrleistung / tausend : ''}
                                onBlur={(e: InputEvent) =>
                                    handleOnLeaveFahrzeugField(businessId, 'fahrleistung', isEmpty(e.currentTarget.value) ? null : Number.parseInt(e.currentTarget.value) * tausend)
                                }
                                onChange={(e: InputEvent) =>
                                    handleOnChangeFahrzeugField('fahrleistung', isEmpty(e.currentTarget.value) ? null : Number.parseInt(e.currentTarget.value) * tausend)
                                }
                            />
                            <span className="cssTausender">.000 km</span>
                            <DatePickerRow
                                label="Führerscheindatum"
                                disabled={oberflaecheSperren || isErstvertragMitErsteinstufungsService(angebotsgrund, erstVertragGrund)}
                                valueDate={fuehrerscheinausstellung}
                                onChange={(e: DatePickerEvent, date: Date) => (date ? handleOnLeaveNutzerField(businessId, 'fuehrerscheinausstellung', date) : null)}
                            />
                        </div>
                        <div className="esc_col esc_col-s-12 esc_col-m-6 esc_col-l-4">
                            <InputRow
                                label="Aktueller Tachostand (ca.)"
                                disabled={oberflaecheSperren}
                                placeholder={0}
                                maxLength={3}
                                value={this.formatTachostand(kilometerstand)}
                                onBlur={(e: InputEvent) =>
                                    handleOnLeaveFahrzeugField(
                                        businessId,
                                        'kilometerstand',
                                        isEmpty(e.currentTarget.value) ? null : Number.parseInt(e.currentTarget.value) * tausend
                                    )
                                }
                                onChange={(e: InputEvent) =>
                                    handleOnChangeFahrzeugField('kilometerstand', isEmpty(e.currentTarget.value) ? null : Number.parseInt(e.currentTarget.value) * tausend)
                                }
                            />
                            <span className="cssTausender">.000 km</span>
                            <InputRow
                                label="Fahrzeugidentifikationsnummer"
                                disabled={oberflaecheSperren}
                                value={fahrgestellnummer}
                                error={this.state.fahrgestellnummerErrorText}
                                onChange={(e: InputEvent) => this.changeFahrgestellnummer(e.currentTarget.value.toUpperCase())}
                                onBlur={(e: InputEvent) => this.validateAndUpdateFahrgestellnummer(e.currentTarget.value.toUpperCase())}
                                className="fahrzeugnutzung"
                            />
                            <InputRow label="Hersteller-Nr. (HSN)" disabled={oberflaecheSperren} readOnly value={hsn} />
                            <InputRow label="Typschlüssel-Nr. (TSN)" disabled={oberflaecheSperren} readOnly value={tsn} />
                            <InputRow label="Regionalklasse KH/VK/TK" disabled={oberflaecheSperren} readOnly value={regionalklasse} />
                            <InputRow label="Typklasse KH/VK/TK" disabled={oberflaecheSperren} readOnly value={typKlasse} />
                            <RadioGroupRow
                                label="Nutzung"
                                name="nutzung"
                                disabled={oberflaecheSperren}
                                value={nutzung}
                                onChange={(e: RadioEvent) => {
                                    handleOnLeaveFahrzeugField(businessId, 'nutzung', e.target.value);
                                }}
                            >
                                {createRadioButtons(wertebereichNutzung)}
                            </RadioGroupRow>
                            <RadioGroupRow
                                label="Finanzierung des Fahrzeugs mit Kredit oder Leasing"
                                name="carFundingType"
                                disabled={oberflaecheSperren}
                                value={carFundingType}
                                onChange={(e: RadioEvent) => {
                                    handleOnLeaveCarFundingTypeField(businessId, 'carFundingType', e.target.value);
                                }}
                            >
                                <Radio label="Nein" value={CarFundingType.NONE} />
                                <Radio label="Kredit" value={CarFundingType.CREDIT} />
                                <Radio label="Leasing" value={CarFundingType.LEASING} />
                            </RadioGroupRow>
                        </div>
                        <div className="esc_col esc_col-s-12 esc_col-m-6 esc_col-l-4">
                            <FormRow label="Fahrer">
                                <div className="esc_grid">
                                    <div className="esc_grid__wrapper">
                                        <div className="esc_col esc_col-s-12 checkbox-distance">
                                            <Checkbox
                                                label="Versicherungsnehmer"
                                                disabled={oberflaecheSperren}
                                                value={Fahrzeugnutzer.VERSICHERUNGSNEHMER}
                                                checked={driversIncludePolicyholder}
                                                onChange={(e: CheckboxEvent) => {
                                                    const payload = this.createFahrzeugNutzerPayload(e.target.value as Fahrzeugnutzer, e.target.checked);

                                                    const { birthdateFieldNotDisplayed } = this.getDriverInfo(payload);

                                                    handleOnLeaveFahrzeugnutzer(businessId, payload, birthdateFieldNotDisplayed);
                                                }}
                                            />
                                        </div>
                                        <div className="esc_col esc_col-s-12 checkbox-distance">
                                            <Checkbox
                                                label="(Ehe-)Partner"
                                                disabled={oberflaecheSperren}
                                                value={Fahrzeugnutzer.PARTNER}
                                                checked={driversIncludePartner}
                                                onChange={(e: CheckboxEvent) => {
                                                    const payload = this.createFahrzeugNutzerPayload(e.target.value as Fahrzeugnutzer, e.target.checked);

                                                    const { birthdateFieldNotDisplayed } = this.getDriverInfo(payload);

                                                    handleOnLeaveFahrzeugnutzer(businessId, payload, birthdateFieldNotDisplayed);
                                                }}
                                            />
                                        </div>
                                        <div className="esc_col esc_col-s-12 checkbox-distance">
                                            <Checkbox
                                                label="Familienmitglieder"
                                                disabled={oberflaecheSperren}
                                                value={Fahrzeugnutzer.FAMILIENMITGLIEDER}
                                                checked={driversIncludeFamilymember}
                                                onChange={(e: CheckboxEvent) => {
                                                    const payload = this.createFahrzeugNutzerPayload(e.target.value as Fahrzeugnutzer, e.target.checked);

                                                    const { birthdateFieldNotDisplayed } = this.getDriverInfo(payload);

                                                    handleOnLeaveFahrzeugnutzer(businessId, payload, birthdateFieldNotDisplayed);
                                                }}
                                            />
                                        </div>
                                        <div className="esc_col esc_col-s-12 checkbox-distance">
                                            <Checkbox
                                                label="Sonstige Fahrer (ab 18 Jahren)"
                                                disabled={oberflaecheSperren}
                                                value={Fahrzeugnutzer.SONSTIGE}
                                                checked={driversIncludeOther}
                                                onChange={(e: CheckboxEvent) => {
                                                    const payload = this.createFahrzeugNutzerPayload(e.target.value as Fahrzeugnutzer, e.target.checked);

                                                    const { birthdateFieldNotDisplayed } = this.getDriverInfo(payload);

                                                    handleOnLeaveFahrzeugnutzer(businessId, payload, birthdateFieldNotDisplayed);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormRow>
                            {!birthdateFieldNotDisplayed && (
                                <>
                                    {driversIncludePartner && !driversIncludeFamilymember && !driversIncludeOther && (
                                        <DatePickerRow
                                            label="Geburtsdatum Ehepartner"
                                            disabled={oberflaecheSperren}
                                            valueDate={geburtsdatumEhepartner}
                                            onChange={(e: DatePickerEvent, date: Date) => (date ? handleOnLeaveNutzerField(businessId, 'geburtsdatumEhepartner', date) : null)}
                                        />
                                    )}
                                    {(driversIncludeFamilymember || driversIncludeOther) && (
                                        <DatePickerRow
                                            label="Geburtsdatum jüngster Fahrer"
                                            disabled={oberflaecheSperren}
                                            valueDate={geburtsdatumJuengsterFahrer}
                                            onChange={(e: DatePickerEvent, date: Date) => (date ? handleOnLeaveNutzerField(businessId, 'geburtsdatumJuengsterFahrer', date) : null)}
                                        />
                                    )}
                                </>
                            )}
                            {isFirstCarInsuranceQuestionsAsked && (
                                <FormRow label="Weitere Information">
                                    <Checkbox
                                        label="Erstfahrzeug bei ERGO versichert"
                                        disabled={oberflaecheSperren}
                                        checked={zweitwagen}
                                        onChange={(e: CheckboxEvent) => handleOnLeaveFahrzeugField(businessId, 'zweitwagen', e.target.checked)}
                                    />
                                </FormRow>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

interface FahrzeugnutzungLinkState extends FahrzeugnutzungState {
    businessId: string;
    angebotsgrund: Angebotsgrund;
    erstVertragGrund: ErstfahrzeugInnerRadioButton;
    einstufungsGrund: ClassificationResponse | undefined;
    oberflaecheSperren: boolean;
}

const mapStateToProps = (state: IAppState): FahrzeugnutzungLinkState => ({
    businessId: state.basisdaten!.businessId,
    nutzung: state.fahrzeug!.nutzung,
    nutzer: state.fahrzeug!.nutzer,
    wohnorteAuswahl: state.fahrzeug!.wohnorteAuswahl,
    istSaisonKennzeichen: state.fahrzeug!.istSaisonKennzeichen,
    amtlichesKennzeichen: state.fahrzeug!.amtlichesKennzeichen,
    erstzulassung: state.fahrzeug!.erstzulassung,
    zulassung: state.fahrzeug!.zulassung,
    fahrleistung: state.fahrzeug!.fahrleistung,
    kilometerstand: state.fahrzeug!.kilometerstand,
    fahrgestellnummer: state.fahrzeug!.fahrgestellnummer,
    hsn: state.fahrzeug!.hsn,
    tsn: state.fahrzeug!.tsn,
    regionalklasseHaftpflicht: state.fahrzeug!.regionalklasseHaftpflicht,
    regionalklasseTeilkasko: state.fahrzeug!.regionalklasseTeilkasko,
    regionalklasseVollkasko: state.fahrzeug!.regionalklasseVollkasko,
    typklasseHaftpflicht: state.fahrzeug!.typklasseHaftpflicht,
    typklasseTeilkasko: state.fahrzeug!.typklasseTeilkasko,
    typklasseVollkasko: state.fahrzeug!.typklasseVollkasko,
    carFundingType: state.fahrzeug!.carFundingType,
    zweitwagen: state.fahrzeug!.zweitwagen,
    angebotsgrund: state.angebotsgrund.angebotsgrund,
    erstVertragGrund: state.angebotsgrund.selectedErstfahrzeugInnerRadioButton,
    einstufungsGrund: state.ersteinstufung.detail,
    wertebereichFahrleistung: state.fahrzeug.wertebereichFahrleistung,
    showKennzeichenIsSavingSpinner: state.fahrzeug.showKennzeichenIsSavingSpinner,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

interface DispatchToProps {
    handleOnLeaveNutzerField: (businessId: string, fieldName: string, fieldValue: any) => void;
    handleOnLeaveFahrzeugField: (businessId: string, fieldName: string, fieldValue: any) => void;
    handleOnChangeFahrzeugField: (fieldName: string, fieldValue: any) => void;
    handleOnLeaveCarFundingTypeField: (businessId: string, fieldName: string, fieldValue: any) => void;
    handleOnLeaveKennzeichenField: (businessId: string, fieldName: string, fieldValue: string) => void;
    handleOnChangeKennzeichenField: (fieldName: string, fieldValue: string) => void;
    handleOnChangeResetSaisonKennzeichen: (businessId: string, istSaisonKennzeichen: boolean) => void;
    handleOnLeaveFahrzeugnutzer: (businessId: string, fahrzeugnutzer: Fahrzeugnutzer[], removeYoungestBirthDate?: boolean) => void;
    handleOnLeaveErstzulassung: (businessId: string, erstzulassung: Date, erstzulassungUebernahme: boolean) => void;
    handleOnLeaveOrtHalter: (businessId: string, ortHalter: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToProps =>
    bindActionCreators(
        {
            handleOnLeaveNutzerField: updateNutzerFieldAsync,
            handleOnLeaveFahrzeugField: updateFahrzeugFieldAsync,
            handleOnChangeFahrzeugField: changeFahrzeugFieldSync,
            handleOnLeaveCarFundingTypeField: updateCarFundingTypeFieldAsync,
            handleOnLeaveKennzeichenField: updateKennzeichenFieldAsync,
            handleOnChangeKennzeichenField: changeKennzeichenFieldSync,
            handleOnChangeResetSaisonKennzeichen: resetSaisonkennzeichenAsync,
            handleOnLeaveFahrzeugnutzer: updateFahrzeugnutzerAsync,
            handleOnLeaveErstzulassung: updateErstzulassungAsync,
            handleOnLeaveOrtHalter: updateOrtHalterAsync
        },
        dispatch
    );

export const Fahrzeugnutzung = connect(mapStateToProps, mapDispatchToProps)(FahrzeugnutzungPresentation);
