import Checkbox from '@eg/elements/Checkbox';
import FormSection from '@eg/elements/FormSection';
import ProgressCircle from '@eg/elements/ProgressCircle';
import SalesmanButton from '@eg/elements/SalesmanButton';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAppState } from '../../app/IAppState';
import { CheckboxEvent } from '../../util/UiEventTypes';
import { Angebotsgrund, Hinweis, Verkaufsprozessart, Versicherung } from '../../util/fetch/offerengine/OfferEngineAngebotDto';
import { AbschlussDetailInputProps } from '../Abschluss';
import { pruefeAntragUndDirektAbschlussAsync } from '../AbschlussAction';
import { DirektabschlussState } from '../AbschlussReducer';
import { changeAlleHinweiseBestaetigtSync, updateBestaetigeAlleHinweiseAsync, updateBestaetigeHinweisSync } from '../DirektabschlussAction';
import { EmailSelection } from '../email/EmailSelection';

interface DirektabschlussPresentationProps extends DirektabschlussOwnProps, DirektAbschlussDispatchProps {}

export class DirektAbschlussPresentation extends React.Component<DirektabschlussPresentationProps, {}> {
    createHinweisCheckbox = (hinweis: Hinweis, onChange = (e: CheckboxEvent) => {}): JSX.Element => (
        <div key={hinweis.hinweisart}>
            <Checkbox
                name="hinweis"
                label={hinweis.text}
                checked={hinweis.bestaetigt}
                disabled={this.props.oberflaecheSperren}
                onChange={onChange}
                data-test-id={'CHECKBOX_' + hinweis.hinweisart}
            />
        </div>
    );

    // Note 2019-08-05 bn: MAybe refactor to call api on each onChange Event.
    handleOnChangeCheckbox = (hinweis: Hinweis, checked: boolean): void => {
        const { gewaehlteVersicherung, handleOnCheckHinweis, handleOnAllHinweiseChecked } = this.props;
        const { hinweise } = gewaehlteVersicherung!;

        const index = hinweise.indexOf(hinweis);

        hinweis.bestaetigt = checked;

        handleOnCheckHinweis(hinweis, index);

        hinweise[index].bestaetigt = checked;
        if (hinweise.every((h: Hinweis) => h.bestaetigt)) {
            handleOnAllHinweiseChecked(gewaehlteVersicherung!);
        }
    };

    render() {
        const {
            gewaehlteVersicherung,
            oberflaecheSperren,
            handleOnAntragAbschliessenClick,
            wartenAufPruefErgebnis,
            progressCircleValue,
            progressCircleLabel,
            progressCircleColorValue,
            progressCircleClassName,
            alleHinweiseBestaetigt,
            hasErrorMeldungen,
            disableAbschlussButton
        } = this.props;

        const hinweise = gewaehlteVersicherung !== null ? gewaehlteVersicherung.hinweise : [];

        const hasHinweise: boolean = hinweise && hinweise.length > 0;

        const firstHinweis: Hinweis | null = hasHinweise ? hinweise[0] : null;

        const hinweiseVisible: boolean = firstHinweis != null && firstHinweis.bestaetigt;

        return (
            <div className="esc_grid component-content">
                <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-s-12">
                        <EmailSelection verkaufsprozessart={Verkaufsprozessart.DIREKTABSCHLUSS} oberflaecheSperren={oberflaecheSperren} />
                    </div>
                    <div className="esc_col esc_col-s-12">
                        <div className="abschluss-hinweis">
                            {// create first checkbox
                            hasHinweise &&
                                firstHinweis &&
                                this.createHinweisCheckbox(firstHinweis, (e: CheckboxEvent) => this.handleOnChangeCheckbox(firstHinweis, e.target.checked))}
                            {// create remaining checkboxes
                            hasHinweise &&
                                hinweiseVisible &&
                                hinweise
                                    .slice(1)
                                    .map((hinweis: Hinweis) => this.createHinweisCheckbox(hinweis, (e: CheckboxEvent) => this.handleOnChangeCheckbox(hinweis, e.target.checked)))}
                        </div>
                        {wartenAufPruefErgebnis && (
                            <FormSection heading={progressCircleLabel} className="antrag-pruefen" id="header_ProgressCircle">
                                <div className={progressCircleClassName}>
                                    <ProgressCircle value={progressCircleValue} low={progressCircleColorValue} high={progressCircleColorValue} />
                                </div>
                            </FormSection>
                        )}
                        <SalesmanButton
                            text="Vertrag abschließen"
                            subText=""
                            note=""
                            variant="primary"
                            id="header_button"
                            disabled={oberflaecheSperren || hasErrorMeldungen || !alleHinweiseBestaetigt || disableAbschlussButton}
                            onClick={() => {
                                handleOnAntragAbschliessenClick();
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const scrollToHeader = (): void => {
    const header = document && document.getElementById('header_button');
    header && header.scrollIntoView();
};

interface DirektabschlussOwnProps extends DirektabschlussState {
    angebotsgrund: Angebotsgrund;
    hasErrorMeldungen: boolean;
    oberflaecheSperren: boolean;
    disableAbschlussButton: boolean;
}

const mapStateToProps = (state: IAppState, props: AbschlussDetailInputProps): DirektabschlussOwnProps => ({
    alleHinweiseBestaetigt: state.abschluss.direktAbschluss.alleHinweiseBestaetigt,
    angebotsgrund: state.angebotsgrund.angebotsgrund,
    wartenAufPruefErgebnis: state.abschluss.direktAbschluss.wartenAufPruefErgebnis,
    vergangeneWartezeitInMillisekunden: state.abschluss.direktAbschluss.vergangeneWartezeitInMillisekunden,
    progressCircleValue: state.abschluss.direktAbschluss.progressCircleValue,
    progressCircleLabel: state.abschluss.direktAbschluss.progressCircleLabel,
    progressCircleColorValue: state.abschluss.direktAbschluss.progressCircleColorValue,
    progressCircleClassName: state.abschluss.direktAbschluss.progressCircleClassName,
    oberflaecheSperren: props.oberflaecheSperren,
    hasErrorMeldungen: props.hasErrorMeldungen ? props.hasErrorMeldungen : false,
    gewaehlteVersicherung: props.gewaehlteVersicherung ? props.gewaehlteVersicherung : null,
    disableAbschlussButton: props.disableAbschlussButton ? props.disableAbschlussButton : false
});

interface DirektAbschlussDispatchProps {
    handleOnCheckHinweis: (hinweis: Hinweis, index: number) => void;
    handleOnAllHinweiseChecked: (gewaehlteVersicherung: Versicherung) => void;
    handleOnAntragAbschliessenClick: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DirektAbschlussDispatchProps => ({
    handleOnCheckHinweis: (hinweis: Hinweis, index: number): void => {
        dispatch(updateBestaetigeHinweisSync(hinweis, index));
        dispatch(changeAlleHinweiseBestaetigtSync(false));
    },
    handleOnAllHinweiseChecked: (gewaehlteVersicherung: Versicherung) => {
        // @ts-ignore
        dispatch(updateBestaetigeAlleHinweiseAsync(gewaehlteVersicherung));
    },
    handleOnAntragAbschliessenClick: () => {
        // @ts-ignore
        dispatch(pruefeAntragUndDirektAbschlussAsync());
        // @ts-ignore
       // dispatch(downloadEvbFileAsync());// to send an edcom api request in order to obtain an evb pdf file in string format.
        scrollToHeader();
    }
});

export const Direktabschluss = connect(mapStateToProps, mapDispatchToProps)(DirektAbschlussPresentation);
