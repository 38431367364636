import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../app/IAppState';
import Panel from '@eg/elements/Panel';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { RadioEvent } from '../util/UiEventTypes';
import { Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { createRadioButtons, VERKAUFSPROZESSARTEN } from '../util/WertebereicheHelper';
import { Dispatch } from 'redux';
import { updateVerkaufsprozessartAsync } from './AbschlussAction';

interface AbschlusswunschProps {
    verkaufsprozessart: Verkaufsprozessart | null;
    oberflaecheSperren: boolean;
    onChangeVerkaufsprozessart: (verkaufprozessart: Verkaufsprozessart) => void;
}

interface AbschlusswunschState {
    gewaehlteVerkaufsprozessart: Verkaufsprozessart | null;
}

export class AbschlusswunschPresentation extends React.Component<AbschlusswunschProps, AbschlusswunschState> {
    constructor(props: AbschlusswunschProps) {
        super(props);
        this.state = {
            gewaehlteVerkaufsprozessart: props.verkaufsprozessart
        };
    }

    changeVerkaufsprozessart = (event: RadioEvent) => {
        //@ts-ignore
        const verkaufsprozessart: Verkaufsprozessart = event.target.value as Verkaufsprozessart;
        this.setState(() => ({
            gewaehlteVerkaufsprozessart: verkaufsprozessart
        }));

        this.props.onChangeVerkaufsprozessart(verkaufsprozessart);
    };

    render() {
        const { oberflaecheSperren } = this.props;

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Abschlusswunsch</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper" style={{ marginBottom: -60 }}>
                        <div className="esc_col esc_col-s-2">
                            <RadioGroupRow
                                label=""
                                disabled={oberflaecheSperren}
                                name="Abschlusswunsch"
                                style={{ marginTop: -20 }}
                                value={this.state.gewaehlteVerkaufsprozessart || ''}
                                onChange={this.changeVerkaufsprozessart}
                            >
                                {createRadioButtons(VERKAUFSPROZESSARTEN)}
                            </RadioGroupRow>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    verkaufsprozessart: state.abschluss.verkaufsprozessart,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChangeVerkaufsprozessart: (verkaufsprozessart: Verkaufsprozessart) => {
        // @ts-ignore
        dispatch(updateVerkaufsprozessartAsync(verkaufsprozessart));
    }
});

export const Abschlusswunsch = connect(mapStateToProps, mapDispatchToProps)(AbschlusswunschPresentation);
