import {
    Fahrzeugnutzer,
    Halter,
    Nutzer,
    Nutzung,
    SaisonKennzeichen,
    Tarifgruppe,
    Typ, VersichertesKfz,
    WertebereichAufzaehlung,
    WertebereichBereich
} from '../util/fetch/offerengine/OfferEngineAngebotDto'
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import { getDateValueOrUndefined, getStringValueOrEmpty } from '../util/ReducerHelper';
import {
    CHANGE_FAHRZEUG,
    CHANGE_FAHRZEUGNUTZER,
    CHANGE_KENNZEICHEN,
    CHANGE_NUTZER,
    RESET_SAISONKENNZEICHEN,
    ChangeFahrzeugnutzerAction,
    ChangeKennzeichenFieldAction,
    ChangeNutzerFieldAction,
    ResetSaisonKennzeichenAction,
    LADE_WOHNORTE,
    LoadWohnorteAction,
    ShowKennzeichenIsSavingSpinnerAction,
    ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER
} from './FahrzeugnutzungAction';
import { getRegionalklasseHaftpflicht, getRegionalklasseTeilkasko, getRegionalklasseVollkasko } from './Regionalklassen';
import { getWertebereichFromAngebot } from '../util/WertebereicheHelper';

export type FahrzeugnutzungState = {
    nutzung: Nutzung;
    hsn: string;
    tsn: string;
    fahrgestellnummer: string;
    zulassung: Date | undefined;
    erstzulassung: Date | undefined;
    carFundingType: CarFundingType;
    fahrleistung: number | null;
    kilometerstand: number | null;
    amtlichesKennzeichen: SaisonKennzeichen;
    istSaisonKennzeichen: boolean;
    typklasseHaftpflicht: number | null;
    typklasseTeilkasko: number | null;
    typklasseVollkasko: number | null;
    regionalklasseHaftpflicht: number | null;
    regionalklasseTeilkasko: number | null;
    regionalklasseVollkasko: number | null;
    nutzer: NutzerState;
    wohnorteAuswahl: string[];
    zweitwagen: boolean;
    wertebereichFahrleistung: WertebereichBereich;
    showKennzeichenIsSavingSpinner: boolean;
};

export enum CarFundingType {
    NONE = 'nein',
    CREDIT = 'kredit',
    LEASING = 'leasing',
}

export interface NutzerState {
    halter: Halter | undefined;
    geburtsdatum: Date | undefined;
    tarifgruppe: Tarifgruppe;
    fuehrerscheinausstellung: Date | undefined;
    ortHalter: string;
    postleitzahlHalter: string;
    teilnahmeBegleitetesFahren: boolean;
    fahrzeugnutzer: Fahrzeugnutzer[];
    geburtsdatumEhepartner: Date | undefined;
    geburtsdatumJuengsterFahrer: Date | undefined;

    wertebereichHalter: WertebereichAufzaehlung;
    wertebereichTarifgruppe: WertebereichAufzaehlung;
}

export const initialFahrzeugnutzungState = {
    nutzung: Nutzung.PRIVAT,
    hsn: '',
    tsn: '',
    fahrgestellnummer: '',
    zulassung: undefined,
    erstzulassung: undefined,
    elektrofahrzeug: false,
    fahrzeugtypBezeichnung: '',
    herstellerBezeichnung: '',
    kilowatt: null,
    ps: null,
    carFundingType: CarFundingType.NONE,
    nutzer: {
        halter: Halter.VERSICHERUNGSNEHMER,
        geburtsdatum: undefined,
        tarifgruppe: Tarifgruppe.STANDARD_19,
        fuehrerscheinausstellung: undefined,
        ortHalter: '',
        postleitzahlHalter: '',
        teilnahmeBegleitetesFahren: false,
        fahrzeugnutzer: [Fahrzeugnutzer.VERSICHERUNGSNEHMER],
        geburtsdatumEhepartner: undefined,
        geburtsdatumJuengsterFahrer: undefined,
        geburtsdatumAeltesterFahrer: undefined,
        wertebereichHalter: {
            werte: [],
            attribut: '',
            obligatorisch: false,
            typ: Typ.AUFZAEHLUNG,
            vorbelegung: {}
        },
        wertebereichTarifgruppe: {
            werte: [],
            attribut: '',
            obligatorisch: false,
            typ: Typ.AUFZAEHLUNG,
            vorbelegung: {}
        }
    },
    fahrleistung: null,
    kilometerstand: null,
    amtlichesKennzeichen: {
        buchstabenkombination: '',
        ort: '',
        saisonkennzeichenBis: 10,
        saisonkennzeichenVon: 3,
        ziffernkombination: null
    },
    istSaisonKennzeichen: false,
    typklasseHaftpflicht: null,
    typklasseTeilkasko: null,
    typklasseVollkasko: null,
    regionalklasseHaftpflicht: null,
    regionalklasseTeilkasko: null,
    regionalklasseVollkasko: null,
    wohnorteAuswahl: [],
    zweitwagen: false,
    wertebereichFahrleistung: {
        min: 1000,
        max: 999000,
        schrittweite: 1000,
        attribut: '',
        obligatorisch: false,
        typ: Typ.BEREICH,
        vorbelegung: {}
    },
    showKennzeichenIsSavingSpinner: false
};

type FahrzeugnutzungAction = LoadOfferAction &
    LoadWohnorteAction &
    ChangeNutzerFieldAction &
    ChangeKennzeichenFieldAction &
    ResetSaisonKennzeichenAction &
    ChangeFahrzeugnutzerAction &
    ShowKennzeichenIsSavingSpinnerAction;

export const fahrzeugnutzungReducer = (state: FahrzeugnutzungState = initialFahrzeugnutzungState, action: FahrzeugnutzungAction): FahrzeugnutzungState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                nutzung: action.angebot.versichertesKfz.nutzung,
                hsn: getStringValueOrEmpty(action.angebot.versichertesKfz.hsn),
                tsn: getStringValueOrEmpty(action.angebot.versichertesKfz.tsn),
                fahrgestellnummer: getStringValueOrEmpty(action.angebot.versichertesKfz.fahrgestellnummer),
                zulassung: getDateValueOrUndefined(action.angebot.versichertesKfz.zulassung),
                erstzulassung: getDateValueOrUndefined(action.angebot.versichertesKfz.erstzulassung),
                carFundingType: getCarFundingType(action.angebot.versichertesKfz),
                nutzer: getNutzer(action.angebot.nutzer),
                fahrleistung: action.angebot.versichertesKfz.fahrleistung,
                kilometerstand: action.angebot.versichertesKfz.kilometerstand,
                amtlichesKennzeichen: getKennzeichen(action.angebot.versichertesKfz.amtlichesKennzeichen),
                istSaisonKennzeichen:
                    action.angebot.versichertesKfz.amtlichesKennzeichen.saisonkennzeichenVon !== null &&
                    action.angebot.versichertesKfz.amtlichesKennzeichen.saisonkennzeichenBis !== null &&
                    action.angebot.versichertesKfz.amtlichesKennzeichen.saisonkennzeichenVon !== 0 &&
                    action.angebot.versichertesKfz.amtlichesKennzeichen.saisonkennzeichenBis !== 0,
                typklasseHaftpflicht: action.angebot.versichertesKfz.typklasseHaftpflicht,
                typklasseTeilkasko: action.angebot.versichertesKfz.typklasseTeilkasko,
                typklasseVollkasko: action.angebot.versichertesKfz.typklasseVollkasko,
                zweitwagen: action.angebot.versichertesKfz.zweitwagen,
                regionalklasseHaftpflicht: getRegionalklasseHaftpflicht(action.angebot.vertraege),
                regionalklasseTeilkasko: getRegionalklasseTeilkasko(action.angebot.vertraege),
                regionalklasseVollkasko: getRegionalklasseVollkasko(action.angebot.vertraege),
                wertebereichFahrleistung: getWertebereichFromAngebot('fahrleistung', action.angebot.versichertesKfz.wertebereiche)
            };
        }
        case LADE_WOHNORTE: {
            return {
                ...state,
                wohnorteAuswahl: action.payload.wohnorte
            };
        }
        case CHANGE_NUTZER: {
            return {
                ...state,
                nutzer: {
                    ...state.nutzer,
                    [action.payload.fieldName]: action.payload.fieldValue
                }
            };
        }
        case CHANGE_FAHRZEUG: {
            return {
                ...state,
                [action.payload.fieldName]: action.payload.fieldValue
            };
        }

        case CHANGE_KENNZEICHEN: {
            return {
                ...state,
                amtlichesKennzeichen: {
                    ...state.amtlichesKennzeichen,
                    [action.payload.fieldName]: action.payload.fieldValue
                }
            };
        }
        case RESET_SAISONKENNZEICHEN: {
            return {
                ...state,
                amtlichesKennzeichen: {
                    ...state.amtlichesKennzeichen,
                    saisonkennzeichenBis: action.saisonkennzeichenBis,
                    saisonkennzeichenVon: action.saisonkennzeichenVon
                },
                istSaisonKennzeichen: action.istSaisonKennzeichen
            };
        }
        case CHANGE_FAHRZEUGNUTZER: {
            return {
                ...state,
                nutzer: {
                    ...state.nutzer,
                    fahrzeugnutzer: action.fahrzeugnutzer
                }
            };
        }
        case ZEIGE_KENNZEICHEN_WIRD_GESPEICHERT_SPINNER: {
            return {
                ...state,
                showKennzeichenIsSavingSpinner: action.showSpinner
            };
        }
        default:
            return state;
    }
};

const getCarFundingType = (data: VersichertesKfz): CarFundingType => {
    if(data.kreditfinanziert) {
        return CarFundingType.CREDIT
    } else if(data.leasing) {
        return CarFundingType.LEASING
    }
    return CarFundingType.NONE
}

const getNutzer = (nutzer: Nutzer): NutzerState => {
    return {
        halter: nutzer.halter === null ? undefined : nutzer.halter,
        geburtsdatum: getDateValueOrUndefined(nutzer.geburtsdatum),
        tarifgruppe: nutzer.tarifgruppe ? nutzer.tarifgruppe : Tarifgruppe.STANDARD_19,
        fuehrerscheinausstellung: getDateValueOrUndefined(nutzer.fuehrerscheinausstellung),
        ortHalter: getStringValueOrEmpty(nutzer.ortHalter),
        postleitzahlHalter: getStringValueOrEmpty(nutzer.postleitzahlHalter),
        teilnahmeBegleitetesFahren: nutzer.teilnahmeBegleitetesFahren,
        fahrzeugnutzer: nutzer.fahrzeugnutzer,
        geburtsdatumEhepartner: getDateValueOrUndefined(nutzer.geburtsdatumEhepartner),
        geburtsdatumJuengsterFahrer: getDateValueOrUndefined(nutzer.geburtsdatumJuengsterFahrer),
        wertebereichHalter: getWertebereichFromAngebot<WertebereichAufzaehlung>('halter', nutzer.wertebereiche),
        wertebereichTarifgruppe: getWertebereichFromAngebot<WertebereichAufzaehlung>('tarifgruppe', nutzer.wertebereiche)
    };
};

const getKennzeichen = (kennzeichen: SaisonKennzeichen): SaisonKennzeichen => {
    return {
        buchstabenkombination: getStringValueOrEmpty(kennzeichen.buchstabenkombination),
        ort: getStringValueOrEmpty(kennzeichen.ort),
        saisonkennzeichenBis: kennzeichen.saisonkennzeichenBis,
        saisonkennzeichenVon: kennzeichen.saisonkennzeichenVon,
        ziffernkombination: kennzeichen.ziffernkombination
    };
};
