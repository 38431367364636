import { PdsPerson } from '../personen/PersonDto';

export interface OfferEngineAngebotDto {
    versions: ProductVersions;
    angebot: OfferEngineAngebot;
    personen: PdsPerson[];
    versicherungsGesellschaften: VersicherungsGesellschaft[];
}

export interface ProductVersions {
    offerEngineBaseVersion: string;
    kfz2019V2Version: string;
}

export interface OfferEngineAngebot {
    adsId: string;
    aenderungsdatum: string;
    aktionsnummer: string;
    angebotsgrund: Angebotsgrund;
    angebotsnummer: string;
    angebotsstatus: Angebotsstatus;
    angebotsstatusaenderung: string;
    bankverbindung: Bankverbindung;
    beitraege: Beitrag[];
    beratungsprotokollId: string;
    bonitaet: Bonitaet;
    businessId: string;
    erstelldatum: string;
    erstellernummer: string;
    erstellername: string | null;
    evb: Evb;
    historiennummer: string;
    kanalrichtung: Kanalrichtung | null;
    kundenberater: Kundenberater;
    kundennummer: string;
    leistungsvereinbarungen: Leistungsvereinbarung[];
    link: Link;
    mandant: string;
    nutzer: Nutzer;
    quellsystem: Quellsystem;
    rollen: Rolle[];
    sfrEinstufung: SfrEinstufung;
    sparte: string;
    tarifgeneration: string;
    ursprungAngebotsnummer: string;
    verkaufsname: string;
    verkaufsproduktart: string;
    verkaufsprozessart: Verkaufsprozessart;
    vermittler: Vermittler;
    versandmail: string;
    versandweg: Versandweg;
    versichertesKfz: VersichertesKfz;
    versicherungen: Versicherung[];
    vertraege: Vertrag[];
    vertriebskanal: Vertriebskanal;
    vorversicherung: Vorversicherung;
    vsnrErgoDirekt: string;
    vsnrRisikotraeger: string;
    wertebereiche: WertebereichAngebot[];
}

export enum Angebotsgrund {
    ERSTVERTRAG = 'ERSTVERTRAG',
    VERSICHERER_WECHSEL = 'VERSICHERER_WECHSEL',
    BISHERIGES_FAHRZEUG_ERGO = 'BISHERIGES_FAHRZEUG_ERGO',
    BISHERIGES_FAHRZEUG_ANDERER_VERSICHERER = 'BISHERIGES_FAHRZEUG_ANDERER_VERSICHERER'
}

export enum Angebotsstatus {
    INITIALISIERT = 'INITIALISIERT ',
    AKTUALISIERT = 'AKTUALISIERT',
    LOESCHBAR = 'LOESCHBAR'
}

export interface Bankverbindung {
    bankname: string;
    bic: string;
    iban: string;
    kontoinhaber: string;
}

export interface Beitrag {
    bruttobeitragNachZahlweise: number;
    link: Link;
    nettobeitragNachZahlweise: number;
    struktur: Struktur;
}

export interface Link {
    methods: string[];
    uri: string;
}

export interface Struktur {
    versicherungen: string;
    vertraege: string;
    additionalProp3: string;
}

export enum Bonitaet {
    NICHT_GEPRUEFT = 'NICHT_GEPRUEFT',
    POSITIV = 'POSITIV',
    NEGATIV = 'NEGATIV'
}

export interface Evb {
    angefordert: boolean;
    gueltigBis: Date;
    nummer: string;
}

export enum Kanalrichtung {
    EINGEHEND = 'EINGEHEND',
    AUSGEHEND = 'AUSGEHEND'
}

export interface Kundenberater {
    kundencentermitarbeiter: boolean;
    nummer: string;
}

export interface Leistungsvereinbarung {
    auswaehlbar: boolean;
    leistungsvereinbarungsId: string;
    leistungsvereinbarungsart: Leistungsvereinbarungsart;
    link: Link;
    obligatorisch: boolean;
    progression: number;
    struktur: Struktur;
    vereinbart: boolean;
    versicherungssumme: number;
    wertebereiche: WertebereichAngebot[];
}

export enum Leistungsvereinbarungsart {
    SCHUTZBRIEF = 'SCHUTZBRIEF',
    WERTSCHUTZ_24 = 'WERTSCHUTZ_24',
    WERTSCHUTZ_36 = 'WERTSCHUTZ_36',
    RABATTSCHUTZ = 'RABATTSCHUTZ',
    GAP = 'GAP',
    ERSATZFAHRZEUG_PLUS = 'ERSATZFAHRZEUG_PLUS',
    WERKSTATTBONUS = 'WERKSTATTBONUS',
    SAFE_DRIVE = 'SAFE_DRIVE'
}

export interface WertebereichAngebot {
    attribut: string;
    obligatorisch: boolean;
    typ: Typ;
    vorbelegung: {};
}

export interface WertebereichAufzaehlung extends WertebereichAngebot {
    werte: string[] | number[];
}

export interface WertebereichBereich extends WertebereichAngebot {
    min: number | Date;
    max: number | Date;
    schrittweite: number | Date | null;
}

export interface WertebereichUneingeschraenkt extends WertebereichAngebot {}

export enum Typ {
    AUFZAEHLUNG,
    BEREICH,
    UNEINGESCHRAENKT
}

export interface Nutzer {
    fahrzeugnutzer: Fahrzeugnutzer[];
    fuehrerscheinausstellung: Date;
    geburtsdatum: Date;
    geburtsdatumAeltesterFahrer: Date;
    geburtsdatumEhepartner: Date;
    geburtsdatumJuengsterFahrer: Date;
    halter: Halter;
    kindUnter16JahrenImHaushalt: boolean;
    ortHalter: string;
    postleitzahlHalter: string;
    tarifgruppe: Tarifgruppe;
    teilnahmeBegleitetesFahren: boolean;
    wertebereiche: WertebereichAngebot[];
}

export enum Fahrzeugnutzer {
    VERSICHERUNGSNEHMER = 'VERSICHERUNGSNEHMER',
    PARTNER = 'PARTNER',
    FAMILIENMITGLIEDER = 'FAMILIENMITGLIEDER',
    SONSTIGE = 'SONSTIGE'
}

export enum Halter {
    VERSICHERUNGSNEHMER = 'VERSICHERUNGSNEHMER',
    PARTNER = 'PARTNER',
    KIND = 'KIND',
    SONSTIGE = 'SONSTIGE'
}

export enum Tarifgruppe {
    STANDARD_19 = 'STANDARD_19',
    FINANZDIENSTLEISTER_INNENDIENST = 'FINANZDIENSTLEISTER_INNENDIENST',
    FINANZDIENSTLEISTER_AUSSENDIENST = 'FINANZDIENSTLEISTER_AUSSENDIENST',
    BEAMTE_RICHTER_PENSIONAERE_19 = 'BEAMTE_RICHTER_PENSIONAERE_19',
    BESCHAEFTIGTE_19 = 'BESCHAEFTIGTE_19',
    AGRARIER_19 = 'AGRARIER_19'
}

export enum Quellsystem {
    DAVID = 'DAVID',
    EDWIN = 'EDWIN',
    ERGO_INTERNET = 'ERGO_INTERNET',
    ERGO_VERMITTLERHOMEPAGE = 'ERGO_VERMITTLERHOMEPAGE',
    ERGODIREKT_INTERNET = 'ERGODIREKT_INTERNET'
}

export interface Rolle {
    bankverbindungsId: string | null;
    personId: string;
    rollentyp: Rollentyp;
}

export enum Rollentyp {
    VERSICHERUNGSNEHMER = 'VERSICHERUNGSNEHMER',
    VERSICHERTE_PERSON = 'VERSICHERTE_PERSON',
    BEITRAGSZAHLER = 'BEITRAGSZAHLER',
    BETEILIGTER_FAHRER = 'BETEILIGTER_FAHRER',
    HALTER = 'HALTER'
}

export interface SfrEinstufung {
    berechtigterBeiErgo: boolean;
    uebernahmePerson: boolean;
    uebernahmeVorvertrag: boolean;
    vsnrErstwagenErgo: string;
}

export enum Verkaufsprozessart {
    VOLLANGEBOT = 'VOLLANGEBOT',
    DIREKTABSCHLUSS = 'DIREKTABSCHLUSS'
}

export interface Vermittler {
    euVermittlerrichtlinie: boolean;
    identifikationsnummer: string;
    vermittlernummer: string;
}

export enum Versandweg {
    POST = 'POST',
    EMAIL = 'EMAIL'
}

export interface VersichertesKfz {
    alterFahrzeug: number | null;
    alterFahrzeugMonate: number | null;
    amtlichesKennzeichen: SaisonKennzeichen;
    elektrofahrzeug: boolean;
    erstzulassung: Date;
    fahrgestellnummer: string;
    fahrleistung: number | null;
    fahrzeugtypBezeichnung: string;
    herstellerBezeichnung: string;
    hsn: string;
    kilometerstand: number | null;
    kilowatt: number | null;
    kreditfinanziert: boolean;
    leasing: boolean;
    neuwert: number | null;
    nutzung: Nutzung;
    ps: number | null;
    tsn: string;
    typklasseHaftpflicht: number | null;
    typklasseTeilkasko: number | null;
    typklasseVollkasko: number | null;
    wagniskennziffer: number;
    wertebereiche: WertebereichAngebot[];
    zulassung: Date; // ohne Zeit
    zweitwagen: boolean;
}

export type SaisonKennzeichen = AmtlichesKennzeichen & {
    saisonkennzeichenBis: number | null;
    saisonkennzeichenVon: number | null;
};

export type AmtlichesKennzeichen = {
    buchstabenkombination: string | null;
    ort: string | null;
    ziffernkombination: number | null;
};

export enum Nutzung {
    PRIVAT = 'PRIVAT',
    GESCHAEFTLICH = 'GESCHAEFTLICH'
}

export interface Versicherung {
    abbuchungstag: number;
    aenderungsdatum: Date; // Zeitpunkt
    antragseingang: Date; // Zeitpunkt
    ausgewaehlt: boolean;
    berechenbar: boolean;
    deckungsumfang: Deckungsumfang;
    druckauftragsinformationen: Druckauftragsinformation[];
    hinweise: Hinweis[];
    historien: Historie[];
    link: Link;
    statusaenderungsdatum: Date; // Zeitpunkt
    tarifvariante: string;
    variantennummer: number;
    verkaufsname: string;
    verkaufszeitpunkt: Date; // Zeitpunkt
    versicherungsId: VersicherungsId;
    versicherungsbeginn: Date; // Zeitpunkt
    versicherungsende: Date;
    versicherungsstatus: Versicherungsstatus;
    wertebereiche: WertebereichAngebot[];
    workflownummer: string;
    zahlweise: Zahlweise;
}

export interface Hinweis {
    hinweisart: string;
    bestaetigt: boolean;
    kategorie: string;
    text: string;
}

export enum Deckungsumfang {
    HAFTPFLICHT = 'HAFTPFLICHT',
    HAFTPFLICHT_VOLLKASKO = 'HAFTPFLICHT_VOLLKASKO',
    HAFTPFLICHT_TEILKASKO = 'HAFTPFLICHT_TEILKASKO'
}

export interface Druckauftragsinformation {
    auftragsnummer: string;
    aussendeart: Aussendeart;
    freundschaftswerbung: boolean;
    versandweg: VersandwegDruckauftrag;
    zeitpunktAuftragserstellung: string;
}

export enum Aussendeart {
    HAUPT_AUSSENDUNG = 'HAUPT_AUSSENDUNG',
    NACHFASS = 'NACHFASS'
}

export enum VersandwegDruckauftrag {
    POST = 'POST',
    EMAIL = 'EMAIL',
    DIGITALE_KUNDENAKTE = 'DIGITALE_KUNDENAKTE',
    FAX = 'FAX',
    SACHBEARBEITER = 'SACHBEARBEITER',
    SIMULATION = 'SIMULATION'
}

export interface Historie {
    historiennummer: number;
    versicherungsstatus: Versicherungsstatus;
    versicherungsstatusaenderung: string;
}

export enum VersicherungsId {
    smart = 'smart',
    best = 'best'
}

/*
 * https://confluence.itergo.com/display/as2000ab/Status-Modell
 * */
export enum Versicherungsstatus {
    ANGELEGT = 'ANGELEGT',
    ABSCHLUSS_BEANTRAGT = 'ABSCHLUSS_BEANTRAGT',
    ANGEBOT_BEANTRAGT = 'ANGEBOT_BEANTRAGT',
    ANGEBOT_VERSENDET = 'ANGEBOT_VERSENDET',
    ANTRAG_EINGEGANGEN = 'ANTRAG_EINGEGANGEN',
    ANTRAGSBEARBEITUNG_ABGESCHLOSSEN = 'ANTRAGSBEARBEITUNG_ABGESCHLOSSEN',
    ANTRAG_GEPRUEFT = 'ANTRAG_GEPRUEFT',
    VORGANG_ABGESCHLOSSEN = 'VORGANG_ABGESCHLOSSEN'
}

export enum Zahlweise {
    EINMALZAHLUNG = 'EINMALZAHLUNG',
    JAEHRLICH = 'JAEHRLICH',
    HALBJAEHRLICH = 'HALBJAEHRLICH',
    VIERTELJAEHRLICH = 'VIERTELJAEHRLICH',
    MONATLICH = 'MONATLICH'
}

export interface Vertrag {
    typ: TypVertrag;
    struktur: Struktur;
    vertragsId: VertragsId;
    vertragsart: Vertragsart;
}

export enum TypVertrag {
    haftpflicht = 'haftpflicht',
    vollkasko = 'vollkasko',
    teilkasko = 'teilkasko'
}

export enum VertragsId {
    HAFTPFLICHT = 'HAFTPFLICHT',
    KASKO = 'KASKO'
}

export interface TeilkaskoVertrag extends Vertrag {
    deckungsschluessel: Deckungsschluessel;

    regionalklasseTeilkasko: number | null;

    selbstbeteiligungTeilkasko: SelbstbeteiligungKasko;

    wertebereiche: WertebereichAngebot[];
}

export interface VollkaskoVertrag extends TeilkaskoVertrag {
    regionalklasseVollkasko: number | null;

    selbstbeteiligungVollkasko: SelbstbeteiligungKasko;
    schadenfreiheitsrabatt: Schadenfreiheitsrabatt;
}

export enum SelbstbeteiligungKasko {
    SB_OHNE = 'SB_OHNE',
    SB_150 = 'SB_150',
    SB_300 = 'SB_300',
    SB_500 = 'SB_500',
    SB_1000 = 'SB_1000'
}

export enum Deckungsschluessel {
    VK_OHNE_TK_OHNE = 'VK_OHNE_TK_OHNE',
    VK_150_TK_150 = 'VK_150_TK_150',
    VK_300_TK_150 = 'VK_300_TK_150',
    VK_500_TK_150 = 'VK_500_TK_150',
    VK_1000_TK_150 = 'VK_1000_TK_150',
    VK_150_TK_OHNE = 'VK_150_TK_OHNE',
    VK_300_TK_OHNE = 'VK_300_TK_OHNE',
    VK_500_TK_OHNE = 'VK_500_TK_OHNE',
    VK_1000_TK_OHNE = 'VK_1000_TK_OHNE',
    VK_500_TK_500 = 'VK_500_TK_500',
    VK_1000_TK_1000 = 'VK_1000_TK_1000',
    TK_OHNE = 'TK_OHNE',
    TK_150 = 'TK_150',
    TK_500 = 'TK_500'
}

export interface Schadenfreiheitsrabatt {
    einstufungsgrund: Einstufungsgrund;
    prozent: number;
    schadenfreiheitsklasse: Schadenfreiheitsklasse;
    wertebereiche: WertebereichAngebot[];
}

export enum Einstufungsgrund {
    ZWEITWAGENREGELUNG_ZWEITKRADREGELUNG_01 = 'ZWEITWAGENREGELUNG_ZWEITKRADREGELUNG_01',
    FUEHRERSCHEINREGELUNG_03 = 'FUEHRERSCHEINREGELUNG_03',
    VERBESSERTE_ZWEITWAGENREGELUNG_04 = 'VERBESSERTE_ZWEITWAGENREGELUNG_04',
    DIENSTWAGEN_17 = 'DIENSTWAGEN_17',
    ERSTVERSICHERUNG_18 = 'ERSTVERSICHERUNG_18',
    BESONDERE_VEREINBARUNG_11 = 'BESONDERE_VEREINBARUNG_11'
}

export enum Schadenfreiheitsklasse {
    SF_M = 'SF_M',
    KL_0 = 'KL_0',
    SF_S = 'SF_S',
    SF_1_2 = 'SF_1_2',
    SF_1 = 'SF_1',
    SF_2 = 'SF_2',
    SF_3 = 'SF_3',
    SF_4 = 'SF_4',
    SF_5 = 'SF_5',
    SF_6 = 'SF_6',
    SF_7 = 'SF_7',
    SF_8 = 'SF_8',
    SF_9 = 'SF_9',
    SF_10 = 'SF_10',
    SF_11 = 'SF_11',
    SF_12 = 'SF_12',
    SF_13 = 'SF_13',
    SF_14 = 'SF_14',
    SF_15 = 'SF_15',
    SF_16 = 'SF_16',
    SF_17 = 'SF_17',
    SF_18 = 'SF_18',
    SF_19 = 'SF_19',
    SF_20 = 'SF_20',
    SF_21 = 'SF_21',
    SF_22 = 'SF_22',
    SF_23 = 'SF_23',
    SF_24 = 'SF_24',
    SF_25 = 'SF_25',
    SF_26 = 'SF_26',
    SF_27 = 'SF_27',
    SF_28 = 'SF_28',
    SF_29 = 'SF_29',
    SF_30 = 'SF_30',
    SF_31 = 'SF_31',
    SF_32 = 'SF_32',
    SF_33 = 'SF_33',
    SF_34 = 'SF_34',
    SF_35 = 'SF_35',
    SF_36 = 'SF_36',
    SF_37 = 'SF_37',
    SF_38 = 'SF_38',
    SF_39 = 'SF_39',
    SF_40 = 'SF_40',
    SF_41 = 'SF_41',
    SF_42 = 'SF_42',
    SF_43 = 'SF_43',
    SF_44 = 'SF_44',
    SF_45 = 'SF_45',
    SF_46 = 'SF_46',
    SF_47 = 'SF_47',
    SF_48 = 'SF_48',
    SF_49 = 'SF_49',
    SF_50 = 'SF_50'
}

export interface HaftpflichtVertrag extends Vertrag {
    alterJuengsterFahrer: number; // Readonly in Jahren

    regionalklasseHaftpflicht: number | null;
    schadenfreiheitsrabatt: Schadenfreiheitsrabatt;
}

export enum Vertragsart {
    HAFTPFLICHT = 'HAFTPFLICHT',
    KASKO = 'KASKO'
}

export enum Vertriebskanal {
    TELEFON = 'TELEFON',
    INTERNET = 'INTERNET',
    SCHRIFTLICH = 'SCHRIFTLICH',
    CHAT = 'CHAT',
    SKYPE = 'SKYPE',
    EMAIL = 'EMAIL'
}

export interface Vorversicherung {
    bisherigesAmtlichesKennzeichen: AmtlichesKennzeichen;
    fahrzeugGestohlen: boolean | null;
    gekuendigt: Gekuendigt;
    gesellschaft: string;
    versicherungsunternehmennummer: string;
    vorschaedenHaftpflicht: Vorschaeden;
    vorschaedenTeilkasko: Vorschaeden;
    vorschaedenVollkasko: Vorschaeden;
    vorversicherungErgo: boolean;
    vsnr: string;
    wertebereiche: WertebereichAngebot[];
}

export enum Gekuendigt {
    NEIN = 'NEIN',
    JA_VERSICHERUNGSNEHMER = 'JA_VERSICHERUNGSNEHMER',
    JA_VERSICHERUNGSUNTERNEHMEN = 'JA_VERSICHERUNGSUNTERNEHMEN'
}

export enum Vorschaeden {
    KEINE = 'KEINE',
    EIN = 'EIN',
    ZWEI = 'ZWEI',
    MEHR_ALS_ZWEI = 'MEHR_ALS_ZWEI'
}

export interface VersicherungsGesellschaft {
    name: string;
    versicherungsunternehmensNummer: string;
}
