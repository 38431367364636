import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import Radio from '@eg/elements/Radio';
import InputRow from '@eg/elements/InputRow';
import Button from '@eg/elements/Button';
import SearchIcon from '@eg/elements/components/Icons/SearchIcon';
import SelectRow from '@eg/elements/SelectRow';
import RadioTable from '@eg/elements/RadioTable';
import {
    changeHerstellerAsync,
    changeHsnSync,
    changeKategorieAsync,
    changeKraftstoffAsync,
    changeLeistungAsync,
    changeModellAsync,
    changeSearchTypeAsync,
    changeTsnSync,
    resetFahrzeugsucheSync,
    selectFahrzeugAsync,
    storeFahrzeugsucheResponseAsync
} from './FahrzeugsucheAction';
import { isEmpty } from '../util/validate';
import { Fahrzeug, HerstellerCategory, Kategorie, Kraftstoff, Leistung, ModelleCategory } from '../util/fetch/fahrzeugsuche/FahrzeugsucheDto';
import { FahrzeugsucheState, MIT_KFZ_SCHEIN, OHNE_KFZ_SCHEIN } from './FahrzeugsucheReducer';
import Modal from '@eg/elements/Modal';
import Card from '@eg/elements/Card';
import { InputEvent, InputKeyboardEvent, RadioEvent, SelectEvent } from '../util/UiEventTypes';
import UnmanagedDetails from '@eg/elements/UnmanagedDetails';

interface FahrzeugsuchePresentationState {
    isOpen: boolean;
}

export class FahrzeugsuchePresentation extends React.Component<FahrzeugsuchePresentationProps, FahrzeugsuchePresentationState> {
    constructor(props: FahrzeugsuchePresentationProps) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    handleClickRow = (index: number) => {
        if (this.props.fahrzeugsuche.selectedFahrzeugRowIndex !== index) {
            this.props.handleSelectFahrzeug(this.props.businessId, index, this.props.fahrzeugsuche.fahrzeugsucheResponse[index], this.props.oberflaecheSperren);
        }
    };

    componentDidUpdate(prevProps: Readonly<FahrzeugsuchePresentationProps>): void {
        if (prevProps.fahrzeugsuche.closeFahrzeugsuche !== this.props.fahrzeugsuche.closeFahrzeugsuche && this.props.fahrzeugsuche.closeFahrzeugsuche) {
            this.setState(() => ({
                isOpen: false
            }));
        }
    }

    createOptions = (options: any[], categories: string[]): JSX.Element[] => {
        return categories.map((currentCategory: string) => {
            return (
                <optgroup key={currentCategory} label={currentCategory}>
                    {options
                        .filter(option => option.category === currentCategory)
                        .map((option, index) => (
                            <option key={option.label + option.category + index} value={option.label}>
                                {option.label}
                            </option>
                        ))}
                </optgroup>
            );
        });
    };

    render() {
        const {
            businessId,
            fahrzeugsuche,
            oberflaecheSperren,
            handleChangeSearchType,
            handleChangeHsn,
            handleChangeTsn,
            handleGetFahrzeugMitKfzSchein,
            handleChangeHersteller,
            handleChangeModell,
            handleChangeKraftstoff,
            handleChangeKategorie,
            handleChangeLeistung,
            handleResetFahrzeugsuche
        } = this.props;

        return (
            <UnmanagedDetails
                summary={<h2 className="esc_h4">Fahrzeugsuche</h2>}
                isOpen={this.state.isOpen}
                onToggleOpen={() => this.setState((prevState: FahrzeugsuchePresentationState) => ({ isOpen: !prevState.isOpen }))}
            >
                <RadioGroupRow
                    label="Pkw suchen"
                    name="fahrzeugsuche"
                    disabled={oberflaecheSperren}
                    value={fahrzeugsuche.searchType}
                    onChange={(e: RadioEvent) => handleChangeSearchType(businessId, e.target.value)}
                >
                    <Radio label="mit KFZ-Schein" value={MIT_KFZ_SCHEIN} />
                    <Radio label="ohne KFZ-Schein" value={OHNE_KFZ_SCHEIN} />
                </RadioGroupRow>
                {fahrzeugsuche.searchType === MIT_KFZ_SCHEIN ? (
                    <div>
                        <InputRow
                            label="Hersteller-Nr. (HSN)"
                            value={fahrzeugsuche.hsn}
                            disabled={oberflaecheSperren}
                            onChange={(e: InputEvent) => handleChangeHsn(e.currentTarget.value)}
                            onKeyPress={(e: InputKeyboardEvent) =>
                                e.key === 'Enter' &&
                                !isEmpty(fahrzeugsuche.tsn) &&
                                !isEmpty(e.currentTarget.value) &&
                                handleGetFahrzeugMitKfzSchein(businessId, e.currentTarget.value, fahrzeugsuche.tsn)
                            }
                        />
                        <InputRow
                            label="Typschlüssel-Nr. (TSN)"
                            value={fahrzeugsuche.tsn}
                            disabled={oberflaecheSperren}
                            onChange={(e: InputEvent) => handleChangeTsn(e.currentTarget.value.toUpperCase())}
                            onKeyPress={(e: InputKeyboardEvent) =>
                                e.key === 'Enter' &&
                                !isEmpty(fahrzeugsuche.hsn) &&
                                !isEmpty(e.currentTarget.value) &&
                                handleGetFahrzeugMitKfzSchein(businessId, fahrzeugsuche.hsn, e.currentTarget.value.toUpperCase())
                            }
                        />
                        <Button
                            variant="primary"
                            iconLeft={SearchIcon}
                            disabled={isEmpty(fahrzeugsuche.hsn) || isEmpty(fahrzeugsuche.tsn) || oberflaecheSperren}
                            onClick={() => handleGetFahrzeugMitKfzSchein(businessId, fahrzeugsuche.hsn, fahrzeugsuche.tsn)}
                        >
                            Fahrzeug suchen
                        </Button>
                    </div>
                ) : (
                    <div>
                        <SelectRow
                            label="Hersteller"
                            value={fahrzeugsuche.herstellerGewaehlt}
                            disabled={oberflaecheSperren}
                            onChange={(e: SelectEvent) => handleChangeHersteller(businessId, e.target.value)}
                        >
                            <option value="hersteller">Bitte wählen</option>
                            {this.createOptions(fahrzeugsuche.herstellerAuswahl, Object.values(HerstellerCategory))}
                        </SelectRow>
                        <SelectRow
                            label="Modell"
                            value={fahrzeugsuche.modellGewaehlt}
                            disabled={oberflaecheSperren}
                            onChange={(e: SelectEvent) => handleChangeModell(businessId, fahrzeugsuche.herstellerGewaehlt, e.target.value)}
                        >
                            <option value="modelle">Bitte wählen</option>
                            {this.createOptions(fahrzeugsuche.modellAuswahl, Object.values(ModelleCategory))}
                        </SelectRow>
                        <SelectRow
                            label="Kraftstoff"
                            value={fahrzeugsuche.kraftstoffGewaehlt}
                            disabled={oberflaecheSperren}
                            onChange={(e: SelectEvent) => handleChangeKraftstoff(businessId, fahrzeugsuche.herstellerGewaehlt, fahrzeugsuche.modellGewaehlt, e.target.value)}
                        >
                            <option value="kraftstoff">Bitte wählen</option>
                            {fahrzeugsuche.kraftstoffAuswahl &&
                                fahrzeugsuche.kraftstoffAuswahl.length > 0 &&
                                fahrzeugsuche.kraftstoffAuswahl.map((option: Kraftstoff, index) => (
                                    <option key={option.value + index} value={option.value}>
                                        {option.text}
                                    </option>
                                ))}
                        </SelectRow>
                        <SelectRow
                            label="Kategorie"
                            value={fahrzeugsuche.kategorieGewaehlt}
                            disabled={oberflaecheSperren}
                            onChange={(e: SelectEvent) =>
                                handleChangeKategorie(businessId, fahrzeugsuche.herstellerGewaehlt, fahrzeugsuche.modellGewaehlt, fahrzeugsuche.kraftstoffGewaehlt, e.target.value)
                            }
                        >
                            <option value="kategorie">Bitte wählen</option>
                            {fahrzeugsuche.kategorieAuswahl &&
                                fahrzeugsuche.kategorieAuswahl.length > 0 &&
                                fahrzeugsuche.kategorieAuswahl.map((option: Kategorie, index) => (
                                    <option key={option.kategorie + index} value={option.kategorie}>
                                        {option.kategorie}
                                    </option>
                                ))}
                        </SelectRow>
                        <SelectRow
                            label="Leistung"
                            value={fahrzeugsuche.leistungGewaehlt}
                            disabled={oberflaecheSperren}
                            onChange={(e: SelectEvent) =>
                                handleChangeLeistung(
                                    businessId,
                                    fahrzeugsuche.herstellerGewaehlt,
                                    fahrzeugsuche.modellGewaehlt,
                                    fahrzeugsuche.kraftstoffGewaehlt,
                                    fahrzeugsuche.kategorieGewaehlt,
                                    e.target.value
                                )
                            }
                        >
                            <option value="leistung">Bitte wählen</option>
                            {fahrzeugsuche.leistungAuswahl &&
                                fahrzeugsuche.leistungAuswahl.length > 0 &&
                                fahrzeugsuche.leistungAuswahl.map((option: Leistung, index) => (
                                    <option key={option.value + index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                        </SelectRow>
                    </div>
                )}
                {fahrzeugsuche.showResponse &&
                    (fahrzeugsuche.fahrzeugsucheResponse && fahrzeugsuche.fahrzeugsucheResponse.length > 0 ? (
                        <div className="fahrzeugsuche-ergebnis">
                            <RadioTable
                                selectedRow={fahrzeugsuche.selectedFahrzeugRowIndex}
                                onRowClick={(index: number) => this.handleClickRow(index)}
                                disabled={oberflaecheSperren}
                            >
                                <thead>
                                    <tr>
                                        <th>MARKE</th>
                                        <th>MODELL</th>
                                        <th>PS/KW</th>
                                        <th>HSN/TSN</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fahrzeugsuche.fahrzeugsucheResponse.map((fahrzeug: Fahrzeug, index) => (
                                        <tr key={fahrzeug.anzeigeText + index}>
                                            <td>
                                                <Radio label={fahrzeug.herstellerCode} value={fahrzeug.herstellerCode} checked={fahrzeugsuche.selectedFahrzeugRowIndex === index} />
                                            </td>
                                            <td>{fahrzeug.typ}</td>
                                            <td>{fahrzeug.leistungText}</td>
                                            <td>
                                                {fahrzeug.hsn}/{fahrzeug.tsn}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </RadioTable>
                        </div>
                    ) : (
                        <div>
                            <Card padding="small" style={{ color: '#bf1528', marginTop: 20 }} accentColor="#bf1528">
                                <div style={{ paddingLeft: 20 }}>Zu Ihren Angaben konnte kein Fahrzeug gefunden werden!</div>
                            </Card>
                        </div>
                    ))}
                <Modal open={fahrzeugsuche.hoeherwertigesFahrzeug} dismissible backdropDismissesModal onDismiss={handleResetFahrzeugsuche}>
                    Es handelt sich um ein höherwertiges Fahrzeug. Bitte leiten Sie die Kundenanfrage über den Geschäftsvorfall{' '}
                    <i>&quot;550 Weiterleitung an ERGO - ERGO Produkte&quot;</i> in EDwin weiter.
                </Modal>
            </UnmanagedDetails>
        );
    }
}

interface FahrzeugsuchePresentationProps {
    businessId: string;
    fahrzeugsuche: FahrzeugsucheState;
    oberflaecheSperren: boolean;
    handleChangeSearchType: (businessId: string, visibility: string) => void;
    handleChangeHsn: (hsn: string) => void;
    handleChangeTsn: (tsn: string) => void;
    handleGetFahrzeugMitKfzSchein: (businessId: string, hsn: string, tsn: string) => void;
    handleChangeHersteller: (businessId: string, hersteller: string) => void;
    handleChangeModell: (businessId: string, hersteller: string, modell: string) => void;
    handleChangeKraftstoff: (businessId: string, hersteller: string, modell: string, kraftstoff: string) => void;
    handleChangeKategorie: (businessId: string, hersteller: string, modell: string, kraftstoff: string, kategorie: string) => void;
    handleChangeLeistung: (businessId: string, hersteller: string, modell: string, kraftstoff: string, kategorie: string, leistung: string) => void;
    handleSelectFahrzeug: (businessId: string, index: number, fahrzeugGewaehlt: Fahrzeug, oberflaecheSperren: boolean) => void;
    handleResetFahrzeugsuche: () => void;
}

const mapStateToProps = (state: IAppState) => ({
    businessId: state.basisdaten.businessId,
    fahrzeugsuche: state.fahrzeugsuche,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleChangeSearchType: (businessId: string, visibility: string): void => {
        // @ts-ignore
        dispatch(changeSearchTypeAsync(businessId, visibility));
    },
    handleChangeHsn: (hsn: string): void => {
        dispatch(changeHsnSync(hsn));
    },
    handleChangeTsn: (tsn: string): void => {
        dispatch(changeTsnSync(tsn));
    },
    handleGetFahrzeugMitKfzSchein: (businessId: string, hsn: string, tsn: string): void => {
        // @ts-ignore
        dispatch(storeFahrzeugsucheResponseAsync(businessId, hsn, tsn));
    },
    handleChangeHersteller: (businessId: string, hersteller: string): void => {
        // @ts-ignore
        dispatch(changeHerstellerAsync(businessId, hersteller));
    },
    handleChangeModell: (businessId: string, hersteller: string, modell: string): void => {
        // @ts-ignore
        dispatch(changeModellAsync(businessId, hersteller, modell));
    },
    handleChangeKraftstoff: (businessId: string, hersteller: string, modell: string, kraftstoff: string): void => {
        // @ts-ignore
        dispatch(changeKraftstoffAsync(businessId, hersteller, modell, kraftstoff));
    },
    handleChangeKategorie: (businessId: string, hersteller: string, modell: string, kraftstoff: string, kategorie: string): void => {
        // @ts-ignore
        dispatch(changeKategorieAsync(businessId, hersteller, modell, kraftstoff, kategorie));
    },
    handleChangeLeistung: (businessId: string, hersteller: string, modell: string, kraftstoff: string, kategorie: string, leistung: string): void => {
        // @ts-ignore
        dispatch(changeLeistungAsync(businessId, hersteller, modell, kraftstoff, kategorie, leistung));
    },
    handleSelectFahrzeug: (businessId: string, index: number, fahrzeugGewaehlt: Fahrzeug, oberflaecheSperren: boolean): void => {
        if (!oberflaecheSperren) {
            // @ts-ignore
            dispatch(selectFahrzeugAsync(businessId, index, fahrzeugGewaehlt));
        }
    },
    handleResetFahrzeugsuche: (): void => {
        dispatch(resetFahrzeugsucheSync());
    }
});

export const Fahrzeugsuche = connect(mapStateToProps, mapDispatchToProps)(FahrzeugsuchePresentation);
