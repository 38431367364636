import {
    Beitrag,
    Deckungsschluessel,
    Deckungsumfang,
    HaftpflichtVertrag,
    Leistungsvereinbarung,
    OfferEngineAngebot,
    Schadenfreiheitsklasse,
    TeilkaskoVertrag,
    Typ,
    TypVertrag,
    Versicherung as VersicherungAngebot,
    VersicherungsId,
    Vertrag,
    VertragsId,
    VollkaskoVertrag,
    WertebereichAngebot,
    WertebereichAufzaehlung,
    WertebereichBereich,
    Zahlweise
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import { getDateValueOrUndefined } from '../util/ReducerHelper';
import {
    AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG,
    AENDERE_AUSWAHL_VERSICHERUNG,
    AENDERE_VERSICHERUNGSFELD,
    ChangeLeistungsvereinbarungSelectionAction,
    ChangeVersicherungFieldAction,
    ChangeVersicherungSelectionAction,
    ShowVersicherungsschutzLoadingSpinnerAction,
    ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ
} from './VersicherungsschutzAction';
import { BITTE_WAEHLEN, getWertebereichFromAngebot } from '../util/WertebereicheHelper';

export interface VersicherungsschutzState {
    versicherung: Versicherung;
    vertraege: VertragState[];
    showVersicherungsschutzLoadingSpinner: boolean;
}

export interface Versicherung {
    sfkHaftpflicht: Schadenfreiheitsklasse | string;
    versicherungsbeginn: Date | undefined;
    wertebereichVersicherungsbeginn: WertebereichVersicherungsDatum;
    wertebereichVersicherungsende: WertebereichVersicherungsDatum;
    versicherungsende: Date | undefined;
    zahlweise: Zahlweise;
    deckungsumfang: Deckungsumfang;
    sfkVollkasko: Schadenfreiheitsklasse | string;
    selbstbeteiligungVollkasko: Deckungsschluessel;
    selbstbeteiligungTeilkasko: Deckungsschluessel;
    wertebereichZahlweise: WertebereichAufzaehlung;
    wertebereichSfkHaftpflicht: WertebereichAufzaehlung;
    wertebereichSfkVollkasko: WertebereichAufzaehlung;
    wertebereichDeckungsschluesselVollkasko: WertebereichAufzaehlung;
    wertebereichDeckungsschluesselTeilkasko: WertebereichAufzaehlung;
}

export interface WertebereichVersicherungsDatum {
    min: Date | undefined;
    max: Date | undefined;
}

export interface VertragState {
    versicherungsId: VersicherungsId;
    vertragsBeitraege: VertragsBeitrag[];
    ausgewaehlt: boolean;
    berechenbar: boolean;
    BeitragGesamt: number;
    leistungsvereinbarungen: Leistungsvereinbarung[];
}

export interface VertragsBeitrag {
    vertragsId: VertragsId;
    beitrag: number;
}

const initialVersicherungsschutzState = {
    versicherung: {
        sfkHaftpflicht: BITTE_WAEHLEN,
        versicherungsbeginn: undefined,
        wertebereichVersicherungsbeginn: {
            min: new Date(2019, 8, 15),
            max: new Date(2020, 11, 31)
        },
        wertebereichVersicherungsende: {
            min: new Date(2019, 9, 1),
            max: new Date(2020, 8, 1)
        },
        versicherungsende: undefined,
        zahlweise: Zahlweise.JAEHRLICH,
        deckungsumfang: Deckungsumfang.HAFTPFLICHT,
        sfkVollkasko: BITTE_WAEHLEN,
        selbstbeteiligungVollkasko: Deckungsschluessel.VK_OHNE_TK_OHNE,
        selbstbeteiligungTeilkasko: Deckungsschluessel.TK_150,
        vnOhneMailadresse: false,
        wertebereichZahlweise: {
            werte: [],
            attribut: '',
            obligatorisch: false,
            typ: Typ.AUFZAEHLUNG,
            vorbelegung: {}
        },
        wertebereichSfkHaftpflicht: {
            werte: [],
            attribut: '',
            obligatorisch: false,
            typ: Typ.AUFZAEHLUNG,
            vorbelegung: {}
        },
        wertebereichSfkVollkasko: {
            werte: [],
            attribut: '',
            obligatorisch: false,
            typ: Typ.AUFZAEHLUNG,
            vorbelegung: {}
        },
        wertebereichDeckungsschluesselVollkasko: {
            werte: [],
            attribut: '',
            obligatorisch: false,
            typ: Typ.AUFZAEHLUNG,
            vorbelegung: {}
        },
        wertebereichDeckungsschluesselTeilkasko: {
            werte: [],
            attribut: '',
            obligatorisch: false,
            typ: Typ.AUFZAEHLUNG,
            vorbelegung: {}
        }
    },
    vertraege: [],
    showVersicherungsschutzLoadingSpinner: false
};

type VersicherungsschutzAction = LoadOfferAction &
    ChangeVersicherungFieldAction &
    ChangeLeistungsvereinbarungSelectionAction &
    ChangeVersicherungSelectionAction &
    ShowVersicherungsschutzLoadingSpinnerAction;

export const versicherungsschutzReducer = (state: VersicherungsschutzState = initialVersicherungsschutzState, action: VersicherungsschutzAction): VersicherungsschutzState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                versicherung: {
                    ...state.versicherung,
                    versicherungsbeginn: getDateValueOrUndefined(action.angebot.versicherungen[0].versicherungsbeginn),
                    wertebereichVersicherungsbeginn: getWertebereichVersicherungsbeginn(action.angebot.versicherungen[0].wertebereiche),
                    wertebereichVersicherungsende: getWertebereichVersicherungsende(action.angebot.versicherungen[0].wertebereiche),
                    versicherungsende: getDateValueOrUndefined(action.angebot.versicherungen[0].versicherungsende),
                    zahlweise: action.angebot.versicherungen[0].zahlweise,
                    deckungsumfang: action.angebot.versicherungen[0].deckungsumfang,
                    sfkHaftpflicht: getSfkHaftpflicht(action.angebot.vertraege),
                    sfkVollkasko: getSfkVollkasko(action.angebot.vertraege),
                    selbstbeteiligungTeilkasko: getSelbstbeteiligungTeilkasko(action.angebot.vertraege),
                    selbstbeteiligungVollkasko: getSelbstbeteiligungVollkasko(action.angebot.vertraege),
                    wertebereichZahlweise: getWertebereichFromAngebot('zahlweise', action.angebot.versicherungen[0].wertebereiche),
                    wertebereichSfkHaftpflicht: getWertebereichSfk(action.angebot.vertraege, TypVertrag.haftpflicht),
                    wertebereichSfkVollkasko: getWertebereichSfk(action.angebot.vertraege, TypVertrag.vollkasko),
                    wertebereichDeckungsschluesselVollkasko: getWertebereichDeckungsschluessel(action.angebot.vertraege, TypVertrag.vollkasko),
                    wertebereichDeckungsschluesselTeilkasko: getWertebereichDeckungsschluessel(action.angebot.vertraege, TypVertrag.teilkasko)
                },
                vertraege: getVertragInfos(action.angebot)
            };
        }
        case AENDERE_VERSICHERUNGSFELD: {
            return {
                ...state,
                versicherung: {
                    ...state.versicherung,
                    [action.payload.fieldName]: action.payload.fieldValue
                }
            };
        }
        case AENDERE_AUSWAHL_LEISTUNGSVEREINBARUNG: {
            return {
                ...state,
                vertraege: state.vertraege.map((vertrag: VertragState) => {
                    if (vertrag.versicherungsId === action.payload.versicherungsId) {
                        return {
                            ...vertrag,
                            leistungsvereinbarungen: vertrag.leistungsvereinbarungen.map((leistungsvereinbarung: Leistungsvereinbarung) => {
                                if (leistungsvereinbarung.leistungsvereinbarungsart === action.payload.leistungsvereinbarungsart) {
                                    return {
                                        ...leistungsvereinbarung,
                                        vereinbart: action.payload.checked
                                    };
                                }
                                return leistungsvereinbarung;
                            })
                        };
                    }
                    return vertrag;
                })
            };
        }
        case AENDERE_AUSWAHL_VERSICHERUNG: {
            return {
                ...state,
                vertraege: state.vertraege.map((vertrag: VertragState) => {
                    return {
                        ...vertrag,
                        ausgewaehlt: vertrag.versicherungsId === action.payload.versicherungsId
                    };
                })
            };
        }
        case ZEIGE_LOADINGSPINNER_VERSICHERUNGSSCHUTZ: {
            return {
                ...state,
                showVersicherungsschutzLoadingSpinner: action.showLoadingSpinner
            };
        }
        default:
            return state;
    }
};

const getVertragInfos = (angebot: OfferEngineAngebot): VertragState[] => {
    let result: VertragState[] = [];

    Object.values(VersicherungsId).map((versicherungsId: VersicherungsId) => {
        const berechenbar: boolean = angebot.versicherungen.filter((versicherung: VersicherungAngebot) => versicherung.versicherungsId === versicherungsId)[0].berechenbar;

        let vertragsBeitraege: VertragsBeitrag[] = [];
        angebot.vertraege.map((vertrag: Vertrag) => {
            if (!vertragsBeitraege.some((vertragsBeitrag: VertragsBeitrag) => vertragsBeitrag.vertragsId === vertrag.vertragsId)) {
                const vertragsBeitrag: VertragsBeitrag = {
                    vertragsId: vertrag.vertragsId,
                    beitrag: getBeitrag(angebot.beitraege, versicherungsId, berechenbar, vertrag.vertragsId)
                };
                vertragsBeitraege.push(vertragsBeitrag);
            }
            return vertragsBeitraege;
        });

        const vertragInfo: VertragState = {
            versicherungsId: versicherungsId,
            vertragsBeitraege: vertragsBeitraege,
            ausgewaehlt: angebot.versicherungen.filter((versicherung: VersicherungAngebot) => versicherung.versicherungsId === versicherungsId)[0].ausgewaehlt,
            berechenbar: berechenbar,
            BeitragGesamt: getBeitrag(angebot.beitraege, versicherungsId, berechenbar),
            leistungsvereinbarungen: angebot.leistungsvereinbarungen.filter(
                (leistungsvereinbarung: Leistungsvereinbarung) => leistungsvereinbarung.struktur.versicherungen === versicherungsId
            )
        };
        return result.push(vertragInfo);
    });

    return result;
};

const getBeitrag = (beitraege: Beitrag[], versicherungsId: VersicherungsId, berechenbar: boolean, vertragsId?: VertragsId): number => {
    if (beitraege === null || !berechenbar) {
        return 0.0;
    }

    if (!vertragsId) {
        const beitraegeGesamt: Beitrag[] = beitraege.filter((beitrag: Beitrag) => beitrag.struktur.versicherungen === versicherungsId && beitrag.struktur.vertraege === undefined);
        if (!beitraegeGesamt || beitraegeGesamt.length === 0) {
            return 0.0;
        }

        return beitraegeGesamt[0].bruttobeitragNachZahlweise;
    }

    const beitraegeVertrag: Beitrag[] = beitraege.filter((beitrag: Beitrag) => beitrag.struktur.versicherungen === versicherungsId && beitrag.struktur.vertraege === vertragsId);
    if (!beitraegeVertrag || beitraegeVertrag.length === 0) {
        return 0.0;
    }

    return beitraegeVertrag[0].bruttobeitragNachZahlweise;
};

const getWertebereichVersicherungsbeginn = (wertebereiche: WertebereichAngebot[]): WertebereichVersicherungsDatum => {
    let result: WertebereichVersicherungsDatum = {
        min: new Date(2019, 8, 15),
        max: new Date(2020, 11, 31)
    };

    const wertebereich: WertebereichAngebot | undefined = wertebereiche.find((wertebereich: WertebereichAngebot) => wertebereich.attribut.toLowerCase() === 'versicherungsbeginn');

    if (wertebereich !== undefined) {
        const wertebereichVersicherungsbeginn: WertebereichBereich = wertebereich as WertebereichBereich;
        if (wertebereichVersicherungsbeginn.min) {
            // eslint-disable-next-line
            result.min = new Date;
        }
        if (wertebereichVersicherungsbeginn.max) {
            result.max = getDateValueOrUndefined(wertebereichVersicherungsbeginn.max as Date);
        }
    }

    return result;
};

const getWertebereichVersicherungsende = (wertebereiche: WertebereichAngebot[]): WertebereichVersicherungsDatum => {
    let result: WertebereichVersicherungsDatum = {
        min: new Date(2019, 9, 1),
        max: new Date(2020, 8, 1)
    };

    const wertebereich: WertebereichAngebot | undefined = wertebereiche.find((wertebereich: WertebereichAngebot) => wertebereich.attribut.toLowerCase() === 'versicherungsende');

    if (wertebereich !== undefined) {
        const wertebereichVersicherungsende: WertebereichBereich = wertebereich as WertebereichBereich;
        if (wertebereichVersicherungsende.min) {
            result.min = getDateValueOrUndefined(wertebereichVersicherungsende.min as Date);
        }
        if (wertebereichVersicherungsende.max) {
            result.max = getDateValueOrUndefined(wertebereichVersicherungsende.max as Date);
        }
    }

    return result;
};

const getSfkHaftpflicht = (vertraege: Vertrag[]): Schadenfreiheitsklasse | string => {
    const haftpflichtVertraege: Vertrag[] = vertraege.filter((vertrag: Vertrag) => vertrag.typ === TypVertrag.haftpflicht);

    if (haftpflichtVertraege && haftpflichtVertraege.length > 0) {
        const haftpflichtVertrag: HaftpflichtVertrag = haftpflichtVertraege[0] as HaftpflichtVertrag;
        if (haftpflichtVertrag.schadenfreiheitsrabatt && haftpflichtVertrag.schadenfreiheitsrabatt.schadenfreiheitsklasse) {
            return haftpflichtVertrag.schadenfreiheitsrabatt.schadenfreiheitsklasse || BITTE_WAEHLEN;
        }
    }

    return BITTE_WAEHLEN;
};

const getSfkVollkasko = (vertraege: Vertrag[]): Schadenfreiheitsklasse | string => {
    const vollkaskoVertraege: Vertrag[] = vertraege.filter((vertrag: Vertrag) => vertrag.typ === TypVertrag.vollkasko);

    if (vollkaskoVertraege && vollkaskoVertraege.length > 0) {
        const vollkaskoVertrag: VollkaskoVertrag = vollkaskoVertraege[0] as VollkaskoVertrag;
        if (vollkaskoVertrag.schadenfreiheitsrabatt && vollkaskoVertrag.schadenfreiheitsrabatt.schadenfreiheitsklasse) {
            return vollkaskoVertrag.schadenfreiheitsrabatt.schadenfreiheitsklasse || BITTE_WAEHLEN;
        }
    }

    return BITTE_WAEHLEN;
};

const getSelbstbeteiligungVollkasko = (vertraege: Vertrag[]): Deckungsschluessel => {
    const vollkaskoVertraege: Vertrag[] = vertraege.filter((vertrag: Vertrag) => vertrag.typ === TypVertrag.vollkasko);

    if (vollkaskoVertraege && vollkaskoVertraege.length > 0) {
        const vollkaskoVertrag: VollkaskoVertrag = vollkaskoVertraege[0] as VollkaskoVertrag;
        if (vollkaskoVertrag.deckungsschluessel) {
            return vollkaskoVertrag.deckungsschluessel;
        }
    }

    return Deckungsschluessel.VK_OHNE_TK_OHNE;
};

const getSelbstbeteiligungTeilkasko = (vertraege: Vertrag[]): Deckungsschluessel => {
    const teilkaskoVertraege: Vertrag[] = vertraege.filter((vertrag: Vertrag) => vertrag.typ === TypVertrag.teilkasko);

    if (teilkaskoVertraege && teilkaskoVertraege.length > 0) {
        const teilkaskoVertrag: TeilkaskoVertrag = teilkaskoVertraege[0] as TeilkaskoVertrag;
        if (teilkaskoVertrag.deckungsschluessel) {
            return teilkaskoVertrag.deckungsschluessel;
        }
    }

    return Deckungsschluessel.TK_150;
};

const getWertebereichSfk = (vertraege: Vertrag[], vertragsTyp: TypVertrag): WertebereichAufzaehlung => {
    let result: WertebereichAufzaehlung = {
        werte: [],
        attribut: '',
        obligatorisch: false,
        typ: Typ.AUFZAEHLUNG,
        vorbelegung: {}
    };

    const vorhandeneVertraege: Vertrag[] = vertraege.filter((vertrag: Vertrag) => vertrag.typ === vertragsTyp);
    if (vorhandeneVertraege && vorhandeneVertraege.length > 0 && (vorhandeneVertraege[0] as HaftpflichtVertrag).schadenfreiheitsrabatt) {
        result = getWertebereichFromAngebot<WertebereichAufzaehlung>('schadenfreiheitsklasse', (vorhandeneVertraege[0] as HaftpflichtVertrag).schadenfreiheitsrabatt.wertebereiche);
    }

    return result;
};

const getWertebereichDeckungsschluessel = (vertraege: Vertrag[], vertragsTyp: TypVertrag): WertebereichAufzaehlung => {
    let result: WertebereichAufzaehlung = {
        werte: [],
        attribut: '',
        obligatorisch: false,
        typ: Typ.AUFZAEHLUNG,
        vorbelegung: {}
    };

    const vorhandeneVertraege: Vertrag[] = vertraege.filter((vertrag: Vertrag) => vertrag.typ === vertragsTyp);
    if (vorhandeneVertraege && vorhandeneVertraege.length > 0) {
        result = getWertebereichFromAngebot<WertebereichAufzaehlung>('deckungsschluessel', (vorhandeneVertraege[0] as TeilkaskoVertrag).wertebereiche);
    }

    return result;
};
