import Radio from '@eg/elements/Radio';
import React from 'react';
import { Verkaufsprozessart, Versandweg, WertebereichAngebot } from './fetch/offerengine/OfferEngineAngebotDto';

export interface WertebereichLokal {
    value: string | number | undefined;
    name: string;
}

export const BITTE_WAEHLEN = 'Bitte wählen';

export const createOptions = (array: WertebereichLokal[]): JSX.Element[] =>
    array.map((element: WertebereichLokal) => (
        <option key={getKey(element)} value={element.value}>
            {element.name}
        </option>
    ));

export const createOptionsFromWertebereichAngebot = (werte: string[], names: WertebereichLokal[] = []): JSX.Element[] => {
    const getDisplayName = (names: WertebereichLokal[], element: string): string => {
        //
        const found = names.find(x => element === x.value);
        return found ? found.name : element;
    };

    return werte.map((element: string) => (
        <option key={element} value={element}>
            {getDisplayName(names, element)}
        </option>
    ));
};

export const createRadioButtons = (array: WertebereichLokal[]): JSX.Element[] => {
    return array.map((element: WertebereichLokal) => <Radio label={element.name} value={getKey(element)} key={element.value} />);
};

export const getWertebereichElementName = (array: WertebereichLokal[], value: string | number): string => {
    if (!value) {
        return 'KEIN_WERT';
    }

    const foundElement: WertebereichLokal | undefined = array.find((element: WertebereichLokal) => element.value === value);

    if (foundElement) {
        return foundElement.name;
    }

    return value.toString() + '_FIX_ME';
};

const getKey = (wertebereichElement: WertebereichLokal) => (wertebereichElement.value === undefined ? wertebereichElement.name : wertebereichElement.value);

export const getWertebereichFromAngebot = <T extends WertebereichAngebot>(attribut: string, wertebereiche: WertebereichAngebot[]): T => {
    return wertebereiche.find(w => attribut === w.attribut) as T;
};

export const VERKAUFSPROZESSARTEN: WertebereichLokal[] = [
    { value: Verkaufsprozessart.DIREKTABSCHLUSS, name: 'Direkt abschliessen' },
    { value: Verkaufsprozessart.VOLLANGEBOT, name: 'Vollangebot' }
];

export const VERSANDWEGE: WertebereichLokal[] = [
    { value: Versandweg.POST, name: 'Post' },
    { value: Versandweg.EMAIL, name: 'E-Mail' }
];
