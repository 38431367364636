import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../app/IAppState';
import { Rollentyp, Verkaufsprozessart, Versandweg } from '../../util/fetch/offerengine/OfferEngineAngebotDto';
import { MailAdresse } from '../../util/fetch/personen/PersonDto';
import { getPersonMitRolle } from '../../util/PersonenHelper';
import { ANDERE_EMAIL, isEmail, WAEHLE_EMAIL } from '../AbschlussReducer';
import SelectRow from '@eg/elements/SelectRow';
import EmailInputRow from './EmailInputRow';
import { InputEvent, InputKeyboardEvent, SelectEvent } from '../../util/UiEventTypes';
import { Dispatch } from 'redux';
import { updateVersandmailAsync } from '../AbschlussAction';
import { Person } from '../../personen/PersonenReducer';
import { updateMailAdresseAsync } from '../../versicherungsschutz/VersicherungsschutzAction';
import { AbschlussDetailInputProps } from '../Abschluss';

interface EmailSelectionPresentationProps extends EmailSelectionOwnProps, EmailSelectionDispatchProps {}

interface EmailSelectionOwnProps {
    versandweg: Versandweg | undefined;
    versandmail: string;
    versicherungsnehmer: Person | null;
    tarifMitSafeDrivegewaehlt: boolean;
    verkaufsprozessart: Verkaufsprozessart | null;
    oberflaecheSperren: boolean;
}

interface EmailSelectionDispatchProps {
    handleOnChangeEmail: (email: string) => void;
    setNewVersandmail: (versicherungsnehmer: Person | null, email: string) => void;
}

interface EmailSelectionPresentationState {
    versandmail: string | undefined;
}

export class EmailSelectionPresentation extends React.Component<EmailSelectionPresentationProps, EmailSelectionPresentationState> {
    constructor(props: EmailSelectionPresentationProps) {
        super(props);
        this.state = {
            versandmail: props.versandmail
        };
    }

    componentDidUpdate(prevProps: Readonly<EmailSelectionPresentationProps>): void {
        if (this.props.versandmail && this.props.versandmail !== prevProps.versandmail) {
            this.setState(() => ({
                versandmail: this.props.versandmail
            }));
        }
    }

    changeVersandmail = (event: SelectEvent) => {
        const versandmail: string = event.target.value;
        this.setState(() => ({
            versandmail: versandmail
        }));

        isEmail(versandmail) ? this.props.handleOnChangeEmail(versandmail) : versandmail === ANDERE_EMAIL && this.props.handleOnChangeEmail('');
    };

    setNewVersandmail = (event: InputEvent | InputKeyboardEvent) => {
        const versandmail: string = event.currentTarget.value;
        this.setState(() => ({
            versandmail: versandmail
        }));
        this.props.setNewVersandmail(this.props.versicherungsnehmer, versandmail);
    };

    render() {
        const { versandmail } = this.state;
        const { versandweg, tarifMitSafeDrivegewaehlt, verkaufsprozessart, oberflaecheSperren } = this.props;
        const mailAdressenVN: MailAdresse[] = this.props.versicherungsnehmer ? this.props.versicherungsnehmer.mailadressen.sort(sortPrivateMailadresseZuerst) : [];

        // Die E-Mail Auswahl darf nicht angezeigt werden, wenn der Direktabschluss ausgewählt wurde und kein SafeDrive gewählt ist
        // ODER wenn Vollangebot ohne Versandart E-Mail ausgewählt wurde und ebenfalls kein SafeDrive gewählt wurde
        if ((verkaufsprozessart === Verkaufsprozessart.DIREKTABSCHLUSS || versandweg !== Versandweg.EMAIL) && !tarifMitSafeDrivegewaehlt) {
            return null;
        }

        return (
            <div>
                <p>
                    <b>{emailLabel(tarifMitSafeDrivegewaehlt, verkaufsprozessart, versandweg)}</b>
                </p>
                <SelectRow label="E-Mail-Adresse auswählen" value={versandmail} disabled={oberflaecheSperren} onChange={this.changeVersandmail}>
                    {versandmail === WAEHLE_EMAIL && <option key={WAEHLE_EMAIL} label={WAEHLE_EMAIL} value={WAEHLE_EMAIL} disabled={true} />}
                    {mailAdressenVN.map((mailadresse: MailAdresse) => (
                        <option key={mailadresse.adresse} label={mailadresse.adresse} value={mailadresse.adresse} />
                    ))}
                    <option key={ANDERE_EMAIL} label={ANDERE_EMAIL} value={ANDERE_EMAIL} />
                </SelectRow>
                {versandmail === ANDERE_EMAIL && <EmailInputRow disabled={oberflaecheSperren} label="E-Mail eingeben" onLeave={this.setNewVersandmail} value={''} />}
            </div>
        );
    }
}

const sortPrivateMailadresseZuerst = (a: MailAdresse, b: MailAdresse) => (a.typ < b.typ ? 1 : -1);

const emailLabel = (tarifMitSafeDriveGewaehlt: boolean, verkaufsprozessart: Verkaufsprozessart | null, versandweg: Versandweg | undefined): string => {
    if (tarifMitSafeDriveGewaehlt) {
        if (verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT && versandweg === Versandweg.EMAIL) {
            return 'Bitte geben Sie eine E-Mail-Adresse für den Versand und Safe Drive an';
        }
        return 'Bitte geben Sie eine E-Mail-Adresse für Safe Drive an';
    }

    return 'Bitte geben Sie eine E-Mail-Adresse für den Versand an';
};

const mapStateToProps = (state: IAppState, props: AbschlussDetailInputProps): EmailSelectionOwnProps => ({
    versandweg: state.abschluss.versandAbschluss.versandweg || undefined,
    versandmail: state.abschluss.versandmail
        ? state.abschluss.versandmail
        : getPersonMitRolle(state.personen.personen, Rollentyp.VERSICHERUNGSNEHMER)!.mailadressen.length > 0
        ? WAEHLE_EMAIL
        : ANDERE_EMAIL,
    versicherungsnehmer: getPersonMitRolle(state.personen.personen, Rollentyp.VERSICHERUNGSNEHMER),
    tarifMitSafeDrivegewaehlt: state.abschluss.tarifMitSafeDriveGewaehlt,
    verkaufsprozessart: props.verkaufsprozessart ? props.verkaufsprozessart : null,
    oberflaecheSperren: props.oberflaecheSperren
});

const mapDispatchToProps = (dispatch: Dispatch): EmailSelectionDispatchProps => ({
    handleOnChangeEmail: (email: string) => {
        //@ts-ignore
        dispatch(updateVersandmailAsync(email));
    },
    setNewVersandmail: (versicherungsnehmer: Person | null, email: string) => {
        //@ts-ignore
        dispatch(updateMailAdresseAsync(versicherungsnehmer, email));
        //@ts-ignore
        dispatch(updateVersandmailAsync(email));
    }
});

export const EmailSelection = connect(mapStateToProps, mapDispatchToProps)(EmailSelectionPresentation);
