export enum FahrzeughalterPopupInformationKeys {
    ZULASSUNGFAHRZEUGHALTER = 'ZULASSUNGFAHRZEUGHALTER',
    ERSTZULASSUNG = 'ERSTZULASSUNG'
};

type FahrzeughalterPopupInformation = {
    [key in FahrzeughalterPopupInformationKeys]: {
        heading: string;
        text: string;
    }
};

export const infoTooltipMessages: FahrzeughalterPopupInformation = {
    [FahrzeughalterPopupInformationKeys.ZULASSUNGFAHRZEUGHALTER]: {
        heading: 'Letzte Zulassung auf den Fahrzeughalter',
        text: `Bitte geben Sie an, <b>wann der Pkw auf Sie</b> bzw. den angegebenen Fahrzeughalter <b>zugelassen wurde</b>. Sie finden das Datum in der Zulassungsbescheinigung Teil I unter Punkt I bzw. im alten Fahrzeugschein unter der Halteradresse.
        <br/><b>Tipp</b>: Das Datum der Zulassung auf den Fahrzeughalter ist meist das <b>Kaufdatum</b>.`,
    },
    [FahrzeughalterPopupInformationKeys.ERSTZULASSUNG]: {
        heading: 'Erstzulassung',
        text:
            'Der Tag, an dem Ihr Pkw <b>erstmals allgemein</b> zum öffentlichen Verkehr zugelassen wurde. Siehe Zulassungsbescheinigung Teil I, Position B bzw. Fahrzeugschein, Punkt 32 (Tag der ersten Zulassung).',
    }
};