import { Dispatch } from 'redux';
import { getAngebotHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { OfferEngineAngebot, OfferEngineAngebotDto, ProductVersions, Vertriebskanal } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { loadWohnorteAsync } from '../fahrzeugnutzung/FahrzeugnutzungAction';
import { loadPersonsSync } from '../personen/PersonenAction';
import { loadVorversicherungenSync } from '../vorversicherung/VorversicherungAction';

export const LADE_ANGEBOT: string = 'LADE_ANGEBOT';
export type LADE_ANGEBOT = typeof LADE_ANGEBOT;
export const LADE_PRODUKT_VERSIONEN: string = 'LADE_PRODUKT_VERSIONEN';
export type LADE_PRODUKT_VERSIONEN = typeof LADE_PRODUKT_VERSIONEN;

export interface LoadOfferAction {
    type: LADE_ANGEBOT;
    angebot: OfferEngineAngebot;
}

export const storeOfferSync = (angebot: OfferEngineAngebot): LoadOfferAction => {
    return {
        type: LADE_ANGEBOT,
        angebot: angebot
    };
};

export const onFulfilledStoreOffer = (response: FrontendResponse<OfferEngineAngebotDto>, dispatch: Dispatch): void => {
    response.payload && response.payload.angebot && dispatch(storeOfferSync(response.payload.angebot));
};

export interface LoadProductVersionsAction {
    type: LADE_PRODUKT_VERSIONEN;
    productVersions: ProductVersions;
}

export const storeVersionsSync = (productVersions: ProductVersions): LoadProductVersionsAction => {
    return {
        type: LADE_PRODUKT_VERSIONEN,
        productVersions: productVersions
    };
};

const throwErrorIfInvalid = (payload: OfferEngineAngebotDto): void => {
    if (payload.personen === null || payload.personen.length === 0) {
        throw new Error('Das Angebot ist keiner Person zugeordnet.');
    }

    if ((payload.angebot.erstellername === null || payload.angebot.erstellername.length === 0) && Vertriebskanal.INTERNET !== payload.angebot.vertriebskanal) {
        throw new Error("Das Feld 'erstellername' im Angebot ist leer.");
    }
    // ...
};

export const ladeAngebotAsync = (businessId: string) => {
    return (dispatch: Dispatch) => {
        return getAngebotHttpRequest(businessId, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                if (!response.payload) {
                    return;
                }

                throwErrorIfInvalid(response.payload);

                dispatch(storeVersionsSync(response.payload.versions)) &&
                    dispatch(storeOfferSync(response.payload.angebot)) &&
                    dispatch(loadPersonsSync(response.payload.personen)) &&
                    dispatch(loadVorversicherungenSync(response.payload.versicherungsGesellschaften));

                if (response.payload.angebot.nutzer.postleitzahlHalter && response.payload.angebot.nutzer.postleitzahlHalter.length === 5) {
                    // @ts-ignore
                    dispatch(loadWohnorteAsync(businessId, response.payload.angebot.nutzer.postleitzahlHalter));
                }
            })
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};
