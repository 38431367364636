export interface HerstellerAuswahlDto {
    hersteller: Hersteller[];
}

export interface Hersteller {
    label: string;
    category: HerstellerCategory;
}

export enum HerstellerCategory {
    BELIEBTE_HERSTELLER = '- Beliebte Hersteller -',
    ALLE_HERSTELLER = '- Alle Hersteller -'
}

export interface ModelleAuswahlDto {
    modelle: Modell[];
}

export interface Modell {
    label: string;
    value: string;
    category: ModelleCategory;
}

export enum ModelleCategory {
    BELIEBTE_MODELLE = '- Beliebte Modelle -',
    ALLE_MODELLE = '- Alle Modelle -'
}

export interface KrafstoffAuswahlDto {
    antriebsarten: Kraftstoff[];
}

export interface Kraftstoff {
    text: string;
    value: string;
}

export interface KategorieAuswahlDto {
    kategorien: Kategorie[];
}

export interface Kategorie {
    kategorie: string;
}

export interface LeistungAuswahlDto {
    leistungsbereiche: Leistung[];
}

export interface Leistung {
    label: string;
    value: string;
}

export interface FahrzeugsucheResponseDto {
    fahrzeuge: Fahrzeug[];
}

export interface Fahrzeug {
    antriebsart: string;
    anzeigeText: string;
    istElektro: boolean;
    hersteller: string;
    herstellerCode: string;
    hsn: string;
    hsntsn: string;
    leistungKW: number;
    leistungPS: number;
    leistungText: string;
    marke: string;
    modell: string;
    prodEndJMax: number;
    prodBegJMin: number;
    tsn: string;
    typ: string;
}

export interface HoeherwertigesFahrzeugDto {
    hochwertig: boolean;
}

export interface TypklasseDto {
    typklasse: Typklasse;
}

export interface Typklasse {
    hp: number;
    tk: number;
    vk: number;
}
