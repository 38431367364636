import { Person, Personen } from '../personen/PersonenReducer';
import { Rollentyp } from './fetch/offerengine/OfferEngineAngebotDto';
import { Rolleninhaber } from './fetch/personen/ChangeRolleRequestDto';

export const getPersonMitRolle = (personen: Personen, neededRollenTyp: Rollentyp): Person | null => {
    const foundPersons: Person[] = Object.values(personen).filter((person: Person) => person.rollen && person.rollen.some((rollenTyp: Rollentyp) => rollenTyp === neededRollenTyp));

    if (foundPersons && foundPersons.length > 0) {
        return foundPersons[0];
    }

    return null;
};

export const getAllRollen = (personen: Personen): Rolleninhaber[] => {
    const result: Rolleninhaber[] = [];

    Object.values(personen).map((person: Person) => {
        person.rollen.map((rolle: Rollentyp) => {
            const rolleninhaber: Rolleninhaber = {
                pdsId: person.pdsId,
                rollentyp: rolle
            };
            return result.push(rolleninhaber);
        });
        return result;
    });

    return result;
};
