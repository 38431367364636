import { AENDERE_OUTER_RADIO_BUTTON, ChangeFahrzeugwechselRadioButtonAction } from '../angebotsgrund/AngebotsgrundAction';

import { LADE_ANGEBOT, LoadOfferAction } from './AppAction';
import { Angebotsgrund } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { ChangeAbschlussErgebnisAction } from '../abschluss/AbschlussAction';

export interface OberflacheSperrenState {
    angebotsgrundBisherigesFahrzeugBeiErgoVersichert: boolean;
}

const initialOberflacheSperrenState: OberflacheSperrenState = {
    angebotsgrundBisherigesFahrzeugBeiErgoVersichert: false
};

type OberflaecheSperrenAction = LoadOfferAction & ChangeFahrzeugwechselRadioButtonAction & ChangeAbschlussErgebnisAction;

export const oberflaecheSperrenReducer = (state: OberflacheSperrenState = initialOberflacheSperrenState, action: OberflaecheSperrenAction): OberflacheSperrenState => {
    switch (action.type) {
        case AENDERE_OUTER_RADIO_BUTTON: {
            return {
                ...state,
                angebotsgrundBisherigesFahrzeugBeiErgoVersichert: action.payload.showMeldungErgoFahrzeug
            };
        }
        case LADE_ANGEBOT: {
            return {
                ...state,
                angebotsgrundBisherigesFahrzeugBeiErgoVersichert: action.angebot.angebotsgrund === Angebotsgrund.BISHERIGES_FAHRZEUG_ERGO
            };
        }

        default:
            return state;
    }
};
