import { Dispatch } from 'redux';
import { FrontendResponse } from '../client/FrontendResponse';
import { ParisKunde, PersonensucheDto } from './PersonensucheDto';
import { sendGetRequest, sendPostRequest } from '../client/FetchBaseClient';
import { PersonDto } from '../personen/PersonDto';

export const getParisKundeHttpRequest = (businessId: string, kundennummer: string, dispatch: Dispatch): Promise<FrontendResponse<PersonensucheDto>> => {
    return sendGetRequest(`/kfz2019/personen/${businessId}/suche/pariskunden/?kundennummer=${kundennummer}`, dispatch);
};

export const selectParisKundeHttpRequest = (businessId: string, payload: ParisKunde, dispatch: Dispatch): Promise<FrontendResponse<PersonDto>> => {
    return sendPostRequest(`/kfz2019/personen/${businessId}/speichere/pariskunden/`, payload, dispatch);
};
