import { Bankverbindung } from '../offerengine/OfferEngineAngebotDto';

export interface PersonDto {
    pdsPerson: PdsPerson;
}

export interface PdsPerson {
    adresse: Adresse;
    anrede: Anrede;
    bankverbindungen: Bankverbindung[];
    businessId: string;
    geburtsdatum: Date;
    geburtsort: string;
    mailAdressen: MailAdresse[];
    mandant: Mandant;
    nachname: string;
    partnerReferenzen: PartnerReferenz[];
    personId: string;
    staatsangehoerigkeit: string;
    telefonnummer: Telefonnummer;
    titel: string;
    vorname: string;
    vsnr: number;
}

export interface Adresse {
    adressZusatz: string;
    hausnummer: string;
    hausnummerZusatz: string;
    land: string;
    ort: string;
    ortZusatz: string;
    postleitzahl: string;
    strasse: string;
}

export enum Anrede {
    Herr = 'Herr',
    Frau = 'Frau'
}

export interface MailAdresse {
    adresse: string;
    typ: Typ;
}

export enum Typ {
    privat = 'privat',
    geschaeftlich = 'geschäftlich'
}

export enum Mandant {
    ergo = 'ergo',
    ergodirekt = 'ergodirekt',
    neckermann = 'neckermann'
}

export interface PartnerReferenz {
    // Todo: Validator bauen
    // Nur Werte 1,2,3 erlaubt!
    adressnummer: number;
    kundennummer: string;
    partnerSystem: PartnerSystem;
}

export enum PartnerSystem {
    ergo = 'ergo',
    ergodirekt = 'ergodirekt'
}

export interface Telefonnummer {
    laenderkennzahl: string;
    rufnummer: string;
    vorwahl: string;
}
