import {
    Evb,
    Hinweis,
    Leistungsvereinbarung,
    Leistungsvereinbarungsart,
    OfferEngineAngebot,
    Verkaufsprozessart,
    Versandweg,
    Versicherung as VersicherungAngebot,
    Versicherung,
    Versicherungsstatus
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import {
    AENDERE_PROGRESSCIRCLE_LABEL,
    AENDERE_PROGRESSCIRCLE_VALUE,
    ANGEBOT_PRUEFEN_ABGESCHLOSSEN_LABEL,
    ANGEBOT_WIRD_GEPRUEFT_LABEL,
    BESTAETIGE_ALLE_HINWEISE,
    BESTAETIGE_HINWEIS,
    ChangeBestaetigeAlleHinweiseAction,
    ChangeHinweisAction,
    ChangeProgressCircleValueAction,
    ChangeWartenAufPruefergebnisAction,
    DIREKTABSCHLUSS_NICHT_ERFOLGREICH,
    WARTEN_AUF_PRUEFERGEBNIS
} from './DirektabschlussAction';
import {
    AENDERE_ABSCHLUSS_ERGEBNIS,
    CHANGE_VERKAUFSPROZESSART,
    CHANGE_VERSANDMAIL,
    CHANGE_VERSANDWEG,
    ChangeAbschlussErgebnisAction,
    ChangeProgressCircleLabelAction,
    ChangeVerkaufsprozessartAction,
    ChangeVersandmailAction,
    ChangeVersandwegAction,
    SET_BUTTON_LOADING,
    SetButtonLoadingAction,
    SHOW_ABSCHLUSS_DIALOG,
    ShowAbschlussErgebnisDialogAction
} from './AbschlussAction';

const GREEN_COLOR_VALUE: number = 0;
const RED_COLOR_VALUE: number = 100;

export const WAEHLE_EMAIL: string = 'Bitte E-Mail wählen';
export const ANDERE_EMAIL: string = 'Andere E-Mail angeben';

export interface AbschlussState {
    verkaufsprozessart: Verkaufsprozessart | null;
    direktAbschluss: DirektabschlussState;
    versandAbschluss: VersandabschlussState;
    evb: Evb;
    versandmail?: string | null;
    abschlussErfolgreich: boolean;
    showAbschlussErfolgreichDialog: boolean;
    abschlussErfolgreichDetails: string;
    tarifMitSafeDriveGewaehlt: boolean;
    disableAbschlussButton: boolean;
}

export interface VersandabschlussState {
    versandweg?: Versandweg | null;
    setButtonLoading: boolean;
}

export interface DirektabschlussState {
    // We need "versicherungsId" for updating "hinweise" and "hinweise" for displaying
    gewaehlteVersicherung: Versicherung | null;
    alleHinweiseBestaetigt: boolean;
    // true, during Antrag pruefen, else false
    wartenAufPruefErgebnis: boolean;
    vergangeneWartezeitInMillisekunden: number;
    progressCircleValue: number;
    progressCircleLabel: string;
    progressCircleColorValue: number;
    progressCircleClassName: string;
}

const initialAbschlussState: AbschlussState = {
    verkaufsprozessart: null,
    abschlussErfolgreich: false,
    abschlussErfolgreichDetails: '',
    showAbschlussErfolgreichDialog: false,
    evb: {
        angefordert: false,
        gueltigBis: new Date(),
        nummer: ''
    },
    versandmail: null,
    tarifMitSafeDriveGewaehlt: false,
    disableAbschlussButton: false,
    direktAbschluss: {
        gewaehlteVersicherung: null,
        alleHinweiseBestaetigt: false,
        wartenAufPruefErgebnis: false,
        vergangeneWartezeitInMillisekunden: 0,
        progressCircleValue: 0,
        progressCircleLabel: ANGEBOT_WIRD_GEPRUEFT_LABEL,
        progressCircleColorValue: GREEN_COLOR_VALUE,
        progressCircleClassName: 'progress-circle__green'
    },
    versandAbschluss: {
        versandweg: null,
        setButtonLoading: false
    }
};

export type AllAbschlussActions = LoadOfferAction &
    ChangeHinweisAction &
    ChangeBestaetigeAlleHinweiseAction &
    ChangeWartenAufPruefergebnisAction &
    ChangeAbschlussErgebnisAction &
    ChangeProgressCircleValueAction &
    ChangeProgressCircleLabelAction &
    ShowAbschlussErgebnisDialogAction &
    ChangeVerkaufsprozessartAction &
    ChangeVersandwegAction &
    ChangeVersandmailAction &
    SetButtonLoadingAction;

export const abschlussReducer = (state: AbschlussState = initialAbschlussState, action: AllAbschlussActions): AbschlussState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                verkaufsprozessart: action.angebot.verkaufsprozessart,
                abschlussErfolgreich: getAbschlussErfolgreich(action.angebot.versicherungen),
                direktAbschluss: getDirektabschluss(action.angebot, state.direktAbschluss),
                versandAbschluss: { versandweg: action.angebot.versandweg, setButtonLoading: false },
                evb: action.angebot.evb,
                versandmail: action.angebot.versandmail,
                tarifMitSafeDriveGewaehlt: tarifMitSafeDriveGewaehlt(action.angebot),
                disableAbschlussButton: disableAbschlussButton(action.angebot)
            };
        }
        case BESTAETIGE_HINWEIS: {
            const hinweise = [...state.direktAbschluss.gewaehlteVersicherung!.hinweise];
            hinweise[action.payload.index] = action.payload.hinweis;

            return {
                ...state,
                direktAbschluss: {
                    ...state.direktAbschluss,
                    gewaehlteVersicherung: {
                        ...state.direktAbschluss.gewaehlteVersicherung!,
                        hinweise
                    }
                }
            };
        }
        case BESTAETIGE_ALLE_HINWEISE: {
            return {
                ...state,
                direktAbschluss: {
                    ...state.direktAbschluss,
                    alleHinweiseBestaetigt: action.alleHinweiseBestaetigt
                }
            };
        }
        case WARTEN_AUF_PRUEFERGEBNIS: {
            if (!action.wartenAufPruefErgebnis) {
                return {
                    ...state,
                    direktAbschluss: {
                        ...state.direktAbschluss,
                        wartenAufPruefErgebnis: action.wartenAufPruefErgebnis,
                        progressCircleValue: 0
                    }
                };
            }

            return {
                ...state,
                direktAbschluss: {
                    ...state.direktAbschluss,
                    wartenAufPruefErgebnis: action.wartenAufPruefErgebnis
                }
            };
        }
        case AENDERE_ABSCHLUSS_ERGEBNIS: {
            if (action.payload.evbNummer) {
                return {
                    ...state,
                    abschlussErfolgreich: action.payload.abschlussErfolgreich,
                    abschlussErfolgreichDetails: action.payload.abschlussErfolgreichDetails,
                    direktAbschluss: {
                        ...state.direktAbschluss,
                        wartenAufPruefErgebnis: !action.payload.abschlussErfolgreich
                    },
                    evb: {
                        ...state.evb,
                        nummer: action.payload.evbNummer
                    }
                };
            }
            return {
                ...state,
                abschlussErfolgreich: action.payload.abschlussErfolgreich
            };
        }
        case AENDERE_PROGRESSCIRCLE_VALUE: {
            return {
                ...state,
                direktAbschluss: {
                    ...state.direktAbschluss,
                    vergangeneWartezeitInMillisekunden: action.payload.vergangeneWartezeit,
                    progressCircleValue: action.payload.progressCircleValue
                }
            };
        }
        case AENDERE_PROGRESSCIRCLE_LABEL: {
            return {
                ...state,
                direktAbschluss: {
                    ...state.direktAbschluss,
                    progressCircleLabel: action.label,
                    progressCircleColorValue:
                        action.label === ANGEBOT_PRUEFEN_ABGESCHLOSSEN_LABEL || action.label === DIREKTABSCHLUSS_NICHT_ERFOLGREICH ? RED_COLOR_VALUE : GREEN_COLOR_VALUE,
                    progressCircleClassName:
                        action.label === ANGEBOT_PRUEFEN_ABGESCHLOSSEN_LABEL || action.label === DIREKTABSCHLUSS_NICHT_ERFOLGREICH
                            ? 'progress-circle__red'
                            : 'progress-circle__green'
                }
            };
        }
        case SHOW_ABSCHLUSS_DIALOG: {
            return {
                ...state,
                showAbschlussErfolgreichDialog: action.showDialog
            };
        }
        case CHANGE_VERKAUFSPROZESSART: {
            return {
                ...state,
                verkaufsprozessart: action.verkaufsprozessart
            };
        }
        case CHANGE_VERSANDWEG: {
            return {
                ...state,
                versandAbschluss: {
                    ...state.versandAbschluss,
                    versandweg: action.versandweg
                }
            };
        }
        case CHANGE_VERSANDMAIL: {
            return {
                ...state,
                disableAbschlussButton: !isEmail(action.versandmail),
                versandmail: action.versandmail
            };
        }
        case SET_BUTTON_LOADING: {
            return {
                ...state,
                versandAbschluss: {
                    ...state.versandAbschluss,
                    setButtonLoading: action.setButtonLoading
                }
            };
        }
        default:
            return state;
    }
};

const getDirektabschluss = (angebot: OfferEngineAngebot, direktabschlussState: DirektabschlussState): DirektabschlussState => {
    return {
        ...direktabschlussState,
        gewaehlteVersicherung: getGewaehlteVersicherung(angebot.versicherungen),
        alleHinweiseBestaetigt: alleHinweiseBestaetigt(angebot.versicherungen)
    };
};

export const getAbschlussErfolgreich = (versicherungen: Versicherung[]): boolean => {
    const gewaehlteVersicherung: Versicherung | null = getGewaehlteVersicherung(versicherungen);
    if (gewaehlteVersicherung === null) {
        return false;
    }
    return gewaehlteVersicherung.versicherungsstatus !== Versicherungsstatus.ANGELEGT;
};

export const getGewaehlteVersicherung = (versicherungen: Versicherung[]): Versicherung | null => {
    if (versicherungen === null) {
        return null;
    }

    const [first = null] = versicherungen.filter(v => v.ausgewaehlt);
    return first;
};

const tarifMitSafeDriveGewaehlt = (angebot: OfferEngineAngebot): boolean => {
    const ausgewaehlteVersicherung: VersicherungAngebot | undefined = angebot.versicherungen.find((versicherung: VersicherungAngebot) => versicherung.ausgewaehlt);

    if (!ausgewaehlteVersicherung) {
        return false;
    }

    return angebot.leistungsvereinbarungen.some(
        (leistungsvereinbarung: Leistungsvereinbarung) =>
            leistungsvereinbarung.struktur.versicherungen === ausgewaehlteVersicherung.versicherungsId &&
            leistungsvereinbarung.leistungsvereinbarungsart === Leistungsvereinbarungsart.SAFE_DRIVE &&
            leistungsvereinbarung.vereinbart
    );
};

const disableAbschlussButton = (angebot: OfferEngineAngebot): boolean =>
    (tarifMitSafeDriveGewaehlt(angebot) || (angebot.verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT && angebot.versandweg === Versandweg.EMAIL)) &&
    !isEmail(angebot.versandmail);

export const isEmail = (versandmail: string): boolean => !!versandmail && ![ANDERE_EMAIL, WAEHLE_EMAIL].includes(versandmail);

const alleHinweiseBestaetigt = (versicherungen: Versicherung[]): boolean => {
    const versicherung = getGewaehlteVersicherung(versicherungen);

    if (versicherung === null) {
        return false;
    }

    const hinweise = versicherung.hinweise;

    if (hinweise === null || hinweise.length === 0) {
        return false;
    }

    return hinweise.every((h: Hinweis) => h.bestaetigt);
};
