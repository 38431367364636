import { WertebereichLokal } from '../util/WertebereicheHelper';

export enum OuterRadioButton {
    ERSTVERTRAG = 'ERSTVERTRAG',
    VERSICHERER_WECHSEL = 'VERSICHERER_WECHSEL',
    FAHRZEUG_WECHSELN = 'FAHRZEUG_WECHSELN'
}

export enum ErstfahrzeugInnerRadioButton {
    SFK_MIT_EES = 'SFK_MIT_EES',
    SFK_UEBERNAHME_PERSON = 'SFK_UEBERNAHME_PERSON'
}

export enum FahrzeugwechselInnerRadioButton {
    BISHERIGES_FAHRZEUG_ERGO = 'BISHERIGES_FAHRZEUG_ERGO',
    BISHERIGES_FAHRZEUG_ANDERER_VERSICHERER = 'BISHERIGES_FAHRZEUG_ANDERER_VERSICHERER'
}

export const outerRadioButtons: WertebereichLokal[] = [
    { value: OuterRadioButton.ERSTVERTRAG, name: 'Erstvertrag - erstmals ein Fahrzeug versichern' },
    {
        value: OuterRadioButton.VERSICHERER_WECHSEL,
        name: 'Versichererwechsel - mit dem aktuellen Auto zu ERGO wechseln'
    }
];

export const erstfahrzeugInnerRadioButtons: WertebereichLokal[] = [
    {
        value: ErstfahrzeugInnerRadioButton.SFK_MIT_EES,
        name: 'Schadensfreiheitsklasse mit dem Ersteinstufungsservice ermitteln'
    },
    {
        value: ErstfahrzeugInnerRadioButton.SFK_UEBERNAHME_PERSON,
        name: 'Schadensfreiheitsklasse von einer anderen Person übernehmen'
    }
];

export const fahrzeugwechselInnerRadioButtons: WertebereichLokal[] = [
    {
        value: FahrzeugwechselInnerRadioButton.BISHERIGES_FAHRZEUG_ERGO,
        name: 'Bisheriges Fahrzeug ist bei ERGO versichert'
    },
    {
        value: FahrzeugwechselInnerRadioButton.BISHERIGES_FAHRZEUG_ANDERER_VERSICHERER,
        name: 'Bisheriges Fahrzeug ist bei einem anderen Versicherer versichert'
    }
];
