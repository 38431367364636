import { ErrorObject } from '../util/fetch/client/ErrorObject';
import { SPEICHERE_TECHNISCHE_FEHLER, StoreTechnischeFehlerAction } from './TechnischeFehlerAction';

export interface TechnischeFehlerState {
    hasError: boolean;
    errorObject: ErrorObject | undefined;
}

export const initialTechnischeFehlerState = {
    hasError: false,
    errorObject: undefined
};

type TechnischeFehlerAction = StoreTechnischeFehlerAction;

export const technischeFehlerReducer = (state: TechnischeFehlerState = initialTechnischeFehlerState, action: TechnischeFehlerAction): TechnischeFehlerState => {
    switch (action.type) {
        case SPEICHERE_TECHNISCHE_FEHLER: {
            return {
                ...state,
                hasError: action.errorObject !== null && action.errorObject !== undefined,
                errorObject: action.errorObject
            };
        }
        default:
            return state;
    }
};
