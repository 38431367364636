import { SET_EDCOM_RESPONSE, CHANGE_EVB_FILE_DOWNLOAD_INPROGRESS, ChangeIsEvbFileDownloadInProgressAction } from './EvbAction';


const initialEvbState = {
    isEvbFileDownloadInProgress: false,// flag to show that an edcom request is being executed.
    edcomResponse: undefined,// actual edcom response that includes a string-formatted evb pdf file. 
};

export interface EvbState {
    isEvbFileDownloadInProgress: boolean,
    edcomResponse: EdcomResponse | undefined
}

export interface EdcomResponse {
    druckauftragId: string,
    auftraege: EdcomAuftraege[],
    code: number;
    msg: string;
}

export interface EdcomAuftraege {
    auftragId: string,
    dokument: {
        name: string,
        pdf: string
    }
}

type EvbAction = ChangeIsEvbFileDownloadInProgressAction;

export const evbReducer = (state: EvbState = initialEvbState, action: EvbAction): EvbState => {
    switch (action.type) {
        case CHANGE_EVB_FILE_DOWNLOAD_INPROGRESS: {
            return { ...state, [action.payload.fieldName]: action.payload.fieldValue }
        }
        case SET_EDCOM_RESPONSE: {
            return { ...state, [action.payload.fieldName]: action.payload.fieldValue }
        }
        default:
            return state;
    }
}