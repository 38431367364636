// This file serves for rendering the UI for the evb radio field.
import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import YesNoInput from '@eg/elements/YesNoInput';
import { updateEvbFieldAsync } from './EvbAction';

interface EvbPresentationProps {
    evb: boolean;
    businessId: string;
    handleChangeEvbField: (businessId: string, fieldValue: boolean) => void;
}

export class EvbPresentation extends React.Component<EvbPresentationProps> {
    render() {
        return (
            <>
                <h2 className="margin-0">Elektronische Versicherungsbestätigung (eVB)</h2>
                <YesNoInput
                    onChange={(value: boolean) =>
                        this.props.handleChangeEvbField(this.props.businessId, value)
                    }
                    value={this.props.evb}
                    name="eVB"
                />
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    evb: state.abschluss.evb.angefordert,
    businessId: state.basisdaten.businessId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleChangeEvbField: (businessId: string, fieldValue: boolean) => {
        // @ts-ignore
        dispatch(updateEvbFieldAsync(businessId, fieldValue));// to update the value of the evb radio by sending an OE request.
    }
});

export const EVB = connect(mapStateToProps, mapDispatchToProps)(EvbPresentation);