import { Dispatch } from 'redux';
import { getBankdatenHttpRequest } from '../util/fetch/bankdaten/BankdatenController';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { BankdatenDto } from '../util/fetch/bankdaten/BankdatenDto';
import { OfferEngineBankverbindungRequestDto } from '../util/fetch/offerengine/OfferEngineBankverbindungRequestDto';
import { updateBankverbindungHttpRequest, updateVersicherungenHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { OfferEngineVersicherungenRequestDto, VersicherungOptionalRequestDto } from '../util/fetch/offerengine/OfferEngineVersicherungenRequestDto';
import { createVersicherungenRequest } from '../util/OfferEngineRequestHelper';
import { Person } from '../personen/PersonenReducer';
import { Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export const AKTUALISIERE_VERSICHERUNGSNEHMER: string = 'AKTUALISIERE_VERSICHERUNGSNEHMER';
export type AKTUALISIERE_VERSICHERUNGSNEHMER = typeof AKTUALISIERE_VERSICHERUNGSNEHMER;
export const AENDERE_GEWAEHLTEN_ROW_ENTRY: string = 'AKTUALISIERE_IBAN';
export type AENDERE_GEWAEHLTEN_ROW_ENTRY = typeof AENDERE_GEWAEHLTEN_ROW_ENTRY;
export const AENDERE_IBAN_ENTRY: string = 'AENDERE_IBAN_ENTRY';
export type AENDERE_IBAN_ENTRY = typeof AENDERE_IBAN_ENTRY;
export const AENDERE_AKTIVIERUNG_IBAN_FELD: string = 'AENDERE_AKTIVIERUNG_IBAN_FELD';
export type AENDERE_AKTIVIERUNG_IBAN_FELD = typeof AENDERE_AKTIVIERUNG_IBAN_FELD;
export const ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER: string = 'ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER';
export type ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER = typeof ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER;
export const SETZE_IBAN_NICHT_VALIDE: string = 'SETZE_IBAN_NICHT_VALIDE';
export type SETZE_IBAN_NICHT_VALIDE = typeof SETZE_IBAN_NICHT_VALIDE;
export const AENDERE_ABBUCHUNGSTAG: string = 'AENDERE_ABBUCHUNGSTAG';
export type AENDERE_ABBUCHUNGSTAG = typeof AENDERE_ABBUCHUNGSTAG;

export enum IbanVerificationStatus {
    NOT_VERIFIED = 'NOT_VERIFIED',
    SUCCESSFULLY_VERIFIED = 'SUCCESSFULLY_VERIFIED',
    NOT_SUCCESSFULLY_VERIFIED = 'NOT_SUCCESSFULLY_VERIFIED'
}

export interface SaveNewVersicherungsnehmerAction {
    type: AKTUALISIERE_VERSICHERUNGSNEHMER;
    versicherungsnehmer: Person | null;
}
export const saveNewVersicherungsnehmerSync = (versicherungsnehmer: Person | null): SaveNewVersicherungsnehmerAction => {
    return {
        type: AKTUALISIERE_VERSICHERUNGSNEHMER,
        versicherungsnehmer: versicherungsnehmer
    };
};

export interface ChangeSelectedRowEntryAction {
    type: AENDERE_GEWAEHLTEN_ROW_ENTRY;
    iban: string;
    verkaufsprozessart: Verkaufsprozessart | null;
}

export const changeSelectedRowEntrySync = (iban: string, verkaufsprozessart: Verkaufsprozessart | null): ChangeSelectedRowEntryAction => {
    return {
        type: AENDERE_GEWAEHLTEN_ROW_ENTRY,
        iban: iban,
        verkaufsprozessart: verkaufsprozessart
    };
};

export interface UpdateIbanEntryAction {
    type: AENDERE_IBAN_ENTRY;
    iban: string;
    ibanValidationStatus: string;
}
export const updateIbanEntrySync = (data: { iban: string, error: string }): UpdateIbanEntryAction => {
    return {
        type: AENDERE_IBAN_ENTRY,
        iban: data.iban,
        ibanValidationStatus: data.error
    };
};

export interface ChangeActivationIbanFieldAction {
    type: AENDERE_AKTIVIERUNG_IBAN_FELD;
    isIbanUnderVerification: boolean;
}
export const changeActivationIbanFieldSync = (isIbanUnderVerification: boolean): ChangeActivationIbanFieldAction => {
    return {
        type: AENDERE_AKTIVIERUNG_IBAN_FELD,
        isIbanUnderVerification: isIbanUnderVerification
    };
};

export interface ShowBankverbindungIsLoadingSpinnerAction {
    type: ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER;
    showBankverbindungIsLoadingSpinner: boolean;
}
export const showBankverbindungIsLoadingSpinnerSync = (showBankverbindungIsLoadingSpinner: boolean): ShowBankverbindungIsLoadingSpinnerAction => {
    return {
        type: ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER,
        showBankverbindungIsLoadingSpinner: showBankverbindungIsLoadingSpinner
    };
};

export interface IbanNotValidAction {
    type: SETZE_IBAN_NICHT_VALIDE;
}
export const updateIbanNotValidSync = (): IbanNotValidAction => {
    return {
        type: SETZE_IBAN_NICHT_VALIDE
    };
};

export const verifyAndUpdateBankverbindungAsync = (businessId: string, kontoinhaber: string, iban: string) => {
    return (dispatch: Dispatch) => {
        dispatch(changeActivationIbanFieldSync(true));
        let waitForBankdatenValidation = setTimeout(() => {
            dispatch(showBankverbindungIsLoadingSpinnerSync(true));
        }, 300);

        return getBankdatenHttpRequest(businessId, iban, dispatch)
            .then((response: FrontendResponse<BankdatenDto>) => {
                clearTimeout(waitForBankdatenValidation);
                if (response.payload && response.payload.bankdaten) {
                    const request: OfferEngineBankverbindungRequestDto = {
                        bankverbindung: {
                            kontoinhaber: kontoinhaber,
                            iban: response.payload.bankdaten.iban,
                            bic: response.payload.bankdaten.bic,
                            bankname: response.payload.bankdaten.bank.participantName
                        }
                    };
                    updateBankverbindungHttpRequest(businessId, request, dispatch)
                        .then(response => onFulfilledStoreOffer(response, dispatch))
                        .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
                } else {
                    dispatch(updateIbanNotValidSync());
                }
            })
            .catch((e: Error) => {
                clearTimeout(waitForBankdatenValidation);
                onRejectedStoreTechnischerFehler(e, dispatch);
            })
            .finally(() => {
                dispatch(showBankverbindungIsLoadingSpinnerSync(false));
                dispatch(changeActivationIbanFieldSync(false));
            });
    };
};

export interface ChangeAbbuchungstagAction {
    type: AENDERE_ABBUCHUNGSTAG;
    abbuchungstag: number;
}

const changeAbbuchungstagSync = (abbuchungstag: number): ChangeAbbuchungstagAction => {
    return {
        type: AENDERE_ABBUCHUNGSTAG,
        abbuchungstag: abbuchungstag
    };
};

export const updateAbbuchungstagAsync = (businessId: string, abbuchungstag: number) => {
    return (dispatch: Dispatch) => {
        dispatch(changeAbbuchungstagSync(abbuchungstag));
        const payload: VersicherungOptionalRequestDto = {
            abbuchungstag: abbuchungstag
        };

        const request: OfferEngineVersicherungenRequestDto = createVersicherungenRequest(payload);
        return updateVersicherungenHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
};
