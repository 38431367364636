import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import { Person } from './PersonenReducer';
import { geburtsdatumToString } from '../util/DateFormattingHelper';
import { MailAdresse } from '../util/fetch/personen/PersonDto';
import Chip from '@eg/elements/Chip';
import Button from '@eg/elements/Button';
import { Bonitaet, Rollentyp } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { getWertebereichElementName, WertebereichLokal } from '../util/WertebereicheHelper';
import { wertebereichMailTyp, wertebereichRollen } from './PersonenWertebereiche';
import CloseIcon from '@eg/elements/components/Icons/CloseIcon';
import { addRolleAsync, deletePersonAsync, deleteRolleAsync } from './PersonenAction';
import LoadingSpinner from '@eg/elements/LoadingSpinner';

const bonitaetMap: Map<Bonitaet, { color: string; label: string }> = new Map<Bonitaet, { color: string; label: string }>();
bonitaetMap.set(Bonitaet.POSITIV, { color: '#5de38e', label: 'Bonität positiv' });
bonitaetMap.set(Bonitaet.NEGATIV, { color: '#bf1528', label: 'Bonität negativ' });
bonitaetMap.set(Bonitaet.NICHT_GEPRUEFT, { color: '#e87a16', label: 'Bonität nicht geprüft' });

interface PersonenPresentationProps {
    businessId: string;
    aktionsnummer: string;
    personen: Person[];
    bonitaetspruefungVN: Bonitaet;
    showPersonLoadingSpinner: boolean;
    oberflaecheSperren: boolean;
    handleDeletePerson: (businessId: string, personToDelete: Person) => void;
    handleAddRolle: (businessId: string, aktionsnummer: string, personToAddRolle: Person, rolle: Rollentyp) => void;
    handleRemoveRolle: (businessId: string, personPdsIdToRemoveRolle: string, rolle: Rollentyp) => void;
}

export class PersonenPresentation extends React.Component<PersonenPresentationProps, {}> {
    showRolle = (rolle: Rollentyp, personRollen: Rollentyp[], person: Person) =>
        personRollen && personRollen.includes(rolle) ? (
            <div key={rolle} className="rolle">
                <Button
                    variant="primary"
                    disabled={this.props.oberflaecheSperren || rolle === Rollentyp.VERSICHERUNGSNEHMER || rolle === Rollentyp.HALTER}
                    onClick={() => this.props.handleRemoveRolle(this.props.businessId, person.pdsId, rolle)}
                >
                    {getWertebereichElementName(wertebereichRollen, rolle)}
                </Button>
            </div>
        ) : (
            <div key={rolle} className="rolle">
                <Button
                    disabled={this.props.oberflaecheSperren}
                    variant="tertiary"
                    onClick={() => this.props.handleAddRolle(this.props.businessId, this.props.aktionsnummer, person, rolle)}
                >
                    {getWertebereichElementName(wertebereichRollen, rolle)}
                </Button>
            </div>
        );

    render() {
        const { businessId, personen, bonitaetspruefungVN, showPersonLoadingSpinner, oberflaecheSperren, handleDeletePerson } = this.props;

        return (
            <div className="personen-block">
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Personen</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12" style={{ position: 'relative' }}>
                            <div className="esc_simple-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th />
                                            <th>Name, Kdnr.</th>
                                            <th>Geburtsdatum</th>
                                            <th>Adresse</th>
                                            <th>E-Mail</th>
                                            <th>Rollen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {personen.map((person: Person) => (
                                            <tr key={person.pdsId}>
                                                <td className="personen-block-delete">
                                                    {person.rollen && !person.rollen.includes(Rollentyp.VERSICHERUNGSNEHMER) && (
                                                        <Button disabled={oberflaecheSperren} variant="primary" width="auto" onClick={() => handleDeletePerson(businessId, person)}>
                                                            <CloseIcon height={30} width={30} />
                                                        </Button>
                                                    )}
                                                </td>
                                                <td>
                                                    {person.nachname}, {person.vorname} {person.titel ? `, ${person.titel}` : ''}
                                                    <br />
                                                    {person.kundennummerParis}
                                                    <br />
                                                    {person.rollen && person.rollen.includes(Rollentyp.VERSICHERUNGSNEHMER) && (
                                                        <Chip backgroundColor={bonitaetMap.get(bonitaetspruefungVN)!.color}>{bonitaetMap.get(bonitaetspruefungVN)!.label}</Chip>
                                                    )}
                                                </td>
                                                <td>{geburtsdatumToString(person.geburtsdatum)}</td>
                                                <td>
                                                    {person.adresse.strasse} {person.adresse.hausnummer}
                                                    {person.adresse.hausnummerZusatz}
                                                    <br />
                                                    {person.adresse.postleitzahl} {person.adresse.ort}
                                                </td>
                                                <td>
                                                    {person.mailadressen.map((mailadresse: MailAdresse) => (
                                                        <p key={mailadresse.adresse}>
                                                            {getWertebereichElementName(wertebereichMailTyp, mailadresse.typ)}: {mailadresse.adresse}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td>
                                                    {wertebereichRollen.map((entry: WertebereichLokal) => {
                                                        return this.showRolle(entry.value as Rollentyp, person.rollen, person);
                                                    })}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <LoadingSpinner viewport="relative" show={showPersonLoadingSpinner} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    businessId: state.basisdaten.businessId,
    aktionsnummer: state.basisdaten.aktionsnummer,
    personen: Object.values(state.personen.personen),
    bonitaetspruefungVN: state.personen.bonitaetspruefungVN,
    showPersonLoadingSpinner: state.personen.showPersonLoadingSpinner,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleDeletePerson: (businessId: string, personToDelete: Person) => {
        //@ts-ignore
        dispatch(deletePersonAsync(businessId, personToDelete));
    },
    handleAddRolle: (businessId: string, aktionsnummer: string, personToAddRolle: Person, rolle: Rollentyp) => {
        //@ts-ignore
        dispatch(addRolleAsync(businessId, aktionsnummer, personToAddRolle, rolle));
    },
    handleRemoveRolle: (businessId: string, personPdsIdToRemoveRolle: string, rolle: Rollentyp) => {
        //@ts-ignore
        dispatch(deleteRolleAsync(businessId, personPdsIdToRemoveRolle, rolle));
    }
});

export const Personen = connect(mapStateToProps, mapDispatchToProps)(PersonenPresentation);
