import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import SelectRow from '@eg/elements/SelectRow';
import InputRow from '@eg/elements/InputRow';
import { Person } from '../personen/PersonenReducer';
import { ANDERE_IBAN, BankverbindungState } from './BankverbindungReducer';
import { normalizeIban } from '@eg/elements/utils/validation/iban';
import {
    changeSelectedRowEntrySync,
    IbanVerificationStatus,
    saveNewVersicherungsnehmerSync,
    updateAbbuchungstagAsync,
    updateIbanEntrySync,
    verifyAndUpdateBankverbindungAsync
} from './BankverbindungAction';
import CheckIcon from '@eg/elements/components/Icons/CheckIcon';
import DamageIcon from '@eg/elements/components/Icons/DamageIcon';
import LoaderIcon from '@eg/elements/components/Icons/LoaderIcon';
import FormRow from '@eg/elements/FormRow';
import { createOptions, createOptionsFromWertebereichAngebot } from '../util/WertebereicheHelper';
import { Rollentyp, Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { getPersonMitRolle } from '../util/PersonenHelper';
import { InputEvent, SelectEvent } from '../util/UiEventTypes';
import { erlaubteAbbuchungstage } from './BankverbindungWertebereich';
import { checkIbanError } from './BankVerbindung.utils';

interface BankverbindungPresentationProps {
    businessId: string;
    verkaufsprozessart: Verkaufsprozessart | null;
    versicherungsnehmer: Person | null;
    bankverbindung: BankverbindungState;
    oberflaecheSperren: boolean;
    handleSaveNewVersicherungsnehmer: (versicherungsnehmer: Person | null) => void;
    handleChangeSelectedRowEntry: (selectedEntry: string, verkaufsprozessart: Verkaufsprozessart | null) => void;
    handleUpdateIban: (data: { iban: string, error: string }) => void;
    handleVerifyAndUpdateBankverbindung: (businessId: string, kontoinhaber: string, iban: string) => void;
    handleChangeAbbuchungstag: (businessId: string, abbuchungstag: number) => void;
}

interface BankverbindungPresentationState { }

export class BankverbindungPresentation extends React.Component<BankverbindungPresentationProps, BankverbindungPresentationState> {
    constructor(props: BankverbindungPresentationProps) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
    }

    componentDidMount(): void {
        this.changeHandler(this.props.bankverbindung.iban);
    }

    componentDidUpdate(prevProps: Readonly<BankverbindungPresentationProps>, prevState: Readonly<BankverbindungPresentationState>): void {
        if (!prevProps.versicherungsnehmer || !this.props.versicherungsnehmer) {
            return;
        }

        if (prevProps.versicherungsnehmer.kundennummerParis !== this.props.versicherungsnehmer.kundennummerParis) {
            this.props.handleSaveNewVersicherungsnehmer(this.props.versicherungsnehmer);
        }
    }

    async changeHandler(value: string) {
        const iban: string = normalizeIban(value);
        const ibanErrorMessage = checkIbanError(iban);
        this.props.handleUpdateIban({ iban, error: ibanErrorMessage });
        if (!ibanErrorMessage && iban) {
            this.props.handleVerifyAndUpdateBankverbindung(this.props.businessId, this.props.bankverbindung.kontoinhaber, iban);
        }
    }

    render() {
        const { businessId, bankverbindung, oberflaecheSperren, handleChangeAbbuchungstag, verkaufsprozessart } = this.props;
        const verkaufsprozessartSuffix: string = verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT ? '  (optional)' : '';
        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>{<h2 className="esc_h4">Bankverbindung{verkaufsprozessartSuffix}</h2>}</Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <div>
                                {this.props.versicherungsnehmer && (
                                    <InputRow label="Kontoinhaber" disabled={oberflaecheSperren} value={bankverbindung.kontoinhaber} placeholder="Kontoinhaber" readOnly />
                                )}
                                {this.props.bankverbindung.selectRowEntries && this.props.bankverbindung.selectRowEntries.length > 1 && (
                                    <SelectRow
                                        label="Bekannte Bankverbindungen"
                                        value={this.props.bankverbindung.selectedRowEntry}
                                        error={''}
                                        onChange={(e: SelectEvent) => this.props.handleChangeSelectedRowEntry(e.target.value, verkaufsprozessart)}
                                        disabled={oberflaecheSperren || bankverbindung.showBankverbindungIsLoadingSpinner}
                                        className="bankverbindung"
                                    >
                                        {this.props.bankverbindung.selectRowEntries.map((entry: string) => (
                                            <option key={entry} label={entry} value={entry} />
                                        ))}
                                    </SelectRow>
                                )}
                                {this.props.bankverbindung.selectedRowEntry === ANDERE_IBAN && (
                                    <InputRow
                                        label="IBAN"
                                        placeholder="Bitte IBAN eingeben"
                                        value={bankverbindung.iban}
                                        onChange={(e: InputEvent) => this.changeHandler(e.currentTarget.value)}
                                        disabled={oberflaecheSperren || bankverbindung.isIbanUnderVerification}
                                        adornmentRight={renderIcon(bankverbindung.ibanVerificationStatus)}
                                        error={bankverbindung.ibanValidationStatus}
                                    />
                                )}
                                {bankverbindung.showBankverbindungIsLoadingSpinner ? (
                                    <FormRow label="BIC wird ermittelt...">
                                        <LoaderIcon spinning width={30} height={30} style={{ marginTop: 10 }} />
                                    </FormRow>
                                ) : (
                                    <InputRow
                                        label="BIC"
                                        value={bankverbindung.bic}
                                        disabled={oberflaecheSperren}
                                        error={bankverbindung.ibanVerificationStatus === IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED}
                                        placeholder="Wird automatisch ermittelt, wenn IBAN vorhanden"
                                        readOnly
                                    />
                                )}
                                {bankverbindung.showBankverbindungIsLoadingSpinner ? (
                                    <FormRow label="Bank wird ermittelt...">
                                        <LoaderIcon spinning width={30} height={30} style={{ marginTop: 10 }} />
                                    </FormRow>
                                ) : (
                                    <InputRow
                                        label="Bank"
                                        disabled={oberflaecheSperren}
                                        value={bankverbindung.bankname}
                                        error={bankverbindung.ibanVerificationStatus === IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED}
                                        placeholder="Wird automatisch ermittelt, wenn IBAN vorhanden"
                                        readOnly
                                    />
                                )}
                                <SelectRow
                                    label="Abbuchung am"
                                    disabled={oberflaecheSperren}
                                    value={bankverbindung.abbuchungstag}
                                    onChange={(e: SelectEvent) => handleChangeAbbuchungstag(businessId, parseInt(e.target.value))}
                                >
                                    {bankverbindung.wertebereichAbbuchungstag.werte.length > 0
                                        ? createOptionsFromWertebereichAngebot(bankverbindung.wertebereichAbbuchungstag.werte as string[], erlaubteAbbuchungstage)
                                        : createOptions(erlaubteAbbuchungstage)}
                                </SelectRow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const renderIcon = (ibanVerificationStatus: IbanVerificationStatus): JSX.Element | null => {
    switch (ibanVerificationStatus) {
        case IbanVerificationStatus.SUCCESSFULLY_VERIFIED:
            return <CheckIcon style={{ fill: 'green' }} />;

        /* Question: When will this ever be possibly set?
         *
         * Answer:
         * die ergo-elements prüfen die IBAN nur syntaktisch (blz, kntr,
         * inklusive prüfziffer), aber nicht semantisch (gibt es das Konto
         * wirklich?). Es gibt valide IBANs, für die es kein Bank-Konto gibt
         * z.B. DE36 0000 0000 0000 0000 00
         * dort schmeißt, das BFF dann einen Fehler und wir können anhand des
         * Icons unterscheiden, welcher der zwei Fälle eingetreten ist.
         */
        case IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED:
            return <DamageIcon style={{ fill: 'red' }} />;

        default:
            return null;
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        businessId: state.basisdaten.businessId,
        verkaufsprozessart: state.abschluss.verkaufsprozessart || null,
        versicherungsnehmer: getPersonMitRolle(state.personen.personen, Rollentyp.VERSICHERUNGSNEHMER),
        bankverbindung: state.bankverbindung,
        oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleSaveNewVersicherungsnehmer(versicherungsnehmer: Person | null): void {
        dispatch(saveNewVersicherungsnehmerSync(versicherungsnehmer));
    },
    handleChangeSelectedRowEntry(selectedEntry: string, verkaufsprozessart: Verkaufsprozessart | null): void {
        dispatch(changeSelectedRowEntrySync(selectedEntry, verkaufsprozessart));
    },
    handleUpdateIban: (data: { iban: string, error: string }): void => {
        dispatch(updateIbanEntrySync(data));
    },
    handleVerifyAndUpdateBankverbindung: (businessId: string, kontoinhaber: string, iban: string): void => {
        // @ts-ignore
        dispatch(verifyAndUpdateBankverbindungAsync(businessId, kontoinhaber, iban));
    },
    handleChangeAbbuchungstag: (businessId: string, abbuchungstag: number): void => {
        //@ts-ignore
        dispatch(updateAbbuchungstagAsync(businessId, abbuchungstag));
    }
});

export const Bankverbindung = connect(mapStateToProps, mapDispatchToProps)(BankverbindungPresentation);
