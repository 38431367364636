import { LADE_ANGEBOT, LADE_PRODUKT_VERSIONEN, LoadOfferAction, LoadProductVersionsAction } from '../app/AppAction';
import { Kanalrichtung, OfferEngineAngebot, Vertriebskanal } from '../util/fetch/offerengine/OfferEngineAngebotDto';

const ERSTELLER_NAME_INTERNET = 'Internet';

export interface BasisdatenState {
    offerEngineBaseVersion: string;
    kfz2019V2Version: string;
    angebotsnummer: string;
    businessId: string;
    aktionsnummer: string;
    verkaufsproduktart: string;
    beratungsprotokollId: string;
    erstellername: string; //Note bn 20190907: null will be rejected in ladeAngebotAsync and reduceErstellername
    kanalrichtung: Kanalrichtung | null;
}

const initialBasisdatenState: BasisdatenState = {
    offerEngineBaseVersion: '',
    kfz2019V2Version: '',
    angebotsnummer: '',
    businessId: '',
    aktionsnummer: '',
    verkaufsproduktart: '',
    beratungsprotokollId: '',
    erstellername: '',
    kanalrichtung: null
};

type BasisdatenAction = LoadOfferAction & LoadProductVersionsAction;

export const basisdatenReducer = (state: BasisdatenState = initialBasisdatenState, action: BasisdatenAction): BasisdatenState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                businessId: action.angebot.businessId,
                angebotsnummer: action.angebot.angebotsnummer,
                aktionsnummer: action.angebot.aktionsnummer ? action.angebot.aktionsnummer : '',
                verkaufsproduktart: action.angebot.verkaufsproduktart,
                beratungsprotokollId: action.angebot.beratungsprotokollId,
                erstellername: reduceErstellername(action.angebot),
                kanalrichtung: action.angebot.kanalrichtung
            };
        }
        case LADE_PRODUKT_VERSIONEN: {
            return {
                ...state,
                offerEngineBaseVersion: action.productVersions.offerEngineBaseVersion,
                kfz2019V2Version: action.productVersions.kfz2019V2Version
            };
        }
        default:
            return state;
    }
};

function reduceErstellername(angebot: OfferEngineAngebot): string {
    return (angebot.erstellername === null || angebot.erstellername.length === 0) && Vertriebskanal.INTERNET === angebot.vertriebskanal
        ? ERSTELLER_NAME_INTERNET
        : angebot.erstellername!;
}
