import InputRow from '@eg/elements/InputRow';
import Modal from '@eg/elements/Modal';
import Panel from '@eg/elements/Panel';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState } from '../app/IAppState';
import { EdcomResponse, EvbState } from "../evb/EvbReducer";
import { Meldung, Schweregrad } from '../util/fetch/client/MeldungenDto';
import { Verkaufsprozessart, Versicherung } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { showAbschlussErgebnisDialogSync } from './AbschlussAction';
import { AbschlussState } from './AbschlussReducer';
import { Direktabschluss } from './direktabschluss/Direktabschluss';
import { Versand } from './versand/Versand';

interface AbschlussPresentationProps extends AbschlussDispatchProps, AbschlussLinkState {}

export class AbschlussPresentation extends React.Component<AbschlussPresentationProps, {}> {
    isEvbPdfVisible: Boolean = false;
    cancelDialogHandler = () => {
        this.props.cancelAbschlussErfolgreichDialogHandler(false);
    };
    renderPdfLink = (response: EvbState): JSX.Element => {// render the download link for the evb file
        
        if ((this.props.evb.angefordert) && (this.props.evbEdcom.edcomResponse && this.props.evbEdcom.edcomResponse.code === 200)) {
            this.isEvbPdfVisible = true;
        }
        if (response.edcomResponse !== undefined && this.isEvbPdfVisible) {
            let pdfLink: JSX.Element;
            const blob = this.getDecodedPDF(response.edcomResponse);
            const objectUrl = URL.createObjectURL(blob);
            pdfLink = (
                <b>
                    <a href={objectUrl} target="_blank" rel="noopener noreferrer">
                        Bescheinigung zur Versicherungsbestätigungs-Nummer (PDF)
                    </a>
                </b>
            );
            return pdfLink;
        } else {
            return <></>;
        }
    }

    getDecodedPDF = (response: EdcomResponse) => {// convert evb file from string to blob format
        const decodedPDF = atob(response.auftraege[0].dokument.pdf);
        const arrayBuffer = new ArrayBuffer(decodedPDF.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < decodedPDF.length; i++) {
            uintArray[i] = decodedPDF.charCodeAt(i);
        }
        return new Blob([uintArray], { type: 'application/pdf' });
    }

    render() {
        const {
            evb,
            oberflaecheSperren,
            abschlussErfolgreich,
            abschlussErfolgreichDetails,
            showAbschlussErfolgreichDialog,
            verkaufsprozessart,
            hasErrorMeldungen,
            gewaehlteVersicherung,
            disableAbschlussButton
        } = this.props;

        const headerTitle: string = verkaufsprozessart === Verkaufsprozessart.DIREKTABSCHLUSS ? 'Abschluss' : 'Versand';
        const component: JSX.Element =
            verkaufsprozessart === Verkaufsprozessart.DIREKTABSCHLUSS ? (
                <Direktabschluss
                    hasErrorMeldungen={hasErrorMeldungen}
                    oberflaecheSperren={oberflaecheSperren}
                    gewaehlteVersicherung={gewaehlteVersicherung}
                    disableAbschlussButton={disableAbschlussButton}
                />
            ) : (
                <Versand
                    hasErrorMeldungen={hasErrorMeldungen}
                    oberflaecheSperren={oberflaecheSperren}
                    gewaehlteVersicherung={gewaehlteVersicherung}
                    disableAbschlussButton={disableAbschlussButton}
                />
            );

        const evbNummer: string = evb && evb.nummer ? evb.nummer : '';

        const abschlussErfolgreichHeading: string =
            verkaufsprozessart && Verkaufsprozessart.VOLLANGEBOT === verkaufsprozessart ? 'Angebot erfolgreich versendet' : 'Abschluss erfolgreich';

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">{headerTitle}</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        {component}
                        <Modal open={abschlussErfolgreich && showAbschlussErfolgreichDialog} onDismiss={this.cancelDialogHandler} dismissible>
                            <div style={{ textAlign: 'center' }}>
                                <h1>{abschlussErfolgreichHeading}</h1>
                                <br />
                                {abschlussErfolgreichDetails.length > 0 && <h2>{abschlussErfolgreichDetails}</h2>}
                                {this.renderPdfLink(this.props.evbEdcom)}
                            </div>
                        </Modal>
                    </div>
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-8">
                            <InputRow
                                disabled={oberflaecheSperren}
                                label="eVB-Nummer"
                                placeholder="Wird nach Abschluss des Vorgangs autom. ermittelt"
                                value={evbNummer}
                                readOnly
                            />
                            {this.renderPdfLink(this.props.evbEdcom)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const errorMeldungen = (meldungen: Meldung[]): boolean => meldungen.some((meldung: Meldung) => meldung.schweregrad === Schweregrad.ERROR);

const mapStateToProps = (state: IAppState): AbschlussLinkState => ({
    verkaufsprozessart: state.abschluss.verkaufsprozessart,
    direktAbschluss: state.abschluss.direktAbschluss,
    versandAbschluss: state.abschluss.versandAbschluss,
    evb: state.abschluss.evb,
    abschlussErfolgreich: state.abschluss.abschlussErfolgreich,
    abschlussErfolgreichDetails: state.abschluss.abschlussErfolgreichDetails,
    showAbschlussErfolgreichDialog: state.abschluss.showAbschlussErfolgreichDialog,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert,
    hasErrorMeldungen: errorMeldungen(state.meldungen.meldungenFE) || errorMeldungen(state.meldungen.meldungenBFF) || errorMeldungen(state.meldungen.meldungenOE),
    gewaehlteVersicherung: state.abschluss.direktAbschluss.gewaehlteVersicherung,
    tarifMitSafeDriveGewaehlt: state.abschluss.tarifMitSafeDriveGewaehlt,
    disableAbschlussButton: state.abschluss.disableAbschlussButton,
    evbEdcom : state.evb
});

interface AbschlussLinkState extends AbschlussState {
    verkaufsprozessart: Verkaufsprozessart | null;
    oberflaecheSperren: boolean;
    abschlussErfolgreich: boolean;
    abschlussErfolgreichDetails: string;
    showAbschlussErfolgreichDialog: boolean;
    hasErrorMeldungen: boolean;
    gewaehlteVersicherung: Versicherung | null;
    evbEdcom : EvbState;// this redux state contain mainly edcom response for evb pdf file.
}

interface AbschlussDispatchProps {
    cancelAbschlussErfolgreichDialogHandler: (showDialog: boolean) => void;
}

export interface AbschlussDetailInputProps {
    hasErrorMeldungen?: boolean;
    oberflaecheSperren: boolean;
    gewaehlteVersicherung?: Versicherung | null;
    disableAbschlussButton?: boolean;
    verkaufsprozessart?: Verkaufsprozessart | null;
}

const mapDispatchToProps = (dispatch: Dispatch): AbschlussDispatchProps =>
    bindActionCreators(
        {
            cancelAbschlussErfolgreichDialogHandler: showAbschlussErgebnisDialogSync
        },
        dispatch
    );

export const Abschluss = connect(mapStateToProps, mapDispatchToProps)(AbschlussPresentation);
