import { Meldung } from '../util/fetch/client/MeldungenDto';

export const LADE_MELDUNGEN: string = 'LADE_MELDUNGEN';
export type LADE_MELDUNGEN = typeof LADE_MELDUNGEN;
export const SPEICHERE_MELDUNG_FE: string = 'SPEICHERE_MELDUNG_FE';
export type SPEICHERE_MELDUNG_FE = typeof SPEICHERE_MELDUNG_FE;
export const LOESCHE_MELDUNG_FE: string = 'LOESCHE_MELDUNG_FE';
export type LOESCHE_MELDUNG_FE = typeof LOESCHE_MELDUNG_FE;
export const LOESCHE_MELDUNG_ADRESSE_KORRIGIERT: string = 'LOESCHE_MELDUNG_ADRESSE_KORRIGIERT';
export const LOESCHE_MELDUNGEN_NACH_ABSCHLUSS: string = 'LOESCHE_MELDUNGEN_NACH_ABSCHLUSS';

export interface StoreMeldungenAction {
    type: LADE_MELDUNGEN;
    meldungen: Meldung[];
}

export const storeMeldungenSync = (meldungen: Meldung[]): StoreMeldungenAction => {
    return {
        type: LADE_MELDUNGEN,
        meldungen: meldungen
    };
};

export interface SaveMeldungFeAction {
    type: SPEICHERE_MELDUNG_FE;
    meldung: Meldung;
}

export const saveMeldungFeSync = (meldung: Meldung): SaveMeldungFeAction => {
    return {
        type: SPEICHERE_MELDUNG_FE,
        meldung: meldung
    };
};

export interface DeleteMeldungFeAction {
    type: LOESCHE_MELDUNG_FE;
    code: string;
}

export const deleteMeldungFeSync = (code: string): DeleteMeldungFeAction => {
    return {
        type: LOESCHE_MELDUNG_FE,
        code: code
    };
};

export const deleteMeldungPdsSync = (code: string): DeleteMeldungFeAction => {
    return {
        type: LOESCHE_MELDUNG_ADRESSE_KORRIGIERT,
        code: code
    };
};

export interface ClearMeldungAfterAbschlussAction {
    type: typeof LOESCHE_MELDUNGEN_NACH_ABSCHLUSS;
}

export const clearMeldungenAfterAbschlussSync = (): ClearMeldungAfterAbschlussAction => {
    return {
        type: LOESCHE_MELDUNGEN_NACH_ABSCHLUSS
    };
};
