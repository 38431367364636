import { Einstufungsgrund, Fahrzeugnutzer } from "../util/fetch/offerengine/OfferEngineAngebotDto";
import { ClassificationResponse } from "./ErsteinstufungsReducer";

export function isFirstCarInsuranceQuestionsAsked(
    contextId: Einstufungsgrund | undefined, 
    vehicleUsers: Fahrzeugnutzer[]
): boolean {
    const isNotFirstCarInsurance = 
        contextId === Einstufungsgrund.VERBESSERTE_ZWEITWAGENREGELUNG_04
        || vehicleUsers.includes(Fahrzeugnutzer.FAMILIENMITGLIEDER) 
        || vehicleUsers.includes(Fahrzeugnutzer.SONSTIGE)
    ;

    return !isNotFirstCarInsurance;
}

export function getEinstufungsGrundContextId(einstufungsGrund: ClassificationResponse | undefined) {
    if (!einstufungsGrund) {
        return undefined;
    }

    return einstufungsGrund.context.id as Einstufungsgrund;
}
