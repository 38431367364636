
import { IAppState } from '../app/IAppState';
import { VertragState } from '../versicherungsschutz/VersicherungsschutzReducer';
import { EdcomEvbFileRequestDto } from './fetch/edcom/EdcomEvbFileRequestDto';

export const createEvbFileRequest = (getState: () => IAppState): EdcomEvbFileRequestDto => {
    let pdsId = Object.keys(getState().personen.personen)[0];
    const verkaufsname = getVerkaufsname(getState().versicherungsschutz.vertraege);

    return {
        evb: getState().abschluss.evb.nummer,
        vorname: getState().personen.personen[pdsId].vorname,
        nachname: getState().personen.personen[pdsId].nachname,
        geschlecht: getState().personen.personen[pdsId].anrede,
        verkaufsname: verkaufsname,
        titel: getState().personen.personen[pdsId].titel
    };
};
const getVerkaufsname = (vertraege: VertragState[]): string => {
    const selectedVertrag = vertraege.find((vertrag) => vertrag.ausgewaehlt);
    return selectedVertrag ? selectedVertrag.versicherungsId : 'default value';
};

