import { Dispatch } from 'redux';
import { updateAngebotTopLevelHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { onRejectedStoreTechnischerFehler } from '../technischeFehler/TechnischeFehlerAction';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { UpdateTopLevelRequestDto } from '../util/fetch/offerengine/UpdateTopLevelRequestDto';

export function updateIddIdAsync(businessId: string, iddId: string) {
    return (dispatch: Dispatch) => {
        const payload: UpdateTopLevelRequestDto = {
            beratungsprotokollId: iddId
        };

        return updateAngebotTopLevelHttpRequest(businessId, payload, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreTechnischerFehler(e, dispatch));
    };
}
