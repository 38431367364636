import {
    SpeicherPersonAction,
    AddRolleAction,
    LADE_PERSONEN,
    LoadPersonsAction,
    ROLLE_HINZUFUEGEN,
    DeletePersonAction,
    PERSON_LOESCHEN,
    DeleteRolleAction,
    ROLLE_LOESCHEN,
    ShowPersonLoadingSpinnerAction,
    ZEIGE_LOADINGSPINNER_PERSON,
    PERSON_SPEICHERN
} from './PersonenAction';
import { Adresse, Anrede, MailAdresse, PartnerReferenz, PartnerSystem, PdsPerson } from '../util/fetch/personen/PersonDto';
import { getDateValueOrUndefined } from '../util/ReducerHelper';
import { Bankverbindung, Bonitaet, Rolle, Rollentyp } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';

export interface PersonenState {
    personen: Personen;
    bonitaetspruefungVN: Bonitaet;
    showPersonLoadingSpinner: boolean;
}

export interface Personen {
    [Key: string]: Person; // Key ist die pdsId
}

export interface Person {
    kundennummerErgo: string | null;
    kundennummerParis: string | null;
    pdsId: string;
    geburtsdatum: Date | undefined;
    anrede: Anrede;
    nachname: string;
    vorname: string;
    titel: string;
    mailadressen: MailAdresse[];
    adresse: Adresse;
    bankverbindungen: Bankverbindung[];
    rollen: Rollentyp[];
}

const initialPersonenState = {
    personen: {},
    bonitaetspruefungVN: Bonitaet.NICHT_GEPRUEFT,
    showPersonLoadingSpinner: false
};

type PersonenAction = LoadOfferAction & LoadPersonsAction & SpeicherPersonAction & AddRolleAction & DeleteRolleAction & DeletePersonAction & ShowPersonLoadingSpinnerAction;

export const personenReducer = (state: PersonenState = initialPersonenState, action: PersonenAction): PersonenState => {
    switch (action.type) {
        case LADE_PERSONEN: {
            const personen: Personen = action.personen.reduce(
                (currentPersonen: Personen, person: PdsPerson) => ({
                    ...currentPersonen,
                    [person.personId]: mapPerson(person, state.personen)
                }),
                state.personen
            );

            return {
                ...state,
                personen: personen
            };
        }
        case LADE_ANGEBOT: {
            const personen: Personen = action.angebot.rollen
                ? action.angebot.rollen.reduce(
                      (currentPersonen: Personen, rolle: Rolle) => ({
                          ...currentPersonen,
                          [rolle.personId]: mapPersonMitRollen(rolle.personId, state.personen, action.angebot.rollen)
                      }),
                      state.personen
                  )
                : state.personen;

            return {
                ...state,
                bonitaetspruefungVN: action.angebot.bonitaet,
                personen: personen
            };
        }
        case PERSON_SPEICHERN: {
            return {
                ...state,
                personen: {
                    ...state.personen,
                    [action.person.personId]: mapPerson(action.person, state.personen)
                }
            };
        }
        case PERSON_LOESCHEN: {
            return {
                ...state,
                personen: Object.keys(state.personen)
                    .filter((key: string) => key !== action.pdsId)
                    .reduce((currentPersonen: Personen, currentKey: string) => {
                        currentPersonen[currentKey] = state.personen[currentKey];
                        return currentPersonen;
                    }, {})
            };
        }
        case ROLLE_HINZUFUEGEN: {
            return {
                ...state,
                personen: {
                    ...state.personen,
                    [action.payload.pdsId]: {
                        ...state.personen[action.payload.pdsId],
                        rollen: state.personen[action.payload.pdsId].rollen.concat([action.payload.rollentyp])
                    }
                }
            };
        }
        case ROLLE_LOESCHEN: {
            return {
                ...state,
                personen: {
                    ...state.personen,
                    [action.payload.pdsId]: {
                        ...state.personen[action.payload.pdsId],
                        rollen: state.personen[action.payload.pdsId].rollen.filter((rolle: Rollentyp) => rolle !== action.payload.rollentyp)
                    }
                }
            };
        }
        case ZEIGE_LOADINGSPINNER_PERSON: {
            return {
                ...state,
                showPersonLoadingSpinner: action.showSpinner
            };
        }
        default:
            return state;
    }
};

const mapPerson = (person: PdsPerson, personen: Personen): Person => {
    let kundennummerErgo: string | null = null;
    const ergoPartnerReferenzen: PartnerReferenz[] = person.partnerReferenzen.filter((referenz: PartnerReferenz) => referenz.partnerSystem === PartnerSystem.ergo);
    if (ergoPartnerReferenzen && ergoPartnerReferenzen.length > 0) {
        kundennummerErgo = ergoPartnerReferenzen[0].kundennummer;
    }

    return {
        ...personen[person.personId],
        kundennummerErgo: kundennummerErgo,
        kundennummerParis: person.partnerReferenzen.filter((referenz: PartnerReferenz) => referenz.partnerSystem === PartnerSystem.ergodirekt)[0].kundennummer,
        pdsId: person.personId,
        geburtsdatum: getDateValueOrUndefined(person.geburtsdatum),
        anrede: person.anrede,
        nachname: person.nachname,
        vorname: person.vorname,
        titel: person.titel,
        mailadressen: person.mailAdressen,
        adresse: person.adresse,
        bankverbindungen: person.bankverbindungen,
        rollen: personen[person.personId] && personen[person.personId].rollen ? personen[person.personId].rollen : []
    };
};

const mapPersonMitRollen = (pdsId: string, personen: Personen, rollen: Rolle[]): Person => {
    return {
        ...personen[pdsId],
        kundennummerErgo: personen[pdsId] && personen[pdsId].kundennummerErgo ? personen[pdsId].kundennummerErgo : null,
        kundennummerParis: personen[pdsId] && personen[pdsId].kundennummerParis ? personen[pdsId].kundennummerParis : null,
        pdsId: pdsId,
        geburtsdatum: personen[pdsId] && personen[pdsId].geburtsdatum ? personen[pdsId].geburtsdatum : undefined,
        anrede: personen[pdsId] && personen[pdsId].anrede ? personen[pdsId].anrede : Anrede.Frau,
        nachname: personen[pdsId] && personen[pdsId].nachname ? personen[pdsId].nachname : '',
        vorname: personen[pdsId] && personen[pdsId].vorname ? personen[pdsId].vorname : '',
        titel: personen[pdsId] && personen[pdsId].titel ? personen[pdsId].titel : '',
        mailadressen: personen[pdsId] && personen[pdsId].mailadressen ? personen[pdsId].mailadressen : [],
        adresse: personen[pdsId] && personen[pdsId].adresse ? personen[pdsId].adresse : getDefaultAddress(),
        bankverbindungen: personen[pdsId] && personen[pdsId].bankverbindungen ? personen[pdsId].bankverbindungen : [],
        rollen: mapRollen(rollen, pdsId)
    };
};

const getDefaultAddress = () => ({
    adressZusatz: '',
    hausnummer: '',
    hausnummerZusatz: '',
    land: '',
    ort: '',
    ortZusatz: '',
    postleitzahl: '',
    strasse: ''
});

const mapRollen = (rollen: Rolle[], pdsId: string): Rollentyp[] => {
    let result: Rollentyp[] = [];

    const rollenPdsId: Rolle[] = rollen.filter((rolle: Rolle) => rolle.personId === pdsId);

    if (rollenPdsId && rollenPdsId.length > 0) {
        return rollenPdsId.map((rolle: Rolle) => {
            return rolle.rollentyp;
        });
    }
    return result;
};
