import { HaftpflichtVertrag, TeilkaskoVertrag, TypVertrag, Vertrag, Vertragsart, VollkaskoVertrag } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export const getRegionalklasseHaftpflicht = (vertraege: Vertrag[]): number | null => {
    let vertragHp: HaftpflichtVertrag | null;
    vertragHp = getVertrag(vertraege, 0);

    return vertragHp !== null && vertragHp && vertragHp.vertragsart === Vertragsart.HAFTPFLICHT && vertragHp.typ === TypVertrag.haftpflicht
        ? vertragHp.regionalklasseHaftpflicht
        : null;
};

export const getRegionalklasseTeilkasko = (vertraege: Vertrag[]): number | null => {
    let vertragTk: TeilkaskoVertrag | null;
    vertragTk = getVertrag(vertraege, 1);

    return vertragTk !== null && vertragTk && vertragTk.vertragsart === Vertragsart.KASKO && vertragTk.typ === TypVertrag.teilkasko && vertragTk.regionalklasseTeilkasko
        ? vertragTk.regionalklasseTeilkasko
        : null;
};

export const getRegionalklasseVollkasko = (vertraege: Vertrag[]): number | null => {
    let vertragVk: VollkaskoVertrag | null;
    vertragVk = getVertrag(vertraege, 1);
    return vertragVk !== null && vertragVk && vertragVk.vertragsart === Vertragsart.KASKO && vertragVk.typ === TypVertrag.vollkasko && vertragVk.regionalklasseVollkasko
        ? vertragVk.regionalklasseVollkasko
        : null;
};

const getVertrag = <T extends Vertrag>(vertraege: Vertrag[], vertragsIndex: number): T | null => {
    if (vertraege === undefined || vertragsIndex >= vertraege.length) {
        return null;
    }

    return vertraege[vertragsIndex] as T;
};

export const formatKlasse = (regionalOrTypklasse: number | null): string => {
    return regionalOrTypklasse === null ? ' - ' : regionalOrTypklasse.toString();
};
