import { combineReducers } from 'redux';
import { basisdatenReducer } from './basisdaten/BasisdatenReducer';
import { fahrzeugnutzungReducer } from './fahrzeugnutzung/FahrzeugnutzungReducer';
import { fahrzeugsucheReducer } from './fahrzeugsuche/FahrzeugsucheReducer';
import { meldungenReducer } from './meldungen/MeldungenReducer';
import { technischeFehlerReducer } from './technischeFehler/TechnischeFehlerReducer';
import { personenReducer } from './personen/PersonenReducer';
import { ersteinstufungsserviceReducer } from './ersteinstufungsservice/ErsteinstufungsReducer';
import { bankverbindungReducer } from './bankverbindung/BankverbindungReducer';
import { angebotsgrundReducer } from './angebotsgrund/AngebotsgrundReducer';
import { vorversicherungReducer } from './vorversicherung/VorversicherungReducer';
import { versicherungsschutzReducer } from './versicherungsschutz/VersicherungsschutzReducer';
import { personensucheReducer } from './personensuche/PersonensucheReducer';
import { abschlussReducer } from './abschluss/AbschlussReducer';
import { oberflaecheSperrenReducer } from './app/OberflacheSperrenReducer';
import unauthenticatedReducer from './auth/unauthenticatedReducer'
import businessIdReducer from './app/actions/businessIdReducer'
import { evbReducer } from './evb/EvbReducer';

export const combinedReducers = combineReducers({
    basisdaten: basisdatenReducer,
    fahrzeugsuche: fahrzeugsucheReducer,
    fahrzeug: fahrzeugnutzungReducer,
    meldungen: meldungenReducer,
    technischeFehler: technischeFehlerReducer,
    ersteinstufung: ersteinstufungsserviceReducer,
    personen: personenReducer,
    personensuche: personensucheReducer,
    bankverbindung: bankverbindungReducer,
    angebotsgrund: angebotsgrundReducer,
    versicherungsschutz: versicherungsschutzReducer,
    vorversicherung: vorversicherungReducer,
    abschluss: abschlussReducer,
    oberflaecheSperren: oberflaecheSperrenReducer,
    unauthenticated: unauthenticatedReducer,
    businessId: businessIdReducer,
    evb:evbReducer
});
