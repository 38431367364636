import RadioGroupRow from '@eg/elements/RadioGroupRow';
import SalesmanButton from '@eg/elements/SalesmanButton';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IAppState } from '../../app/IAppState';
import { getPersonMitRolle } from '../../util/PersonenHelper';
import { RadioEvent } from '../../util/UiEventTypes';
import { VERSANDWEGE, createRadioButtons } from '../../util/WertebereicheHelper';
import { Rollentyp, Verkaufsprozessart, Versandweg } from '../../util/fetch/offerengine/OfferEngineAngebotDto';
import { AbschlussDetailInputProps } from '../Abschluss';
import { updateVersandwegSyncAsync, versendeVollangebotAsync } from '../AbschlussAction';
import { ANDERE_EMAIL, WAEHLE_EMAIL } from '../AbschlussReducer';
import { EmailSelection } from '../email/EmailSelection';

interface VersandPresentationProps extends VersandOwnProps, VersandDispatchProps {}

interface VersandPresentationState {
    versandweg: Versandweg | undefined;
}

export class VersandPresentation extends React.Component<VersandPresentationProps, VersandPresentationState> {
    constructor(props: VersandPresentationProps) {
        super(props);
        this.state = {
            versandweg: props.versandweg
        };
    }

    changeVersandweg = (event: RadioEvent) => {
        //@ts-ignore
        const versandweg: Versandweg = event.target.value as Versandweg;
        this.setState(() => ({
            versandweg: versandweg
        }));
        this.props.handleOnChangeVersandweg(versandweg);
    };

    render() {
        const { gewaehlteVersicherung, oberflaecheSperren, handleOnAntragAbschliessenClick, hasErrorMeldungen, setButtonLoading, disableAbschlussButton } = this.props;

        return (
            <div className="esc_grid component-content">
                <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-s-2">
                        <RadioGroupRow label="Versandkanal" name="versandweg" value={this.state.versandweg || ''} disabled={oberflaecheSperren} onChange={this.changeVersandweg}>
                            {createRadioButtons(VERSANDWEGE)}
                        </RadioGroupRow>
                    </div>
                    <div className="esc_col esc_col-s-12">
                        <EmailSelection verkaufsprozessart={Verkaufsprozessart.VOLLANGEBOT} oberflaecheSperren={oberflaecheSperren} />
                    </div>
                    <div className="esc_col esc_col-s-12">
                        <SalesmanButton
                            text="Angebot erstellen"
                            subText=""
                            note=""
                            variant="primary"
                            id="header_button"
                            disabled={oberflaecheSperren || hasErrorMeldungen || gewaehlteVersicherung === null || this.state.versandweg === null || disableAbschlussButton}
                            loading={setButtonLoading}
                            onClick={() => {
                                handleOnAntragAbschliessenClick();
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const scrollToHeader = (): void => {
    const header = document && document.getElementById('header_button');
    header && header.scrollIntoView();
};

const mapStateToProps = (state: IAppState, props: AbschlussDetailInputProps): VersandOwnProps => ({
    versandweg: state.abschluss.versandAbschluss.versandweg || undefined,
    versandmail: state.abschluss.versandmail
        ? state.abschluss.versandmail
        : getPersonMitRolle(state.personen.personen, Rollentyp.VERSICHERUNGSNEHMER)!.mailadressen.length > 0
        ? WAEHLE_EMAIL
        : ANDERE_EMAIL,
    hasErrorMeldungen: props.hasErrorMeldungen,
    oberflaecheSperren: props.oberflaecheSperren,
    gewaehlteVersicherung: props.gewaehlteVersicherung,
    setButtonLoading: state.abschluss.versandAbschluss.setButtonLoading,
    disableAbschlussButton: props.disableAbschlussButton
});

interface VersandDispatchProps {
    handleOnAntragAbschliessenClick: () => void;
    handleOnChangeVersandweg: (versandweg: Versandweg) => void;
}

interface VersandOwnProps extends AbschlussDetailInputProps {
    versandweg: Versandweg | undefined;
    versandmail: string;
    setButtonLoading: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch): VersandDispatchProps => ({
    handleOnChangeVersandweg: (versandweg: Versandweg) => {
        // @ts-ignore
        dispatch(updateVersandwegSyncAsync(versandweg));
    },
    handleOnAntragAbschliessenClick: () => {
        // @ts-ignore
        dispatch(versendeVollangebotAsync());
        // @ts-ignore
        //dispatch(downloadEvbFileAsync());// to send an edcom api request in order to obtain an evb pdf file in string format.
        scrollToHeader();
    }
});

export const Versand = connect(mapStateToProps, mapDispatchToProps)(VersandPresentation);
