import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import InputRow from '@eg/elements/InputRow';
import Button from '@eg/elements/Button';
import SearchIcon from '@eg/elements/components/Icons/SearchIcon';
import { enterKundennummerSync, getParisKundeAsync, selectKundeAsync, showAddressIsInvalidSync } from './PersonensucheAction';
import { InputEvent, InputKeyboardEvent } from '../util/UiEventTypes';
import { Adresse, ParisKunde } from '../util/fetch/personensuche/PersonensucheDto';
import Card from '@eg/elements/Card';
import { isEmpty } from '../util/validate';
import FormRow from '@eg/elements/FormRow';
import LoaderIcon from '@eg/elements/components/Icons/LoaderIcon';
import UnmanagedDetails from '@eg/elements/UnmanagedDetails';
import Modal from '@eg/elements/Modal';

interface PersonensuchePresentationProps {
    businessId: string;
    kundennummer: string;
    showResponse: boolean;
    kunde: ParisKunde | null;
    errormessage: string;
    showPersonIsLoadingSpinner: boolean;
    closePersonensuche: boolean;
    showAddressIsInvalid: boolean;
    addressIsInvalidMessage: string;
    oberflaecheSperren: boolean;
    handleEnterKundennummer: (kundennummer: string) => void;
    handleSubmitSearchParisKunde: (businessId: string, kundennummer: string) => void;
    handleSelectKunde: (businessId: string, kunde: ParisKunde) => void;
    handleResetAddressIsInvalid: () => void;
}

interface PersonensuchePresentationState {
    isOpen: boolean;
}

export class PersonensuchePresentation extends React.Component<PersonensuchePresentationProps, PersonensuchePresentationState> {
    constructor(props: PersonensuchePresentationProps) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidUpdate(prevProps: Readonly<PersonensuchePresentationProps>): void {
        if (prevProps.closePersonensuche !== this.props.closePersonensuche && this.props.closePersonensuche) {
            this.setState(() => ({
                isOpen: false
            }));
        }
    }

    render() {
        const {
            businessId,
            kundennummer,
            showResponse,
            kunde,
            errormessage,
            showPersonIsLoadingSpinner,
            showAddressIsInvalid,
            addressIsInvalidMessage,
            oberflaecheSperren,
            handleEnterKundennummer,
            handleSubmitSearchParisKunde,
            handleSelectKunde,
            handleResetAddressIsInvalid
        } = this.props;

        return (
            <UnmanagedDetails
                summary={<h2 className="esc_h4">Personensuche</h2>}
                isOpen={this.state.isOpen}
                onToggleOpen={() => this.setState((prevState: PersonensuchePresentationState) => ({ isOpen: !prevState.isOpen }))}
            >
                <InputRow
                    label="Kundennummer"
                    value={kundennummer}
                    disabled={oberflaecheSperren}
                    maxLength={8}
                    onChange={(e: InputEvent) => handleEnterKundennummer(e.currentTarget.value)}
                    onKeyPress={(e: InputKeyboardEvent) => e.key === 'Enter' && !isEmpty(e.currentTarget.value) && handleSubmitSearchParisKunde(businessId, e.currentTarget.value)}
                    className="personensuche"
                />
                <Button
                    variant="primary"
                    iconLeft={SearchIcon}
                    disabled={isEmpty(kundennummer) || oberflaecheSperren}
                    onClick={() => handleSubmitSearchParisKunde(businessId, kundennummer)}
                >
                    Partner suchen
                </Button>
                {showPersonIsLoadingSpinner && (
                    <FormRow label={'Paris-Kunde wird gesucht'}>
                        <LoaderIcon spinning width={40} height={40} style={{ marginTop: 5 }} />
                    </FormRow>
                )}
                {showResponse &&
                    (kunde ? (
                        <div className="personensuche-personenanzeige">
                            <Button disabled={oberflaecheSperren} onClick={() => handleSelectKunde(businessId, kunde)}>
                                <div>
                                    <span>
                                        <b>
                                            {kunde.liste[0].person[0].vorname} {kunde.liste[0].person[0].nachname}
                                        </b>
                                    </span>
                                    <span style={{ float: 'right' }}>{kunde.kundennummer}</span>
                                </div>
                                <div>
                                    <small>
                                        {getAdresse(kunde).strasse} {getAdresse(kunde).hausnummer} {getAdresse(kunde).hausnummerzusatz}
                                        <br />
                                        {getAdresse(kunde).postleitzahl} {getAdresse(kunde).ort}
                                    </small>
                                </div>
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Card padding="small" style={{ color: '#bf1528', marginTop: 20 }} accentColor="#bf1528">
                                <div style={{ paddingLeft: 20 }}>{errormessage}</div>
                            </Card>
                        </div>
                    ))}

                <Modal open={showAddressIsInvalid} dismissible backdropDismissesModal onDismiss={handleResetAddressIsInvalid}>
                    {addressIsInvalidMessage}
                </Modal>
            </UnmanagedDetails>
        );
    }
}

const getAdresse = (kunde: ParisKunde): Adresse => {
    return kunde.liste[0].adressen.find((adresse: Adresse) => adresse.hauptadresse) || kunde.liste[0].adressen[0];
};

const mapStateToProps = (state: IAppState) => ({
    businessId: state.basisdaten.businessId,
    kundennummer: state.personensuche.kundennummer,
    showResponse: state.personensuche.showResponse,
    kunde: state.personensuche.kunde,
    errormessage: state.personensuche.errormessage,
    showPersonIsLoadingSpinner: state.personensuche.showPersonIsLoadingSpinner,
    closePersonensuche: state.personensuche.closePersonensuche,
    showAddressIsInvalid: state.personensuche.showAddressIsInvalid,
    addressIsInvalidMessage: state.personensuche.addressIsInvalidMessage,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich || state.oberflaecheSperren.angebotsgrundBisherigesFahrzeugBeiErgoVersichert
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleEnterKundennummer: (kundennummer: string) => {
        dispatch(enterKundennummerSync(kundennummer));
    },
    handleSubmitSearchParisKunde: (businessId: string, kundennummer: string) => {
        //@ts-ignore
        dispatch(getParisKundeAsync(businessId, kundennummer));
    },
    handleSelectKunde: (businessId: string, kunde: ParisKunde) => {
        //@ts-ignore
        dispatch(selectKundeAsync(businessId, kunde));
    },
    handleResetAddressIsInvalid: () => {
        //@ts-ignore
        dispatch(showAddressIsInvalidSync(false));
    }
});

export const Personensuche = connect(mapStateToProps, mapDispatchToProps)(PersonensuchePresentation);
